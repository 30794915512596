import { connect } from 'react-redux';
import { fetchSurvey, fetchSurveyPages, postSurveyAnswers } from '../../actions/api';
import { updateHeader, updateSurveyAnswers } from '../../actions/local';
import Survey from './Survey';
import Answered from './Answered';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';

import {
  selectCurrentSurvey,
  selectFilteredPages,
  selectFilteredAnswers
} from '../../selectors/surveys';
import { selectUser } from '../../selectors/user';
import { selectGlobal } from '../../selectors/global';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class SurveyContainer extends React.Component {
  state = {
    answered: false,
    isSending: false
  };

  componentDidMount() {
    const { appId, eventId, surveyId } = this.props.match.params;
    this.getAPiData(this.props.match.params);
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'survey',
      viewableId: +surveyId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { surveyId } = this.props.match.params;
    const oldSurveyId = prevProps.match.params.surveyId;
    if (surveyId !== oldSurveyId) {
      this.getAPiData(this.props.match.params);
    }
    if (
      this.props.survey &&
      prevProps.survey &&
      this.props.survey.reset_count !== prevProps.survey.reset_count
    ) {
      this.refreshData();
      let surveyAnswers = {};
      surveyAnswers[surveyId] = { currentPage: 0, answers: {} };
      this.props.updateSurveyAnswers(surveyAnswers);
      this.setState({ answered: false });
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }
  }

  getAPiData({ eventId, surveyId }) {
    this.props.fetchSurvey({
      event_id: eventId,
      survey_id: surveyId
    });

    this.props.fetchSurveyPages({
      event_id: eventId,
      survey_id: surveyId
    });
  }

  refreshData = event => {
    this.getAPiData(this.props.match.params);
  };

  goToNextPage = () => {
    this.changePage(this.props.currentAnswers.currentPage + 1);
  };

  goToPrevPage = () => {
    this.changePage(this.props.currentAnswers.currentPage - 1);
  };

  changePage = currentPage => {
    let surveyAnswers = {};
    surveyAnswers[this.props.match.params.surveyId] = {
      ...this.props.currentAnswers,
      ...{ currentPage }
    };
    this.props.updateSurveyAnswers(surveyAnswers);
  };

  isPageValid = () => {
    const currentAnswers = this.props.currentAnswers;
    const currentPage = currentAnswers.currentPage;
    const answers = currentAnswers.answers;
    const questions = this.props.pages[currentPage].data;
    let requiredQuestions = questions.filter(question => question.is_required);

    for (const question of requiredQuestions) {
      if (!answers[question.id]) {
        return false;
      } else if (
        ['checkbox', 'radio', 'select'].indexOf(question.type) !== -1 &&
        (!answers[question.id].options || !answers[question.id].options.length)
      ) {
        return false;
      } else if (['inputtext'].indexOf(question.type) !== -1 && !answers[question.id].value) {
        return false;
      }
    }

    let maxChoicesQuestions = questions.filter(question => question.max_choices);
    for (const question of maxChoicesQuestions) {
      if (answers[question.id] && answers[question.id].options.length > question.max_choices) {
        return false;
      }
    }

    return true;
  };

  updateAnswer = (questionId, value, type) => {
    let surveyAnswers = {};
    let newAnswer = {};
    let options = [];

    if (['checkbox', 'radio', 'select'].indexOf(type) !== -1) {
      if (type === 'checkbox') {
        options = this.props.currentAnswers.answers[questionId]
          ? [...this.props.currentAnswers.answers[questionId].options]
          : [];

        const index = options.indexOf(+value);

        if (index !== -1) {
          options.splice(index, 1);
        } else {
          options.push(+value);
        }
      } else if (['radio', 'select'].indexOf(type) !== -1) {
        options.push(+value);
      }

      newAnswer[questionId] = {
        question_id: questionId,
        options
      };
    } else {
      newAnswer[questionId] = {
        question_id: questionId,
        value
      };
    }

    const answers = { ...this.props.currentAnswers.answers, ...newAnswer };

    surveyAnswers[this.props.match.params.surveyId] = {
      ...this.props.currentAnswers,
      answers
    };

    this.props.updateSurveyAnswers(surveyAnswers);
  };

  sendResults = () => {
    const { eventId, surveyId } = this.props.match.params;
    const answers = Object.values(this.props.currentAnswers.answers);

    this.setState({
      isSending: true
    });

    this.props.postSurveyAnswers({
      event_id: eventId,
      survey_id: surveyId,
      answers,
      done: () => {
        this.refreshData();
        let surveyAnswers = {};
        surveyAnswers[surveyId] = { currentPage: 0, answers: {} };
        this.props.updateSurveyAnswers(surveyAnswers);
        this.setState({ answered: true });
      },
      always: () => {
        this.setState({
          isSending: false
        });
      }
    });
  };

  voteAgain = () => {
    this.setState({ answered: false });
  };

  render() {
    if (!this.props.survey) {
      return null;
    }

    const { goToNextPage, goToPrevPage, sendResults, updateAnswer, isPageValid, isSending } = this;
    const { survey, pages, theme, t, currentAnswers } = this.props;
    const matchParams = this.props.match.params;
    const answers = currentAnswers.answers;
    const currentPage = currentAnswers.currentPage;
    const { offline } = this.props.global;

    return survey.is_open && !survey.answered && !this.state.answered ? (
      <Survey
        {...{
          survey,
          pages,
          theme,
          t,
          matchParams,
          currentPage,
          goToNextPage,
          goToPrevPage,
          updateAnswer,
          isPageValid,
          isSending,
          answers,
          sendResults,
          offline
        }}
      />
    ) : !survey.is_open ? (
      <ClosedService
        icon={getServiceIconByType(survey.custom_icon || 'survey')}
        name={survey.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
      />
    ) : survey.answered || this.state.answered ? (
      <Answered
        icon={getServiceIconByType(survey.custom_icon || 'survey')}
        name={survey.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
        canVoteAgain={!survey.answered}
        voteAgain={this.voteAgain}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchSurvey: fetchSurvey,
  fetchSurveyPages: fetchSurveyPages,
  postSurveyAnswers: postSurveyAnswers,
  updateSurveyAnswers: updateSurveyAnswers
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    survey: selectCurrentSurvey(state, ownProps),
    pages: selectFilteredPages(state, ownProps),
    currentAnswers: selectFilteredAnswers(state, ownProps),
    global: selectGlobal(state, ownProps)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyContainer);
