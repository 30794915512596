import { SUCCESS, FETCH_FAVORITES, POST_FAVORITE, DELETE_FAVORITE } from '../constants/actionApi';
import { UPDATE_FAV_MODEL } from '../constants/actionLocal';
import { addOrUpdateItemsInState, removeItemInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.favorites, { type, payload }) => {
  switch (type) {
    case FETCH_FAVORITES + SUCCESS:
    case POST_FAVORITE + SUCCESS:
      if (payload.params.type === 'exist') {
        return state;
      }
      return addOrUpdateItemsInState(state, payload.data);
    case UPDATE_FAV_MODEL:
      if (!payload.data.is_favorite && payload.favorite_id) {
        return removeItemInState(state, payload.favorite_id);
      } else {
        return state;
      }
    case DELETE_FAVORITE + SUCCESS:
      const favId = Object.keys(state).find(favKey => {
        return state[favKey].model.id === payload.params.favorite_id;
      });
      if (favId) {
        return removeItemInState(state, favId);
      } else {
        return state;
      }
    default:
      return state;
  }
};
