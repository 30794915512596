import Button from '../../../components/shared/Button';
import Modal from '../../../components/modal/Modal';
import React from 'react';

const ModalOptions = props => {
  const {
    onClose,
    onClickDelete,
    onClickUpdateStatus,
    onClickEdit,
    open,
    t,
    theme,
    user,
    meeting
  } = props;
  return (
    <Modal onClose={onClose} open={open}>
      {meeting.owner.id === user.id ? (
        <React.Fragment>
          <Button btntype={'primary'} onClick={onClickEdit} display="block" width="100%">
            {t('btn_edit')}
          </Button>
          <Button btntype={'danger'} onClick={onClickDelete} display="block" width="100%">
            {t('btn_delete')}
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Button
            display="block"
            width="100%"
            data-status={'declined'}
            onClick={onClickUpdateStatus}
            color={meeting.viewer_status === 'declined' ? '#FFF' : '#000'}
            backgroundColor={meeting.viewer_status === 'declined' ? '#D0021B' : theme.lightGrey}
          >
            {t('btn_refuse')}
          </Button>
          <Button
            display="block"
            width="100%"
            data-status={'tentative'}
            onClick={onClickUpdateStatus}
            color={meeting.viewer_status === 'tentative' ? '#FFF' : '#000'}
            backgroundColor={meeting.viewer_status === 'tentative' ? '#F2A900' : theme.lightGrey}
          >
            {t('btn_tentative')}
          </Button>
          <Button
            display="block"
            width="100%"
            data-status={'accepted'}
            onClick={onClickUpdateStatus}
            color={meeting.viewer_status === 'accepted' ? '#FFF' : '#000'}
            backgroundColor={meeting.viewer_status === 'accepted' ? '#7ED321' : theme.lightGrey}
          >
            {t('btn_accept')}
          </Button>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default ModalOptions;
