import { getFullName, getJobWithCompany } from '../../helpers/format';

import CardIcon from '../../components/shared/card/CardIcon';
import FlexParent from '../../components/shared/flex/FlexParent';
import IconMail from '../../components/icons/IconMail';
import IconPoi from '../../components/icons/IconPoi';
import IconWebsite from '../../components/icons/IconWebsite';
import React from 'react';
import InfoLine from '../../components/shared/InfoLine';
import SocialLinks from '../../components/shared/SocialLinks';
import LinkedSessions from '../../components/shared/LinkedSessions';
import { DownloadVCard } from '../../components/shared/ListItemDetails';
import Star from '../../components/shared/fav/Star';
import styled from 'styled-components';

import {
  ItemName,
  ItemSubName,
  FavItem,
  Description,
  VipIcon
} from '../../components/shared/ListItemDetails';

const Back = styled.div`
  padding-top: 20px;
  color: ${props => props.theme.mainColor};
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
`;

class Speaker extends React.Component {
  componentDidMount() {
    this.props.fetchSpeaker();
  }

  componentDidUpdate(prevProps) {
    if (this.props.speakerId !== prevProps.speakerId) {
      this.props.fetchSpeaker();
    }
  }

  onClickBackBtn = event => {
    window.history.back();
  };


  onClickDownloadVCard = event => {
    const { t, speaker } = this.props;

    if (window.cordova && navigator.contacts) {
      event.stopPropagation();
      event.preventDefault();

      let contact = navigator.contacts.create();

      contact.displayName = `${speaker.first_name} ${speaker.last_name}`;
      let name = new window.ContactName();
      name.givenName = speaker.first_name;
      name.familyName = speaker.last_name;
      contact.name = name;

      let emails = [new window.ContactField('work', speaker.email, false)];
      contact.emails = emails;

      let urls = [];
      if (speaker.website_url) {
        urls.push(new window.ContactField('work', speaker.website_url, false));
      }
      if (speaker.linkedin_url) {
        urls.push(new window.ContactField('work', speaker.linkedin_url, false));
      }
      if (speaker.facebook_url) {
        urls.push(new window.ContactField('work', speaker.facebook_url, false));
      }
      if (speaker.twitter_url) {
        urls.push(new window.ContactField('work', speaker.twitter_url, false));
      }
      if (urls.length) {
        contact.urls = urls;
      }

      if (speaker.company) {
        let organizations = [
          new window.ContactOrganization(false, 'work', speaker.company, null, null)
        ];
        contact.organizations = organizations;
      }

      contact.save(
        function() {
          alert(t('added_to_contacts'));
        },
        function() {}
      );
    }
  };


  render() {
    if (!this.props.speaker) {
      return null;
    }
    const { history, theme, t, timezone, speaker, matchParams, isInAppLive } = this.props;
    const { appId, eventId } = matchParams;

    const {
      avatar_url,
      biography,
      company,
      company_website_url,
      country_name,
      email,
      facebook_url,
      first_name,
      is_vip,
      job_title,
      last_name,
      linkedin_url,
      sessions,
      title,
      twitter_url,
      website_url,
      vcard_url
    } = speaker || {};

    return (
      <div>
        {isInAppLive ? <Back onClick={this.onClickBackBtn}>{t('Back')}</Back> : null}
        <CardIcon
          margin={'0 0 20px'}
          padding={'45px 20px 20px'}
          image={avatar_url}
          theme={theme}
          logoBorderRadius={'50%'}
          withShadow={true}
        >
          <FavItem>
            <Star
              {...{ theme }}
              size={'40px'}
              model={speaker}
              type={'speaker'}
              matchParams={matchParams}
            />
          </FavItem>

          <ItemName>
            <FlexParent justifyContent={'center'} alignItems={'center'}>
              {getFullName(first_name, last_name, title)}
              <VipIcon isVip={is_vip} width={'25px'} height={'25px'} theme={theme} />
            </FlexParent>
          </ItemName>

          <ItemSubName>{getJobWithCompany(company, job_title)}</ItemSubName>

          <SocialLinks
            linkedinUrl={linkedin_url}
            facebookUrl={facebook_url}
            twitterUrl={twitter_url}
          />

          <InfoLine icon={IconPoi} label={country_name} iconColor={theme.ashesGrey} />
          <InfoLine icon={IconMail} label={email} iconColor={theme.ashesGrey} type="email" />
          <InfoLine
            icon={IconWebsite}
            label={website_url}
            iconColor={theme.ashesGrey}
            type="link"
          />
          <InfoLine
            icon={IconWebsite}
            label={company_website_url}
            iconColor={theme.ashesGrey}
            type="link"
          />

          <DownloadVCard href={vcard_url} onClick={this.onClickDownloadVCard} download>
            {t('add_to_contact')}
          </DownloadVCard>

          <Description description={biography} heading={t('biography')} />
          <LinkedSessions
            sessions={sessions || []}
            heading={t('sessions')}
            appId={appId}
            eventId={eventId}
            history={history}
            timezone={timezone}
            isInAppLive={isInAppLive}
          />
        </CardIcon>
      </div>
    );
  }
}

export default Speaker;
