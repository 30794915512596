import React from 'react';
import Card from '../../../components/shared/card/Card';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import { CONVERSATION_DETAILS } from '../../../constants/app';
import { ListItemTitle, ListItemShadow } from '../../../components/shared/ListItem';
import styled from 'styled-components';
import { getTimeFromNow } from '../../../helpers/date';

const Notif = styled.span`
  border-radius: 50%;
  background-color: #d0021b;
  color: white;
  font-weight: 700;
  padding: 0;
  font-size: 1rem;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
`;

const ConversationTitle = styled(ListItemTitle)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ConversationSubtitle = styled(FlexParent)`
  color: ${props => props.theme.ashesGrey};
  hyphens: auto;
  margin-top: 3px;
  word-break: break-all;
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const Avatar = styled.img`
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
`;

const SubAvatar = styled.img`
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  bottom: -4px;
  right: -4px;
  border: 1px solid white;
  border-radius: 50%;
  z-index: 6;
`;

const LastMessageText = styled(FlexItem)`
  font-size: 1.3rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const LastMessageDate = styled.div`
  font-size: 1.1rem;
  white-space: nowrap;
`;

class ConversationItem extends React.Component {
  onClickConversation = () => {
    this.props.updateView({ view: CONVERSATION_DETAILS, id: this.props.conversation.id });
  };

  render() {
    const { conversation, user, t, unReadMessages } = this.props;

    if (!conversation) {
      return null;
    }

    let title = conversation.description || null;

    if (title === null) {
      let conversationAttendees = conversation.attendees.filter(attendee => {
        return attendee.id !== user.id;
      });

      if (conversation.owner.id !== user.id) {
        conversationAttendees.push(conversation.owner);
      }

      title = conversationAttendees
        .map(attendee => {
          return attendee.first_name;
        })
        .sort()
        .join(', ');
    }

    let avatarUrl = '';
    let subAvatarUrl = '';
    let attendeeName = '';
    let lastMessageDate = '';
    let lastMessageText = '';

    // Logged user is the owner
    if (!conversation.attendees.length) {
      avatarUrl = conversation.owner.avatar_url;
    } else if (conversation.owner.id === user.id) {
      avatarUrl = conversation.attendees[0].avatar_url;
      attendeeName = conversation.attendees[0].name;
    } else {
      avatarUrl = conversation.owner.avatar_url;
      attendeeName = conversation.owner.name;
    }

    if (conversation.attendees.length > 1) {
      subAvatarUrl = conversation.attendees[1].avatar_url;
    }

    if (conversation.latest_message) {
      const text =
        conversation.latest_message.attendee.id === user.id
          ? t('me')
          : conversation.latest_message.attendee.first_name;

      lastMessageText = t('text_colon', { text });
      lastMessageText += ` ${conversation.latest_message.text || t('latest_message_picture')}`;

      lastMessageDate = ` · ${getTimeFromNow(conversation.latest_message.created_at)}`;
    }

    return (
      <Card margin={'0 0 20px'} padding={'10px'} onClick={this.onClickConversation}>
        <FlexParent justifyContent={'space-between'}>
          <AvatarContainer>
            <Avatar src={avatarUrl} alt={attendeeName} />
            {subAvatarUrl ? <SubAvatar src={subAvatarUrl} /> : null}
            <ListItemShadow />
          </AvatarContainer>

          <FlexItem grow={'1'} flex={'1'} width={'0'}>
            <ConversationTitle>
              {title}{' '}
              {unReadMessages > 0 && <Notif>{unReadMessages > 9 ? '9+' : unReadMessages}</Notif>}
            </ConversationTitle>
            <ConversationSubtitle>
              <LastMessageText grow={'1'}>{lastMessageText}</LastMessageText>
              <LastMessageDate>{lastMessageDate}</LastMessageDate>
            </ConversationSubtitle>
          </FlexItem>
        </FlexParent>
      </Card>
    );
  }
}

export default ConversationItem;
