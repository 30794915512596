import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M22.62,2.04h-1.78V1.33c0-0.73-0.6-1.33-1.33-1.33c-0.73,0-1.33,0.59-1.33,1.33 v0.71H6.77V1.33C6.77,0.59,6.18,0,5.45,0S4.12,0.59,4.12,1.33v0.71H2.34C1.6,2.04,1,2.64,1,3.38v20.28C1,24.4,1.6,25,2.34,25h20.28 c0.74,0,1.34-0.6,1.34-1.34V3.38C23.96,2.64,23.36,2.04,22.62,2.04z M19,2.44V1.33c0-0.29,0.23-0.52,0.52-0.52 c0.29,0,0.52,0.23,0.52,0.52v1.11h0V3.9c0,0.29-0.23,0.52-0.52,0.52S19,4.19,19,3.9L19,2.44z M4.93,1.33 c0-0.29,0.23-0.52,0.52-0.52c0.29,0,0.52,0.23,0.52,0.52v1.11h0V3.9c0,0.29-0.23,0.52-0.52,0.52c-0.29,0-0.52-0.23-0.52-0.52 l0-1.87V1.33z M11.12,19.69H6.38c-0.35,0-0.63-0.28-0.63-0.63c0-1.49,1.22-2.29,2.41-3.06c1.11-0.72,2.16-1.41,2.16-2.6 c0-0.87-0.6-1.41-1.56-1.41c-0.94,0-1.28,0.47-1.52,0.98c-0.11,0.25-0.33,0.39-0.58,0.39c-0.35,0-0.63-0.28-0.63-0.63 c0-0.07,0.01-0.14,0.04-0.22c0.37-1.12,1.36-1.77,2.69-1.77c1.75,0,2.88,1.04,2.88,2.64c0,1.86-1.65,2.93-2.97,3.78 C8,17.61,7.35,18.03,7.15,18.42h3.97c0.35,0,0.63,0.28,0.63,0.63C11.75,19.4,11.47,19.69,11.12,19.69z M15.87,19.86 c-1.96,0-3.12-1.03-3.12-2.75c0-0.98,0.43-1.77,1.19-2.22c-0.55-0.42-0.82-1.01-0.82-1.75c0-1.52,1-2.39,2.76-2.39 s2.75,0.87,2.75,2.39c0,0.74-0.28,1.32-0.82,1.75C18.57,15.33,19,16.12,19,17.1C19,18.83,17.83,19.86,15.87,19.86z M22,6.97H3V6h19 V6.97z" />
      <path d="M15.87,14.34c0.66,0,1.44-0.21,1.44-1.2c0-0.76-0.49-1.15-1.44-1.15 c-0.96,0-1.45,0.39-1.45,1.15C14.43,14.13,15.21,14.34,15.87,14.34z" />
      <path d="M15.87,15.57c-1.22,0-1.81,0.5-1.81,1.53c0,1.25,0.99,1.52,1.81,1.52 s1.81-0.26,1.81-1.52C17.69,16.07,17.09,15.57,15.87,15.57z" />
    </g>
  </svg>
);
