import React from 'react';
import Scrollable from '../../../components/shared/Scrollable';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import Card from '../../../components/shared/card/Card';
import styled from 'styled-components';
import { ATTENDEE_DETAILS, TAB_ATTENDEES } from '../../../constants/app';

const Filer = styled(FlexItem)`
  border-bottom: 3px solid #fff;
  padding: 15px 0 0;
`;

const ItemHeading = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 0 15px;
  text-align: center;
`;

const AttendeeName = styled.div`
  text-align: center;
  font-size: 1.2rem;
  padding: 5px 10px;
`;

export const AttendeeAvatar = styled.img`
  display: block;
  width: 50px;
  min-width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0 auto;
  border-radius: ${props => props.borderRadius || '50%'};
`;

class Favorites extends React.Component {
  onClickAttendee = event => {
    this.props.updateView({
      tab: TAB_ATTENDEES,
      view: ATTENDEE_DETAILS,
      id: +event.currentTarget.dataset.id
    });
  };

  render() {
    const { t, favoritesAttendees, favoritesAttendeesIds } = this.props;
    return (
      <Card margin={'20px 0'} padding={'25px 20px'}>
        <ItemHeading>{t('favorite_attendees')}</ItemHeading>
        <Scrollable>
          <FlexParent>
            <Filer grow={1}>&nbsp;</Filer>
            {favoritesAttendeesIds.map(key => {
              const attendee = favoritesAttendees[key].model;
              return (
                <FlexItem
                  key={attendee.id}
                  width={window.matchMedia('(min-width: 480px)').matches ? '22%' : '40%'}
                  onClick={this.onClickAttendee}
                  data-id={attendee.id}
                >
                  <AttendeeAvatar src={attendee.avatar_url} alt={attendee.name} />
                  <AttendeeName>{attendee.name}</AttendeeName>
                </FlexItem>
              );
            })}
            <Filer grow={1}>&nbsp;</Filer>
          </FlexParent>
        </Scrollable>
      </Card>
    );
  }
}

export default Favorites;
