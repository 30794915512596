import React from 'react';
import styled from 'styled-components';

/*
PROPS
--------------------
backgroundColor
border
borderRadius
bottom
display
height
left
lineHeight
margin
onClick
padding
position
right
top
transform
transition
width
--------------------
*/
const IconContainer = styled.div`
  background-color: ${props => props.backgroundColor || 'transparent'};
  margin: ${props => props.margin || '0'};
  padding: ${props => props.padding || '0'};
  width: ${props => props.width || '25px'};
  min-width: ${props => props.width || '25px'};
  height: ${props => props.height || '25px'};
  border-radius: ${props => props.borderRadius || 'initial'};
  z-index: 5;
  transform: ${props => props.transform || 'initial'};
  transition: ${props => props.transition || 'initial'};
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  display: ${props => props.display || 'block'};
  position: ${props => props.position || 'relative'};
  top: ${props => props.top || 'initial'};
  bottom: ${props => props.bottom || 'initial'};
  left: ${props => props.left || 'initial'};
  right: ${props => props.right || 'initial'};
  border: ${props => props.border || 'initial'};
  line-height: ${props => props.lineHeight || 'initial'};
  vertical-align: ${props => props.verticalAlign || 'initial'};
  justify-content: ${props => props.justifyContent || 'initial'};
  align-items: ${props => props.alignItems || 'initial'};
`;

const Icon = ({ icon: SvgIcon, ...props }) => {
  return (
    SvgIcon && (
      <IconContainer {...props}>
        <SvgIcon color={props.color || '#000'} viewBox={props.viewBox} />
      </IconContainer>
    )
  );
};

export default Icon;
