import React from 'react';
import AttendeeItem from './AttendeeItem';
import InfiniteScroll from '../../../components/shared/InfiniteScroll';
import Favorites from './Favorites';

class AttendeesList extends React.Component {
  componentDidMount() {
    this.props.fetchAttendees({ reset: true });
    this.props.fetchFavorites({ reset: true, type: 'attendee' });
  }

  componentDidUpdate(prevProps) {
    if (this.props.whosHere.id !== prevProps.whosHere.id) {
      this.props.fetchAttendees({ reset: true });
      this.props.fetchFavorites({ reset: true, type: 'attendee' });
    }
  }

  render() {
    const {
      attendees,
      attendeesIds,
      favoritesAttendees,
      favoritesAttendeesIds,
      theme,
      isLoadingAttendees,
      hasMoreAttendees,
      matchParams,
      history,
      updateView,
      user,
      fetchAttendees,
      presenceIds,
      t,
      whosHere
    } = this.props;

    return (
      <InfiniteScroll
        loadMore={fetchAttendees}
        hasMore={hasMoreAttendees}
        isLoading={isLoadingAttendees}
      >
        {favoritesAttendeesIds.length && whosHere.is_starable ? (
          <Favorites {...{ t, favoritesAttendees, favoritesAttendeesIds, updateView }} />
        ) : null}

        {attendeesIds.map(key => {
          const attendee = attendees[key];
          return (
            <AttendeeItem
              key={attendee.id}
              id={attendee.id}
              isOnline={presenceIds.indexOf(attendee.id) !== -1}
              {...{ t, attendee, theme, matchParams, history, user, updateView, whosHere }}
            />
          );
        })}
      </InfiniteScroll>
    );
  }
}

export default AttendeesList;
