import EventItem from './EventItem';
import React from 'react';
import styled from 'styled-components';
import Tabs from '../../components/shared/tabs/Tabs';
import { TAB_PAST, TAB_CURRENT_WEEK, TAB_UPCOMING } from '../../constants/app';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import Card from '../../components/shared/card/Card';
import SwipeContainer from '../../components/swipe/SwipeContainer';
import { isDarkBgColor } from '../../helpers/helpers';
import Button from '../../components/shared/Button';
import Icon from '../../components/icons/Icon';
import IconCloseCross from '../../components/icons/IconCloseCross';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled.div`
  position: relative;
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

class App extends React.Component {
  state = {
    selectedId: null,
    loginCardHidden: false
  };

  componentDidMount() {
    this.props.fetchEvents({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.appId !== prevProps.appId || this.props.currentTab !== prevProps.currentTab) {
      this.props.fetchEvents({ reset: true });
    }
  }

  onSwipeEnd = event => {
    const pos = this.tabs
      .map(function(e) {
        return e.slug;
      })
      .indexOf(this.props.currentTab);

    if (event.x > 150 && this.tabs[pos - 1]) {
      this.props.updateTab({ tab: this.tabs[pos - 1].slug });
    } else if (event.x < -150 && this.tabs[pos + 1]) {
      this.props.updateTab({ tab: this.tabs[pos + 1].slug });
    }
  };

  hasSelected = eventId => {
    this.setState({ selectedId: eventId });
  };

  onClickLogin = () => {
    const { history, appId } = this.props;
    history.push(`/apps/${appId}/signin`);
  };

  onClickCloseLoginCard = () => {
    this.setState({
      loginCardHidden: true
    });
  };

  render() {
    const {
      t,
      theme,
      history,
      headerHeight,
      events,
      eventsIds,
      currentTab,
      updateTab,
      setComponentFixedTopHeight,
      componentFixedTopHeight,
      hasMoreEvents,
      isLoadingEvents,
      fetchEvents,
      header,
      user,
      global,
      app
    } = this.props;

    this.tabs = [
      {
        slug: TAB_PAST,
        label: t('filter_past')
      },
      {
        slug: TAB_CURRENT_WEEK,
        label: t('filter_current')
      },
      {
        slug: TAB_UPCOMING,
        label: t('filter_coming')
      }
    ];

    return (
      <SwipeContainer onSwipeEnd={this.onSwipeEnd}>
        {!header.showSearchBar && !this.state.selectedId ? (
          <Tabs
            {...{
              t,
              theme,
              headerHeight,
              currentTab,
              updateTab,
              setComponentFixedTopHeight
            }}
            tabs={this.tabs}
          />
        ) : null}

        <Container componentFixedTopHeight={componentFixedTopHeight}>
          {!this.state.selectedId && (
            <AnimatePresence>
              {!user && !this.state.loginCardHidden && (
                <motion.div
                  key="login_card"
                  initial={false}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <Card
                    margin={'0 0 15px 0'}
                    padding={'15px'}
                    textAlign={'center'}
                    backgroundColor={app.color}
                    color={isDarkBgColor(app.color) ? 'white' : theme.darkGrey}
                    fontWeight={'bold'}
                    position={'relative'}
                  >
                    <Icon
                      icon={IconCloseCross}
                      position={'absolute'}
                      top={'2px'}
                      right={'2px'}
                      width={'20px'}
                      height={'20px'}
                      color={isDarkBgColor(app.color) ? 'white' : theme.darkGrey}
                      padding={'6px'}
                      onClick={this.onClickCloseLoginCard}
                    />

                    {t('app_login')}

                    <Button
                      btntype={'white'}
                      margin={'10px auto 0 auto'}
                      display={'block'}
                      onClick={this.onClickLogin}
                    >
                      {t('signin')}
                    </Button>
                  </Card>
                </motion.div>
              )}
            </AnimatePresence>
          )}

          <InfiniteScroll
            loadMore={fetchEvents}
            hasMore={hasMoreEvents}
            isLoading={isLoadingEvents && !this.state.selectedId}
          >
            {!eventsIds.length && !isLoadingEvents ? (
              <Card padding={'15px'} textAlign={'center'}>
                {t(
                  header.showSearchBar && global.searchValue.length >= 3
                    ? 'no_event_found'
                    : t(
                        header.showSearchBar && global.searchValue.length < 3
                          ? 'search_help'
                          : 'no_event'
                      )
                )}
              </Card>
            ) : (
              <React.Fragment>
                <React.Fragment>
                  {eventsIds.map(key => {
                    const event = events[key];
                    return (
                      (!this.state.selectedId || this.state.selectedId === event.id) && (
                        <EventItem
                          key={event.id}
                          event={event}
                          {...{ t, theme, history }}
                          hasSelected={this.hasSelected}
                          selectedId={this.state.selectedId}
                        />
                      )
                    );
                  })}
                </React.Fragment>
              </React.Fragment>
            )}
          </InfiniteScroll>
        </Container>
      </SwipeContainer>
    );
  }
}

export default App;
