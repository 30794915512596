import { createSelector } from 'reselect';

const getNav = (state, props) => state.nav;

export const selectNav = createSelector(
  [getNav],
  nav => {
    return nav;
  }
);
