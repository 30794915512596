import React from 'react';
import styled from 'styled-components';
import FlexParent from '../flex/FlexParent';
import {
  SMILEY_ANGRY,
  SMILEY_HAHA,
  SMILEY_LIKE,
  SMILEY_LOVE,
  SMILEY_WOW,
  REACTION_ANGRY,
  REACTION_HAHA,
  REACTION_LIKE,
  REACTION_LOVE,
  REACTION_WOW
} from '../../../constants/app';
import ClickOutside from 'react-click-outside';
import { motion, AnimatePresence } from 'framer-motion';

const Emoji = styled.div`
  font-size: 4rem;
  line-height: 4rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

const ReactionStyle = styled.div`
  top: 0;
  left: -10px;
  position: absolute;
  background-color: white;
  border-radius: 40px;
  z-index: 50;
  padding: 7px;
  border: 1px solid ${props => props.theme.dustGrey};
  width: 300px;
`;

const ReactionContainer = styled(motion.div)`
  position: relative;
  transform-origin: bottom left;
`;

class PopupReaction extends React.Component {
  onClickSmiley = event => {
    event.preventDefault();
    event.stopPropagation();
    const type = event.currentTarget.dataset.type;
    this.props.onValidate(type);
  };

  render() {
    const { onClose, open } = this.props;
    return (
      <AnimatePresence>
        {open ? (
          <ReactionContainer
            key="popup_reaction"
            initial={{ opacity: 0, y: 0, scale: 0 }}
            animate={{ opacity: 1, y: -60, scale: 1 }}
            exit={{ opacity: 0, y: 0, scale: 0 }}
          >
            <ClickOutside onClickOutside={this.props.onClose}>
              <ReactionStyle onClose={onClose} open={open}>
                <FlexParent justifyContent={'space-around'} alignItems={'center'}>
                  <Emoji onClick={this.onClickSmiley} data-type={REACTION_LIKE}>
                    {SMILEY_LIKE}
                  </Emoji>
                  <Emoji onClick={this.onClickSmiley} data-type={REACTION_HAHA}>
                    {SMILEY_HAHA}
                  </Emoji>
                  <Emoji onClick={this.onClickSmiley} data-type={REACTION_LOVE}>
                    {SMILEY_LOVE}
                  </Emoji>
                  <Emoji onClick={this.onClickSmiley} data-type={REACTION_WOW}>
                    {SMILEY_WOW}
                  </Emoji>
                  <Emoji onClick={this.onClickSmiley} data-type={REACTION_ANGRY}>
                    {SMILEY_ANGRY}
                  </Emoji>
                </FlexParent>
              </ReactionStyle>
            </ClickOutside>
          </ReactionContainer>
        ) : null}
      </AnimatePresence>
    );
  }
}

export default PopupReaction;
