import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <polygon points="11.8,3.38 11.8,16.75 13.57,16.75 13.57,3.39 16.13,5.95 17.38,4.7 12.68,0 7.98,4.7 9.23,5.95 " />
      <polygon points="14.65,6.58 14.65,8.35 20.15,8.35 20.15,23.23 5.27,23.23 5.27,8.35 10.94,8.35 10.94,6.58 3.5,6.58 3.5,25 21.92,25 21.92,6.58" />
    </g>
  </svg>
);
