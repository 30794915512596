import React from 'react';
import FlexParent from '../../../components/shared/flex/FlexParent';
import styled from 'styled-components';
import Textarea from '../../../components/shared/form/Textarea';
import Button from '../../../components/shared/Button';
import Icon from '../../../components/icons/Icon';
import IconCamera from '../../../components/icons/IconCamera';
import IconPictures from '../../../components/icons/IconPictures';
import IconCloseCross from '../../../components/icons/IconCloseCross';
import { FileInput } from '../../../components/shared/smartqa/Styled';
import Loader from '../../../components/shared/Loader';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 10px;
  z-index: 25;
`;

const ContainerInner = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Actions = styled(FlexParent)`
  margin: 3px 0 0 0;
`;

const PreviewImageContainer = styled(motion.div)`
  position: relative;
  height: 120px;
  width: 120px;
  display: block;
  margin: 10px auto;
  background-color: black;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const OfflineMessage = styled.div`
  text-align: center;
  font-size: 1.1rem;
  color: ${props => props.theme.darkGrey};
`;

class MessageCreate extends React.Component {
  targetElement = null;

  state = {
    message: '',
    pictureUrl: '',
    isSaving: false,
    uploading: false,
    autofocus: false,
    preventAutofocus: true
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setComponentFixedBottomHeight(this.containerRef.current.clientHeight);
    window.addEventListener('keyboardDidShow', this.onKeyboardDidShow, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.offline !== prevProps.offline) {
      this.props.setComponentFixedBottomHeight(this.containerRef.current.clientHeight);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyboardDidShow', this.onKeyboardDidShow, true);
  }

  onKeyboardDidShow = event => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  onClickCamera = event => {
    if (window.cordova) {
      this.getPictureFromCamera();
    }
  };

  getPictureFromCamera = () => {
    navigator.camera.getPicture(
      fileUri => {
        this.setState({
          uploading: true
        });
        this.props.uploadCameraFile({
          fileUri,
          fileName: fileUri.substr(fileUri.lastIndexOf('/') + 1),
          accessToken: this.props.accessToken,
          params: {
            type: 'whoshereconversationmessage',
            whos_here_id: this.props.conversation.whos_here_id,
            done: response => {
              if (response && response.data && response.data.url) {
                this.setState({
                  pictureUrl: response.data.url
                });
              }
            },
            always: () => {
              this.setState({
                uploading: false
              });
            }
          }
        });
      },
      error => {
        console.log(error);
      },
      {
        sourceType: navigator.camera.PictureSourceType.CAMERA,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        correctOrientation: true,
        saveToPhotoAlbum: navigator.camera.PictureSourceType.CAMERA
      }
    );
  };

  onClickPicture = event => {
    this.fileInputRef.current.click();
  };

  onChangeFile = event => {
    const file = event.target.files[0];
    this.setState({
      uploading: true
    });
    this.props.uploadPostFile({
      file,
      type: 'whoshereconversationmessage',
      done: response => {
        if (response && response.data && response.data.url) {
          this.setState({
            pictureUrl: response.data.url
          });
        }
      },
      always: () => {
        this.setState({
          uploading: false
        });
      }
    });
    event.target.value = null;
  };

  onFormSubmit = event => {
    event.preventDefault();
    this.setState(
      { autofocus: false, isSaving: true, message: '', pictureUrl: '', preventAutofocus: false },
      () => {
        this.setState({ autofocus: true });
      }
    );

    this.props.postMessage({
      text: this.state.message,
      conversation_id: this.props.conversation.id,
      conversation_type: this.props.conversation.type,
      picture_url: this.state.pictureUrl,
      always: () => {
        this.setState({
          isSaving: false
        });
        setTimeout(() => {
          this.props.setComponentFixedBottomHeight(this.containerRef.current.clientHeight);
        }, 500);
      }
    });
  };

  onChangeInput = event => {
    this.setState({ message: event.target.value });
  };

  onResizeInput = event => {
    this.setState({ message: event.target.value });
    this.props.setComponentFixedBottomHeight(this.containerRef.current.clientHeight);
  };

  onFocus = event => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  onClickRemovePicture = event => {
    this.setState({
      pictureUrl: ''
    });
  };

  render() {
    const { t, offline } = this.props;

    return (
      <Container ref={this.containerRef}>
        {offline ? (
          <OfflineMessage>{t('offline_post_message')}</OfflineMessage>
        ) : (
          <ContainerInner>
            <form onSubmit={this.onFormSubmit}>
              <FileInput
                accept={'image/*'}
                ref={this.fileInputRef}
                type="file"
                onChange={this.onChangeFile}
              />

              <AnimatePresence>
                {this.state.pictureUrl && (
                  <PreviewImageContainer
                    key={`preview_image`}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <Icon
                      icon={IconCloseCross}
                      position={'absolute'}
                      top={'5px'}
                      right={'5px'}
                      width={'20px'}
                      height={'20px'}
                      color={'#fff'}
                      padding={'5px'}
                      onClick={this.onClickRemovePicture}
                    />
                    <PreviewImage src={this.state.pictureUrl} />
                  </PreviewImageContainer>
                )}
              </AnimatePresence>

              {this.state.uploading && !this.state.pictureUrl ? <Loader /> : null}

              {this.state.autofocus || this.state.preventAutofocus ? (
                <Textarea
                  type="text"
                  value={this.state.message}
                  onResize={this.onResizeInput}
                  onChange={this.onChangeInput}
                  onFocus={this.onFocus}
                  maxRows={4}
                  autoFocus={this.state.autofocus}
                />
              ) : null}

              <Actions margin={'10px 0'} justifyContent={'space-between'} alignItems={'center'}>
                <FlexParent justifyContent={'space-between'}>
                  {navigator.camera ? (
                    <Icon
                      icon={IconCamera}
                      width={'25px'}
                      height={'25px'}
                      color={'#b0b0b0'}
                      margin={'0 6px'}
                      onClick={this.onClickCamera}
                    />
                  ) : (
                    ''
                  )}

                  <Icon
                    icon={IconPictures}
                    width={'25px'}
                    height={'25px'}
                    color={'#b0b0b0'}
                    margin={'0 6px'}
                    onClick={this.onClickPicture}
                  />
                </FlexParent>

                <Button
                  type={'submit'}
                  btntype={'primary'}
                  margin={'3px 0'}
                  disabled={
                    (this.state.message || this.state.pictureUrl) && !this.state.uploading
                      ? false
                      : 'disabled'
                  }
                >
                  {this.state.isSaving ? t('btn_publishing') : t('btn_publish')}
                </Button>
              </Actions>
            </form>
          </ContainerInner>
        )}
      </Container>
    );
  }
}

export default MessageCreate;
