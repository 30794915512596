import { combineReducers } from 'redux';

// API
import agendas from './agendas.js';
import agendaCategories from './agendaCategories.js';
import agendaSessions from './agendaSessions.js';
import app from './app.js';
import bonusLists from './bonusLists.js';
import bonuses from './bonuses.js';
import countries from './countries.js';
import errors from './errors.js';
import events from './events.js';
import exhibitorLists from './exhibitorLists.js';
import exhibitors from './exhibitors.js';
import favorites from './favorites.js';
import loading from './loading.js';
import maps from './maps.js';
import mapPois from './mapPois.js';
import menuItems from './menuItems.js';
import notifications from './notifications.js';
import notifs from './notifs.js';
import pagination from './pagination.js';
import practicalInfo from './practicalInfo.js';
import smartqa from './smartqa.js';
import smartqaPosts from './smartqaPosts.js';
import smartqaComments from './smartqaComments.js';
import socialMedia from './socialMedia.js';
import socialMediaTweets from './socialMediaTweets.js';
import socialMediaYoutubeVideos from './socialMediaYoutubeVideos.js';
import speakerLists from './speakerLists.js';
import speakers from './speakers.js';
import sponsorLists from './sponsorLists.js';
import sponsors from './sponsors.js';
import user from './user.js';
import shortLinks from './shortLinks.js';
import success from './success.js';
import surveys from './surveys.js';
import surveyPages from './surveyPages.js';
import surveyAnswers from './surveyAnswers.js';
import timelines from './timelines.js';
import timelineItems from './timelineItems.js';
import votes from './votes.js';
import voteRounds from './voteRounds.js';
import voteAnswers from './voteAnswers.js';
import whosHere from './whosHere.js';
import whosHereAttendees from './whosHereAttendees.js';
import whosHereMessages from './whosHereMessages.js';
import whosHereMeetings from './whosHereMeetings.js';
import whosHereConversations from './whosHereConversations.js';
import whosHereMessagesNotifications from './whosHereMessagesNotifications.js';

// LOCAL
import global from './global.js';
import header from './header.js';
import nav from './nav.js';
import sortIds from './sortIds.js';
import theme from './theme.js';

export default combineReducers({
  loading,
  agendas,
  agendaCategories,
  app,
  bonusLists,
  bonuses,
  countries,
  errors,
  events,
  exhibitorLists,
  exhibitors,
  favorites,
  global,
  header,
  maps,
  mapPois,
  menuItems,
  nav,
  notifications,
  notifs,
  pagination,
  practicalInfo,
  agendaSessions,
  shortLinks,
  smartqa,
  smartqaPosts,
  smartqaComments,
  socialMedia,
  socialMediaTweets,
  socialMediaYoutubeVideos,
  sortIds,
  speakerLists,
  speakers,
  sponsorLists,
  sponsors,
  success,
  surveys,
  surveyPages,
  surveyAnswers,
  timelines,
  timelineItems,
  votes,
  voteRounds,
  voteAnswers,
  theme,
  user,
  whosHere,
  whosHereAttendees,
  whosHereMeetings,
  whosHereMessages,
  whosHereConversations,
  whosHereMessagesNotifications
});
