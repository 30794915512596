import Pusher from 'pusher-js';
import Echo from 'laravel-echo/dist/echo.js';
import { API_URL_2 } from '../constants/apiRoutes';
import store from '../store';
import { UPDATE_GLOBAL } from '../constants/actionLocal';
import produce from 'immer';

class Socket {
  constructor() {
    if (!Socket.instance) {
      Socket.instance = this;
      const accessToken = localStorage.getItem('access_token');
      Pusher.logToConsole = true;

      this.echoSocket = new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_KEY,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
        auth: {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
            'X-Socket-Id': null
          }
        },
        authEndpoint: `${API_URL_2}broadcasting/auth`
      });

      this.echoSocket.connector.pusher.connection.bind('connected', ({ socket_id }) => {
        this.echoSocket.connector.options.auth.headers['X-Socket-Id'] = socket_id;

        store.dispatch({
          type: UPDATE_GLOBAL,
          payload: {
            socketId: socket_id
          }
        });
      });
    }
    return Socket.instance;
  }

  setAccessToken(accessToken) {
    const token = accessToken ? `Bearer ${accessToken}` : null;
    this.echoSocket.connector.options.auth.headers.Authorization = token;
    this.echoSocket.connector.pusher.config.auth.headers.Authorization = token;
  }

  subscribeChannel(channelName, isPublic) {
    console.log(
      '%c subscribeChannel: ',
      'color: red',
      channelName,
      isPublic ? 'public' : 'private'
    );
    if (isPublic) {
      return this.echoSocket.channel(channelName);
    }
    return this.echoSocket.private(channelName);
  }

  unsubscribeChannel(channelName) {
    console.log('%c unsubscribeChannel: ', 'color: red', channelName);
    this.echoSocket.leave(channelName);
  }

  listenEvents(channel, events) {
    for (const event of events) {
      console.log('%c Listen: ', 'color: red', channel.name, event);
      channel.listen(`.${event}`, data => {
        console.log('%c Event: ', 'color: red', event, data);
        const user = store.getState().user;
        store.dispatch({
          type: event,
          payload: { data, user }
        });
      });
    }
  }

  joinEvent(eventId, userId) {
    if (!userId || !eventId) {
      return false;
    }

    this.echoSocket
      .join(`event.${eventId}`)
      .here(users => {
        store.dispatch({
          type: UPDATE_GLOBAL,
          payload: {
            presenceIds: users.map(user => user.id)
          }
        });
      })
      .joining(user => {
        const presenceIds = produce(store.getState().global.presenceIds, draftState => {
          draftState.push(user.id);
          return draftState;
        });

        store.dispatch({
          type: UPDATE_GLOBAL,
          payload: {
            presenceIds
          }
        });
      })
      .leaving(user => {
        const presenceIds = produce(store.getState().global.presenceIds, draftState => {
          draftState.filter(id => id !== user.id);
          return draftState;
        });

        store.dispatch({
          type: UPDATE_GLOBAL,
          payload: {
            presenceIds
          }
        });
      });
  }

  leaveEvent(eventId) {
    this.echoSocket.leave(`event.${eventId}`);
  }
}

const instance = new Socket();
Object.freeze(instance);

export default instance;
