import { createSelector } from 'reselect';

const currentPracticalInfoId = (state, props) => props.match.params.practicalInfoId;
const practicalInfos = (state, props) => state.practicalInfo;

export const selectCurrentPracticalInfo = createSelector(
  [practicalInfos, currentPracticalInfoId],
  (practicalInfos, currentPracticalInfoId) => {
    return practicalInfos[currentPracticalInfoId] || null;
  }
);
