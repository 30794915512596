import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M11.07,7.44c-0.05-0.05-0.11-0.09-0.18-0.12v3.61h3.61 c-0.03-0.07-0.07-0.13-0.12-0.18L11.07,7.44z" />
      <path d="M11.94,14.6c0,0.29-0.23,0.52-0.52,0.52H4.12 c-0.29,0-0.52-0.23-0.52-0.52c0-0.29,0.23-0.52,0.52-0.52h7.29C11.7,14.08,11.94,14.31,11.94,14.6 M11.42,18.24H4.12 c-0.29,0-0.52-0.23-0.52-0.52c0-0.29,0.23-0.52,0.52-0.52h7.29c0.29,0,0.52,0.23,0.52,0.52C11.94,18.01,11.7,18.24,11.42,18.24  M11.42,21.37H4.12c-0.29,0-0.52-0.23-0.52-0.52c0-0.29,0.23-0.52,0.52-0.52h7.29c0.29,0,0.52,0.23,0.52,0.52 C11.94,21.13,11.7,21.37,11.42,21.37 M9.85,11.46V7.29H1.52C1.23,7.29,1,7.52,1,7.81v16.67C1,24.77,1.23,25,1.52,25h12.5 c0.29,0,0.52-0.23,0.52-0.52v-12.5h-4.17C10.09,11.98,9.85,11.74,9.85,11.46" />
      <path d="M15.06,7.81V3.65H6.73c-0.29,0-0.52,0.23-0.52,0.52v2.08h4.5 c0.43,0,0.83,0.17,1.12,0.48l3.29,3.29c0.3,0.3,0.46,0.69,0.46,1.1v10.23h3.65c0.29,0,0.52-0.23,0.52-0.52V8.33h-4.17 C15.29,8.33,15.06,8.1,15.06,7.81" />
      <path d="M16.28,3.8c-0.05-0.05-0.11-0.09-0.18-0.12v3.61h3.61 c-0.03-0.07-0.07-0.13-0.12-0.18L16.28,3.8z" />
      <path d="M19.75,4.69c-0.29,0-0.52-0.23-0.52-0.52V0H10.9 c-0.29,0-0.52,0.23-0.52,0.52V2.6h4.5h1.04c0.43,0,0.83,0.17,1.12,0.48l3.29,3.29c0.3,0.3,0.46,0.69,0.46,1.1v10.23h2.6 c0.29,0,0.52-0.23,0.52-0.52V4.69H19.75z" />
      <path d="M23.76,3.46l-3.31-3.31c-0.05-0.05-0.11-0.09-0.18-0.12v3.61h3.61 C23.85,3.58,23.81,3.52,23.76,3.46" />
    </g>
  </svg>
);
