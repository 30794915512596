import styled from 'styled-components';
import FlexParent from '../flex/FlexParent';

export const PostAvatar = styled.img`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 0 10px 0 0;
`;

export const PostAuthorName = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
`;

export const PostHour = styled.div`
  color: ${props => props.theme.ashesGrey};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const PostMessage = styled.div`
  margin: 15px 0;
`;

export const PostPicture = styled.img`
  display: block;
  width: 100%;
  margin: 15px 0;
`;

export const PostCommentsCount = styled.div`
  color: ${props => props.theme.ashesGrey};
  font-size: 1.2rem;
  text-align: right;
  cursor: pointer;
`;

export const PostLike = styled.span`
  color: ${props => props.theme.shadowGrey};
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

export const PostActionContainer = styled(FlexParent)`
  padding: 12px 0 ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  border-top: 1px solid ${props => props.theme.dustGrey};
`;

export const PostReactionContainer = styled(FlexParent)`
  padding: 0 0 10px 0;
`;

export const FileInput = styled.input`
  display: none;
`;
