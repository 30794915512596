import { FAILED, SUCCESS, FETCH_MENU_ITEMS } from '../constants/actionApi';
import { RESET_EVENT, USER_LOGOUT } from '../constants/actionLocal';
import initialState from '../store/initialState';
import { updateObjectInState, resetObjectInState } from '../helpers/data';

export default (state = initialState.menuItems, { type, payload }) => {
  switch (type) {
    case FETCH_MENU_ITEMS + SUCCESS:
      return updateObjectInState(state, payload.data, true, initialState.menuItems);
    case FETCH_MENU_ITEMS + FAILED:
    case RESET_EVENT:
    case USER_LOGOUT:
      return resetObjectInState(state, initialState.menuItems);
    default:
      return state;
  }
};
