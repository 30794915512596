import Button from '../Button';
import Icon from '../../icons/Icon';
import IconInvisible from '../../icons/IconInvisible';
import Modal from '../../modal/Modal';
import React from 'react';
import styled from 'styled-components';

const Heading = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
`;

const Message = styled.div`
  font-size: 1.4rem;
  padding: 10px 0 30px;
`;

const ModalIsAnonymous = props => {
  const { onClose, onValidate, open, theme, t } = props;
  return (
    <Modal onClose={onClose} open={open}>
      <Icon
        icon={IconInvisible}
        width={'80px'}
        height={'80px'}
        color={'#fff'}
        margin={'0 auto 20px'}
        padding={'15px'}
        borderRadius={'50%'}
        backgroundColor={theme.mainColor}
      />
      <Heading>{t('smart_qa_modal_anonymous_heading')}</Heading>
      <Message>{t('smart_qa_modal_anonymous_message')}</Message>
      <Button btntype={'primary'} onClick={onValidate}>
        {t('btn_ok')}
      </Button>
    </Modal>
  );
};

export default ModalIsAnonymous;
