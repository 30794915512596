import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <polygon points="22.1,25 3.3,25 6.4,15.9 8.8,15.9 8.8,17.7 7.7,17.7 5.8,23.2 19.6,23.2 17.7,17.7 16.5,17.7 16.5,15.9 18.9,15.9 " />
      <path d="M6.9,6.2c0,5.6,5.4,12,5.6,12.3l0.3,0.3l0.3-0.3c0.2-0.3,5.6-6.8,5.6-12.3C18.6,2.8,16,0,12.8,0 C9.5,0,6.9,2.8,6.9,6.2z M10.2,6c0-1.5,1.2-2.6,2.6-2.6c1.5,0,2.6,1.2,2.6,2.6c0,1.5-1.2,2.6-2.6,2.6C11.4,8.6,10.2,7.4,10.2,6z" />
    </g>
  </svg>
);
