import React from 'react';
import styled, { withTheme } from 'styled-components';
import { isDarkBgColor } from '../../helpers/helpers';
import { motion } from 'framer-motion';
/*
PROPS
----------------------------------------------------------------------------------------------
margin: <string>
padding: <string>
display: <string>
fontSize: <string>
----------------------------------------------------------------------------------------------
*/
const ButtonStyled = styled(motion.button)`
  appearance: none;
  display: ${props => (props.display ? props.display : 'inline-block')};
  padding: ${props => (props.padding ? props.padding : '9px 12px')};
  margin: ${props => (props.margin ? props.margin : '3px')};
  width: ${props => (props.width ? props.width : 'initial')};
  text-transform: uppercase;
  border-radius: 8px;
  font-size: ${props => (props.fontSize ? props.fontSize : ' 1.2rem')};
  letter-spacing: 0.3px;
  border: none;
  cursor: pointer;
  background-color: ${props => {
    switch (props.btntype) {
      case 'primary':
        return props.theme.mainColor;
      case 'secondary':
      case 'danger':
        return props.theme.lightGrey;
      case 'white':
        return 'white';
      case 'linkGrey':
      case 'linkMain':
      default:
        return props.backgroundColor || 'transparent';
    }
  }};

  color: ${props => {
    switch (props.btntype) {
      case 'primary':
        return isDarkBgColor(props.theme.mainColor) ? '#fff' : '#000';
      case 'secondary':
        return '#000';
      case 'white':
        return isDarkBgColor(props.theme.mainColor) ? props.theme.mainColor : props.theme.darkGrey;
      case 'linkGrey':
        return props.theme.ashesGrey;
      case 'danger':
        return 'red';
      case 'linkMain':
      default:
        return props.color || props.theme.mainColor;
    }
  }};

  font-weight: ${props => {
    switch (props.btntype) {
      case 'linkGrey':
      case 'linkMain':
      case 'danger':
        return 'bold';
      default:
        return 'initial';
    }
  }};

  :focus {
    outline: none;
  }

  &[disabled] {
    background-color: ${props => props.theme.dustGrey};
    color: white;
  }
`;

const Button = props => {
  return (
    <ButtonStyled {...props} whileTap={{ scale: 0.9 }}>
      {props.children}
    </ButtonStyled>
  );
};

export default withTheme(Button);
