import { connect } from 'react-redux';
import { updateHeader, updateGlobal } from '../../actions/local';
import { postUserIncognito } from '../../actions/api';
import { updateNav } from '../../actions/local';
import Header from './Header';
import React from 'react';

import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';

import ModalIsIncognito from './ModalIsIncognito';
import ModalIsNotIncognito from './ModalIsNotIncognito';

import { selectFilteredCategoriesIds } from '../../selectors/agendas';

class HeaderContainer extends React.Component {
  state = {
    openIsIncognitoModal: false,
    openIsNotIncognitoModal: false
  };
  componentDidUpdate(prevProps) {
    if (
      !this.props.header.showSearchBar &&
      this.props.header.showSearchBar !== prevProps.header.showSearchBar
    ) {
      this.props.updateGlobal({
        searchValue: ''
      });
    }

    if (
      this.props.header.rightBtn !== prevProps.header.rightBtn &&
      (this.props.header.rightBtn !== 'search' && this.props.header.rightBtn !== 'closeSearch')
    ) {
      this.props.updateHeader({
        showSearchBar: false
      });
    }
  }

  onClickNavBtn = event => {
    this.props.updateNav({
      isOpened: true
    });

    if (this.props.header.showSearchBar) {
      this.props.updateHeader({
        rightBtn: 'search',
        showSearchBar: false
      });
    }
  };

  onClickBackBtn = event => {
    window.history.back();
  };

  onClickSearchBtn = event => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.props.updateHeader({
      rightBtn: 'closeSearch',
      showSearchBar: true
    });
  };

  onClickFilterBtn = event => {
    this.props.updateHeader({
      rightBtn: 'closeFilter',
      isCategoriesFiltersOpen: true
    });
  };

  onClickCloseSearchBtn = event => {
    this.props.updateHeader({
      rightBtn: 'search',
      showSearchBar: false
    });
    this.props.updateGlobal({
      searchValue: ''
    });
  };

  onClickCloseFilterBtn = event => {
    this.props.updateHeader({
      rightBtn: 'filter',
      isCategoriesFiltersOpen: false
    });
  };

  onClickIncognitoBtn = event => {
    const { appId, eventId } = this.props.match.params;
    this.props.postUserIncognito({
      app_id: +appId,
      event_id: +eventId
    });
    this.openIsNotIncognitoModal();
  };

  onClickNotIncognitoBtn = event => {
    const { appId, eventId } = this.props.match.params;
    this.props.postUserIncognito({
      app_id: +appId,
      event_id: +eventId
    });
    this.openIsIncognitoModal();
  };

  openIsIncognitoModal = () => {
    this.setState({ openIsIncognitoModal: true });
  };

  closeIsIncognitoModal = () => {
    this.setState({ openIsIncognitoModal: false });
  };

  openIsNotIncognitoModal = () => {
    this.setState({ openIsNotIncognitoModal: true });
  };

  closeIsNotIncognitoModal = () => {
    this.setState({ openIsNotIncognitoModal: false });
  };

  onChangeSearchValue = searchValue => {
    this.props.updateGlobal({
      searchValue
    });
  };

  onClickUserBtn = event => {
    const { appId } = this.props.match.params;
    this.props.history.push(`/apps/${appId}/profile`);
  };

  setHeaderHeight = height => {
    this.props.updateHeader({
      height
    });
  };

  render() {
    const {
      onClickNavBtn,
      onClickBackBtn,
      onClickSearchBtn,
      onClickFilterBtn,
      onChangeSearchValue,
      onClickCloseSearchBtn,
      onClickCloseFilterBtn,
      onClickIncognitoBtn,
      onClickNotIncognitoBtn,
      onClickUserBtn,
      setHeaderHeight
    } = this;
    const { global, header, theme, t, user } = this.props;

    const { agendaCategoriesFilters } = global;
    const agendaCategoriesFiltersCount = agendaCategoriesFilters.length
      ? agendaCategoriesFilters.length
      : 0;

    return (
      <React.Fragment>
        <Header
          {...{
            header,
            global,
            theme,
            user,
            t
          }}
          {...{
            onClickNavBtn,
            onClickBackBtn,
            onClickSearchBtn,
            onClickFilterBtn,
            onClickCloseSearchBtn,
            onClickCloseFilterBtn,
            onClickIncognitoBtn,
            onClickNotIncognitoBtn,
            onClickUserBtn,
            onChangeSearchValue,
            setHeaderHeight,
            agendaCategoriesFiltersCount
          }}
        />

        <ModalIsIncognito
          open={this.state.openIsIncognitoModal}
          onClose={this.closeIsIncognitoModal}
          onValidate={this.closeIsIncognitoModal}
          theme={theme}
          t={t}
        />

        <ModalIsNotIncognito
          open={this.state.openIsNotIncognitoModal}
          onClose={this.closeIsNotIncognitoModal}
          onValidate={this.closeIsNotIncognitoModal}
          theme={theme}
          t={t}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  updateGlobal: updateGlobal,
  updateNav: updateNav,
  postUserIncognito: postUserIncognito
};

const mapStateToProps = (state, ownProps) => {
  return {
    header: selectHeader(state, ownProps),
    global: selectGlobal(state, ownProps),
    user: selectUser(state, ownProps),
    categoriesIds: selectFilteredCategoriesIds(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
