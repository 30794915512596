import { UPDATE_SUCCESS } from '../constants/actionLocal';
import initialState from '../store/initialState';
import { resetObjectInState } from '../helpers/data';

export default (state = initialState.success, { type, payload }) => {
  switch (type) {
    case UPDATE_SUCCESS:
      return resetObjectInState(state, payload);
    default:
      return state;
  }
};
