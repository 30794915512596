import { updateHeader, updateGlobal } from '../../actions/local';
import App from './App';
import React from 'react';
import { fetchEvents } from '../../actions/api';
import { resetEvents } from '../../actions/local';
import { connect } from 'react-redux';
import { TAB_CURRENT_WEEK } from '../../constants/app';

import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';
import {
  selectFilteredEvents,
  selectFilteredEventsIds,
  selectHasMoreEvents,
  selectIsLoadingEvents
} from '../../selectors/events';
import { selectCurrentApp } from '../../selectors/app';
import { SEARCH_DELAY } from '../../constants/app';

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    let { appId, currentTab } = this.props.match.params;

    if (!currentTab) {
      currentTab = this.props.global.currentTabEvents || TAB_CURRENT_WEEK;
      this.props.history.replace(`/apps/${appId}/tab/${currentTab}`);
    }

    this.props.updateGlobal({
      currentTabEvents: currentTab
    });

    this.props.updateHeader({
      leftBtn: 'user',
      rightBtn: 'search',
      rightBtn2: null
    });
  }

  componentDidUpdate(prevProps) {
    let { appId, currentTab } = this.props.match.params;

    if (!currentTab) {
      currentTab = this.props.global.currentTabEvents || TAB_CURRENT_WEEK;
      this.props.history.replace(`/apps/${appId}/tab/${currentTab}`);
      this.props.updateGlobal({
        currentTabEvents: currentTab
      });
    }

    if (this.props.global.searchValue !== prevProps.global.searchValue) {
      this.search(this.props.global.searchValue);
    }

    if (!this.props.header.showSearchBar && prevProps.header.showSearchBar) {
      this.fetchEvents({ reset: true });
    }

    if (this.props.header.showSearchBar && !prevProps.header.showSearchBar) {
      this.setComponentFixedTopHeight(0);
      this.props.resetEvents();
    }

    if (
      this.props.header.showSearchBar &&
      !this.props.global.searchValue.length !== prevProps.global.searchValue.length &&
      this.props.global.searchValue.length < 3
    ) {
      this.props.resetEvents();
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.fetchEvents({ reset: true });
    }
  }

  search = () => {
    if (this.props.global.searchValue.length < 3) {
      return false;
    }
    if (this.searchTimeout) {
      this.searchTimeout = clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchEvents({ reset: true });
    }, SEARCH_DELAY);
  };

  fetchEvents = data => {
    const { appId, currentTab } = this.props.match.params;
    this.props.fetchEvents({
      ...data,
      ...{
        q: this.props.global.searchValue,
        group_id: +appId,
        timeframe: this.props.global.searchValue ? null : currentTab
      }
    });
  };

  updateTab = ({ tab }) => {
    const { appId } = this.props.match.params;
    const baseUrl = `/apps/${appId}/tab`;
    this.props.updateGlobal({
      currentTabEvents: tab
    });
    this.props.history.push(`${baseUrl}/${tab}`);
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  render() {
    if (!this.props.app) {
      return null;
    }
    const {
      t,
      theme,
      history,
      header,
      updateGlobal,
      global,
      events,
      user,
      app,
      eventsIds,
      hasMoreEvents,
      isLoadingEvents
    } = this.props;
    const { height: headerHeight } = header;
    const { componentFixedTopHeight } = global;
    const { appId, currentTab } = this.props.match.params;
    const { fetchEvents, updateTab, setComponentFixedTopHeight } = this;
    return (
      <App
        {...{
          appId,
          app,
          events,
          eventsIds,
          t,
          theme,
          history,
          headerHeight,
          currentTab,
          updateTab,
          updateGlobal,
          componentFixedTopHeight,
          setComponentFixedTopHeight,
          fetchEvents,
          hasMoreEvents,
          isLoadingEvents,
          header,
          global,
          user
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  updateGlobal: updateGlobal,
  fetchEvents: fetchEvents,
  resetEvents: resetEvents
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    app: selectCurrentApp(state, ownProps),
    events: selectFilteredEvents(state, ownProps),
    eventsIds: selectFilteredEventsIds(state, ownProps),
    hasMoreEvents: selectHasMoreEvents(state, ownProps),
    isLoadingEvents: selectIsLoadingEvents(state, ownProps),
    header: selectHeader(state, ownProps),
    global: selectGlobal(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer);
