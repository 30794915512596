import { createSelector } from 'reselect';

const getMenuItems = (state, props) => state.menuItems;

export const selectMenuItems = createSelector(
  [getMenuItems],
  menuItems => {
    return menuItems || null;
  }
);

export const selectAnimatorItems = createSelector(
  [getMenuItems],
  menuItems => {
    if (!menuItems) {
      return null;
    }
    return Object.keys(menuItems)
      .filter(key => {
        const item = menuItems[key];
        return item.type === 'instaphoto' || item.type === 'smartqa';
      })
      .map(id => {
        return menuItems[id];
      });
  }
);
