import { createSelector } from 'reselect';

const getShortLinks = (state, props) => state.shortLinks;

export const selectShortLinks = createSelector(
  [getShortLinks],
  shortLinks => {
    return shortLinks || null;
  }
);
