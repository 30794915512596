import React from 'react';
import styled from 'styled-components';
import FlexParent from '../flex/FlexParent';
import {
  SMILEY_ANGRY,
  SMILEY_HAHA,
  SMILEY_LIKE,
  SMILEY_LOVE,
  SMILEY_WOW,
  REACTION_ANGRY,
  REACTION_HAHA,
  REACTION_LIKE,
  REACTION_LOVE,
  REACTION_WOW
} from '../../../constants/app';

const Reaction = styled(FlexParent)`
  margin: 0 5px 0 0;
`;

const ReactionCount = styled.div`
  color: ${props => props.theme.ashesGrey};
  font-size: 1.2rem;
`;

const ReactionSmiley = styled.div`
  font-size: 1.2rem;
`;

const ViewerReactionContainer = styled.div`
  cursor: pointer;
`;

const ViewerReactionSmiley = styled.div`
  font-size: ${props => (props.isComment ? '1.8rem' : '2.5rem')};
`;

const ViewerReactionName = styled.div`
  color: ${props => props.theme.mainColor};
  font-size: ${props => (props.isComment ? '1.2rem' : '1.5rem')};
  font-weight: bold;
  margin-left: 5px;
`;

export const Reactions = props => {
  const { angry, haha, like, love, wow } = props;
  return (
    <React.Fragment>
      {angry.count ? (
        <Reaction alignItems={'center'}>
          <ReactionSmiley>{SMILEY_ANGRY}</ReactionSmiley>
          <ReactionCount>{angry.count}</ReactionCount>
        </Reaction>
      ) : null}

      {haha.count ? (
        <Reaction alignItems={'center'}>
          <ReactionSmiley>{SMILEY_HAHA}</ReactionSmiley>
          <ReactionCount>{haha.count}</ReactionCount>
        </Reaction>
      ) : null}

      {like.count ? (
        <Reaction alignItems={'center'}>
          <ReactionSmiley>{SMILEY_LIKE}</ReactionSmiley>
          <ReactionCount>{like.count}</ReactionCount>
        </Reaction>
      ) : null}

      {love.count ? (
        <Reaction alignItems={'center'}>
          <ReactionSmiley>{SMILEY_LOVE}</ReactionSmiley>
          <ReactionCount>{love.count}</ReactionCount>
        </Reaction>
      ) : null}

      {wow.count ? (
        <Reaction alignItems={'center'}>
          <ReactionSmiley>{SMILEY_WOW}</ReactionSmiley>
          <ReactionCount>{wow.count}</ReactionCount>
        </Reaction>
      ) : null}
    </React.Fragment>
  );
};

export const ViewerReaction = props => {
  const { reaction } = props;
  let smiley;
  let label;
  switch (reaction) {
    case REACTION_ANGRY:
      smiley = SMILEY_ANGRY;
      label = props.t('reaction_angry');
      break;
    case REACTION_HAHA:
      smiley = SMILEY_HAHA;
      label = props.t('reaction_haha');
      break;
    case REACTION_LIKE:
      smiley = SMILEY_LIKE;
      label = props.t('reaction_like');
      break;
    case REACTION_LOVE:
      smiley = SMILEY_LOVE;
      label = props.t('reaction_love');
      break;
    case REACTION_WOW:
      smiley = SMILEY_WOW;
      label = props.t('reaction_wow');
      break;
    default:
      break;
  }
  return (
    <ViewerReactionContainer isComment={props.isComment}>
      <FlexParent alignItems={'center'} onClick={props.onClick} data-type={reaction}>
        <ViewerReactionSmiley isComment={props.isComment}>{smiley}</ViewerReactionSmiley>{' '}
        <ViewerReactionName isComment={props.isComment}>{label}</ViewerReactionName>
      </FlexParent>
    </ViewerReactionContainer>
  );
};
