import React from 'react';
import styled from 'styled-components';
import FlexParent from '../shared/flex/FlexParent';
import FlexItem from '../shared/flex/FlexItem';
import Icon from '../icons/Icon';
import IconAnimator from '../icons/IconAnimator';
import IconCloseCross from '../../components/icons/IconCloseCross';
import { selectNotif } from '../../selectors/notifs';
import { connect } from 'react-redux';
import { resetNotif } from '../../actions/local';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled(motion.div)`
  position: fixed;
  z-index: 100;
  top: 0px;
  right: -350px;
  max-width: 300px;
  margin: 10px;
  min-height: 50px;
  color: white;
  border-radius: 8px;
  background: repeating-linear-gradient(135deg, #616161, #616161 10px, #585858 10px, #585858 20px);
  padding: 15px;
  text-shadow: 0.075em 0.08em 0.1em rgba(30, 30, 30, 0.8);
  box-shadow: -2px 1px 5px 0px rgba(85, 85, 85, 0.75);
  font-weight: bold;
`;

const Label = styled.div`
  padding-right: 10px;
`;

const Timer = styled(FlexItem)`
  position: relative;
  font-size: 12px;
  text-align: center;
  width: 70px;
  height: 70px;
  color: white;
  border-radius: 50%;
  padding-top: 27px;
  border: 3px solid #262626;
  margin-right: 10px;

  #timer-label {
    position: absolute;
    top: 23px;
    left: 0;
    text-align: center;
    margin: 0 auto;
    right: 0;
}
  }

  #timer-inner {
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
  }

  #timer-circle {
    width: 6px;
    height: 6px;
    background-color: white;
    margin: 0 auto;
    border-radius: 50%;
  }

  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rotating {
    from {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
`;

class NotifContainer extends React.Component {
  constructor() {
    super();
    this.state = { countdown: 0 };
    this.timer = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.notif.countdown && prevProps.notif.countdown !== this.props.notif.countdown) {
      this.setState(
        {
          countdown: this.props.notif.countdown
        },
        () => {
          if (this.timer) {
            this.timer = clearInterval(this.timer);
          }
          this.timer = setInterval(this.countDown, 1000);
        }
      );
    }
  }

  countDown = () => {
    let countdown = this.state.countdown - 1;
    this.setState({
      countdown
    });

    if (countdown === 0) {
      this.timer = clearInterval(this.timer);
      this.props.resetNotif({});
    }
  };

  onClickClose = event => {
    this.props.resetNotif({});
  };

  render() {
    return (
      <AnimatePresence>
        {this.props.notif.message && (
          <Container
            key="notif_message"
            initial={{ x: 0 }}
            animate={{ x: -350 }}
            exit={{ x: 0 }}
            onClick={this.onClickClose}
          >
            <FlexParent alignItems={'center'}>
              {!this.state.countdown ? (
                <Icon
                  icon={IconAnimator}
                  color={'#616161'}
                  padding={'7px'}
                  margin={'0 10px 0 0'}
                  width={'40px'}
                  height={'40px'}
                  borderRadius={'50%'}
                  backgroundColor={'#fff'}
                />
              ) : (
                <Timer shrink={'0'}>
                  <div id="timer-label">{this.state.countdown}</div>
                  <div id="timer-inner" className={this.state.countdown ? 'rotating' : ''}>
                    <div id="timer-circle"></div>
                  </div>
                </Timer>
              )}

              <FlexItem grow={1}>
                <Label>{this.props.notif.message}</Label>
              </FlexItem>
              <Icon
                icon={IconCloseCross}
                position={'absolute'}
                top={'3px'}
                right={'3px'}
                width={'20px'}
                height={'20px'}
                color={'#fff'}
                padding={'5px'}
              />
            </FlexParent>
          </Container>
        )}
      </AnimatePresence>
    );
  }
}

const mapDispatchToProps = {
  resetNotif: resetNotif
};

const mapStateToProps = (state, ownProps) => {
  return {
    notif: selectNotif(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotifContainer);
