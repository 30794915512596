import React from 'react';
import i18n from '../../i18n';

import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';

import IconWebsite from '../../components/icons/IconWebsite';
import IconAddPeople from '../../components/icons/IconAddPeople';
import IconAnswerRight from '../../components/icons/IconAnswerRight';
import IconAnswerWrong from '../../components/icons/IconAnswerWrong';
import IconBack from '../../components/icons/IconBack';
import IconBonusList from '../../components/icons/IconBonusList';
import IconCalendar from '../../components/icons/IconCalendar';
import IconCamera from '../../components/icons/IconCamera';
import IconChevron from '../../components/icons/IconChevron';
import IconCloseCross from '../../components/icons/IconCloseCross';
import IconCloud from '../../components/icons/IconCloud';
import IconCommentFilled from '../../components/icons/IconCommentFilled';
import IconCommentOutline from '../../components/icons/IconCommentOutline';
import IconDocumentAcrobat from '../../components/icons/IconDocumentAcrobat';
import IconDocumentImage from '../../components/icons/IconDocumentImage';
import IconDocumentMusic from '../../components/icons/IconDocumentMusic';
import IconDocumentText from '../../components/icons/IconDocumentText';
import IconDocumentVideo from '../../components/icons/IconDocumentVideo';
import IconDragAndDropArrows from '../../components/icons/IconDragAndDropArrows';
import IconExhibitorList from '../../components/icons/IconExhibitorList';
import IconExpressYourself from '../../components/icons/IconExpressYourself';
import IconFacebook from '../../components/icons/IconFacebook';
import IconFilter from '../../components/icons/IconFilter';
import IconHome from '../../components/icons/IconHome';
import IconInvisible from '../../components/icons/IconInvisible';
import IconLinkedin from '../../components/icons/IconLinkedin';
import IconLockerFilled from '../../components/icons/IconLockerFilled';
import IconLockerOutline from '../../components/icons/IconLockerOutline';
import IconMail from '../../components/icons/IconMail';
import IconMap from '../../components/icons/IconMap';
import IconMenuBurger from '../../components/icons/IconMenuBurger';
import IconNetworking from '../../components/icons/IconNetworking';
import IconNotifications from '../../components/icons/IconNotifications';
import IconOption from '../../components/icons/IconOption';
import IconPencil from '../../components/icons/IconPencil';
import IconPhone from '../../components/icons/IconPhone';
import IconPictures from '../../components/icons/IconPictures';
import IconPlay from '../../components/icons/IconPlay';
import IconPoi from '../../components/icons/IconPoi';
import IconPracticalInfo from '../../components/icons/IconPracticalInfo';
import IconQRcode from '../../components/icons/IconQRcode';
import IconQuiz from '../../components/icons/IconQuiz';
import IconReport from '../../components/icons/IconReport';
import IconSearch from '../../components/icons/IconSearch';
import IconSettings from '../../components/icons/IconSettings';
import IconShare from '../../components/icons/IconShare';
import IconSort from '../../components/icons/IconSort';
import IconSpeakerList from '../../components/icons/IconSpeakerList';
import IconSponsorList from '../../components/icons/IconSponsorList';
import IconStarFilled from '../../components/icons/IconStarFilled';
import IconStarOutline from '../../components/icons/IconStarOutline';
import IconSurvey from '../../components/icons/IconSurvey';
import IconSwitchEvent from '../../components/icons/IconSwitchEvent';
import IconTimeline from '../../components/icons/IconTimeline';
import IconTrash from '../../components/icons/IconTrash';
import IconTwitter from '../../components/icons/IconTwitter';
import IconAvatar from '../../components/icons/IconAvatar';
import IconVip from '../../components/icons/IconVip';
import IconVisible from '../../components/icons/IconVisible';
import IconVote from '../../components/icons/IconVote';
import IconPlus from '../../components/icons/IconPlus';
import IconMinus from '../../components/icons/IconMinus';
import IconOffline from '../../components/icons/IconOffline';

import Loader from '../../components/shared/Loader';

const icons = [
  IconWebsite,
  IconAddPeople,
  IconAnswerRight,
  IconAnswerWrong,
  IconBack,
  IconBonusList,
  IconCalendar,
  IconCamera,
  IconChevron,
  IconCloseCross,
  IconCloud,
  IconCommentFilled,
  IconCommentOutline,
  IconDocumentAcrobat,
  IconDocumentImage,
  IconDocumentMusic,
  IconDocumentText,
  IconDocumentVideo,
  IconDragAndDropArrows,
  IconExhibitorList,
  IconExpressYourself,
  IconFacebook,
  IconFilter,
  IconHome,
  IconInvisible,
  IconLinkedin,
  IconLockerFilled,
  IconLockerOutline,
  IconMail,
  IconMap,
  IconMenuBurger,
  IconNetworking,
  IconNotifications,
  IconOption,
  IconPencil,
  IconPhone,
  IconPictures,
  IconPlay,
  IconPoi,
  IconPracticalInfo,
  IconQRcode,
  IconQuiz,
  IconReport,
  IconSearch,
  IconSettings,
  IconShare,
  IconSort,
  IconSpeakerList,
  IconSponsorList,
  IconStarFilled,
  IconStarOutline,
  IconSurvey,
  IconSwitchEvent,
  IconTimeline,
  IconTrash,
  IconTwitter,
  IconAvatar,
  IconVip,
  IconVisible,
  IconVote,
  IconPlus,
  IconMinus,
  IconOffline
];

const Sandbox = props => (
  <div>
    <Loader />

    <h1>{props.t('today')}</h1>
    <p>{props.t('_exemple_plural', { count: 0 })}</p>
    <p>{props.t('_exemple_plural', { count: 1 })}</p>
    <p>{props.t('_exemple_plural', { count: 10 })}</p>
    {props.children}
    <button onClick={props.onClickTest}>test</button>

    <button
      onClick={() => {
        i18n.changeLanguage('fr');
      }}
    >
      {props.t('lang_fr')}
    </button>
    <button
      onClick={() => {
        i18n.changeLanguage('en');
      }}
    >
      {props.t('lang_en')}
    </button>

    <FlexParent wrap={'wrap'}>
      {icons.map((icon, index) => {
        return (
          <Icon
            key={index}
            margin={'2px'}
            backgroundColor={'#ccc'}
            icon={icon}
            width={'40px'}
            height={'40px'}
            color={props.theme.mainColor}
          />
        );
      })}

      {icons.map((icon, index) => {
        return (
          <Icon
            key={index}
            margin={'2px'}
            backgroundColor={props.theme.mainColor}
            icon={icon}
            width={'40px'}
            height={'40px'}
            color={'#fff'}
            borderRadius={'50%'}
            padding={'8px'}
          />
        );
      })}
    </FlexParent>
  </div>
);

export default Sandbox;
