import * as action from '../constants/actionApi';

//FETCH

export const searchApp = payload => ({
  payload,
  type: action.SEARCH_APP
});

export const fetchApp = payload => ({
  payload,
  type: action.FETCH_APP
});

export const fetchEvent = payload => ({
  payload,
  type: action.FETCH_EVENT
});

export const fetchEvents = payload => ({
  payload,
  type: action.FETCH_EVENTS
});

export const fetchMenuItems = payload => ({
  payload,
  type: action.FETCH_MENU_ITEMS
});

export const fetchShortLinks = payload => ({
  payload,
  type: action.FETCH_SHORT_LINKS
});

export const fetchAgenda = payload => ({
  payload,
  type: action.FETCH_AGENDA
});

export const fetchSessions = payload => ({
  payload,
  type: action.FETCH_SESSIONS
});

export const fetchAgendaCategories = payload => ({
  payload,
  type: action.FETCH_AGENDA_CATEGORIES
});

export const fetchSession = payload => ({
  payload,
  type: action.FETCH_SESSION
});

export const fetchBonusList = payload => ({
  payload,
  type: action.FETCH_BONUS_LIST
});

export const fetchBonuses = payload => ({
  payload,
  type: action.FETCH_BONUSES
});

export const fetchBonus = payload => ({
  payload,
  type: action.FETCH_BONUS
});

export const fetchSponsorList = payload => ({
  payload,
  type: action.FETCH_SPONSOR_LIST
});

export const fetchSponsors = payload => ({
  payload,
  type: action.FETCH_SPONSORS
});

export const fetchSponsor = payload => ({
  payload,
  type: action.FETCH_SPONSOR
});

export const fetchExhibitorList = payload => ({
  payload,
  type: action.FETCH_EXHIBITOR_LIST
});

export const fetchExhibitors = payload => ({
  payload,
  type: action.FETCH_EXHIBITORS
});

export const fetchExhibitor = payload => ({
  payload,
  type: action.FETCH_EXHIBITOR
});

export const fetchSurveyPages = payload => ({
  payload,
  type: action.FETCH_SURVEY_PAGES
});

export const fetchVoteRounds = payload => ({
  payload,
  type: action.FETCH_VOTE_ROUNDS
});

export const fetchSmartqa = payload => ({
  payload,
  type: action.FETCH_SMARTQA
});

export const fetchSmartqaPosts = payload => ({
  payload,
  type: action.FETCH_SMARTQA_POSTS
});

export const fetchSmartqaPost = payload => ({
  payload,
  type: action.FETCH_SMARTQA_POST
});

export const fetchSmartqaComments = payload => ({
  payload,
  type: action.FETCH_SMARTQA_COMMENTS
});

export const fetchSpeakerList = payload => ({
  payload,
  type: action.FETCH_SPEAKER_LIST
});

export const fetchSpeakers = payload => ({
  payload,
  type: action.FETCH_SPEAKERS
});

export const fetchSpeaker = payload => ({
  payload,
  type: action.FETCH_SPEAKER
});

export const fetchPracticalInfo = payload => ({
  payload,
  type: action.FETCH_PRACTICAL_INFO
});

export const fetchMap = payload => ({
  payload,
  type: action.FETCH_MAP
});

export const fetchMapPois = payload => ({
  payload,
  type: action.FETCH_MAP_POIS
});

export const fetchSurvey = payload => ({
  payload,
  type: action.FETCH_SURVEY
});

export const fetchVote = payload => ({
  payload,
  type: action.FETCH_VOTE
});

export const fetchSocialMedia = payload => ({
  payload,
  type: action.FETCH_SOCIAL_MEDIA
});

export const fetchTweets = payload => ({
  payload,
  type: action.FETCH_TWEETS
});

export const fetchYoutubeVideos = payload => ({
  payload,
  type: action.FETCH_YOUTUBE_VIDEOS
});

export const fetchCountries = payload => ({
  payload,
  type: action.FETCH_COUNTRIES
});

export const fetchWhosHere = payload => ({
  payload,
  type: action.FETCH_WHOS_HERE
});

export const fetchAttendees = payload => ({
  payload,
  type: action.FETCH_ATTENDEES
});

export const fetchWhosHereConversations = payload => ({
  payload,
  type: action.FETCH_WHOS_HERE_CONVERSATIONS
});

export const fetchWhosHereConversation = payload => ({
  payload,
  type: action.FETCH_WHOS_HERE_CONVERSATION
});

export const fetchWhosHereMessages = payload => ({
  payload,
  type: action.FETCH_WHOS_HERE_MESSAGES
});

export const fetchAttendee = payload => ({
  payload,
  type: action.FETCH_ATTENDEE
});

export const fetchWhosHereMeetings = payload => ({
  payload,
  type: action.FETCH_WHOS_HERE_MEETINGS
});

export const fetchWhosHereMeeting = payload => ({
  payload,
  type: action.FETCH_WHOS_HERE_MEETING
});

export const fetchTimeline = payload => ({
  payload,
  type: action.FETCH_TIMELINE
});

export const fetchTimelineItems = payload => ({
  payload,
  type: action.FETCH_TIMELINE_ITEMS
});

export const fetchFavorites = payload => ({
  payload,
  type: action.FETCH_FAVORITES
});

export const fetchDisclaimer = payload => ({
  payload,
  type: action.FETCH_DISCLAIMER
});

export const fetchOffline = payload => ({
  payload,
  type: action.FETCH_OFFLINE
});

// POST
export const postSmartqaPost = payload => ({
  payload,
  type: action.POST_SMARTQA_POST
});

export const postSmartqaComment = payload => ({
  payload,
  type: action.POST_SMARTQA_COMMENT
});

export const postSmartqaPostReaction = payload => ({
  payload,
  type: action.POST_SMARTQA_POST_REACTION
});

export const postSmartqaPostHideToAnimator = payload => ({
  payload,
  type: action.POST_SMARTQA_POST_HIDE_TO_ANIMATORS
});

export const postSmartqaCommentReaction = payload => ({
  payload,
  type: action.POST_SMARTQA_COMMENT_REACTION
});

export const postSmartqaPostReport = payload => ({
  payload,
  type: action.POST_SMARTQA_POST_REPORT
});

export const postSmartqaCommentReport = payload => ({
  payload,
  type: action.POST_SMARTQA_COMMENT_REPORT
});

export const postFile = payload => ({
  payload,
  type: action.POST_FILE
});

export const postCameraFile = payload => ({
  payload,
  type: action.POST_CAMERA_FILE
});

export const postSurveyAnswers = payload => ({
  payload,
  type: action.POST_SURVEY_ANSWERS
});

export const postVoteAnswers = payload => ({
  payload,
  type: action.POST_VOTE_ANSWERS
});

export const postWhosHereMessage = payload => ({
  payload,
  type: action.POST_WHOS_HERE_MESSAGE
});

export const postWhosHereMeeting = payload => ({
  payload,
  type: action.POST_WHOS_HERE_MEETING
});

export const postWhosHereConversation = payload => ({
  payload,
  type: action.POST_WHOS_HERE_CONVERSATION
});

export const postFavorite = payload => ({
  payload,
  type: action.POST_FAVORITE
});

export const fetchWhosHereMessageUnread = payload => ({
  payload,
  type: action.FETCH_WHOS_HERE_MESSAGES_UNREAD
});

export const postWhosHereMessageRead = payload => ({
  payload,
  type: action.POST_WHOS_HERE_MESSAGES_READ
});

export const deleteFavorite = payload => ({
  payload,
  type: action.DELETE_FAVORITE
});

// USER
export const fetchUser = payload => ({
  payload,
  type: action.FETCH_USER
});
export const postUser = payload => ({
  payload,
  type: action.POST_USER
});
export const putUser = payload => ({
  payload,
  type: action.PUT_USER
});

export const postUserEmail = payload => ({
  payload,
  type: action.POST_USER_EMAIL
});

export const postUserPwd = payload => ({
  payload,
  type: action.POST_USER_PWD
});

export const postUserAvatarFile = payload => ({
  payload,
  type: action.POST_USER_AVATAR_FILE
});

export const postUserIncognito = payload => ({
  payload,
  type: action.POST_USER_INCOGNITO
});

export const postForgotPwd = payload => ({
  payload,
  type: action.POST_FORGOT_PWD
});

export const postResetPwd = payload => ({
  payload,
  type: action.POST_RESET_PWD
});

// DELETE
export const deleteSmartqaPostReaction = payload => ({
  payload,
  type: action.DELETE_SMARTQA_POST_REACTION
});

export const deleteSmartqaCommentReaction = payload => ({
  payload,
  type: action.DELETE_SMARTQA_COMMENT_REACTION
});

export const postWhosHereMeetingStatus = payload => ({
  payload,
  type: action.POST_WHOS_HERE_MEETING_STATUS
});

export const deleteWhosHereMeeting = payload => ({
  payload,
  type: action.DELETE_WHOS_HERE_MEETING
});

export const postWhosHereConversationLeave = payload => ({
  payload,
  type: action.POST_WHOS_HERE_CONVERSATION_LEAVE
});

export const postPushNotifSubscription = payload => ({
  payload,
  type: action.POST_PUSH_NOTIF_SUBSCRIPTION
});

export const deleteUser = payload => ({
  payload,
  type: action.DELETE_USER
});
