import { SUCCESS, FETCH_SPEAKER_LIST } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_SPEAKER_LIST, UPDATED } from '../constants/socket';

export default (state = initialState.speakerLists, { type, payload }) => {
  switch (type) {
    case FETCH_SPEAKER_LIST + SUCCESS:
    case SC_SPEAKER_LIST + UPDATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
