import React from 'react';
import styled from 'styled-components';
import { selectErrors } from '../../selectors/errors';
import { connect } from 'react-redux';
import FlexParent from '../shared/flex/FlexParent';
import FlexItem from '../shared/flex/FlexItem';
import Icon from '../icons/Icon';
import IconReport from '../icons/IconReport';
import { updateErrors } from '../../actions/local';
import IconCloseCross from '../../components/icons/IconCloseCross';
import { motion, AnimatePresence } from 'framer-motion';
import HtmlContent from '../shared/HtmlContent';

const Container = styled(motion.div)`
  position: fixed;
  z-index: 100;
  top: 0px;
  right: -350px;
  max-width: 300px;
  margin: 10px;
  min-height: 50px;
  color: white;
  border-radius: 8px;
  background: repeating-linear-gradient(135deg, #cb0001, #cb0001 10px, #d01213 10px, #d01213 20px);
  padding: 15px;
  text-shadow: 0.075em 0.08em 0.1em rgba(30, 30, 30, 0.8);
  box-shadow: -2px 1px 5px 0px rgba(85, 85, 85, 0.75);
  font-weight: bold;
`;

const Label = styled.div`
  padding-right: 10px;
`;

class ErrorContainer extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      (!prevProps.error.message && this.props.error.message) ||
      (prevProps.error.message &&
        this.props.error.message &&
        prevProps.error.message !== this.props.error.message)
    ) {
      if (this.timeout) {
        this.timeout = clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.props.updateErrors({});
      }, 8000);
    }
  }

  onClickClose = event => {
    this.props.updateErrors({});
  };

  parseErrorDetails = error => {
    if (error && error.errors) {
      return Object.keys(error.errors)
        .map((key, index) => {
          let errors = error.errors[key];
          let out = errors
            .map(error => {
              return error;
            })
            .join('<br/>');
          return out;
        })
        .join('<br/>');
    }
    return null;
  };

  render() {
    let errorsDetails = this.parseErrorDetails(this.props.error);
    return (
      <AnimatePresence>
        {this.props.error.message && (
          <Container
            key="error_message"
            initial={{ x: 0 }}
            animate={{ x: -350 }}
            exit={{ x: 0 }}
            onClick={this.onClickClose}
          >
            <FlexParent alignItems={'center'}>
              <Icon
                icon={IconReport}
                color={'#cb0001'}
                padding={'7px'}
                margin={'0 10px 0 0'}
                width={'40px'}
                height={'40px'}
                borderRadius={'50%'}
                backgroundColor={'#fff'}
              />
              <FlexItem grow={1}>
                <Label>
                  <HtmlContent>{this.props.error.message}</HtmlContent>
                  <HtmlContent>{errorsDetails ? errorsDetails : null}</HtmlContent>
                </Label>
              </FlexItem>
              <Icon
                icon={IconCloseCross}
                position={'absolute'}
                top={'3px'}
                right={'3px'}
                width={'20px'}
                height={'20px'}
                color={'#fff'}
                padding={'5px'}
              />
            </FlexParent>
          </Container>
        )}
      </AnimatePresence>
    );
  }
}

const mapDispatchToProps = {
  updateErrors: updateErrors
};

const mapStateToProps = (state, ownProps) => {
  return {
    error: selectErrors(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorContainer);
