import React from 'react';
import { selectGlobal } from '../../selectors/global';
import { connect } from 'react-redux';
import { searchApp } from '../../actions/api';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  text-align: center;
  font-size: 14rem;
  margin-top: 100px;
  font-weight: bold;
`;

class RedirectContainer extends React.Component {
  state = {
    redirectUrl: null,
    error: false
  };

  constructor(props) {
    super(props);
    this.getSlug();
  }

  getSlug = () => {
    let subdomain = window.location.pathname.replace(/^\/|\/$/g, '');
    if (subdomain) {
      this.props.searchApp({
        subdomain,
        done: response => {
          if (response.data.app_id || response.data.event_id) {
            this.setState({
              redirectUrl: `/apps/${response.data.app_id}${
                response.data.event_id ? `/events/${response.data.event_id}/home` : ''
              }`
            });
          } else {
            this.setState({
              error: true
            });
          }
        }
      });
    } else {
      this.setState({
        error: true
      });
    }
  };

  render() {
    return this.state.redirectUrl ? (
      <Redirect
        to={{
          pathname: this.state.redirectUrl
        }}
      />
    ) : this.state.error ? (
      <ErrorContainer>404</ErrorContainer>
    ) : null;
  }
}

const mapDispatchToProps = {
  searchApp: searchApp
};

const mapStateToProps = (state, ownProps) => {
  return {
    global: selectGlobal(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectContainer);
