import { connect } from 'react-redux';
import { fetchSponsorList, fetchSponsors, fetchSponsor } from '../../actions/api';
import { updateHeader } from '../../actions/local';
import React from 'react';
import SponsorList from './SponsorList';
import ClosedService from '../../components/shared/ClosedService';
import Sponsor from './Sponsor';
import { SEARCH_DELAY } from '../../constants/app';
import { selectCurrentEvent } from '../../selectors/events';
import {
  selectCurrentSponsorList,
  selectFilteredSponsors,
  selectFilteredSponsorsIds,
  selectCurrentSponsor,
  selectIsLoadingSponsors,
  selectHasMoreSponsors
} from '../../selectors/sponsorLists';
import { selectGlobal } from '../../selectors/global';
import { selectHeader } from '../../selectors/header';
import { selectUser } from '../../selectors/user';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class SponsorListContainer extends React.Component {
  state = {
    scrollY: null
  };

  componentDidMount() {
    const { appId, eventId, sponsorListId, sponsorId } = this.props.match.params;
    this.props.fetchSponsorList({ event_id: eventId, list_id: sponsorListId });
    this.props.updateHeader({
      leftBtn: this.props.match.params.sponsorId && window.history.length > 1 ? 'back' : 'nav',
      rightBtn: 'search',
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: sponsorId ? 'sponsor' : 'sponsorlist',
      viewableId: sponsorId ? +sponsorId : +sponsorListId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { appId, eventId, sponsorListId, sponsorId } = this.props.match.params;
    const oldSponsorListId = prevProps.match.params.sponsorListId;
    const oldSponsorId = prevProps.match.params.sponsorId;
    if (sponsorListId !== oldSponsorListId) {
      this.props.fetchSponsorList({ event_id: eventId, list_id: sponsorListId });
    }

    if (sponsorId !== oldSponsorId) {
      this.props.updateHeader({
        leftBtn: sponsorId && window.history.length > 1 ? 'back' : 'nav',
        rightBtn: sponsorId ? null : 'search'
      });

      if (sponsorId) {
        Mixpanel.track('view', {
          viewableType: 'sponsor',
          viewableId: +sponsorId,
          appId: +appId,
          eventId: +eventId,
          userId: this.props.user ? this.props.user.id : null
        });
      }
    }

    if (this.props.global.searchValue !== prevProps.global.searchValue) {
      this.search(this.props.global.searchValue);
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }

    if (!sponsorId && prevProps.match.params.sponsorId && this.state.scrollY) {
      this.restoreScroll();
    }
  }

  restoreScroll = () => {
    window.scrollTo({ top: this.state.scrollY });
    // Recursive to fix infine scroll with ios
    setTimeout(() => {
      if (window.pageYOffset < this.state.scrollY) {
        this.restoreScroll();
      }
    }, 500);
  };

  refreshData = () => {
    const { eventId, sponsorListId } = this.props.match.params;
    this.props.fetchSponsorList({ event_id: eventId, list_id: sponsorListId });
  };

  showItemDetails = itemId => {
    const { appId, eventId, sponsorListId } = this.props.match.params;
    this.setState({ scrollY: window.pageYOffset });
    this.props.history.push(
      `/apps/${appId}/events/${eventId}/sponsor_lists/${sponsorListId}/${itemId}`
    );
  };

  fetchSponsors = data => {
    const { eventId, sponsorListId } = this.props.match.params;
    this.props.fetchSponsors({
      ...data,
      q: this.props.global.searchValue,
      event_id: eventId,
      list_id: sponsorListId
    });
  };

  fetchSponsor = () => {
    const { appId, eventId, sponsorListId, sponsorId } = this.props.match.params;
    this.props.fetchSponsor({
      app_id: appId,
      event_id: eventId,
      list_id: sponsorListId,
      sponsor_id: sponsorId
    });
  };

  search = () => {
    if (this.searchTimeout) {
      this.searchTimeout = clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchSponsors({ reset: true });
    }, SEARCH_DELAY);
  };

  render() {
    if (!this.props.sponsorList) {
      return null;
    }
    const { refreshData, showItemDetails, fetchSponsors, fetchSponsor } = this;
    const {
      sponsorList,
      sponsors,
      sponsorsIds,
      sponsor,
      t,
      history,
      theme,
      event,
      isLoadingSponsors,
      hasMoreSponsors,
      global,
      header
    } = this.props;
    const matchParams = this.props.match.params;
    const { sponsorId } = matchParams;
    const timezone = event.timezone;

    return sponsorList.is_open && !sponsorId ? (
      <SponsorList
        {...{
          matchParams,
          sponsorList,
          sponsors,
          sponsorsIds,
          isLoadingSponsors,
          hasMoreSponsors,
          t,
          history,
          theme,
          global,
          header
        }}
        {...{
          showItemDetails,
          fetchSponsors
        }}
      />
    ) : sponsorList.is_open && sponsorId ? (
      <Sponsor
        {...{ matchParams, sponsor, sponsorId, t, theme, history, timezone }}
        {...{ fetchSponsor }}
      />
    ) : !sponsorList.is_open ? (
      <ClosedService
        icon={getServiceIconByType(sponsorList.custom_icon || 'sponsorlist')}
        name={sponsorList.name}
        theme={theme}
        t={t}
        onRefresh={refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchSponsorList: fetchSponsorList,
  fetchSponsors: fetchSponsors,
  fetchSponsor: fetchSponsor
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    sponsorList: selectCurrentSponsorList(state, ownProps),
    sponsors: selectFilteredSponsors(state, ownProps),
    sponsorsIds: selectFilteredSponsorsIds(state, ownProps),
    sponsor: selectCurrentSponsor(state, ownProps),
    isLoadingSponsors: selectIsLoadingSponsors(state, ownProps),
    hasMoreSponsors: selectHasMoreSponsors(state, ownProps),
    global: selectGlobal(state, ownProps),
    header: selectHeader(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsorListContainer);
