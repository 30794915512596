import Button from '../../components/shared/Button';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconCamera from '../../components/icons/IconCamera';
import IconInvisible from '../../components/icons/IconInvisible';
import IconPictures from '../../components/icons/IconPictures';
import IconCloseCross from '../../components/icons/IconCloseCross';
import IconVisible from '../../components/icons/IconVisible';
import React from 'react';
import styled from 'styled-components';
import { FileInput } from '../../components/shared/smartqa/Styled';
import Textarea from '../../components/shared/form/Textarea';
import Loader from '../../components/shared/Loader';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled.div`
  padding: 15px 0 ${props => (props.commentsCount ? '15px' : '0')};
  border-top: 1px solid ${props => props.theme.dustGrey};
`;

const PreviewImageContainer = styled(motion.div)`
  position: relative;
  height: 120px;
  width: 120px;
  display: block;
  margin: 10px auto;
  background-color: black;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

class AddComment extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  state = {
    message: '',
    pictureUrl: '',
    uploading: false
  };

  onChangeInput = event => {
    this.setState({ message: event.target.value });
  };

  onResizeInput = event => {
    this.setState({ message: event.target.value });
  };

  onFormSubmit = event => {
    event.preventDefault();
    const message = this.state.message;
    const pictureUrl = this.state.pictureUrl;
    this.props.postSmartqaComment({ text: message, picture_url: pictureUrl });
    this.setState({ message: '', pictureUrl: '' });
  };

  onClickCamera = event => {
    if (window.cordova) {
      this.getPictureFromCamera();
    }
  };

  onClickVisibility = event => {
    this.props.setVisibility();
  };

  onClickPicture = event => {
    this.fileInputRef.current.click();
  };

  onChangeFile = event => {
    const file = event.target.files[0];
    this.setState({
      uploading: true
    });
    this.props.uploadCommentFile({
      type: 'smartqaquestioncomment',
      file,
      done: response => {
        if (response && response.data && response.data.url) {
          this.setState({
            pictureUrl: response.data.url
          });
        }
      },
      always: () => {
        this.setState({
          uploading: false
        });
      }
    });
    event.target.value = null;
  };

  onClickRemoveCommentFile = event => {
    this.setState({ pictureUrl: '' });
  };

  getPictureFromCamera = () => {
    navigator.camera.getPicture(
      fileUri => {
        this.setState({
          uploading: true
        });
        this.props.uploadCameraFile({
          fileUri,
          fileName: fileUri.substr(fileUri.lastIndexOf('/') + 1),
          accessToken: this.props.accessToken,
          params: {
            type: 'smartqaquestioncomment',
            smart_qa_id: this.props.smartqa.id,
            smart_qa_question_id: this.props.post.id,
            done: response => {
              if (response && response.data && response.data.url) {
                this.setState({
                  pictureUrl: response.data.url
                });
              }
            },
            always: () => {
              this.setState({
                pictureUrl: false
              });
            }
          }
        });
      },
      error => {
        console.warn(error);
      },
      {
        sourceType: navigator.camera.PictureSourceType.CAMERA,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        correctOrientation: true,
        saveToPhotoAlbum: navigator.camera.PictureSourceType.CAMERA
      }
    );
  };

  render() {
    const { smartqa, user, t, commentsCount, isAnonymous, theme, isSaving, offline } = this.props;
    return (
      <React.Fragment>
        {!offline && (
          <Container commentsCount={commentsCount}>
            <form onSubmit={this.onFormSubmit}>
              <FileInput
                accept={'image/*'}
                ref={this.fileInputRef}
                type="file"
                onChange={this.onChangeFile}
              />
              <Textarea
                type="text"
                placeholder={t('smartqa_add_post_placeholder')}
                value={this.state.message}
                onResize={this.onResizeInput}
                onChange={this.onChangeInput}
                maxRows={3}
              />

              <AnimatePresence>
                {this.state.pictureUrl && (
                  <PreviewImageContainer
                    key={`preview_image`}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <Icon
                      icon={IconCloseCross}
                      position={'absolute'}
                      top={'5px'}
                      right={'5px'}
                      width={'20px'}
                      height={'20px'}
                      color={'#fff'}
                      padding={'5px'}
                      onClick={this.onClickRemoveCommentFile}
                    />
                    <PreviewImage src={this.state.pictureUrl} />
                  </PreviewImageContainer>
                )}
              </AnimatePresence>

              {this.state.uploading && !this.state.pictureUrl ? <Loader /> : null}

              <FlexParent justifyContent={'space-between'} alignItems={'center'}>
                <FlexParent justifyContent={'space-between'}>
                  {smartqa.allows_to_upload_picture ? (
                    <React.Fragment>
                      {navigator.camera ? (
                        <Icon
                          icon={IconCamera}
                          width={'25px'}
                          height={'25px'}
                          color={'#b0b0b0'}
                          margin={'0 6px'}
                          onClick={this.onClickCamera}
                        />
                      ) : (
                        ''
                      )}

                      <Icon
                        icon={IconPictures}
                        width={'25px'}
                        height={'25px'}
                        color={'#b0b0b0'}
                        margin={'0 6px'}
                        onClick={this.onClickPicture}
                      />
                    </React.Fragment>
                  ) : null}

                  {user && smartqa.anonymous === 'enabled' ? (
                    <Icon
                      icon={isAnonymous ? IconInvisible : IconVisible}
                      width={'25px'}
                      height={'25px'}
                      color={isAnonymous ? theme.mainColor : '#b0b0b0'}
                      margin={'0 6px'}
                      onClick={this.onClickVisibility}
                    />
                  ) : null}
                </FlexParent>

                <Button
                  type={'submit'}
                  btntype={'primary'}
                  margin={'3px 0'}
                  disabled={
                    (this.state.message || this.state.pictureUrl) && !this.state.uploading
                      ? false
                      : 'disabled'
                  }
                >
                  {isSaving ? t('btn_publishing') : t('btn_publish')}
                </Button>
              </FlexParent>
            </form>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default AddComment;
