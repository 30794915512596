import React from 'react';
import { connect } from 'react-redux';
import UserRegister from './UserRegister';
import { updateHeader } from '../../../actions/local';
import { postUser, postUserPwd } from '../../../actions/api';
import { selectCurrentEvent } from '../../../selectors/events';
import { selectErrors } from '../../../selectors/errors';
import { Redirect } from 'react-router-dom';
import { selectUser } from '../../../selectors/user';
import Mixpanel from '../../../Mixpanel';
class UserRegisterContainer extends React.Component {
  state = {
    isSaving: false
  };
  componentDidMount() {
    const { appId, eventId } = this.props.match.params;
    if (this.props.event && ['public', 'protected'].indexOf(this.props.event.visibility) === -1) {
      this.navigateToSignin();
    }

    this.props.updateHeader({
      leftBtn: window.history.length > 1 ? 'back' : 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'user-register',
      viewableId: null,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.event && ['public', 'protected'].indexOf(this.props.event.visibility) === -1) {
      this.navigateToSignin();
    }
  }

  postUser = data => {
    this.setState({
      isSaving: true
    });

    const { appId, eventId } = this.props.match.params;
    this.props.postUser({
      ...data,
      ...{
        app_id: +appId,
        event_id: +eventId,
        done: () => {
          this.testUserPwd({
            username: data.email,
            email: data.email,
            password: data.password,
            always: () => {
              this.setState({
                isSaving: false
              });
            }
          });
        },
        fail: () => {
          this.setState({
            isSaving: false
          });
        }
      }
    });
  };

  testUserPwd = data => {
    const { appId, eventId } = this.props.match.params;
    this.props.postUserPwd({
      ...data,
      ...{
        app_id: appId,
        event_id: eventId,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        scope: '*',
        grant_type: 'password'
      }
    });
  };

  navigateToSignin = event => {
    const { appId, eventId } = this.props.match.params;
    const url = `/apps/${appId}${eventId ? `/events/${eventId}` : ''}/signin`;
    this.props.history.replace(url);
  };

  redirect = () => {
    const { appId, eventId } = this.props.match.params;
    const redirectUrl = `/apps/${appId}${eventId ? `/events/${eventId}/home` : ''}`;
    return (
      <Redirect
        to={{
          pathname: redirectUrl,
          state: { from: this.props.location }
        }}
      />
    );
  };

  render() {
    const { t, theme, history, user, errors } = this.props;
    const matchParams = this.props.match.params;
    const { navigateToSignin, postUser } = this;
    const inputErrors = errors.errors || {};
    const isSaving = this.state.isSaving;

    return !user ? (
      <UserRegister
        {...{ t, theme, history, matchParams, postUser, navigateToSignin, inputErrors, isSaving }}
      />
    ) : (
      this.redirect()
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  postUser: postUser,
  postUserPwd: postUserPwd
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    errors: selectErrors(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegisterContainer);
