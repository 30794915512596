import { SUCCESS, FETCH_SMARTQA_COMMENTS, POST_SMARTQA_COMMENT } from '../constants/actionApi';
import { UPDATE_SMART_QA_COMMENT } from '../constants/actionLocal';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_SMART_QA_COMMENT, UPDATED, CREATED } from '../constants/socket';

export default (state = initialState.smartqaComments, { type, payload }) => {
  switch (type) {
    case FETCH_SMARTQA_COMMENTS + SUCCESS:
    case POST_SMARTQA_COMMENT + SUCCESS:
    case UPDATE_SMART_QA_COMMENT:
    case SC_SMART_QA_COMMENT + UPDATED:
    case SC_SMART_QA_COMMENT + CREATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
