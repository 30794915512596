import { connect } from 'react-redux';
import { updateHeader } from '../../actions/local';
import NotFound from './NotFound';
import React from 'react';

import { selectCurrentEvent } from '../../selectors/events';

class NotFoundContainer extends React.Component {
  componentDidMount() {
    this.props.updateHeader({
      isVisible: this.props.event ? true : false,
      leftBtn: this.props.event ? 'nav' : null,
      rightBtn: null,
      rightBtn2: null
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.event || (this.props.event && this.props.event.id !== prevProps.event.id)) {
      this.props.updateHeader({
        isVisible: this.props.event ? true : false,
        leftBtn: this.props.event ? 'nav' : null
      });
    }
  }

  onClickBackBtn = event => {
    window.history.back();
  };

  render() {
    return <NotFound onClickBackBtn={this.onClickBackBtn} />;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader
};

const mapStateToProps = (state, ownProps) => {
  return {
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFoundContainer);
