import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from '../reducers/index';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  return {
    ...createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleware))),
    runSaga: sagaMiddleware.run
  };
}

const store = configureStore();
store.runSaga(rootSaga);

export default store;
