import {
  SUCCESS,
  FETCH_WHOS_HERE_CONVERSATIONS,
  FETCH_WHOS_HERE_CONVERSATION,
  PUT_WHOS_HERE_CONVERSATION,
  POST_WHOS_HERE_CONVERSATION,
  POST_WHOS_HERE_CONVERSATION_LEAVE
} from '../constants/actionApi';
import { addOrUpdateItemsInState, removeItemInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_WHOS_HERE_CONVERSATION, UPDATED, CREATED } from '../constants/socket';

export default (state = initialState.whosHereConversations, { type, payload }) => {
  let userInConversation;
  switch (type) {
    case FETCH_WHOS_HERE_CONVERSATIONS + SUCCESS:
    case FETCH_WHOS_HERE_CONVERSATION + SUCCESS:
    case POST_WHOS_HERE_CONVERSATION + SUCCESS:
    case PUT_WHOS_HERE_CONVERSATION + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    case SC_WHOS_HERE_CONVERSATION + CREATED:
      userInConversation =
        payload.data.attendees.find(attendee => attendee.id === payload.user.id) ||
        payload.data.owner.id === payload.user.id;
      if (userInConversation) {
        return addOrUpdateItemsInState(state, payload.data);
      } else {
        return state;
      }
    case SC_WHOS_HERE_CONVERSATION + UPDATED:
      userInConversation =
        payload.data.attendees.find(attendee => attendee.id === payload.user.id) ||
        payload.data.owner.id === payload.user.id;
      if (userInConversation) {
        return addOrUpdateItemsInState(state, payload.data);
      } else if (!userInConversation && state[payload.data.id]) {
        return removeItemInState(state, payload.data.id);
      } else {
        return state;
      }
    case POST_WHOS_HERE_CONVERSATION_LEAVE + SUCCESS:
      return removeItemInState(state, payload.params.conversation_id);
    default:
      return state;
  }
};
