import React from 'react';
import Card from '../../../components/shared/card/Card';
import Label from '../../../components/shared/form/Label';
import Input from '../../../components/shared/form/Input';
import Button from '../../../components/shared/Button';
import Loader from '../../../components/shared/Loader';
import {
  CONVERSATION_CREATE_ATTENDEES,
  CONVERSATION_EDIT_ATTENDEES,
  CONVERSATION_DETAILS
} from '../../../constants/app';
import SelectAttendees from '../create/SelectAttendees';
import SelectedAttendees from '../create/SelectedAttendees';
import styled from 'styled-components';

const LabelSub = styled.span`
  font-size: 1.1rem;
`;

class ConversationCreate extends React.Component {
  state = {
    description: '',
    selectedAttendees: [],
    isSending: false
  };

  constructor(props) {
    super(props);
    if (props.currentConversation) {
      this.state = {
        id: props.currentConversation.id,
        description: props.currentConversation.description,
        selectedAttendees: props.currentConversation.attendees,
        isSending: false
      };
    } else {
      this.state = {
        description: '',
        selectedAttendees: [],
        isSending: false
      };
    }
  }

  componentDidMount() {
    if (this.props.matchParams.currentId && !this.props.currentConversation) {
      this.props.fetchConversation({ conversation_id: this.props.matchParams.currentId });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.matchParams.currentId && !this.props.currentConversation) {
      this.props.fetchConversation({ conversation_id: this.props.matchParams.currentId });
    }
    if (!prevProps.currentConversation && this.props.currentConversation) {
      this.setState({
        id: this.props.currentConversation.id,
        description: this.props.currentConversation.description,
        selectedAttendees: this.props.currentConversation.attendees
      });
    }
  }

  onFormSubmit = event => {
    event.preventDefault();
    const data = {
      id: this.state.id,
      description: this.state.description,
      attendee_ids: this.state.selectedAttendees.map(attendee => attendee.id),
      done: response => {
        if (this.props.currentConversation) {
          this.props.history.goBack();
        } else {
          this.props.updateView({
            view: CONVERSATION_DETAILS,
            id: response.data.id,
            replace: true
          });
        }
      },
      fail: () => {
        this.setState({
          isSending: false
        });
      }
    };
    this.setState({
      isSending: true
    });
    this.props.postConversation(data);
  };

  onChangeInput = event => {
    this.setState({ description: event.target.value });
  };

  onClickAttendee = event => {
    const id = +event.currentTarget.dataset.id;
    const attendee = this.state.selectedAttendees.filter(att => att.id === id)[0];
    this.unselectAttendee(attendee);
  };

  onClickAddAttendee = () => {
    if (this.props.matchParams.currentId) {
      this.props.updateView({
        view: CONVERSATION_EDIT_ATTENDEES,
        id: this.props.matchParams.currentId
      });
    } else {
      this.props.updateView({ view: CONVERSATION_CREATE_ATTENDEES });
    }
  };

  selectAttendee = attendee => {
    let selectedAttendees = [...this.state.selectedAttendees, attendee];
    this.setState({ selectedAttendees: selectedAttendees });
  };

  unselectAttendee = attendee => {
    let selectedAttendees = this.state.selectedAttendees.filter(att => attendee.id !== att.id);
    this.setState({ selectedAttendees: selectedAttendees });
  };

  render() {
    const { t, fetchAttendees } = this.props;
    const { currentView, hasMoreAttendees, isLoadingAttendees, offline } = this.props;

    return (
      <React.Fragment>
        {this.state.isSending ? <Loader overlay={true} /> : null}

        {[CONVERSATION_CREATE_ATTENDEES, CONVERSATION_EDIT_ATTENDEES].indexOf(currentView) ===
        -1 ? (
          <form onSubmit={this.onFormSubmit}>
            <Card margin={'0 0 20px'} padding={'10px'}>
              <Label htmlFor={'description'}>
                {t('conversation_name')} <LabelSub>({t('optional')})</LabelSub>{' '}
              </Label>
              <Input
                id="description"
                type="text"
                name="description"
                value={this.state.description}
                onChange={this.onChangeInput}
              />
              <SelectedAttendees
                selectedAttendees={this.state.selectedAttendees}
                onClickAddAttendee={this.onClickAddAttendee}
                onClickAttendee={this.onClickAttendee}
                t={t}
              />
            </Card>

            <Button
              display={'block'}
              width={'100%'}
              type={'submit'}
              btntype={'primary'}
              margin={'3px 0'}
              disabled={!offline && this.state.selectedAttendees.length ? false : 'disabled'}
            >
              {t('btn_publish')}
            </Button>
          </form>
        ) : (
          <SelectAttendees
            user={this.props.user}
            theme={this.props.theme}
            attendees={this.props.attendees}
            attendeesIds={this.props.attendeesIds}
            selectedAttendees={this.state.selectedAttendees}
            fetchAttendees={fetchAttendees}
            selectAttendee={this.selectAttendee}
            unselectAttendee={this.unselectAttendee}
            hasMoreAttendees={hasMoreAttendees}
            isLoadingAttendees={isLoadingAttendees}
            t={t}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ConversationCreate;
