import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M20.8,10h-1.6V7.2c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7V10H4.3C3.6,10,3,10.6,3,11.3v12 c0,0.7,0.6,1.3,1.3,1.3h16.6c0.7,0,1.3-0.6,1.3-1.3v-12C22.1,10.6,21.5,10,20.8,10z M7.6,7.2c0-2.7,2.2-4.9,4.9-4.9s4.9,2.2,4.9,4.9 V10H7.6V7.2z M20.3,22.8l-15.5,0l0-11l1.1,0v0l0,0l14.4,0V22.8z" />
      <path d="M11.5,18.7v1c0,0.5,0.5,0.9,1.1,0.9c0.6,0,1.1-0.4,1.1-0.9v-1c0.8-0.4,1.4-1.2,1.4-2.2c0-1.4-1.1-2.5-2.5-2.5 c-1.4,0-2.5,1.1-2.5,2.5C10.1,17.5,10.7,18.3,11.5,18.7z" />
    </g>
  </svg>
);
