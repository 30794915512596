import React from 'react';
import styled from 'styled-components';
import VideoItem from './VideoItem';
import Loader from '../../components/shared/Loader';

const Player = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: #000;
`;

const ResponsivePlayer = styled.div`
  position: relative;
  padding-bottom: 56.25% /* 16:9 */;
  padding-top: 25px;
  height: 0;
`;

class Youtube extends React.Component {
  state = { videoLoaded: false };

  componentDidMount() {
    this.props.fetchYoutubeVideos();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentVideo !== prevProps.currentVideo) {
      this.setState({ videoLoaded: false });
    }
  }

  videoIsLoaded = () => {
    this.setState({ videoLoaded: true });
  };

  render() {
    const { youtubeVideos, currentVideo, setCurrentVideo } = this.props;
    return (
      <React.Fragment>
        {youtubeVideos && currentVideo ? (
          <ResponsivePlayer>
            <Player
              key={currentVideo.contentDetails.videoId}
              onLoad={this.videoIsLoaded}
              type="text/html"
              src={`https://www.youtube.com/embed/${currentVideo.contentDetails.videoId}?autoplay=1&rel=0`}
            />
          </ResponsivePlayer>
        ) : null}
        {currentVideo && !this.state.videoLoaded ? <Loader /> : null}

        {Object.keys(youtubeVideos).length ? (
          Object.keys(youtubeVideos).map((key, index) => {
            const video = youtubeVideos[key];
            return <VideoItem key={video.id} video={video} {...{ setCurrentVideo }} />;
          })
        ) : (
          <Loader />
        )}
      </React.Fragment>
    );
  }
}

export default Youtube;
