import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M12.6,20.2c-0.5,0-1,0.4-1,1s0.4,1,1,1c0.5,0,1-0.4,1-1S13.1,20.2,12.6,20.2z M10,20.3c0.4-1.1,1.4-1.8,2.6-1.8 c1.2,0,2.2,0.8,2.6,1.8H25v1.8h-9.8c-0.4,1.1-1.4,1.8-2.6,1.8c-1.2,0-2.2-0.8-2.6-1.8H0v-1.8H10z" />
      <path d="M5.6,12.1c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1s1-0.4,1-1C6.5,12.5,6.1,12.1,5.6,12.1z M5.6,10.3 c1.2,0,2.2,0.7,2.5,1.8H25v1.8H8.2c-0.3,1.1-1.4,1.9-2.6,1.9c-1.2,0-2.3-0.8-2.6-1.9H0v-1.8h3C3.4,11,4.4,10.3,5.6,10.3z" />
      <path d="M20.9,4.3c-0.4-1.1-1.4-1.8-2.6-1.8c-1.2,0-2.2,0.8-2.6,1.8H0V6h15.7c0.4,1.1,1.4,1.8,2.6,1.8 c1.2,0,2.2-0.8,2.6-1.8H25V4.3H20.9z M18.3,4.2c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1C17.3,4.6,17.8,4.2,18.3,4.2z" />
    </g>
  </svg>
);
