import { isSameDay, applyTimezone } from '../helpers/date';
import { filterObjects, filterIds } from '../helpers/data';
import { createSelector } from 'reselect';
import { API_AGENDA_CATEGORIES, API_AGENDA_SESSIONS } from '../constants/apiRoutes';

const currentAgendaId = (state, props) => props.match.params.agendaId;
const currentSessionId = (state, props) => props.match.params.sessionId;
const event = (state, props) => state.events[props.match.params.eventId];
const agendas = (state, props) => state.agendas;
const sessions = state => state.agendaSessions;
const sessionsIds = (state, props) => state.sortIds[API_AGENDA_SESSIONS] || [];
const categories = (state, props) => state.agendaCategories;
const categoriesIds = (state, props) => state.sortIds[API_AGENDA_CATEGORIES] || [];
const global = (state, props) => state.global;

// --- Select current object --------------
export const selectCurrentAgenda = createSelector(
  [agendas, currentAgendaId],
  (agendas, currentAgendaId) => {
    return agendas[currentAgendaId] || null;
  }
);

export const selectCurrentSession = createSelector(
  [sessions, currentSessionId],
  (sessions, sessionId) => {
    return sessions[sessionId] || null;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredCategories = createSelector(
  [currentAgendaId, categories],
  (agendaId, categories) => {
    return filterObjects(categories, category => {
      return category.agenda_id === +agendaId;
    });
  }
);

export const selectFilteredCurrentAgendaSessions = createSelector(
  [sessions, currentAgendaId],
  (sessions, agendaId) => {
    return filterObjects(sessions, session => {
      return session.agenda_id === +agendaId;
    });
  }
);

export const selectFilteredSessions = createSelector(
  [global, event, selectFilteredCurrentAgendaSessions],
  (global, event, sessions) => {
    const dateFilter = global.agendaCurrentDate;
    const categoriesFilters = global.agendaCategoriesFilters;
    let categoriesIds = null;

    if (dateFilter) {
      sessions = filterObjects(sessions, session => {
        return isSameDay(dateFilter, applyTimezone(session.start_date, event.timezone));
      });
    }

    if (categoriesFilters.length) {
      sessions = filterObjects(sessions, session => {
        categoriesIds = session.categories.map(cat => cat.id);
        return containAnyCategories(categoriesFilters, categoriesIds);
      });
    }
    return sessions;
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredSessionsIds = createSelector(
  [sessionsIds, selectFilteredSessions],
  (sessionsIds, sessions) => {
    return filterIds(sessionsIds, sessions);
  }
);

export const selectFilteredCategoriesIds = createSelector(
  [categoriesIds, selectFilteredCategories],
  (categoriesIds, categories) => {
    return filterIds(categoriesIds, categories);
  }
);
// ----------------------------------------

export const selectAgendaCurrentDate = createSelector(
  [global],
  global => {
    return global.agendaCurrentDate;
  }
);

const containAnyCategories = (categoriesFilters, categoriesIds) => {
  return categoriesFilters.some(id => categoriesIds.indexOf(id) >= 0);
};
