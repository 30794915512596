import { connect } from 'react-redux';
import { fetchFavorites } from '../../actions/api';
import { updateHeader, updateGlobal } from '../../actions/local';

import React from 'react';
import FavoriteList from './FavoriteList';

import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';
import { selectFilteredFavorites, selectFilteredFavoritesIds } from '../../selectors/favorites';
import { selectCurrentEvent } from '../../selectors/events';
import { selectMenuItems } from '../../selectors/menuItems';
import Mixpanel from '../../Mixpanel';
class FavoriteContainer extends React.Component {
  state = {
    type: 'sessions',
    existingFavorites: null
  };

  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    const { appId, eventId } = this.props.match.params;
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    this.fetchExistingFavorites();

    Mixpanel.track('view', {
      viewableType: 'star',
      viewableId: null,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { appId, eventId, currentTab } = this.props.match.params;

    if (!currentTab && this.state.existingFavorites) {
      this.props.history.replace(
        `/apps/${appId}/events/${eventId}/favorites/${this.state.existingFavorites[0]}`
      );
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.fetchExistingFavorites();
    }

    const scrollY = window.localStorage.getItem(`scrollY_fav`);
    if (this.props.favoritesIds.length && scrollY && this.state.existingFavorites && currentTab) {
      window.scrollTo({ top: scrollY });
      window.localStorage.removeItem(`scrollY_fav`);
    }
  }

  updateTab = ({ tab }) => {
    const { appId, eventId } = this.props.match.params;
    this.props.history.push(`/apps/${appId}/events/${eventId}/favorites/${tab}`);
  };

  fetchFavorites = data => {
    const { appId, eventId, currentTab } = this.props.match.params;
    this.props.fetchFavorites({
      ...{
        app_id: +appId,
        event_id: +eventId,
        type: currentTab
      },
      ...data
    });
  };

  fetchExistingFavorites = () => {
    const { appId, eventId } = this.props.match.params;
    this.props.fetchFavorites({
      app_id: +appId,
      event_id: +eventId,
      type: 'exist',
      done: response => {
        const existingFavorites = Object.keys(response.data).filter(function(key) {
          return response.data[key];
        });
        this.setState({
          existingFavorites
        });
      }
    });
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  render() {
    if (!this.props.match.params.currentTab || !this.state.existingFavorites) {
      return null;
    }
    const {
      t,
      theme,
      history,
      header,
      favorites,
      favoritesIds,
      isLoadingFavorites,
      hasMoreFavorites,
      event,
      global
    } = this.props;
    const { height: headerHeight } = header;
    const { fetchFavorites, updateTab, setComponentFixedTopHeight } = this;
    const currentTab = this.props.match.params.currentTab;
    const matchParams = this.props.match.params;
    const { componentFixedTopHeight } = global;
    const existingFavorites = this.state.existingFavorites;

    let whosHereId = 0;

    const whosHereMenuItemId = Object.keys(this.props.menuItems).find(id => {
      return this.props.menuItems[id].type === 'whoshere';
    });

    if (whosHereMenuItemId) {
      whosHereId = this.props.menuItems[whosHereMenuItemId].items[0].id;
    }

    return (
      <FavoriteList
        {...{
          t,
          theme,
          matchParams,
          currentTab,
          updateTab,
          favorites,
          favoritesIds,
          fetchFavorites,
          isLoadingFavorites,
          hasMoreFavorites,
          setComponentFixedTopHeight,
          headerHeight,
          history,
          event,
          componentFixedTopHeight,
          existingFavorites,
          whosHereId
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchFavorites: fetchFavorites,
  updateGlobal: updateGlobal
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    favorites: selectFilteredFavorites(state, ownProps),
    favoritesIds: selectFilteredFavoritesIds(state, ownProps),
    global: selectGlobal(state, ownProps),
    header: selectHeader(state, ownProps),
    menuItems: selectMenuItems(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavoriteContainer);
