import { SUCCESS, FETCH_SOCIAL_MEDIA } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_SOCIAL_MEDIA, UPDATED } from '../constants/socket';

export default (state = initialState.socialMedia, { type, payload }) => {
  switch (type) {
    case FETCH_SOCIAL_MEDIA + SUCCESS:
    case SC_SOCIAL_MEDIA + UPDATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
