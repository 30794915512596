import React from 'react';
import Button from '../../../components/shared/Button';
import FlexParent from '../../../components/shared/flex/FlexParent';
import FlexItem from '../../../components/shared/flex/FlexItem';
import styled from 'styled-components';
import FormGroup from '../../../components/shared/form/FormGroup';
import Label from '../../../components/shared/form/Label';
import Input from '../../../components/shared/form/Input';

const Container = styled.div`
  margin-top: 100px;
`;

class ResetPassword extends React.Component {
  state = {
    email: '',
    password: '',
    error: ''
  };

  onSubmitForm = event => {
    event.preventDefault();
    this.props.resetPwd({
      token: this.props.token,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password,
      done: () => {
        this.props.testUserPwd({
          email: this.state.email,
          username: this.state.email,
          password: this.state.password,
          done: () => {
            this.props.redirect();
          }
        });
      }
    });
  };

  onChangeInput = event => {
    let value = {};
    value[event.currentTarget.name] = event.currentTarget.value;
    this.setState(value);
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <form onSubmit={this.onSubmitForm}>
          <FormGroup>
            <Label htmlFor="email">{t('email')}</Label>
            <Input
              id="email"
              type="text"
              name="email"
              placeholder={t('email')}
              value={this.state.email}
              onChange={this.onChangeInput}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="password">{t('new_password')}</Label>
            <Input
              id="password"
              type="password"
              name="password"
              placeholder={t('new_password')}
              value={this.state.password}
              onChange={this.onChangeInput}
            />
          </FormGroup>

          <FlexParent alignItems={'center'}>
            <FlexItem grow={1} />

            <FlexItem>
              <Button
                type={'submit'}
                btntype={'primary'}
                margin={'3px 0'}
                disabled={!this.state.password || !this.state.email ? 'disabled' : false}
              >
                {t('btn_validate')}
              </Button>
            </FlexItem>
          </FlexParent>
        </form>
      </Container>
    );
  }
}

export default ResetPassword;
