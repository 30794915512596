import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M21.9,21.7H3l0-2.3c1.1-0.3,1.2-0.6,1.2-0.8V9.8c0-3.9,2.6-7.1,6.3-8V0h3.9v1.8c3.7,0.9,6.3,4.2,6.3,8v8.7 c0,0.2,0.1,0.5,1.2,0.8L21.9,21.7z M6.4,19.4h12.2c-0.1-0.3-0.1-0.6-0.1-0.9V9.8c0-3-2.3-5.6-5.3-5.9l-0.7-0.1l-0.7,0.1 c-3,0.3-5.3,2.9-5.3,5.9v8.7C6.5,18.8,6.5,19.1,6.4,19.4z" />
      <path d="M10.2,22.4c0,0.9,0.5,2.6,2.2,2.6s2.2-1.6,2.2-2.6H10.2z" />
    </g>
  </svg>
);
