import { connect } from 'react-redux';
import { fetchExhibitorList, fetchExhibitors, fetchExhibitor } from '../../actions/api';
import { updateHeader } from '../../actions/local';
import ExhibitorList from './ExhibitorList';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';
import Exhibitor from './Exhibitor';
import { SEARCH_DELAY } from '../../constants/app';
import { selectCurrentEvent } from '../../selectors/events';
import {
  selectCurrentExhibitorList,
  selectFilteredExhibitors,
  selectFilteredExhibitorsIds,
  selectCurrentExhibitor,
  selectIsLoadingExhibitors,
  selectHasMoreExhibitors
} from '../../selectors/exhibitorLists';
import { selectGlobal } from '../../selectors/global';
import { selectHeader } from '../../selectors/header';
import { selectUser } from '../../selectors/user';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class ExhibitorListContainer extends React.Component {
  state = {
    scrollY: null
  };

  componentDidMount() {
    const { appId, eventId, exhibitorListId, exhibitorId } = this.props.match.params;
    this.props.fetchExhibitorList({ event_id: eventId, list_id: +exhibitorListId });

    this.props.updateHeader({
      leftBtn: this.props.match.params.exhibitorId && window.history.length > 1 ? 'back' : 'nav',
      rightBtn: 'search',
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: exhibitorId ? 'exhibitor' : 'exhibitorlist',
      viewableId: exhibitorId ? +exhibitorId : +exhibitorListId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { appId, eventId, exhibitorListId, exhibitorId } = this.props.match.params;
    const oldExhibitorListId = prevProps.match.params.exhibitorListId;
    const oldExhibitorId = prevProps.match.params.exhibitorId;
    if (exhibitorListId !== oldExhibitorListId) {
      this.props.fetchExhibitorList({ event_id: eventId, list_id: +exhibitorListId });
    }

    if (exhibitorId !== oldExhibitorId) {
      this.props.updateHeader({
        leftBtn: exhibitorId && window.history.length > 1 ? 'back' : 'nav',
        rightBtn: exhibitorId ? null : 'search'
      });

      if (exhibitorId) {
        Mixpanel.track('view', {
          viewableType: 'exhibitor',
          viewableId: +exhibitorId,
          appId: +appId,
          eventId: +eventId,
          userId: this.props.user ? this.props.user.id : null
        });
      }
    }

    if (this.props.global.searchValue !== prevProps.global.searchValue) {
      this.search();
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }

    if (!exhibitorId && prevProps.match.params.exhibitorId && this.state.scrollY) {
      this.restoreScroll();
    }
  }

  restoreScroll = () => {
    window.scrollTo({ top: this.state.scrollY });
    // Recursive to fix infine scroll with ios
    setTimeout(() => {
      if (window.pageYOffset < this.state.scrollY) {
        this.restoreScroll();
      }
    }, 500);
  };

  refreshData = event => {
    const { eventId, exhibitorListId } = this.props.match.params;
    this.props.fetchExhibitorList({ event_id: eventId, list_id: +exhibitorListId });
  };

  showItemDetails = itemId => {
    const { appId, eventId, exhibitorListId } = this.props.match.params;
    this.setState({ scrollY: window.pageYOffset });
    this.props.history.push(
      `/apps/${appId}/events/${eventId}/exhibitor_lists/${exhibitorListId}/${itemId}`
    );
  };

  fetchExhibitors = data => {
    const { eventId, exhibitorListId } = this.props.match.params;
    this.props.fetchExhibitors({
      ...data,
      q: this.props.global.searchValue,
      event_id: eventId,
      list_id: +exhibitorListId
    });
  };

  fetchExhibitor = () => {
    const { appId, eventId, exhibitorListId, exhibitorId } = this.props.match.params;
    this.props.fetchExhibitor({
      app_id: appId,
      event_id: eventId,
      list_id: exhibitorListId,
      exhibitor_id: exhibitorId
    });
  };

  search = () => {
    if (this.searchTimeout) {
      this.searchTimeout = clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchExhibitors({ reset: true });
    }, SEARCH_DELAY);
  };

  render() {
    if (!this.props.exhibitorList) {
      return null;
    }
    // functions
    const { refreshData, showItemDetails, fetchExhibitors, fetchExhibitor } = this;
    // properties
    const {
      exhibitorList,
      exhibitors,
      exhibitorsIds,
      exhibitor,
      t,
      history,
      theme,
      event,
      isLoadingExhibitors,
      hasMoreExhibitors,
      global,
      header
    } = this.props;
    const matchParams = this.props.match.params;
    const { exhibitorId } = matchParams;
    const timezone = event.timezone;

    return exhibitorList.is_open && !exhibitorId ? (
      <ExhibitorList
        {...{
          matchParams,
          exhibitorList,
          exhibitors,
          exhibitorsIds,
          isLoadingExhibitors,
          hasMoreExhibitors,
          t,
          history,
          theme,
          global,
          header
        }}
        {...{
          showItemDetails,
          fetchExhibitors
        }}
      />
    ) : exhibitorList.is_open && exhibitorId ? (
      <Exhibitor
        {...{ matchParams, exhibitor, exhibitorId, t, theme, history, timezone }}
        {...{ fetchExhibitor }}
      />
    ) : !exhibitorList.is_open ? (
      <ClosedService
        icon={getServiceIconByType(exhibitorList.custom_icon || 'exhibitorlist')}
        name={exhibitorList.name}
        theme={theme}
        t={t}
        onRefresh={refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchExhibitorList: fetchExhibitorList,
  fetchExhibitors: fetchExhibitors,
  fetchExhibitor: fetchExhibitor
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    exhibitorList: selectCurrentExhibitorList(state, ownProps),
    exhibitors: selectFilteredExhibitors(state, ownProps),
    exhibitorsIds: selectFilteredExhibitorsIds(state, ownProps),
    exhibitor: selectCurrentExhibitor(state, ownProps),
    isLoadingExhibitors: selectIsLoadingExhibitors(state, ownProps),
    hasMoreExhibitors: selectHasMoreExhibitors(state, ownProps),
    global: selectGlobal(state, ownProps),
    header: selectHeader(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExhibitorListContainer);
