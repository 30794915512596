import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M3.5,9C1.6,9,0,10.6,0,12.5S1.6,16,3.5,16S7,14.4,7,12.5S5.4,9,3.5,9" />
      <path d="M21.5,9c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S23.4,9,21.5,9" />
      <path d="M12.5,9C10.6,9,9,10.6,9,12.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S14.4,9,12.5,9" />
    </g>
  </svg>
);
