import React from 'react';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import {
  TAB_FAV_SESSIONS,
  TAB_FAV_SPEAKERS,
  TAB_FAV_SPONSORS,
  TAB_FAV_EXHIBITORS,
  TAB_FAV_ATTENDEES,
  TAB_FAV_BONUSES
} from '../../constants/app';
import Tabs from '../../components/shared/tabs/Tabs';
import AgendaSession from './AgendaSession';
import BonusItem from '../bonus-list/BonusItem';
import styled from 'styled-components';
import ListItem from '../../components/shared/ListItem';
import Card from '../../components/shared/card/Card';
import { getFullName, getJobWithCompany } from '../../helpers/format';
import Separator from '../../components/shared/Separator';
import { isSameDay, applyTimezone, formatDate } from '../../helpers/date';
import SwipeContainer from '../../components/swipe/SwipeContainer';

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;
class FavoriteList extends React.Component {
  componentDidMount() {
    this.props.fetchFavorites({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentTab !== prevProps.currentTab) {
      this.props.fetchFavorites({ reset: true });
    }
  }

  showItemDetails = (serviceName, serviceId, itemId) => {
    const { appId, eventId } = this.props.matchParams;
    window.localStorage.setItem(`scrollY_fav`, window.pageYOffset);

    if (serviceName !== 'whos_here') {
      this.props.history.push(
        `/apps/${appId}/events/${eventId}/${serviceName}/${serviceId}/${itemId}`
      );
    } else {
      this.props.history.push(
        `/apps/${appId}/events/${eventId}/${serviceName}/${this.props.whosHereId}/tab_attendees/attendee_details/${itemId}`
      );
    }
  };

  onSwipeEnd = event => {
    const pos = this.tabs
      .map(function(e) {
        return e.slug;
      })
      .indexOf(this.props.currentTab);

    if (event.x > 150 && this.tabs[pos - 1]) {
      this.props.updateTab({ tab: this.tabs[pos - 1].slug });
    } else if (event.x < -150 && this.tabs[pos + 1]) {
      this.props.updateTab({ tab: this.tabs[pos + 1].slug });
    }
  };

  render() {
    const {
      t,
      theme,
      favorites,
      favoritesIds,
      fetchFavorites,
      isLoadingFavorites,
      hasMoreFavorites,
      currentTab,
      updateTab,
      setComponentFixedTopHeight,
      componentFixedTopHeight,
      headerHeight,
      matchParams,
      history,
      event,
      existingFavorites
    } = this.props;

    const timezone = event.timezone;
    let previousItemDate = null;
    let date;
    let isSame;

    this.tabs = [];

    if (existingFavorites.indexOf(TAB_FAV_ATTENDEES) !== -1) {
      this.tabs.push({
        slug: TAB_FAV_ATTENDEES,
        label: t('tab_fav_attendees')
      });
    }

    if (existingFavorites.indexOf(TAB_FAV_BONUSES) !== -1) {
      this.tabs.push({
        slug: TAB_FAV_BONUSES,
        label: t('tab_fav_bonuses')
      });
    }

    if (existingFavorites.indexOf(TAB_FAV_EXHIBITORS) !== -1) {
      this.tabs.push({
        slug: TAB_FAV_EXHIBITORS,
        label: t('tab_fav_exhibitors')
      });
    }

    if (existingFavorites.indexOf(TAB_FAV_SESSIONS) !== -1) {
      this.tabs.push({
        slug: TAB_FAV_SESSIONS,
        label: t('tab_fav_sessions')
      });
    }

    if (existingFavorites.indexOf(TAB_FAV_SPEAKERS) !== -1) {
      this.tabs.push({
        slug: TAB_FAV_SPEAKERS,
        label: t('tab_fav_speakers')
      });
    }

    if (existingFavorites.indexOf(TAB_FAV_SPONSORS) !== -1) {
      this.tabs.push({
        slug: TAB_FAV_SPONSORS,
        label: t('tab_fav_sponsors')
      });
    }

    return (
      <SwipeContainer onSwipeEnd={this.onSwipeEnd}>
        {existingFavorites.length ? (
          <React.Fragment>
            <Tabs
              {...{
                t,
                theme,
                currentTab,
                updateTab,
                setComponentFixedTopHeight,
                headerHeight
              }}
              tabs={this.tabs}
            />

            <Container componentFixedTopHeight={componentFixedTopHeight}>
              <InfiniteScroll
                loadMore={fetchFavorites}
                hasMore={hasMoreFavorites}
                isLoading={isLoadingFavorites}
              >
                {favoritesIds.map(key => {
                  const favorite = favorites[key];
                  const { id, model } = favorite;

                  if (currentTab === TAB_FAV_SESSIONS) {
                    date = applyTimezone(model.start_date, timezone);
                    isSame = previousItemDate && isSameDay(previousItemDate, date);
                    previousItemDate = date;
                  }

                  return (
                    <React.Fragment key={id}>
                      {currentTab === TAB_FAV_SESSIONS ? (
                        <React.Fragment>
                          {!isSame ? (
                            <Separator>{formatDate(date, 'dddd, MMMM Do')}</Separator>
                          ) : null}
                          <AgendaSession
                            {...{ model: model, matchParams, theme, t, timezone, history }}
                            type={'agenda_session'}
                            favorite_id={id}
                          />
                        </React.Fragment>
                      ) : currentTab === TAB_FAV_BONUSES ? (
                        <React.Fragment>
                          <BonusItem
                            bonus={model}
                            theme={theme}
                            matchParams={matchParams}
                            favorite_id={id}
                          />
                        </React.Fragment>
                      ) : currentTab === TAB_FAV_SPEAKERS ? (
                        <ListItem
                          id={model.id}
                          favorite_id={id}
                          model={model}
                          type={'speaker'}
                          heading={getFullName(model.first_name, model.last_name, model.title)}
                          desc={getJobWithCompany(model.company, model.job_title)}
                          logoUrl={model.avatar_url}
                          isVip={model.is_vip}
                          theme={theme}
                          onClickItem={() => {
                            this.showItemDetails('speaker_lists', model.list_id, model.id);
                          }}
                          matchParams={matchParams}
                        />
                      ) : currentTab === TAB_FAV_SPONSORS ? (
                        <ListItem
                          id={model.id}
                          favorite_id={id}
                          model={model}
                          type={'sponsor'}
                          heading={model.company}
                          desc={model.desc}
                          logoUrl={model.logo_url}
                          isVip={model.is_vip}
                          theme={theme}
                          onClickItem={() => {
                            this.showItemDetails('sponsor_lists', model.list_id, model.id);
                          }}
                          matchParams={matchParams}
                        />
                      ) : currentTab === TAB_FAV_EXHIBITORS ? (
                        <ListItem
                          id={model.id}
                          favorite_id={id}
                          model={model}
                          type={'exhibitor'}
                          heading={model.company}
                          desc={model.desc}
                          logoUrl={model.logo_url}
                          isVip={model.is_vip}
                          theme={theme}
                          onClickItem={() => {
                            this.showItemDetails('exhibitor_lists', model.list_id, model.id);
                          }}
                          matchParams={matchParams}
                        />
                      ) : currentTab === TAB_FAV_ATTENDEES ? (
                        <ListItem
                          id={model.id}
                          favorite_id={id}
                          model={model}
                          type={'attendee'}
                          heading={model.name}
                          desc={getJobWithCompany(
                            model.job_title,
                            model.company,
                            model.country_name
                          )}
                          logoUrl={model.avatar_url}
                          isVip={model.is_vip}
                          theme={theme}
                          onClickItem={() => {
                            this.showItemDetails('whos_here', model.whos_here_id, model.id);
                          }}
                          matchParams={matchParams}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </InfiniteScroll>
            </Container>
          </React.Fragment>
        ) : (
          <Card padding={'15px'} margin={'10px 0 0'} textAlign={'center'}>
            {t('no_favorite_yet')}
          </Card>
        )}
      </SwipeContainer>
    );
  }
}

export default FavoriteList;
