export const UPDATE_HEADER = 'UPDATE_HEADER';
export const UPDATE_NAV = 'UPDATE_NAV';
export const UPDATE_THEME = 'UPDATE_THEME';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const RESET_NOTIF = 'RESET_NOTIF';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_GLOBAL = 'UPDATE_GLOBAL';
export const RESET_EVENT = 'RESET_EVENT';
export const RESET_EVENTS = 'RESET_EVENTS';

export const UPDATE_SMART_QA_POST = 'UPDATE_SMART_QA_POST';
export const UPDATE_SMART_QA_COMMENT = 'UPDATE_SMART_QA_COMMENT';
export const UPDATE_FAV_MODEL = 'UPDATE_FAV_MODEL';

export const USER_LOGOUT = 'USER_LOGOUT';

export const UPDATE_SURVEY_ANSWERS = 'UPDATE_SURVEY_ANSWERS';
export const UPDATE_VOTE_ANSWERS = 'UPDATE_VOTE_ANSWERS';

export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
