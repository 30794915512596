import React from 'react';
import styled from 'styled-components';
import Icon from '../../components/icons/Icon';
import IconCloseCross from '../../components/icons/IconCloseCross';
import IconSearch from '../../components/icons/IconSearch';

const SearchInputContainer = styled.div`
  position: relative;
  padding: 5px 15px;
`;

const SearchInput = styled.input`
  width: 100%;
  margin: 3px 0 2px;
  padding: 0 45px 0 35px;
  background-color: #e4e4e4;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  height: 36px;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  :focus {
    outline: none;
  }
`;

class SearchBar extends React.Component {
  state = {
    autofocus: true
  };

  onChangeSearchValue = event => {
    this.props.onChangeSearchValue(event.currentTarget.value);
  };

  onClickClearSearch = () => {
    this.props.onChangeSearchValue('');
    this.setState({ autofocus: false }, () => {
      this.setState({ autofocus: true });
    });
  };
  render() {
    return (
      <SearchInputContainer>
        <Icon
          icon={IconSearch}
          position={'absolute'}
          top={'8px'}
          left={'15px'}
          width={'36px'}
          height={'36px'}
          color={'#757575'}
          padding={'10px'}
          onClick={this.onClickClearSearch}
          borderRadius={'8px'}
        />

        {this.state.autofocus && (
          <SearchInput
            type="text"
            value={this.props.searchValue}
            placeholder={`${this.props.t('placeholder_search')}`}
            onChange={this.onChangeSearchValue}
            autoFocus={'autofocus'}
          />
        )}

        <Icon
          icon={IconCloseCross}
          position={'absolute'}
          top={'19px'}
          right={'22px'}
          width={'15px'}
          height={'15px'}
          color={'#e4e4e4'}
          padding={'4px'}
          onClick={this.onClickClearSearch}
          backgroundColor={'#757575'}
          borderRadius={'50%'}
        />
      </SearchInputContainer>
    );
  }
}

export default SearchBar;
