import styled from 'styled-components';
import React from 'react';
import { fetchOffline } from '../../actions/api';
import { selectGlobal } from '../../selectors/global';
import FlexParent from '../shared/flex/FlexParent';
import FlexItem from '../shared/flex/FlexItem';
import Icon from '../icons/Icon';
import IconOffline from '../icons/IconOffline';
import { connect } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled(motion.div)`
  position: fixed;
  z-index: 100;
  top: 7px;
  right: -350px;
  max-width: 300px;
  min-width: 260px;
  color: white;
  border-radius: 4px;
  background: repeating-linear-gradient(135deg, #cfcfcf, #cfcfcf 10px, #d5d5d5 10px, #d5d5d5 20px);
  padding: 3px 5px;
  text-shadow: 0.075em 0.08em 0.1em rgba(30, 30, 30, 0.8);
  box-shadow: -2px 1px 5px 0px rgba(85, 85, 85, 0.75);
  font-weight: bold;
`;

const Label = styled.div`
  padding-right: 10px;
`;

class OfflineContainer extends React.Component {
  state = {
    time: 15000,
    timeoutDelay: 1000
  };

  componentDidMount() {
    window.addEventListener(
      'offline',
      () => {
        this.setState({ time: -1000 });
      },
      false
    );
    window.addEventListener(
      'online',
      () => {
        this.setState({ time: -1000 });
      },
      false
    );
    this.decreaseTime();
  }

  decreaseTime() {
    this.timeout = setTimeout(() => {
      this.setState({ time: this.state.time - 1000 });
      if (this.state.time <= -1000) {
        this.fetchOffline();
      } else {
        this.decreaseTime();
      }
    }, this.state.timeoutDelay);
  }

  componentWillUnmount() {
    if (this.timeout) {
      this.timeout = clearTimeout(this.timeout);
    }
  }

  fetchOffline = () => {
    let { appId } = this.props.match.params;
    this.props.fetchOffline({
      app_id: +appId,
      always: () => {
        this.setState({ time: 15000, timeoutDelay: 1000 });
        this.decreaseTime();
      }
    });
  };

  onClickRetry = event => {
    event.preventDefault();
    this.setState({ timeoutDelay: 50 });
  };

  render() {
    return (
      <AnimatePresence>
        {this.props.global.offline && (
          <Container
            key="success_message"
            initial={{ x: 0 }}
            animate={{ x: -355 }}
            exit={{ x: 0 }}
            onClick={this.onClickRetry}
          >
            <FlexParent alignItems={'center'}>
              <FlexItem>
                <Icon
                  icon={IconOffline}
                  color={'#cfcfcf'}
                  padding={'3px'}
                  margin={'0 10px 0 0'}
                  width={'25px'}
                  height={'25px'}
                  borderRadius={'50%'}
                  backgroundColor={'#fff'}
                />
              </FlexItem>

              <FlexItem grow={1}>
                <Label>
                  {this.props.t('offline_message')}{' '}
                  {this.state.time > 0
                    ? `${this.props.t('retry_in')} ${Math.round(this.state.time / 1000)}`
                    : this.props.t('retrying')}
                </Label>
              </FlexItem>
            </FlexParent>
          </Container>
        )}
      </AnimatePresence>
    );
  }
}

const mapDispatchToProps = {
  fetchOffline: fetchOffline
};

const mapStateToProps = (state, ownProps) => {
  return {
    global: selectGlobal(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfflineContainer);
