import FlexParent from './flex/FlexParent';
import Icon from '../icons/Icon';
import styled from 'styled-components';
import React from 'react';

const InfoLineLabel = styled.div`
  color: ${props => props.theme.darkGrey};
  font-size: 1.4rem;
`;

const InfoLineLink = styled.a`
  color: ${props => props.theme.darkGrey};
  font-size: 1.4rem;
  text-decoration: none;
`;

class InfoLine extends React.Component {
  getAddressPrefix = () => {
    if (
      navigator.platform.indexOf('iPhone') !== -1 ||
      navigator.platform.indexOf('iPad') !== -1 ||
      navigator.platform.indexOf('iPod') !== -1 ||
      (window.cordova &&
        window.device &&
        window.device.platform.toLowerCase().indexOf('ios') !== -1)
    ) {
      return 'https://maps.apple.com/?t=m&q=';
    } else {
      return 'https://maps.google.com/?t=m&q=';
    }
  };
  render() {
    const { icon, label, iconColor, type } = this.props;
    return label ? (
      <FlexParent alignItems={'center'}>
        <Icon icon={icon} color={iconColor} height={'20px'} width={'20px'} margin={'10px'} />
        {type === 'link' ? (
          <InfoLineLink href={label} target="_blank">
            {label}
          </InfoLineLink>
        ) : type === 'phone' ? (
          <InfoLineLink href={`tel:${label}`} target="_blank">
            {label}
          </InfoLineLink>
        ) : type === 'email' ? (
          <InfoLineLink href={`mailto:${label}`} target="_blank">
            {label}
          </InfoLineLink>
        ) : type === 'address' ? (
          <InfoLineLink href={`${this.getAddressPrefix()}${label}`} target="_blank">
            {label}
          </InfoLineLink>
        ) : (
          <InfoLineLabel>{label}</InfoLineLabel>
        )}
      </FlexParent>
    ) : null;
  }
}

export default InfoLine;
