import React from 'react';
import WebFont from 'webfontloader';

class CustomFont extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    loadFont(definedFont) {
        if (definedFont.fontProvider === 'google' && definedFont.fontFamily) {
            WebFont.load({
                google: {
                    families: [definedFont.fontFamily],
                },
            });
        }

        if (definedFont.fontFamily) {
            document.body.style.fontFamily = definedFont.fontFamily;
            document.body.style.fontWeight = definedFont.fontWeight;
        }
    }

    defineFont(app, event) {
        let definedFont = {
            'fontProvider': null,
            'fontFamily': null,
            'fontWeight': null,
        };

        if (app) {
            if (app.app_font_provider && app.app_font_family && app.app_font_weight) {
                definedFont.fontProvider = app.app_font_provider;
                definedFont.fontFamily = app.app_font_family;
                definedFont.fontWeight = app.app_font_weight;
            }
        } else if (event) {
            if (event.app_font_provider && event.app_font_family && event.app_font_weight) {
                definedFont.fontProvider = event.app_font_provider;
                definedFont.fontFamily = event.app_font_family;
                definedFont.fontWeight = event.app_font_weight;
            }
        }
        return definedFont;
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app || this.props.event !== prevProps.event) {
            let definedFont = this.defineFont(this.props.app, this.props.event);
            this.loadFont(definedFont);
        }
    }

    componentDidMount(){
        let definedFont = this.defineFont(this.props.app, this.props.event);
        this.loadFont(definedFont);
    }

    render() {
        return null;
    }
}

export default CustomFont;
