import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M24.5,10c-0.2,0-3.5,0-3.5,0c-0.2,0-0.3,0.1-0.3,0.2l-3.2-0.6c-0.1,0-0.6-0.1-1.2-0.1c0,0.1,0,0.1,0,0.2 c0,0.6-0.2,1.1-0.4,1.3c0.1,0.3,0.1,0.5,0.1,0.9c-0.1,1.2-0.6,1.5-1,1.6c0,0.1-0.1,0.4-0.1,0.7c-0.2,0.5-0.4,1.4-2,1.8 c-0.5,0.1-1.2,0.2-2.3,0.2c-0.3,0-0.6,0-0.9,0l0,0l0,0l0,0c-0.2,0.2-0.5,0.5-0.4,1.3c0.2,1,0.7,1.3,1.1,1.4c0.2,0.1,3.1,0.2,4,0.2 c0,0.4-0.4,1.2-0.6,1.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.4,1-0.3,2.3,0.2,3c0.4,0.6,1.1,0.6,1.5,0.5s0.7-0.4,0.7-0.7 c0.1-2.2,0.5-2.4,1-2.8l0.1-0.1c0.4-0.3,1.5-1.5,1.7-2c0.2-0.4,0.3-0.7,0.4-1c0.6-1.6,0.7-1.8,0.9-1.8h0.5v0.1 c0,0.2,0.1,0.3,0.3,0.3h3.7c0.2,0,0.3-0.1,0.3-0.3c0,0,0-6.2,0-6.2S24.8,10,24.5,10z" />
      <path d="M15.6,9.8c0-0.8-0.3-1-0.4-1.1l0,0l0,0c0.2-0.2,0.5-0.5,0.4-1.3c-0.2-1-0.6-1.3-1-1.4S13,5.8,12,5.8 c-0.6,0-1.1,0-1.4,0c0-0.4,0.4-1.2,0.6-1.6c0.1-0.2,0.2-0.4,0.3-0.6c0.4-1,0.3-2.3-0.2-3C10.7,0,9.7,0.1,9.6,0.1 C9.1,0.2,8.9,0.5,8.8,0.7c-0.1,2.2-0.5,2.4-1,2.8c0,0-1.6,1.6-1.8,2.1C5.9,6,5.7,6.4,5.6,6.7C5,8.2,4.9,8.5,4.7,8.5H4.3V8.4 c0-0.2-0.1-0.3-0.3-0.3H0.3C0.1,8.1,0,8.2,0,8.4v6.3C0,14.9,0.1,15,0.3,15H4c0.1,0,0.3-0.1,0.3-0.2l3.2,0.6c0.1,0,1.8,0.2,3.4,0.2 c0.8,0,1.6,0,2-0.2c0.9-0.3,1.4-0.7,1.5-1.3c0.1-0.4,0.1-0.6,0.1-0.7c0-0.1,0-0.1,0-0.1s0.1,0,0.2-0.1c0.2-0.2,0.6-0.4,0.7-1.1 c0.1-0.6-0.1-0.9-0.3-1.1l0,0l0,0C15.3,10.7,15.5,10.5,15.6,9.8" />
    </g>
  </svg>
);
