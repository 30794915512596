import React from 'react';
import Loader from './Loader';

class InfiniteScroll extends React.Component {
  state = {
    prevHeight: 0
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.hasMore) {
      window.removeEventListener('scroll', this.onScroll, false);
    } else if (!prevProps.hasMore && this.props.hasMore) {
      window.removeEventListener('scroll', this.onScroll, false);
      window.addEventListener('scroll', this.onScroll, false);
    }

    if (this.props.reverse) {
      // Fix reverse infinite scroll position when new page content loaded
      if (
        this.state.prevHeight !== document.body.offsetHeight &&
        window.scrollY === 0 &&
        this.props.hasMore
      ) {
        window.scrollTo(0, document.body.offsetHeight - this.state.prevHeight);
      }
    }
  }

  onScroll = () => {
    if (!this.props.reverse) {
      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        if (!this.props.isLoading && this.props.hasMore) {
          this.props.loadMore();
        }
      }
    } else {
      if (window.scrollY === 0) {
        if (!this.props.isLoading && this.props.hasMore) {
          this.setState({ prevHeight: document.body.offsetHeight });
          this.props.loadMore();
        }
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.reverse && this.props.hasMore && this.props.isLoading ? <Loader /> : null}
        {this.props.children}
        {!this.props.reverse && this.props.hasMore && this.props.isLoading ? <Loader /> : null}
      </React.Fragment>
    );
  }
}

export default InfiniteScroll;
