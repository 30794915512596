import { createSelector } from 'reselect';
import { filterObjects, filterIds, hasMore, isLoading } from '../helpers/data';
import { API_EXHIBITORS } from '../constants/apiRoutes';

const currentExhibitorListId = (state, props) => props.match.params.exhibitorListId;
const currentExhibitorId = (state, props) => props.match.params.exhibitorId;
const exhibitorLists = (state, props) => state.exhibitorLists;
const exhibitors = (state, props) => state.exhibitors;
const exhibitorsIds = (state, props) => state.sortIds[API_EXHIBITORS] || [];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;

// --- Select current object --------------
export const selectCurrentExhibitorList = createSelector(
  [exhibitorLists, currentExhibitorListId],
  (exhibitorLists, currentExhibitorListId) => {
    return exhibitorLists[currentExhibitorListId] || null;
  }
);

export const selectCurrentExhibitor = createSelector(
  [exhibitors, currentExhibitorId],
  (exhibitors, exhibitorId) => {
    return exhibitors[exhibitorId] || null;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredExhibitors = createSelector(
  [currentExhibitorListId, exhibitors],
  (exhibitorListId, exhibitors) => {
    return filterObjects(exhibitors, exhibitor => {
      return exhibitor.list_id === +exhibitorListId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredExhibitorsIds = createSelector(
  [exhibitorsIds, selectFilteredExhibitors],
  (exhibitorsIds, exhibitors) => {
    return filterIds(exhibitorsIds, exhibitors);
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMoreExhibitors = createSelector(
  [currentExhibitorListId, pagination],
  (listId, pagination) => {
    return hasMore(pagination, listId, API_EXHIBITORS);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingExhibitors = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_EXHIBITORS);
  }
);
// ----------------------------------------
