import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M21.3,3.1c-0.1,0-0.2,0-0.2,0.1l-0.7,0.7c-0.1,0.1-0.1,0.3,0,0.4L22,6h-3.4c-2.7,0-4.5,2.6-6.1,4.9 c-0.4,0.6-0.8,1.2-1.2,1.7c-0.4,0.6-0.9,1.2-1.3,1.8c-1.4,2-2.8,4.2-4.8,4.2H0.3c-0.2,0-0.3,0.1-0.3,0.3v1c0,0.2,0.1,0.3,0.3,0.3 h4.9c2.7,0,4.4-2.5,5.9-4.8c0.4-0.6,0.8-1.2,1.3-1.8c0.4-0.6,0.9-1.2,1.3-1.8c1.4-2,2.8-4.2,4.7-4.2H22l-1.6,1.7 c-0.1,0.1-0.1,0.3,0,0.4l0.7,0.7c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1L24.9,7c0.1-0.1,0.1-0.3,0-0.4l-3.4-3.4 C21.5,3.2,21.4,3.1,21.3,3.1" />
      <path d="M13.2,13.7c-0.1,0-0.2,0-0.3,0.1c0,0-0.5,0.7-0.5,0.7c-0.1,0.1-0.1,0.2,0,0.4l0.3,0.4c1.6,2.3,3.4,4.9,6.1,4.9 H22L20.4,22c-0.1,0.1-0.1,0.3,0,0.4l0.7,0.7c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l3.4-3.4c0.1-0.1,0.1-0.3,0-0.4l-3.4-3.4 c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1l-0.7,0.7c-0.1,0.1-0.1,0.3,0,0.4l1.6,1.7h-3.4c-1.9,0-3.3-2.1-4.7-4.1 c-0.2-0.2-0.3-0.5-0.5-0.7C13.4,13.8,13.3,13.7,13.2,13.7L13.2,13.7z" />
      <path d="M5,5.5H0.3C0.1,5.5,0,5.6,0,5.8v1c0,0.2,0.1,0.3,0.3,0.3h4.9c1.9,0,3.3,2.1,4.7,4.1c0.2,0.2,0.3,0.5,0.5,0.7 c0.1,0.1,0.2,0.1,0.3,0.1h0c0.1,0,0.2,0,0.3-0.1c0,0,0.5-0.7,0.5-0.7c0.1-0.1,0.1-0.3,0-0.4l-0.3-0.4C9.6,8.1,7.7,5.5,5,5.5" />
    </g>
  </svg>
);
