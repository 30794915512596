import { SUCCESS, FETCH_TIMELINE } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_TIMELINE, UPDATED } from '../constants/socket';

export default (state = initialState.timelines, { type, payload }) => {
  switch (type) {
    case FETCH_TIMELINE + SUCCESS:
    case SC_TIMELINE + UPDATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
