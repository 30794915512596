import Button from '../../../components/shared/Button';
import Modal from '../../../components/modal/Modal';
import FlexParent from '../../../components/shared/flex/FlexParent';
import FlexItem from '../../../components/shared/flex/FlexItem';
import React from 'react';
import styled from 'styled-components';
import {
  ListItemLogo,
  ListItemShadow,
  ListItemTitle,
  ListItemSubTitle
} from '../../../components/shared/ListItem';

const ParticipantList = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const ParticipantListHeading = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
`;

const Attendee = styled.div`
  margin-bottom: 10px;
`;

const ModalOptions = props => {
  const { onClose, onClickOptionLeave, onClickOptionEdit, open, t, conversation, user } = props;
  const attendeesWithOwner = [...conversation.attendees];
  attendeesWithOwner.unshift(conversation.owner);
  return (
    <Modal onClose={onClose} open={open}>
      <React.Fragment>
        <ParticipantList>
          <ParticipantListHeading>
            {attendeesWithOwner.length} {t('attendees').toLowerCase()}
          </ParticipantListHeading>

          {attendeesWithOwner.map(attendee => {
            return (
              <Attendee key={attendee.id}>
                <FlexParent justifyContent={'space-between'} alignItems={'center'}>
                  <FlexItem position={'relative'}>
                    {attendee.avatar_url && (
                      <React.Fragment>
                        <ListItemLogo src={attendee.avatar_url} alt={attendee.name} size={'30px'} />
                        <ListItemShadow size={'30px'} />
                      </React.Fragment>
                    )}
                  </FlexItem>

                  <FlexItem grow={1}>
                    <ListItemTitle>
                      {attendee.id === user.id ? `${t('me')}` : attendee.name}{' '}
                      {attendee.id === conversation.owner.id ? `(${t('admin')})` : ''}
                    </ListItemTitle>
                    <ListItemSubTitle>{attendee.desc}</ListItemSubTitle>
                  </FlexItem>
                </FlexParent>
              </Attendee>
            );
          })}
        </ParticipantList>

        <FlexParent>
          {conversation.owner.id === user.id ? (
            <Button btntype={'primary'} onClick={onClickOptionEdit} display="block" width="100%">
              {t('btn_edit')}
            </Button>
          ) : null}
          <Button btntype={'danger'} onClick={onClickOptionLeave} display="block" width="100%">
            {t('btn_leave')}
          </Button>
        </FlexParent>
      </React.Fragment>
    </Modal>
  );
};

export default ModalOptions;
