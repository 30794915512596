import { connect } from 'react-redux';
import { fetchVote, fetchVoteRounds, postVoteAnswers } from '../../actions/api';
import { updateHeader, updateVoteAnswers } from '../../actions/local';
import Vote from './Vote';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';
import Answered from './Answered';
import ClosedRound from './ClosedRound';
import { Redirect } from 'react-router-dom';

import {
  selectCurrentVote,
  selectCurrentRound,
  selectFilteredAnswers,
  isAllRoundsClosed
} from '../../selectors/votes';

import { selectUser } from '../../selectors/user';
import { selectGlobal } from '../../selectors/global';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class VoteContainer extends React.Component {
  state = {
    answered: false,
    isSending: false,
    totalScore: 0,
    roundCount: 0
  };

  componentDidMount() {
    const { appId, eventId, voteId } = this.props.match.params;
    this.getAPiData(this.props.match.params);
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'vote',
      viewableId: +voteId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { voteId } = this.props.match.params;
    const oldVoteId = prevProps.match.params.voteId;
    if (voteId !== oldVoteId) {
      this.getAPiData(this.props.match.params);
    }

    if (
      this.props.currentRound &&
      prevProps.currentRound &&
      this.props.currentRound.id === prevProps.currentRound.id &&
      this.props.currentRound.answered !== prevProps.currentRound.answered
    ) {
      this.getAPiData(this.props.match.params);
    }

    if (
      this.props.currentRound &&
      prevProps.currentRound &&
      this.props.currentRound.reset_count !== prevProps.currentRound.reset_count
    ) {
      this.refreshData();
      let voteAnswers = {};
      voteAnswers[this.props.match.params.voteId] = {
        answers: {}
      };
      this.props.updateVoteAnswers(voteAnswers);
      this.setState({ answered: false });
    }

    if (
      (this.props.currentRound &&
        prevProps.currentRound &&
        this.props.currentRound.id !== prevProps.currentRound.id) ||
      (!this.props.currentRound && prevProps.currentRound) ||
      (this.props.currentRound && !prevProps.currentRound)
    ) {
      this.setState({ answered: false });
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }
  }

  getAPiData({ eventId, voteId }) {
    this.props.fetchVote({
      event_id: eventId,
      vote_id: voteId
    });

    this.props.fetchVoteRounds({
      event_id: eventId,
      vote_id: voteId,
      done: res => {
        let totalScore = 0;
        res.data.forEach(val => {
          totalScore += Number(val.points);
        });

        this.setState({
          totalScore: totalScore,
          roundCount: res.data.length
        });
      }
    });
  }

  refreshData = () => {
    this.getAPiData(this.props.match.params);
  };

  isRoundValid = () => {
    const currentAnswers = this.props.currentAnswers;
    const answers = currentAnswers.answers;

    const questions = this.props.currentRound.questions;
    let requiredQuestions = questions.filter(question => question.is_required);

    for (const question of requiredQuestions) {
      if (!answers[question.id]) {
        return false;
      } else if (
        ['checkbox', 'radio'].indexOf(question.type) !== -1 &&
        (!answers[question.id].options || !answers[question.id].options.length)
      ) {
        return false;
      }
    }

    let maxChoicesQuestions = questions.filter(question => question.max_choices);
    for (const question of maxChoicesQuestions) {
      if (answers[question.id] && answers[question.id].options.length > question.max_choices) {
        return false;
      }
    }

    return true;
  };

  updateAnswer = (questionId, value, type) => {
    let voteAnswers = {};
    let newAnswer = {};
    let options = [];

    if (type === 'checkbox') {
      options = this.props.currentAnswers.answers[questionId]
        ? [...this.props.currentAnswers.answers[questionId].options]
        : [];

      const index = options.indexOf(+value);

      if (index !== -1) {
        options.splice(index, 1);
      } else {
        options.push(+value);
      }
    } else if (type === 'radio') {
      options.push(+value);
    }

    newAnswer[questionId] = {
      question_id: questionId,
      options
    };

    const answers = { ...this.props.currentAnswers.answers, ...newAnswer };
    voteAnswers[this.props.match.params.voteId] = {
      ...this.props.currentAnswers,
      answers
    };

    this.props.updateVoteAnswers(voteAnswers);
  };

  sendResults = () => {
    const { eventId, voteId } = this.props.match.params;
    const roundQuestionIds = this.props.currentRound.questions.map(question => question.id);
    let answers = Object.values(this.props.currentAnswers.answers);
    answers = answers.filter(answer => roundQuestionIds.indexOf(answer.question_id) !== -1);

    this.setState({
      isSending: true
    });
    this.props.postVoteAnswers({
      event_id: eventId,
      vote_id: voteId,
      round_id: this.props.currentRound.id,
      answers,
      done: () => {
        this.refreshData();
        let voteAnswers = {};
        voteAnswers[this.props.match.params.voteId] = {
          answers: {}
        };
        this.props.updateVoteAnswers(voteAnswers);
        this.setState({ answered: true });
      },
      always: () => {
        this.setState({
          isSending: false
        });
      }
    });
  };

  redirect = () => {
    const { appId, eventId } = this.props.match.params;
    const redirectUrl = `/apps/${appId}/events/${eventId}/signin`;
    return (
      <Redirect
        to={{
          pathname: redirectUrl,
          state: { from: this.props.location }
        }}
      />
    );
  };

  voteAgain = () => {
    this.setState({ answered: false });
  };

  render() {
    if (!this.props.vote) {
      return null;
    }
    if (this.props.vote.is_quiz && !this.props.user) {
      return this.redirect();
    }

    const { isRoundValid, updateAnswer, sendResults } = this;
    const { vote, currentRound, theme, t, currentAnswers, allRoundsClosed } = this.props;
    const matchParams = this.props.match.params;
    const answers = currentAnswers.answers;
    const { isSending } = this.state;
    const { offline } = this.props.global;

    return vote.is_open && currentRound && !currentRound.answered && !this.state.answered ? (
      <Vote
        {...{
          vote,
          currentRound,
          isRoundValid,
          theme,
          t,
          matchParams,
          updateAnswer,
          answers,
          sendResults,
          isSending,
          offline
        }}
      />
    ) : !vote.is_open ? (
      <ClosedService
        icon={getServiceIconByType(vote.custom_icon || vote.is_quiz ? 'quiz' : 'vote')}
        name={vote.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
      />
    ) : allRoundsClosed ? (
      <ClosedRound
        icon={getServiceIconByType(vote.custom_icon || vote.is_quiz ? 'quiz' : 'vote')}
        name={vote.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
      />
    ) : currentRound && (currentRound.answered || this.state.answered) ? (
      <Answered
        vote={vote}
        currentRound={currentRound}
        icon={getServiceIconByType(vote.custom_icon || vote.is_quiz ? 'quiz' : 'vote')}
        name={currentRound.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
        totalScore={this.state.totalScore}
        canVoteAgain={
          !(currentRound.answered || this.state.answered) ||
          this.props.currentRound.max_answers_per_attendee === 0
        }
        voteAgain={this.voteAgain}
        roundCount={this.state.roundCount}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchVote: fetchVote,
  fetchVoteRounds: fetchVoteRounds,
  postVoteAnswers: postVoteAnswers,
  updateVoteAnswers: updateVoteAnswers
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    vote: selectCurrentVote(state, ownProps),
    currentRound: selectCurrentRound(state, ownProps),
    currentAnswers: selectFilteredAnswers(state, ownProps),
    allRoundsClosed: isAllRoundsClosed(state, ownProps),
    global: selectGlobal(state, ownProps)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoteContainer);
