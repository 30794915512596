import React from 'react';
import { connect } from 'react-redux';
import ResetPassword from './ResetPassword';
import { postResetPwd, postUserPwd } from '../../../actions/api';
import { updateHeader } from '../../../actions/local';
import { selectUser } from '../../../selectors/user';
import Mixpanel from '../../../Mixpanel';
class ResetPasswordContainer extends React.Component {
  componentDidMount() {
    const { appId, eventId } = this.props.match.params;
    this.props.updateHeader({
      leftBtn: null,
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'user-reset-password',
      viewableId: null,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  resetPwd = data => {
    const { appId, eventId } = this.props.match.params;
    this.props.postResetPwd({
      ...data,
      ...{
        app_id: appId,
        event_id: eventId
      }
    });
  };

  testUserPwd = data => {
    const { appId, eventId } = this.props.match.params;
    this.props.postUserPwd({
      ...data,
      ...{
        app_id: appId,
        event_id: eventId,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        scope: '*',
        grant_type: 'password'
      }
    });
  };

  redirect = () => {
    const { appId, eventId } = this.props.match.params;
    const redirectUrl = `/apps/${appId}${eventId ? `/events/${eventId}/home` : ''}`;

    return this.props.history.push(redirectUrl);
  };

  render() {
    const { t, theme, history, user } = this.props;
    const matchParams = this.props.match.params;
    const { resetPwd, testUserPwd, redirect } = this;
    const token = matchParams.token;

    return  (
      <ResetPassword {...{ t, theme, history, matchParams, user, resetPwd, testUserPwd, token, redirect }} />
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  postResetPwd: postResetPwd,
  postUserPwd: postUserPwd
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
