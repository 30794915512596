import React from 'react';
import ServiceHeader from '../../components/shared/ServiceHeader';
import Round from './Round';
import Question from '../../components/shared/survey/Question';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class Vote extends React.Component {
  onChangeInputValue = (questionId, value, type) => {
    this.props.updateAnswer(questionId, value, type);
  };

  onClickValidate = () => {
    this.props.sendResults();
  };

  render() {
    const { vote, currentRound, t, answers, isRoundValid, isSending, theme, offline } = this.props;
    const { onClickValidate } = this;
    return (
      <React.Fragment>
        <ServiceHeader
          t={t}
          icon={getServiceIconByType(vote.custom_icon || vote.is_quiz ? 'quiz' : 'vote')}
          name={vote.name}
          description={vote.description}
          theme={theme}
        />

        <Round {...{ isRoundValid, isSending, t, onClickValidate, offline }}>
          {currentRound.questions.map(question => {
            return (
              <Question
                key={question.id}
                type={currentRound.type}
                t={t}
                question={question}
                alignment={question.option_alignment}
                onChangeInputValue={this.onChangeInputValue}
                answer={answers[question.id] || { options: [], value: '' }}
              />
            );
          })}
        </Round>
      </React.Fragment>
    );
  }
}

export default Vote;
