import React from 'react';
import FlexParent from '../../../components/shared/flex/FlexParent';
import MeetingItem from './MeetingItem';
import Button from '../../../components/shared/Button';
import { MEETING_CREATE } from '../../../constants/app';
import Icon from '../../../components/icons/Icon';
import IconPlus from '../../../components/icons/IconPlus';
import InfiniteScroll from '../../../components/shared/InfiniteScroll';
import { isDarkBgColor } from '../../../helpers/helpers';

import { API_WHOS_HERE_MEETINGS } from '../../../constants/apiRoutes';

class MeetingsList extends React.Component {
  componentDidMount() {
    this.props.fetchMeetings({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.whosHere.id !== prevProps.whosHere.id) {
      this.props.fetchMeetings({ reset: true });
    }

    if (this.props.notifsMeetingsCount !== prevProps.notifsMeetingsCount) {
      this.props.resetNotification({
        parent_id: this.props.whosHere.id,
        api_route: API_WHOS_HERE_MEETINGS
      });
    }
  }

  onClickNewMeeting = () => {
    this.props.updateView({ view: MEETING_CREATE });
  };

  render() {
    const {
      meetings,
      meetingsIds,
      theme,
      matchParams,
      history,
      user,
      postMeetingStatus,
      updateView,
      t,
      isLoadingMeetings,
      hasMoreMeetings,
      fetchMeetings,
      event
    } = this.props;
    return (
      <React.Fragment>
        <Button
          margin={'3px auto 15px auto'}
          display={'block'}
          btntype={'primary'}
          onClick={this.onClickNewMeeting}
        >
          <FlexParent alignItems={'center'}>
            <Icon
              icon={IconPlus}
              color={isDarkBgColor(theme.mainColor) ? '#fff' : '#000'}
              border={`1px solid ${isDarkBgColor(theme.mainColor) ? '#fff' : '#000'}`}
              width={'20px'}
              height={'20px'}
              borderRadius={'50%'}
              padding={'4px'}
              margin={'0 5px 0 0'}
            />
            <div>{t('btn_new_meeting')}</div>
          </FlexParent>
        </Button>

        <InfiniteScroll
          loadMore={fetchMeetings}
          hasMore={hasMoreMeetings}
          isLoading={isLoadingMeetings}
        >
          {meetingsIds.map((key, index) => {
            const meeting = meetings[key];
            return (
              <MeetingItem
                key={meeting.id}
                id={meeting.id}
                {...{
                  event,
                  meeting,
                  theme,
                  matchParams,
                  history,
                  user,
                  postMeetingStatus,
                  updateView,
                  t
                }}
              />
            );
          })}
        </InfiniteScroll>
      </React.Fragment>
    );
  }
}

export default MeetingsList;
