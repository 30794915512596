import { connect } from 'react-redux';
import {
  fetchSmartqa,
  fetchSmartqaPosts,
  postSmartqaPost,
  postSmartqaPostReport,
  postFile,
  postCameraFile,
  postSmartqaPostReaction,
  deleteSmartqaPostReaction
} from '../../actions/api';
import { updateHeader, updateGlobal, updateSmartqaPost } from '../../actions/local';
import React from 'react';
import Smartqa from './Smartqa';
import ModalReport from '../../components/shared/smartqa/ModalReport';
import ModalSent from '../../components/shared/smartqa/ModalSent';
import ModalIsAnonymous from '../../components/shared/smartqa/ModalIsAnonymous';
import ModalIsNotAnonymous from '../../components/shared/smartqa/ModalIsNotAnonymous';
import ClosedService from '../../components/shared/ClosedService';
import FullImage from '../../components/shared/FullImage';
import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';
import { selectCurrentEvent } from '../../selectors/events';
import {
  selectCurrentSmartqa,
  selectFilteredPosts,
  selectFilteredPostsIds,
  selectIsLoadingPosts,
  selectHasMorePosts,
  selectNotifsPostsCount
} from '../../selectors/smartqa';
import Socket from '../../socket/Socket';

import {
  CREATED,
  UPDATED,
  DELETED,
  APPROVED,
  SC_SMART_QA,
  SC_SMART_QA_POST
} from '../../constants/socket';

import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';
import produce from 'immer';

class SmartqaContainer extends React.Component {
  state = {
    isSaving: false,
    openReportModal: false,
    openSentModal: false,
    reportPostId: null,
    openIsAnonymousModal: false,
    openIsNotAnonymousModal: false,
    socketIsListening: false,
    fullScreenPictureUrl: null
  };

  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    const { appId, eventId, smartqaId } = this.props.match.params;
    this.fetchSmartqa();
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'smartqa',
      viewableId: +smartqaId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });

    if (this.props.smartqa && this.props.event && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }
  }

  componentDidUpdate(prevProps) {
    const { smartqaId } = this.props.match.params;
    const oldSmartqaId = prevProps.match.params.smartqaId;
    if (smartqaId !== oldSmartqaId) {
      this.fetchSmartqa();
      this.unsubscribeSocketEvents(oldSmartqaId);
    }

    if (this.props.smartqa && this.props.event && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.fetchSmartqa();
    }

    const scrollY = window.localStorage.getItem(`scrollY_smartqa_${smartqaId}`);
    if (scrollY) {
      window.scrollTo({ top: scrollY });
      window.localStorage.removeItem(`scrollY_smartqa_${smartqaId}`);
    }
  }

  componentWillUnmount() {
    const { smartqaId } = this.props.match.params;
    this.unsubscribeSocketEvents(smartqaId);
  }

  subscribeToSocketEvents = () => {
    const { smartqaId } = this.props.match.params;
    this.socket = Socket.subscribeChannel(
      `${SC_SMART_QA}.${smartqaId}`,
      this.props.event.visibility === 'public' ? true : false
    );
    Socket.listenEvents(this.socket, [
      SC_SMART_QA_POST + CREATED,
      SC_SMART_QA_POST + UPDATED,
      SC_SMART_QA_POST + DELETED,
      SC_SMART_QA_POST + APPROVED
    ]);

    this.setState({
      socketIsListening: true
    });
  };

  unsubscribeSocketEvents = smartqaId => {
    Socket.unsubscribeChannel(`${SC_SMART_QA}.${smartqaId}`);
    this.setState({
      socketIsListening: false
    });
  };

  fetchSmartqa = () => {
    this.props.fetchSmartqa(this.getSharedApiParams());
  };

  fetchSmartqaPosts = data => {
    this.props.fetchSmartqaPosts({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  // SEND POST
  postSmartqaPost = data => {
    this.props.postSmartqaPost({
      ...this.getSharedApiParams(),
      ...data,
      ...{
        anonymous:
          this.props.smartqa.anonymous === 'forced'
            ? true
            : this.props.smartqa.anonymous === 'disabled'
            ? false
            : this.props.global.smartqaIsAnonymous,
        always: () => {
          this.setState({
            isSaving: false
          });
        }
      }
    });

    this.setState({
      isSaving: true
    });

    if (this.props.smartqa.is_moderated) {
      this.setState({
        openSentModal: true
      });
    }
  };

  //  FILE UPLOAD
  uploadPostFile = data => {
    this.props.postFile({
      ...this.getSharedApiParams(),
      ...data
    });
  };

  uploadCameraFile = data => {
    this.props.postCameraFile({
      ...this.getSharedApiParams(),
      ...data,
      ...{
        session_id: this.props.global.sessionId
      }
    });
  };

  // SEND REPORT
  sendReport = data => {
    this.openReportModal(data);
  };

  confirmSendReport = event => {
    this.closeReportModal();
    this.props.postSmartqaPostReport({
      ...this.getSharedApiParams(),
      ...{
        post_id: +this.state.reportPostId
      }
    });
  };

  openReportModal = data => {
    this.setState({ openReportModal: true, reportPostId: data.post.id });
  };

  closeReportModal = () => {
    this.setState({ openReportModal: false, reportPostId: null });
  };
  // END SEND REPORT

  openSentModal = data => {
    this.setState({ openSentModal: true });
  };

  closeSentModal = () => {
    this.setState({ openSentModal: false });
  };

  // SEND REACTION
  sendReaction = data => {
    this.props.postSmartqaPostReaction({
      ...this.getSharedApiParams(),
      ...data,
      ...{
        reactable_id: data.post.id,
        reactable_type: 'smart_qa_question'
      }
    });

    const newPost = produce(data.post, draftState => {
      draftState.viewer_reaction = data.type;
      draftState.reactions[data.type].count += 1;
      draftState.reactions.total += 1;
      return draftState;
    });

    this.props.updateSmartqaPost({ data: newPost });
  };
  // END SEND REACTION

  // DELETE REACTION
  deleteReaction = data => {
    this.props.deleteSmartqaPostReaction({
      ...this.getSharedApiParams(),
      ...{
        reactable_id: data.post.id,
        reactable_type: 'smart_qa_question',
        type: data.type
      }
    });

    const newPost = produce(data.post, draftState => {
      draftState.viewer_reaction = null;
      draftState.reactions[data.type].count -= 1;
      draftState.reactions.total -= 1;
      return draftState;
    });

    this.props.updateSmartqaPost({ data: newPost });
  };
  // END DELETE REACTION

  // SET VISIBILITY
  setVisibility = event => {
    if (!this.props.user) {
      return;
    }
    const anonymous = !this.props.global.smartqaIsAnonymous;
    this.props.updateGlobal({
      smartqaIsAnonymous: anonymous
    });

    if (anonymous) {
      this.openIsAnonymousModal();
    } else {
      this.openIsNotAnonymousModal();
    }
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  confirmSetVisibility = event => {};

  openIsAnonymousModal = () => {
    this.setState({ openIsAnonymousModal: true });
  };

  closeIsAnonymousModal = () => {
    this.setState({ openIsAnonymousModal: false });
  };

  openIsNotAnonymousModal = () => {
    this.setState({ openIsNotAnonymousModal: true });
  };

  closeIsNotAnonymousModal = () => {
    this.setState({ openIsNotAnonymousModal: false });
  };

  showFullScreenPicture = ({ url }) => {
    this.setState({
      fullScreenPictureUrl: url
    });
  };

  closeFullScreenPicture = () => {
    this.setState({
      fullScreenPictureUrl: null
    });
  };

  navigateToPostDetails = postId => {
    const { appId, eventId, smartqaId } = this.props.match.params;
    window.localStorage.setItem(`scrollY_smartqa_${smartqaId}`, window.pageYOffset);
    const url = `/apps/${appId}/events/${eventId}/smartqa/${smartqaId}/posts/${postId}`;
    this.props.history.push(url);
  };

  getSharedApiParams = () => {
    const { appId, eventId, smartqaId } = this.props.match.params;
    return {
      app_id: +appId,
      event_id: +eventId,
      smart_qa_id: +smartqaId
    };
  };

  render() {
    if (!this.props.smartqa) {
      return null;
    }
    const {
      t,
      theme,
      smartqa,
      posts,
      postsIds,
      header,
      global,
      user,
      hasMoreSmartqaPosts,
      isLoadingSmartqaPosts,
      notifsPostsCount
    } = this.props;

    const { height: headerHeight } = header;
    const {
      offline,
      componentFixedTopHeight,
      smartqaIsAnonymous: isAnonymous,
      accessToken
    } = global;
    const {
      fetchSmartqaPosts,
      postSmartqaPost,
      sendReport,
      sendReaction,
      deleteReaction,
      uploadPostFile,
      uploadCameraFile,
      navigateToPostDetails,
      fetchSmartqa,
      setVisibility,
      setComponentFixedTopHeight,
      showFullScreenPicture
    } = this;

    const { isSaving } = this.state;

    const matchParams = this.props.match.params;
    const isInAppLive = this.props.global.isInAppLive;

    return smartqa.is_open ? (
      <React.Fragment>
        <FullImage
          url={this.state.fullScreenPictureUrl}
          onClickClose={this.closeFullScreenPicture}
        ></FullImage>
        <Smartqa
          {...{
            fetchSmartqaPosts,
            hasMoreSmartqaPosts,
            isLoadingSmartqaPosts,
            matchParams,
            t,
            theme,
            user,
            smartqa,
            posts,
            postsIds,
            headerHeight,
            componentFixedTopHeight,
            offline,
            postSmartqaPost,
            sendReport,
            sendReaction,
            deleteReaction,
            uploadPostFile,
            uploadCameraFile,
            showFullScreenPicture,
            navigateToPostDetails,
            setVisibility,
            isAnonymous,
            setComponentFixedTopHeight,
            notifsPostsCount,
            isSaving,
            accessToken,
            isInAppLive
          }}
        />

        <ModalReport
          open={this.state.openReportModal}
          onClose={this.closeReportModal}
          onValidate={this.confirmSendReport}
          theme={theme}
          t={t}
        />

        <ModalSent
          open={this.state.openSentModal}
          onClose={this.closeSentModal}
          onValidate={this.closeSentModal}
          theme={theme}
          t={t}
        />

        <ModalIsAnonymous
          open={this.state.openIsAnonymousModal}
          onClose={this.closeIsAnonymousModal}
          onValidate={this.closeIsAnonymousModal}
          theme={theme}
          t={t}
        />

        <ModalIsNotAnonymous
          open={this.state.openIsNotAnonymousModal}
          onClose={this.closeIsNotAnonymousModal}
          onValidate={this.closeIsNotAnonymousModal}
          theme={theme}
          t={t}
        />
      </React.Fragment>
    ) : !smartqa.is_open ? (
      <ClosedService
        icon={getServiceIconByType(smartqa.custom_icon || 'smartqa')}
        name={smartqa.name}
        theme={theme}
        t={t}
        onRefresh={fetchSmartqa}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateGlobal: updateGlobal,
  updateHeader: updateHeader,
  fetchSmartqa: fetchSmartqa,
  fetchSmartqaPosts: fetchSmartqaPosts,
  postSmartqaPost: postSmartqaPost,
  postSmartqaPostReport: postSmartqaPostReport,
  postFile: postFile,
  postCameraFile: postCameraFile,
  postSmartqaPostReaction: postSmartqaPostReaction,
  deleteSmartqaPostReaction: deleteSmartqaPostReaction,
  updateSmartqaPost: updateSmartqaPost
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    smartqa: selectCurrentSmartqa(state, ownProps),
    posts: selectFilteredPosts(state, ownProps),
    postsIds: selectFilteredPostsIds(state, ownProps),
    isLoadingSmartqaPosts: selectIsLoadingPosts(state, ownProps),
    hasMoreSmartqaPosts: selectHasMorePosts(state, ownProps),
    notifsPostsCount: selectNotifsPostsCount(state, ownProps),
    header: selectHeader(state, ownProps),
    global: selectGlobal(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartqaContainer);
