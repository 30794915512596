import React from 'react';
import styled from 'styled-components';
import FlexItem from '../flex/FlexItem';
import { motion } from 'framer-motion';
import HtmlContent from '../HtmlContent';

const Container = styled.div`
  margin: ${props => props.alignment === 'horizontal' ? '0 20px 20px 0' : '0 0 20px 0'};

  width: ${props => props.alignment === 'horizontal' ? 'auto' : '100%'};
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled(motion.label)`
  padding: ${props => props.alignment === 'horizontal' ? '8px 10px' : '8px 5px'};
  display: block;
  text-align: center;
  background-color: ${props => props.theme.lightGrey};
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

const Input = styled.input`
  display: none;
`;

const ImageContainer = styled(FlexItem)`
  max-width: 45%;
  margin: 0 1% 20px;
  cursor: pointer;
`;

const ImageLabel = styled.label`
  img {
    width: 100%;
    margin: 0;
    display: block;
    border-radius: 8px;
  }

  div {
    padding-top: 10px;
    text-align: center;
    font-weight: 300;
    font-size: 1.4rem;
  }
`;

class Radio extends React.Component {
  render() {

    const { id, label, picture, checked, alignment } = this.props;
    return !picture ? (
      <Container alignment={alignment}>
        <Input type="radio" {...this.props} />
        <Label
          htmlFor={id}
          key={`radio_${id}`}
          initial={false}
          animate={checked ? 'selected' : 'unselected'}
          variants={{
            selected: {
              backgroundColor: 'rgb(176,176,176)'
            },
            unselected: {
              backgroundColor: 'rgb(237,237,237)'
            }
          }}
          alignment={alignment}
        >
          <HtmlContent>{label}</HtmlContent>
        </Label>
      </Container>
    ) : (
      <ImageContainer grow={'1'} shrink={'0'}>
        <Input type="radio" {...this.props} />
        <ImageLabel htmlFor={id}>
          {picture && (
            <motion.img
              src={picture}
              alt=""
              key={`radio_${id}`}
              animate={checked ? 'selected' : 'unselected'}
              variants={{
                selected: {
                  scale: 0.9,
                  opacity: 0.75,
                  transition: { type: 'spring', duration: 0.2, mass: 1, stiffness: 100 }
                },
                unselected: {
                  scale: 1,
                  opacity: 1,
                  transition: { type: 'spring', duration: 0.2, mass: 1, stiffness: 100 }
                }
              }}
            />
          )}
          {label && <div>{label}</div>}
        </ImageLabel>
      </ImageContainer>
    );
  }
}

export default Radio;
