import { SUCCESS, FETCH_VOTE } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_VOTE, UPDATED } from '../constants/socket';

export default (state = initialState.votes, { type, payload }) => {
  switch (type) {
    case FETCH_VOTE + SUCCESS:
    case SC_VOTE + UPDATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
