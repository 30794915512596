import { connect } from 'react-redux';
import { fetchBonusList, fetchBonuses } from '../../actions/api';
import { updateHeader } from '../../actions/local';
import BonusList from './BonusList';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';
import { SEARCH_DELAY } from '../../constants/app';
import {
  selectCurrentBonusList,
  selectFilteredBonuses,
  selectFilteredBonusesIds,
  selectIsLoadingBonuses,
  selectHasMoreBonuses
} from '../../selectors/bonusLists';

import { getServiceIconByType } from '../../components/icons/ServiceIcons';

import { selectGlobal } from '../../selectors/global';
import { selectHeader } from '../../selectors/header';
import { selectUser } from '../../selectors/user';
import Mixpanel from '../../Mixpanel';

class BonusListContainer extends React.Component {
  componentDidMount() {
    const { appId, eventId, bonusListId } = this.props.match.params;
    this.getAPiData(this.props.match.params);
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: 'search',
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'bonuslist',
      viewableId: +bonusListId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { bonusListId } = this.props.match.params;
    const oldBonusListId = prevProps.match.params.bonusListId;
    if (bonusListId !== oldBonusListId) {
      this.getAPiData(this.props.match.params);
    }

    if (this.props.global.searchValue !== prevProps.global.searchValue) {
      this.search(this.props.global.searchValue);
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }
  }

  getAPiData({ eventId, bonusListId }) {
    this.props.fetchBonusList({
      event_id: eventId,
      list_id: bonusListId
    });
  }

  fetchBonuses = data => {
    const { eventId, bonusListId } = this.props.match.params;
    this.props.fetchBonuses({
      ...data,
      q: this.props.global.searchValue,
      event_id: eventId,
      list_id: +bonusListId
    });
  };

  refreshData = () => {
    this.getAPiData(this.props.match.params);
  };

  search = () => {
    if (this.searchTimeout) {
      this.searchTimeout = clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchBonuses({ reset: true });
    }, SEARCH_DELAY);
  };

  render() {
    if (!this.props.bonusList) {
      return null;
    }

    const {
      bonusList,
      theme,
      t,
      bonuses,
      bonusesIds,
      isLoadingBonuses,
      hasMoreBonuses,
      global,
      header
    } = this.props;
    const matchParams = this.props.match.params;

    const { fetchBonuses } = this;

    return bonusList.is_open ? (
      <BonusList
        {...{
          bonusList,
          theme,
          t,
          fetchBonuses,
          bonuses,
          bonusesIds,
          isLoadingBonuses,
          hasMoreBonuses,
          matchParams,
          global,
          header
        }}
      />
    ) : !bonusList.is_open ? (
      <ClosedService
        icon={getServiceIconByType(bonusList.custom_icon || 'bonuslist')}
        name={bonusList.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchBonusList: fetchBonusList,
  fetchBonuses: fetchBonuses
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    bonusList: selectCurrentBonusList(state, ownProps),
    bonuses: selectFilteredBonuses(state, ownProps),
    bonusesIds: selectFilteredBonusesIds(state, ownProps),
    isLoadingBonuses: selectIsLoadingBonuses(state, ownProps),
    hasMoreBonuses: selectHasMoreBonuses(state, ownProps),
    global: selectGlobal(state, ownProps),
    header: selectHeader(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BonusListContainer);
