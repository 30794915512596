import { connect } from 'react-redux';
import { fetchSpeakerList, fetchSpeakers, fetchSpeaker } from '../../actions/api';
import { updateHeader } from '../../actions/local';
import React from 'react';
import SpeakerList from './SpeakerList';
import ClosedService from '../../components/shared/ClosedService';
import Speaker from './Speaker';
import { SEARCH_DELAY } from '../../constants/app';
import { selectCurrentEvent } from '../../selectors/events';
import {
  selectCurrentSpeakerList,
  selectFilteredSpeakers,
  selectFilteredSpeakersIds,
  selectCurrentSpeaker,
  selectIsLoadingSpeakers,
  selectHasMoreSpeakers
} from '../../selectors/speakerLists';
import { selectGlobal } from '../../selectors/global';
import { selectHeader } from '../../selectors/header';
import { selectUser } from '../../selectors/user';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class SpeakerListContainer extends React.Component {
  state = {
    scrollY: null
  };

  componentDidMount() {
    const { appId, eventId, speakerListId, speakerId } = this.props.match.params;
    this.props.fetchSpeakerList({ event_id: eventId, list_id: speakerListId });
    this.props.updateHeader({
      leftBtn: this.props.match.params.speakerId && window.history.length > 1 ? 'back' : 'nav',
      rightBtn: 'search',
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: speakerId ? 'speaker' : 'speakerlist',
      viewableId: speakerId ? +speakerId : +speakerListId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { appId, eventId, speakerListId, speakerId } = this.props.match.params;
    const oldSpeakerListId = prevProps.match.params.speakerListId;
    const oldSpeakerId = prevProps.match.params.speakerId;
    if (speakerListId !== oldSpeakerListId) {
      this.props.fetchSpeakerList({ event_id: eventId, list_id: speakerListId });
    }

    if (speakerId !== oldSpeakerId) {
      this.props.updateHeader({
        leftBtn: speakerId && window.history.length > 1 ? 'back' : 'nav',
        rightBtn: speakerId ? null : 'search'
      });

      if (speakerId) {
        Mixpanel.track('view', {
          viewableType: 'speaker',
          viewableId: +speakerId,
          appId: +appId,
          eventId: +eventId,
          userId: this.props.user ? this.props.user.id : null
        });
      }
    }

    if (this.props.global.searchValue !== prevProps.global.searchValue) {
      this.search(this.props.global.searchValue);
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }

    if (!speakerId && prevProps.match.params.speakerId && this.state.scrollY) {
      this.restoreScroll();
    }
  }

  restoreScroll = () => {
    window.scrollTo({ top: this.state.scrollY });
    // Recursive to fix infine scroll with ios
    setTimeout(() => {
      if (window.pageYOffset < this.state.scrollY) {
        this.restoreScroll();
      }
    }, 500);
  };

  refreshData = () => {
    const { eventId, speakerListId } = this.props.match.params;
    this.props.fetchSpeakerList({ event_id: eventId, list_id: speakerListId });
  };

  showItemDetails = itemId => {
    const { appId, eventId, speakerListId } = this.props.match.params;
    this.setState({ scrollY: window.pageYOffset });
    this.props.history.push(
      `/apps/${appId}/events/${eventId}/speaker_lists/${speakerListId}/${itemId}`
    );
  };

  fetchSpeakers = data => {
    const { eventId, speakerListId } = this.props.match.params;
    this.props.fetchSpeakers({
      ...data,
      q: this.props.global.searchValue,
      event_id: eventId,
      list_id: speakerListId
    });
  };

  fetchSpeaker = () => {
    const { appId, eventId, speakerListId, speakerId } = this.props.match.params;
    this.props.fetchSpeaker({
      app_id: appId,
      event_id: eventId,
      list_id: speakerListId,
      speaker_id: speakerId
    });
  };

  search = () => {
    if (this.searchTimeout) {
      this.searchTimeout = clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchSpeakers({ reset: true });
    }, SEARCH_DELAY);
  };

  render() {
    if (!this.props.speakerList) {
      return null;
    }

    const { refreshData, showItemDetails, fetchSpeakers, fetchSpeaker } = this;
    const {
      speakerList,
      speakers,
      speakersIds,
      speaker,
      isLoadingSpeakers,
      hasMoreSpeakers,
      t,
      history,
      theme,
      event,
      global,
      header
    } = this.props;
    const matchParams = this.props.match.params;
    const { speakerId } = matchParams;
    const timezone = event.timezone;

    const isInAppLive = this.props.global.isInAppLive;

    return speakerList.is_open && !speakerId ? (
      <SpeakerList
        {...{
          matchParams,
          speakerList,
          speakers,
          speakersIds,
          isLoadingSpeakers,
          hasMoreSpeakers,
          t,
          history,
          theme,
          global,
          header
        }}
        {...{
          showItemDetails,
          fetchSpeakers
        }}
      />
    ) : speakerList.is_open && speakerId ? (
      <Speaker
        {...{ matchParams, speaker, speakerId, t, theme, history, timezone, isInAppLive }}
        {...{ fetchSpeaker }}
      />
    ) : !speakerList.is_open ? (
      <ClosedService
        icon={getServiceIconByType(speakerList.custom_icon || 'speakerlist')}
        name={speakerList.name}
        theme={theme}
        t={t}
        onRefresh={refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchSpeakerList: fetchSpeakerList,
  fetchSpeakers: fetchSpeakers,
  fetchSpeaker: fetchSpeaker
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    speakerList: selectCurrentSpeakerList(state, ownProps),
    speakers: selectFilteredSpeakers(state, ownProps),
    speakersIds: selectFilteredSpeakersIds(state, ownProps),
    speaker: selectCurrentSpeaker(state, ownProps),
    isLoadingSpeakers: selectIsLoadingSpeakers(state, ownProps),
    hasMoreSpeakers: selectHasMoreSpeakers(state, ownProps),
    global: selectGlobal(state, ownProps),
    header: selectHeader(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeakerListContainer);
