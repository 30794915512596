import { createSelector } from 'reselect';
import { filterObjects } from '../helpers/data';

const currentVoteId = (state, props) => props.match.params.voteId;
const votes = (state, props) => state.votes;
const rounds = (state, props) => state.voteRounds;
const answers = (state, props) => state.voteAnswers;

export const selectCurrentVote = createSelector(
  [votes, currentVoteId],
  (votes, currentVoteId) => {
    return votes[currentVoteId] || null;
  }
);

export const selectCurrentRound = createSelector(
  [rounds, currentVoteId],
  (rounds, voteId) => {
    return (
      filterObjects(
        rounds,
        round => {
          return round.vote_id === +voteId && round.is_open === true;
        },
        true
      )[0] || null
    );
  }
);

export const selectFilteredAnswers = createSelector(
  [answers, currentVoteId],
  (answers, voteId) => {
    const filteredAnswers = answers[voteId];
    if (!filteredAnswers) {
      return { answers: {} };
    } else {
      return filteredAnswers;
    }
  }
);

export const isAllRoundsClosed = createSelector(
  [rounds, selectCurrentRound],
  (rounds, currentRound) => {
    return Object.keys(rounds).length && !currentRound;
  }
);
