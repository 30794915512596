import {
  FETCH_ATTENDEES,
  FETCH_BONUSES,
  FETCH_EVENTS,
  FETCH_EXHIBITORS,
  FETCH_SMARTQA_COMMENTS,
  FETCH_SMARTQA_POSTS,
  FETCH_SPEAKERS,
  FETCH_SPONSORS,
  FETCH_TIMELINE_ITEMS,
  FETCH_WHOS_HERE_CONVERSATIONS,
  FETCH_WHOS_HERE_MEETINGS,
  FETCH_WHOS_HERE_MESSAGES,
  FETCH_FAVORITES,
  PAGINATION_SUCCESS
} from '../constants/actionApi';
import { addOrUpdatePaginationInState } from '../helpers/data';
import initialState from '../store/initialState';
import {
  API_ATTENDEES,
  API_BONUSES,
  API_EVENTS,
  API_EXHIBITORS,
  API_SMARTQA_COMMENTS,
  API_SMARTQA_QUESTIONS,
  API_SPEAKERS,
  API_SPONSORS,
  API_TIMELINE_ITEMS,
  API_WHOS_HERE_CONVERSATIONS,
  API_WHOS_HERE_MEETINGS,
  API_WHOS_HERE_MESSAGES,
  API_FAVORITES
} from '../constants/apiRoutes';

export default (state = initialState.pagination, { type, payload }) => {
  switch (type) {
    case FETCH_EVENTS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(state, payload.data, payload.params.group_id, API_EVENTS);
    case FETCH_EXHIBITORS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.list_id,
        API_EXHIBITORS
      );
    case FETCH_SPEAKERS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.list_id,
        API_SPEAKERS
      );
    case FETCH_SPONSORS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.list_id,
        API_SPONSORS
      );
    case FETCH_BONUSES + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(state, payload.data, payload.params.list_id, API_BONUSES);
    case FETCH_SMARTQA_POSTS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.smart_qa_id,
        API_SMARTQA_QUESTIONS
      );
    case FETCH_SMARTQA_COMMENTS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.commentable_id,
        API_SMARTQA_COMMENTS
      );
    case FETCH_ATTENDEES + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.event_id,
        API_ATTENDEES
      );
    case FETCH_TIMELINE_ITEMS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.timeline_id,
        API_TIMELINE_ITEMS
      );

    case FETCH_WHOS_HERE_CONVERSATIONS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.whos_here_id,
        API_WHOS_HERE_CONVERSATIONS
      );
    case FETCH_WHOS_HERE_MEETINGS + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.whos_here_id,
        API_WHOS_HERE_MEETINGS
      );
    case FETCH_WHOS_HERE_MESSAGES + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.conversation_id,
        API_WHOS_HERE_MESSAGES
      );

    case FETCH_FAVORITES + PAGINATION_SUCCESS:
      return addOrUpdatePaginationInState(
        state,
        payload.data,
        payload.params.event_id,
        API_FAVORITES + '/' + payload.params.type
      );
    default:
      return state;
  }
};
