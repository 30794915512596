import IconAvatar from '../icons/IconAvatar';
import IconBonusList from '../icons/IconBonusList';
import IconCalendar from '../icons/IconCalendar';
import IconCamera from '../icons/IconCamera';
import IconCloud from '../icons/IconCloud';
import IconExhibitorList from '../icons/IconExhibitorList';
import IconExpressYourself from '../icons/IconExpressYourself';
import IconHome from '../icons/IconHome';
import IconLink from '../icons/IconLink';
import IconMap from '../icons/IconMap';
import IconNetworking from '../icons/IconNetworking';
import IconPracticalInfo from '../icons/IconPracticalInfo';
import IconQuiz from '../icons/IconQuiz';
import IconSocialMedia from '../icons/IconSocialMedia';
import IconSpeakerList from '../icons/IconSpeakerList';
import IconSponsorList from '../icons/IconSponsorList';
import IconStarFilled from '../icons/IconStarFilled';
import IconSurvey from '../icons/IconSurvey';
import IconSwitchEvent from '../icons/IconSwitchEvent';
import IconTimeline from '../icons/IconTimeline';
import IconVote from '../icons/IconVote';

export const getServiceIconByType = type => {
  const icons = {
    agenda: IconCalendar,
    app: IconSwitchEvent,
    eventgroup: IconSwitchEvent,
    bonuslist: IconBonusList,
    exhibitorlist: IconExhibitorList,
    fav: IconStarFilled,
    star: IconStarFilled,
    home: IconHome,
    instaphoto: IconCamera,
    link: IconLink,
    map: IconMap,
    practicalinfo: IconPracticalInfo,
    quiz: IconQuiz,
    signin: IconAvatar,
    smartqa: IconExpressYourself,
    socialmedia: IconSocialMedia,
    speakerlist: IconSpeakerList,
    sponsorlist: IconSponsorList,
    survey: IconSurvey,
    tagcloud: IconCloud,
    timeline: IconTimeline,
    vote: IconVote,
    whoshere: IconNetworking
  };

  return icons[type] || null;
};
