import { createSelector } from 'reselect';
import { filterObjects, filterIds, hasMore, isLoading } from '../helpers/data';
import { API_SPEAKERS } from '../constants/apiRoutes';

const currentSpeakerListId = (state, props) => props.match.params.speakerListId;
const currentSpeakerId = (state, props) => props.match.params.speakerId;
const speakerLists = (state, props) => state.speakerLists;
const speakers = (state, props) => state.speakers;
const speakersIds = (state, props) => state.sortIds[API_SPEAKERS] || [];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;

// --- Select current object --------------
export const selectCurrentSpeakerList = createSelector(
  [speakerLists, currentSpeakerListId],
  (speakerLists, currentSpeakerListId) => {
    return speakerLists[currentSpeakerListId] || null;
  }
);

export const selectCurrentSpeaker = createSelector(
  [speakers, currentSpeakerId],
  (speakers, speakerId) => {
    return speakers[speakerId] || null;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredSpeakers = createSelector(
  [currentSpeakerListId, speakers],
  (speakerListId, speakers) => {
    return filterObjects(speakers, speaker => {
      return speaker.list_id === +speakerListId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredSpeakersIds = createSelector(
  [speakersIds, selectFilteredSpeakers],
  (speakersIds, speakers) => {
    return filterIds(speakersIds, speakers);
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMoreSpeakers = createSelector(
  [currentSpeakerListId, pagination],
  (listId, pagination) => {
    return hasMore(pagination, listId, API_SPEAKERS);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingSpeakers = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_SPEAKERS);
  }
);
// ----------------------------------------
