import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M12.2,5.9C11,5.9,10,7,10,8.3v14.3c0,1.3,1,2.4,2.2,2.4s2.2-1.1,2.2-2.4V8.3C14.4,7,13.4,5.9,12.2,5.9" />
      <path d="M14.4,2.2c0,1.2-1,2.2-2.2,2.2S10,3.4,10,2.2C10,1,11,0,12.2,0S14.4,1,14.4,2.2" />
    </g>
  </svg>
);
