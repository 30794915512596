import React from 'react';
import styled from 'styled-components';

const FormGroupStyle = styled.div`
  margin: 0 0 20px 0;
  padding: 0;
`;

const FormGroup = props => {
  return <FormGroupStyle {...props}>{props.children}</FormGroupStyle>;
};

export default FormGroup;
