import React from 'react';
import styled from 'styled-components';

/*
PROPS
----------------------------------------------------------------------------------------------
direction: row | row-reverse | column | column-reverse;
wrap: nowrap | wrap | wrap-reverse;
justifyContent: flex-start | flex-end | center | space-between | space-around | space-evenly;
alignItems: flex-start | flex-end | center | baseline | stretch;
alignContent: flex-start | flex-end | center | space-between | space-around | stretch;
----------------------------------------------------------------------------------------------
*/

const FlexParentStyle = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'flex-start'};
  align-content: ${props => props.alignContent || 'flex-start'};
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
`;

const FlexParent = props => {
  return <FlexParentStyle {...props}>{props.children}</FlexParentStyle>;
};

export default FlexParent;
