import { connect } from 'react-redux';
import {
  fetchSmartqa,
  fetchSmartqaPost,
  fetchSmartqaComments,
  postSmartqaComment,
  postSmartqaPostReaction,
  postSmartqaCommentReaction,
  postSmartqaPostReport,
  postSmartqaCommentReport,
  deleteSmartqaPostReaction,
  deleteSmartqaCommentReaction,
  postFile,
  postCameraFile
} from '../../actions/api';
import {
  updateHeader,
  updateGlobal,
  updateSmartqaPost,
  updateSmartqaComment
} from '../../actions/local';
import Post from './Post';
import React from 'react';
import ModalReport from '../../components/shared/smartqa/ModalReport';
import ModalIsAnonymous from '../../components/shared/smartqa/ModalIsAnonymous';
import ModalIsNotAnonymous from '../../components/shared/smartqa/ModalIsNotAnonymous';
import ClosedService from '../../components/shared/ClosedService';

import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';
import { selectCurrentEvent } from '../../selectors/events';
import {
  selectCurrentSmartqa,
  selectCurrentPost,
  selectFilteredComments,
  selectFilteredCommentsIds,
  selectIsLoadingComments,
  selectHasMoreComments
} from '../../selectors/smartqa';
import Socket from '../../socket/Socket';
import {
  CREATED,
  UPDATED,
  DELETED,
  SC_SMART_QA,
  SC_SMART_QA_POST,
  SC_SMART_QA_COMMENT
} from '../../constants/socket';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';
import produce from 'immer';
class SmartqaPostContainer extends React.Component {
  state = {
    isSaving: false,
    openReportModal: false,
    reportPostId: null,
    openReportCommentModal: false,
    reportCommentId: null,
    openIsAnonymousModal: false,
    openIsNotAnonymousModal: false,
    socketIsListening: false
  };

  componentDidMount() {
    const { appId, eventId, postId } = this.props.match.params;
    this.getAPiData();

    this.props.updateHeader({
      leftBtn: 'back',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'smartqa-post',
      viewableId: +postId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });

    if (this.props.event && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }
  }

  componentDidUpdate(prevProps) {
    const { smartqaId, postId } = this.props.match.params;
    const oldPostId = prevProps.match.params.postId;
    if (postId !== oldPostId) {
      this.getAPiData();
      this.unsubscribeSocketEvents(smartqaId, oldPostId);
    }

    if (this.props.event && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }
  }

  componentWillUnmount() {
    const { smartqaId, postId } = this.props.match.params;
    this.unsubscribeSocketEvents(smartqaId, postId);
  }

  subscribeToSocketEvents = () => {
    const { smartqaId, postId } = this.props.match.params;
    this.postSocket = Socket.subscribeChannel(
      `${SC_SMART_QA_POST}.${postId}`,
      this.props.event.visibility === 'public' ? true : false
    );
    this.smartQaSocket = Socket.subscribeChannel(
      `${SC_SMART_QA}.${smartqaId}`,
      this.props.event.visibility === 'public' ? true : false
    );

    Socket.listenEvents(this.smartQaSocket, [SC_SMART_QA_POST + UPDATED]);

    Socket.listenEvents(this.postSocket, [
      SC_SMART_QA_COMMENT + CREATED,
      SC_SMART_QA_COMMENT + UPDATED,
      SC_SMART_QA_COMMENT + DELETED
    ]);
    this.setState({
      socketIsListening: true
    });
  };

  unsubscribeSocketEvents = (smartqaId, postId) => {
    Socket.unsubscribeChannel(`${SC_SMART_QA}.${smartqaId}`);
    Socket.unsubscribeChannel(`${SC_SMART_QA_POST}.${postId}`);
    this.setState({
      socketIsListening: false
    });
  };

  getAPiData() {
    this.props.fetchSmartqa(this.getSharedApiParams());
    this.props.fetchSmartqaPost({
      ...this.getSharedApiParams(),
      ...{
        post_id: this.props.match.params.postId
      }
    });
  }

  refreshData = () => {
    this.getAPiData();
  };

  fetchSmartqaComments = () => {
    const { postId } = this.props.match.params;
    this.props.fetchSmartqaComments({
      ...this.getSharedApiParams(),
      ...{
        commentable_id: +postId,
        commentable_type: 'smart_qa_question'
      }
    });
  };

  // SEND COMMENT
  postSmartqaComment = data => {
    const { postId } = this.props.match.params;
    // api
    this.props.postSmartqaComment({
      ...this.getSharedApiParams(),
      ...data,
      ...{
        anonymous:
          this.props.smartqa.anonymous === 'forced'
            ? true
            : this.props.smartqa.anonymous === 'disabled'
            ? false
            : this.props.global.smartqaIsAnonymous,
        commentable_id: +postId,
        commentable_type: 'smart_qa_question',
        always: () => {
          this.setState({
            isSaving: false
          });
        }
      }
    });

    this.setState({
      isSaving: true
    });
  };

  // SEND POST REPORT
  sendReport = data => {
    this.openReportModal(data);
  };

  confirmSendReport = event => {
    this.closeReportModal();
    const { postId } = this.props.match.params;
    this.props.postSmartqaPostReport({
      ...this.getSharedApiParams(),
      ...{
        post_id: +postId
      }
    });
  };

  openReportModal = data => {
    this.setState({ openReportModal: true, reportPostId: data.post.id });
  };

  closeReportModal = () => {
    this.setState({ openReportModal: false, reportPostId: null });
  };
  // END SEND POST REPORT

  // SEND COMMENT REPORT
  sendReportComment = data => {
    this.openReportCommentModal(data);
  };

  confirmSendReportComment = event => {
    this.closeReportCommentModal();
    const { postId } = this.props.match.params;
    this.props.postSmartqaCommentReport({
      ...this.getSharedApiParams(),
      ...{
        comment_id: +this.state.reportCommentId,
        commentable_id: +postId,
        commentable_type: 'smart_qa_question'
      }
    });
  };

  openReportCommentModal = data => {
    this.setState({ openReportCommentModal: true, reportCommentId: data.comment.id });
  };

  closeReportCommentModal = () => {
    this.setState({ openReportCommentModal: false, reportCommentId: null });
  };
  // END SEND COMMENT REPORT

  // SEND POST REACTION
  sendReaction = data => {
    this.props.postSmartqaPostReaction({
      ...this.getSharedApiParams(),
      ...{
        reactable_id: data.post.id,
        reactable_type: 'smart_qa_question',
        type: data.type
      }
    });

    const newPost = produce(data.post, draftState => {
      draftState.viewer_reaction = data.type;
      draftState.reactions[data.type].count += 1;
      draftState.reactions.total += 1;
      return draftState;
    });

    this.props.updateSmartqaPost({ data: newPost });
  };
  // END SEND POST REACTION

  // DELETE REACTION
  deleteReaction = data => {
    this.props.deleteSmartqaPostReaction({
      ...this.getSharedApiParams(),
      ...{
        reactable_id: data.post.id,
        reactable_type: 'smart_qa_question',
        type: data.type
      }
    });

    const newPost = produce(data.post, draftState => {
      draftState.viewer_reaction = null;
      draftState.reactions[data.type].count -= 1;
      draftState.reactions.total -= 1;
      return draftState;
    });

    this.props.updateSmartqaPost({ data: newPost });
  };
  // END DELETE REACTION

  // SEND COMMENT REACTION
  sendReactionComment = data => {
    this.props.postSmartqaPostReaction({
      ...this.getSharedApiParams(),
      ...{
        reactable_type: 'comment',
        reactable_id: data.comment.id,
        type: data.type
      }
    });

    const newComment = produce(data.comment, draftState => {
      draftState.viewer_reaction = data.type;
      draftState.reactions[data.type].count += 1;
      draftState.reactions.total += 1;
      return draftState;
    });

    this.props.updateSmartqaComment({ data: newComment });
  };
  // END SEND COMMENT REACTION

  // DELETE COMMENT REACTION
  deleteReactionComment = data => {
    this.props.deleteSmartqaCommentReaction({
      ...this.getSharedApiParams(),
      ...{
        reactable_id: data.comment.id,
        reactable_type: 'comment',
        type: data.type
      }
    });

    const newComment = produce(data.comment, draftState => {
      draftState.viewer_reaction = null;
      draftState.reactions[data.type].count -= 1;
      draftState.reactions.total -= 1;
      return draftState;
    });

    this.props.updateSmartqaComment({ data: newComment });
  };
  // END DELETE COMMENT REACTION

  // SET VISIBILITY
  setVisibility = event => {
    if (!this.props.user) {
      return;
    }
    const anonymous = !this.props.global.smartqaIsAnonymous;
    this.props.updateGlobal({
      smartqaIsAnonymous: anonymous
    });

    if (anonymous) {
      this.openIsAnonymousModal();
    } else {
      this.openIsNotAnonymousModal();
    }
  };

  openIsAnonymousModal = () => {
    this.setState({ openIsAnonymousModal: true });
  };

  closeIsAnonymousModal = () => {
    this.setState({ openIsAnonymousModal: false });
  };

  openIsNotAnonymousModal = () => {
    this.setState({ openIsNotAnonymousModal: true });
  };

  closeIsNotAnonymousModal = () => {
    this.setState({ openIsNotAnonymousModal: false });
  };
  // END SET VISIBILITY

  //  FILE UPLOAD
  uploadCommentFile = data => {
    this.props.postFile({
      ...this.getSharedApiParams(),
      ...data,
      ...{
        smart_qa_question_id: this.props.post.id
      }
    });
  };

  uploadCameraFile = data => {
    // api
    this.props.postCameraFile({
      ...this.getSharedApiParams(),
      ...data,
      ...{
        session_id: this.props.global.sessionId
      }
    });
  };

  getSharedApiParams = () => {
    const { appId, eventId, smartqaId } = this.props.match.params;
    return {
      app_id: +appId,
      event_id: +eventId,
      smart_qa_id: +smartqaId
    };
  };

  render() {
    if (!this.props.smartqa) {
      return null;
    }

    const {
      smartqa,
      post,
      comments,
      commentsIds,
      isLoadingComments,
      hasMoreComments,
      t,
      user,
      theme,
      history,
      global
    } = this.props;
    const { smartqaIsAnonymous: isAnonymous, offline, accessToken, isInAppLive } = global;

    const matchParams = this.props.match.params;
    const id = +matchParams.postId;
    const {
      postSmartqaComment,
      sendReport,
      sendReportComment,
      sendReaction,
      deleteReaction,
      deleteReactionComment,
      sendReactionComment,
      uploadCommentFile,
      refreshData,
      setVisibility,
      fetchSmartqaComments,
      uploadCameraFile
    } = this;

    const { isSaving } = this.state;
    return smartqa.is_open && post ? (
      <React.Fragment>
        <Post
          {...{
            id,
            matchParams,
            smartqa,
            post,
            t,
            user,
            theme,
            history,
            postSmartqaComment,
            sendReport,
            sendReportComment,
            sendReaction,
            deleteReaction,
            deleteReactionComment,
            sendReactionComment,
            uploadCommentFile,
            comments,
            setVisibility,
            isAnonymous,
            commentsIds,
            isLoadingComments,
            hasMoreComments,
            fetchSmartqaComments,
            isSaving,
            uploadCameraFile,
            offline,
            accessToken,
            isInAppLive
          }}
        />

        <ModalReport
          open={this.state.openReportModal}
          onClose={this.closeReportModal}
          onValidate={this.confirmSendReport}
          theme={theme}
          t={t}
        />

        <ModalReport
          open={this.state.openReportCommentModal}
          onClose={this.closeReportCommentModal}
          onValidate={this.confirmSendReportComment}
          theme={theme}
          t={t}
        />

        <ModalIsAnonymous
          open={this.state.openIsAnonymousModal}
          onClose={this.closeIsAnonymousModal}
          onValidate={this.closeIsAnonymousModal}
          theme={theme}
          t={t}
        />

        <ModalIsNotAnonymous
          open={this.state.openIsNotAnonymousModal}
          onClose={this.closeIsNotAnonymousModal}
          onValidate={this.closeIsNotAnonymousModal}
          theme={theme}
          t={t}
        />
      </React.Fragment>
    ) : !smartqa.is_open ? (
      <ClosedService
        icon={getServiceIconByType(smartqa.custom_icon || 'smartqa')}
        name={smartqa.name}
        theme={theme}
        t={t}
        onRefresh={refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateGlobal: updateGlobal,
  updateHeader: updateHeader,
  fetchSmartqa: fetchSmartqa,
  fetchSmartqaPost: fetchSmartqaPost,
  fetchSmartqaComments: fetchSmartqaComments,
  postSmartqaComment: postSmartqaComment,
  postSmartqaPostReaction: postSmartqaPostReaction,
  postSmartqaCommentReaction: postSmartqaCommentReaction,
  postSmartqaPostReport: postSmartqaPostReport,
  postSmartqaCommentReport: postSmartqaCommentReport,
  updateSmartqaPost: updateSmartqaPost,
  updateSmartqaComment: updateSmartqaComment,
  deleteSmartqaPostReaction: deleteSmartqaPostReaction,
  deleteSmartqaCommentReaction: deleteSmartqaCommentReaction,
  postFile: postFile,
  postCameraFile: postCameraFile
};

const mapStateToProps = (state, ownProps) => {
  return {
    smartqa: selectCurrentSmartqa(state, ownProps),
    post: selectCurrentPost(state, ownProps),
    comments: selectFilteredComments(state, ownProps),
    commentsIds: selectFilteredCommentsIds(state, ownProps),
    isLoadingComments: selectIsLoadingComments(state, ownProps),
    hasMoreComments: selectHasMoreComments(state, ownProps),
    global: selectGlobal(state, ownProps),
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartqaPostContainer);
