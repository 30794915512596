import { SUCCESS, FETCH_WHOS_HERE_MESSAGES } from '../constants/actionApi';
import { SC_WHOS_HERE_MESSAGE, CREATED } from '../constants/socket';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.whosHereMessages, { type, payload }) => {
  switch (type) {
    case FETCH_WHOS_HERE_MESSAGES + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    case SC_WHOS_HERE_MESSAGE + CREATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
