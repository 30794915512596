import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <rect x="1.5" y="0" width="21.8" height="2.2" />
      <rect x="1.5" y="6.6" width="21.8" height="2.2" />
      <rect x="1.5" y="13.1" width="21.8" height="2.2" />
      <path d="M6.2,24.9v-3.8l0,0l-1.5,3.6H3.9l-1.5-3.6l0,0v3.8H1.5v-5.3h1.1l1.6,3.9h0.1L6,19.5h1.1v5.3H6.2z" />
      <path d="M11.9,24.9H8.4v-5.3h3.5v0.9H9.4v1.4h2.4v0.8H9.4v1.5h2.5V24.9z" />
      <path d="M13.1,24.9v-5.3h0.9l2.6,3.7h0.1v-3.7h0.9v5.3h-0.9l-2.6-3.7h-0.1v3.7H13.1z" />
      <path d="M21,24.2c0.8,0,1.2-0.4,1.2-1.2v-3.5h1.1V23c0,1.2-0.9,2-2.2,2c-1.3,0-2.2-0.8-2.2-2v-3.5h1V23C19.9,23,19.7,24.2,21,24.2z" />
    </g>
  </svg>
);
