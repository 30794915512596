import { VipIcon } from './ListItemDetails';
import Card from './card/Card';
import FlexItem from './flex/FlexItem';
import FlexParent from './flex/FlexParent';
import React from 'react';
import styled from 'styled-components';
import Star from './fav/Star';

export const ListItemLogo = styled.img`
  display: block;
  width: ${props => props.size || '50px'};
  min-width: ${props => props.size || '50px'};
  height: ${props => props.size || '50px'};
  object-fit: cover;
  margin-right: 10px;
  border-radius: ${props => props.borderRadius || '50%'};
`;

export const ListItemShadow = styled.div`
  position: absolute;
  top: 0;
  height: ${props => props.size || '50px'};
  left: 0;
  width: ${props => props.size || '50px'};
  border-radius: 50%;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 5;
`;

export const ListItemTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0;
  line-height: 2rem;
`;

export const ListItemSubTitle = styled.div`
  font-size: 1.2rem;
  color: ${props => props.theme.ashesGrey};
`;

export const Filler = styled.div`
  margin-top: 15px;
`;

class ListItem extends React.Component {
  onClickItem = event => {
    if (this.props.onClickItem) {
      this.props.onClickItem(this.props.id);
    } else {
      this.props.history.push(this.props.itemUrl);
    }
  };

  render() {
    const { logoUrl, isVip, heading, desc, theme, matchParams, type } = this.props;
    return (
      <Card margin={'0 0 20px'} padding={'10px'} onClick={this.onClickItem}>
        <FlexParent justifyContent={'space-between'} alignItems={'top'}>
          {logoUrl && (
            <ListItemLogo
              src={logoUrl}
              alt={heading}
              borderRadius={type === 'exhibitor' || type === 'sponsor' ? '0' : null}
            />
          )}
          <FlexItem grow={1}>
            <ListItemTitle>
              {heading}
              <VipIcon
                isVip={isVip}
                width={'18px'}
                height={'18px'}
                theme={theme}
                display={'inline-block'}
                verticalAlign={'text-top'}
                margin={'0 0 0 5px'}
              />
            </ListItemTitle>
            <ListItemSubTitle>{desc}</ListItemSubTitle>
          </FlexItem>

          <FlexItem>
            <Star
              {...{ theme }}
              model={this.props.model}
              type={type}
              matchParams={matchParams}
              favorite_id={this.props.favorite_id}
            />
          </FlexItem>
        </FlexParent>
      </Card>
    );
  }
}

export default ListItem;
