import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M18.4,10.8c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9S17.9,10.8,18.4,10.8z" />
      <path d="M21.9,5.7h-3.8l0-0.1c-0.4-0.8-1-1.9-2.5-1.9h-2.9H9.1C7.7,3.7,7,4.9,6.6,5.6c0,0.1-0.1,0.2-0.1,0.2H3.1C1.4,5.7,0,7,0,8.7 v11.2C0,21.6,1.4,23,3.1,23h18.8c1.7,0,3.1-1.4,3.1-3.1V8.7C25,7,23.6,5.7,21.9,5.7z M3,7.4h4.6c0,0,1-1.6,1.2-1.7 c0.2-0.2,0.3-0.4,0.5-0.4h3.6h2.9c0.3,0,0.5,0.2,0.7,0.6c0.1,0.1,0.9,1.5,0.9,1.5h4.6c0.7,0,1.3,0.6,1.3,1.3v11.2 c0,0.7-0.6,1.3-1.3,1.3H3.1c-0.7,0-1.3-0.6-1.3-1.3V8.7C1.8,8.7,1.8,7.4,3,7.4z" />
      <path d="M12.5,19.5c2.8,0,5.2-2.3,5.2-5.2c0-2.8-2.3-5.2-5.2-5.2c-2.8,0-5.2,2.3-5.2,5.2C7.3,17.1,9.6,19.5,12.5,19.5z M12.5,10.9 c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4S10.6,10.9,12.5,10.9z" />
    </g>
  </svg>
);
