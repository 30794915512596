import React from 'react';
import styled from 'styled-components';

const Scroll = styled.div`
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Scrollable = props => {
  return <Scroll>{props.children}</Scroll>;
};

export default Scrollable;
