import React from 'react';
import FlexParent from '../../components/shared/flex/FlexParent';
import FlexItem from '../../components/shared/flex/FlexItem';
import styled from 'styled-components';
import Card from '../../components/shared/card/Card';
import { ListItemTitle } from '../../components/shared/ListItem';
import { getEventIconBySize } from '../../helpers/format';

const Description = styled.div`
  margin: 15px 0 0;
  text-align: justify;
`;

const Podium = styled(FlexItem)`
  margin: 0 5px;
`;

const Bar = styled.div`
  background-color: ${props => props.theme.mainColor};
  height: ${props => props.height};
  text-align: center;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 10px 0 0 0;
`;

const Avatar = styled.img`
  display: block;
  width: 50px;
  min-width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 10%;
  margin: 15px auto 5px;
`;

const AttendeeName = styled.div`
  margin: 0 auto 15px;
  text-align: center;
  font-size: 1.2rem;
`;

class VoteResult extends React.Component {
  render() {
    const { event, voteResult } = this.props;
    return (
      <Card margin={`15px 0`} padding={'15px'}>
        <ListItemTitle>
          {voteResult.name} - {'Podium'}
        </ListItemTitle>
        <Description>
          {'Betty, Fred et Nikki ont obtenu les meilleurs résultats, bravo à eux ! 👏👏👏 '}
        </Description>

        <FlexParent justifyContent={'space-between'} alignItems={'flex-end'}>
          <Podium grow={1}>
            <Avatar src={getEventIconBySize(event.icon_url, '144')} />
            <AttendeeName>{'Thierry Maxel'}</AttendeeName>
            <Bar height={'70px'}>2</Bar>
          </Podium>
          <Podium grow={1}>
            <Avatar src={getEventIconBySize(event.icon_url, '144')} />
            <AttendeeName>{'Thierry Maxel'}</AttendeeName>
            <Bar height={'120px'}>1</Bar>
          </Podium>
          <Podium grow={1}>
            <Avatar src={getEventIconBySize(event.icon_url, '144')} />
            <AttendeeName>{'Thierry Maxel'}</AttendeeName>
            <Bar height={'50px'}>3</Bar>
          </Podium>
        </FlexParent>
      </Card>
    );
  }
}

export default VoteResult;
