import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <polygon points="5.5,0 5.5,3.4 6.7,3.4 6.7,1.2 23.8,1.2 23.8,16.9 19.5,16.9 19.5,21.1 19.5,21.6 25,21.6 25,18.3 25,17.7 25,0 " />
      <path d="M1.2,4.7h17.1v15.6H1.2V4.7z M0,3.4v17.7v0.7V25h19.5v-3.2v-0.7V3.4H0z" />
      <polygon points="2.4,19.3 2.4,18.8 7.9,13.3 12.4,17.8 14.7,15.4 17,18.2 17,19.2 2.4,19.2 " />
      <circle cx="13.7" cy="9.8" r="2" />
    </g>
  </svg>
);
