import React from 'react';
import { connect } from 'react-redux';
import UserProfileEdit from './UserProfileEdit';
import { updateHeader } from '../../../actions/local';
import { putUser, postUserAvatarFile, fetchCountries, deleteUser } from '../../../actions/api';

import { selectGlobal } from '../../../selectors/global';
import { selectUser } from '../../../selectors/user';
import { selectCountries, selectCountriesIds } from '../../../selectors/countries';
import { selectErrors } from '../../../selectors/errors';
import SuccessContainer from '../../../components/alert/SuccessContainer';
import { updateSuccess } from '../../../actions/local';
import Mixpanel from '../../../Mixpanel';
class UserProfileEditContainer extends React.Component {
  state = {
    isSaving: false,
    successMessage: 'yeah'
  };
  componentDidMount() {
    const { appId, eventId } = this.props.match.params;
    this.props.updateHeader({
      leftBtn: window.history.length > 1 ? 'back' : 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    if (!this.props.countriesIds.length) {
      this.props.fetchCountries({
        per_page: 250,
        reset: true
      });
    }

    Mixpanel.track('view', {
      viewableType: 'user-edit-profile',
      viewableId: null,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  deleteUserAccount = fields => {
    this.props.deleteUser({
      ...fields,
      done: () => {
        this.props.updateSuccess({
          message: this.props.t('success_account_deletion')
        });
      }
    });
  }

  saveUserProfile = fields => {
    this.setState({
      isSaving: true
    });

    this.props.putUser({
      ...fields,
      done: () => {
        this.props.updateSuccess({
          message: this.props.t('success_profile')
        });
      },
      always: () => {
        this.setState({
          isSaving: false
        });
      }
    });
  };

  uploadUserAvatarFile = data => {
    const { appId, eventId } = this.props.match.params;
    // api
    this.props.postUserAvatarFile({
      ...data,
      ...{
        type: 'attendee',
        app_id: +appId,
        event_id: +eventId
      }
    });
  };

  render() {
    if (!this.props.user) {
      return null;
    }

    const { saveUserProfile, uploadUserAvatarFile, deleteUserAccount } = this;
    const { t, theme, history, user, global, countries, countriesIds, errors } = this.props;
    const { userAvatarFileUrl, offline } = global;
    const matchParams = this.props.match.params;
    const isSaving = this.state.isSaving;
    const inputErrors = errors.errors || {};
    return (
      <React.Fragment>
        <SuccessContainer />
        <UserProfileEdit
          {...{
            t,
            theme,
            history,
            matchParams,
            user,
            countries,
            countriesIds,
            uploadUserAvatarFile,
            saveUserProfile,
            userAvatarFileUrl,
            isSaving,
            inputErrors,
            offline,
            deleteUserAccount
          }}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  postUserAvatarFile: postUserAvatarFile,
  putUser: putUser,
  deleteUser: deleteUser,
  fetchCountries: fetchCountries,
  updateSuccess: updateSuccess
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    global: selectGlobal(state, ownProps),
    countries: selectCountries(state, ownProps),
    countriesIds: selectCountriesIds(state, ownProps),
    errors: selectErrors(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileEditContainer);
