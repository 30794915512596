import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M12.1,18.7L12.1,18.7c2.1,0,3.8-1.7,3.8-3.7V4c0-2-1.7-3.7-3.7-3.7h0c-2,0-3.7,1.7-3.7,3.7v11 C8.4,17,10.1,18.7,12.1,18.7" />
      <path d="M18.2,15.2v-4.4h-0.9v4.4c0,2.9-2.3,5.2-5.2,5.2h-0.1c-2.9,0-5.2-2.3-5.2-5.2v-4.4H6v4.4c0,3.2,2.5,5.9,5.7,6.1 l0,2.5H6.8v0.9h10.6v-0.9h-4.9l0-2.5C15.7,21.1,18.2,18.4,18.2,15.2" />
    </g>
  </svg>
);
