import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconDocumentAcrobat from '../../components/icons/IconDocumentAcrobat';
import IconDocumentImage from '../../components/icons/IconDocumentImage';
import IconDocumentText from '../../components/icons/IconDocumentText';
import IconDocumentVideo from '../../components/icons/IconDocumentVideo';
import React from 'react';
import styled from 'styled-components';
import HtmlContent from '../../components/shared/HtmlContent';
import Star from '../../components/shared/fav/Star';

const ItemLink = styled.a`
  color: #000;
  text-decoration: none;
`;

const ItemTitle = styled.div`
  font-size: 1.5rem;
  padding-bottom: 5px;
  font-weight: 500;
`;

const ItemDesc = styled.div`
  font-size: 1.2rem;
  color: ${props => props.theme.ashesGrey};
`;

const getTypeIcon = type => {
  if (type && type.includes('image')) {
    return IconDocumentImage;
  } else if (type && type.includes('video')) {
    return IconDocumentVideo;
  } else if (type && type.includes('pdf')) {
    return IconDocumentAcrobat;
  } else {
    return IconDocumentText;
  }
};

const BonusItem = props => {
  const { theme, matchParams } = props;

  const url =
    props.bonus.type && props.bonus.type.includes('pdf') && window.cordova
      ? `https://docs.google.com/viewer?url=${props.bonus.url}&embedded=true`
      : props.bonus.url;

  return (
    <ItemLink href={url} download target="_blank">
      <Card margin={'0 0 20px'} padding={'10px'}>
        <FlexParent>
          <FlexItem>
            <Icon
              icon={getTypeIcon(props.bonus.type)}
              width={'40px'}
              height={'40px'}
              margin={'0 10px 0 0'}
              color={props.theme.mainColor}
            />
          </FlexItem>
          <FlexItem grow={1}>
            <ItemTitle>{props.bonus.name}</ItemTitle>
            <ItemDesc>
              <HtmlContent>{props.bonus.description}</HtmlContent>
            </ItemDesc>
          </FlexItem>
          <FlexItem alignSelf={'top'}>
            <Star
              {...{ theme }}
              model={props.bonus}
              type={'bonus'}
              matchParams={matchParams}
              favorite_id={props.favorite_id}
            />
          </FlexItem>
        </FlexParent>
      </Card>
    </ItemLink>
  );
};

export default BonusItem;
