import { createSelector } from 'reselect';
import { API_TWEETS, API_YOUTUBE_VIDEOS } from '../constants/apiRoutes';
import { isLoading } from '../helpers/data';
const currentSocialMediaId = (state, props) => props.match.params.socialMediaId;
const socialMedia = (state, props) => state.socialMedia;
const socialMediaTweets = (state, props) => state.socialMediaTweets;
const socialMediaYoutubeVideos = (state, props) => state.socialMediaYoutubeVideos;
const loading = (state, props) => state.loading;

export const selectCurrentSocialMedia = createSelector(
  [socialMedia, currentSocialMediaId],
  (socialMedia, currentSocialMediaId) => {
    return socialMedia[currentSocialMediaId] || null;
  }
);

export const selectTweets = createSelector(
  [socialMediaTweets],
  tweets => {
    return tweets;
  }
);

export const selectYoutubeVideos = createSelector(
  [socialMediaYoutubeVideos],
  youtubeVideos => {
    return youtubeVideos;
  }
);

// --- API is loading ---------------------
export const selectIsLoadingTweets = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_TWEETS);
  }
);

export const selectIsLoadingYoutube = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_YOUTUBE_VIDEOS);
  }
);
// ----------------------------------------
