import axios from 'axios';
import store from '../store';
import { GET, DELETE, POST, PUT } from '../constants/actionApi';
import produce from 'immer';
import i18n from '../i18n';

export function sendRequestToApi(method, url, params, isUpload) {
  const s = store.getState();
  const accessToken = s.global.accessToken;
  const sessionId = s.global.sessionId;
  const socketId = s.global.socketId;

  // Duplicate object and clean function params
  params = { ...params };
  delete params.done;
  delete params.fail;
  delete params.always;

  let locale = null ;

  if (params.event_id) {
    if (localStorage.getItem(`locale_event_${params.event_id}`)) {
      locale = localStorage.getItem(`locale_event_${params.event_id}`);
    } else if (localStorage.getItem('locale')){
      locale = localStorage.getItem('locale');
    } else {
      locale = 'en';
    }
  } else {
    locale = 'en';
  }

  let axiosParams = {
    url: url,
    method: method,
    //timeout: 1,
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
      'X-Event-Group-Id': params && params.app_id && params.app_id !== '0' ? params.app_id : null,
      'X-Event-Id': (params && params.event_id) || null,
      'X-Session-Id': sessionId ? sessionId : null,
      'X-locale' : locale,
      'X-Socket-Id': socketId ? socketId : null
    }
  };

  if (isUpload) {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => formData.append(key, value));
    params = formData;
  }

  if ([GET, DELETE].indexOf(method) !== -1) {
    axiosParams.params = params;
  } else if ([POST, PUT].indexOf(method) !== -1) {
    axiosParams.data = params;
  }

  return axios(axiosParams)
    .then(response => ({ response }))
    .catch(error => {
      return parseError(error);
    });
}

export function uploadCameraFile(url, fileUri, options) {
  let ft = new window.FileTransfer();
  let promise = new Promise((resolve, reject) => {
    ft.upload(
      fileUri,
      url,
      res => {
        resolve(JSON.parse(res.response));
      },
      error => {
        reject(error);
      },
      options
    );
  });

  return promise
    .then(response => ({ response }))
    .catch(error => {
      return parseError(error);
    });
}

function parseError(error) {
  let message = null;
  let status = null;
  let errors = null;

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.data) {
      message = error.response.data.message
        ? error.response.data.message
        : error.response.data.error && typeof error.response.data.error === 'string'
        ? error.response.data.error
        : '';
    }
    errors = error.response.data ? error.response.data.errors : null;
    status = error.response.status;
  } else if (error.request) {
    // The request was made but no response was received (timeout)
    message = error.request.responseText;
    status = error.request.status;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = error.message;
  }

  return {
    error: {
      message,
      status,
      errors
    }
  };
}

export function addOrUpdatePaginationInState(baseState, page, parentId, path) {
  if (!page) {
    return baseState;
  }
  const nextState = produce(baseState, draftState => {
    let data = {};
    data[`${parentId}-${path}`] = { ...draftState[`${parentId}-${path}`], ...page };
    draftState = { ...draftState, ...data };
    return draftState;
  });
  return nextState;
}

export function addOrUpdateLoadingInState(baseState, path, loading) {
  if (!path) {
    return baseState;
  }
  const nextState = produce(baseState, draftState => {
    let data = {};
    data[path] = { ...draftState[path], ...loading };
    draftState = { ...draftState, ...data };
    return draftState;
  });
  return nextState;
}

export function addOrUpdateItemsInState(baseState, items, reset, initialState) {
  if (!items) {
    return baseState;
  }
  if (!Array.isArray(items)) {
    items = [items];
  }
  const nextState = produce(baseState, draftState => {
    let data = {};
    items.forEach(item => {
      data[item.id] = { ...draftState[item.id], ...item };
    });
    if (reset) {
      draftState = { ...initialState, ...data };
    } else {
      draftState = { ...draftState, ...data };
    }
    return draftState;
  });
  return nextState;
}

export function addOrUpdateIdsInState(baseState, payload, key, parentKey, atFirst) {
  let items = payload.data;
  const params = payload.params;

  if (!items) {
    return baseState;
  }
  if (!Array.isArray(items)) {
    items = [items];
  }

  const nextState = produce(baseState, draftState => {
    if (params && params.reset) {
      const parentId = +params[parentKey];
      if (draftState[key]) {
        draftState[key] = draftState[key].filter(item => {
          return parentId && item.parent_id !== parentId;
        });
      }
    }

    const draftItems = draftState[key] || [];
    items.forEach(item => {
      if (
        draftItems.find(it => {
          return it.id === item.id && it.parent_id === (item[parentKey] || null);
        })
      ) {
        return draftState;
      }
      item = {
        id: item.id,
        parent_id: item[parentKey] || null
      };
      if (atFirst) {
        draftItems.unshift(item);
      } else {
        draftItems.push(item);
      }
    });
    draftState[key] = draftItems;
    return draftState;
  });
  return nextState;
}

export function removeIdInState(baseState, payload, key) {
  let item = payload.data;
  const nextState = produce(baseState, draftState => {
    let draftItems = draftState[key] || [];
    draftItems = draftItems.filter(it => {
      return it.id !== item.id;
    });
    draftState[key] = draftItems;
    return draftState;
  });
  return nextState;
}

export function addOrUpdateNotifInState(baseState, item, parentId, path) {
  if (!item) {
    return baseState;
  }
  const nextState = produce(baseState, draftState => {
    let data = {};
    path = `${parentId}-${path}`;
    data[item.id] = item;
    draftState[path] = { ...(draftState[path] || {}), ...data };
    return draftState;
  });
  return nextState;
}

export function resetNotifInState(baseState, parentId, path) {
  const nextState = produce(baseState, draftState => {
    path = `${parentId}-${path}`;
    delete draftState[path];
    return draftState;
  });
  return nextState;
}

export function removeItemInState(baseState, id) {
  const nextState = produce(baseState, draftState => {
    delete draftState[id];
    return draftState;
  });
  return nextState;
}

export function updateObjectInState(baseState, item) {
  const nextState = produce(baseState, draftState => {
    draftState = { ...draftState, ...item };
    return draftState;
  });
  return nextState;
}

export function resetObjectInState(baseState, initialState) {
  const nextState = produce(baseState, draftState => {
    draftState = initialState;
    return draftState;
  });
  return nextState;
}

export function getCursorPagination(path, per_page, parentId, reset) {
  const sortIds = store.getState().sortIds;
  if (sortIds[path] && !reset) {
    const currentIds = sortIds[path];
    const filteredIds = currentIds
      .filter(item => {
        return item['parent_id'] === parentId;
      })
      .map(item => {
        return item.id;
      });

    let maxId = filteredIds[filteredIds.length - 1];
    maxId = maxId ? maxId - 1 : null;

    return {
      max_id: maxId,
      per_page: per_page
    };
  }
  return {
    per_page,
    max_id: null
  };
}

export function getPagination(path, per_page, parentId, reset) {
  const pagination = store.getState().pagination;
  if (pagination[`${parentId}-${path}`] && !reset) {
    const meta = pagination[`${parentId}-${path}`];
    const nextPage = meta.current_page + 1;
    return {
      per_page: meta.per_page,
      page: nextPage > meta.last_page ? meta.last_page : nextPage
    };
  }
  return {
    per_page,
    page: 1
  };
}

export function filterObjects(baseObj, fnFilter, asArray) {
  const nextObj = {};
  const nextArray = [];

  for (const key in baseObj) {
    if (!baseObj.hasOwnProperty(key)) continue;
    let obj = baseObj[key];
    if (fnFilter(obj)) {
      if (asArray) {
        nextArray.push(obj);
      } else {
        nextObj[obj.id] = obj;
      }
    }
  }

  return asArray ? nextArray : nextObj;
}

export function filterIds(ids, objects) {
  return ids
    .filter(item => {
      return objects[item.id] ? true : false;
    })
    .map(item => {
      return item.id;
    });
}

export function hasMore(pagination, parentId, key) {
  const meta = pagination[`${parentId}-${key}`];
  return !(meta && meta.current_page === meta.last_page);
}

export function hasMoreCursor(pagination, parentId, key, count) {
  const meta = pagination[`${parentId}-${key}`];
  return !(meta && meta.count <= count);
}

export function isLoading(loading, key) {
  return loading[key] && loading[key].loading;
}

export function getNotifs(notifications, parentId, key) {
  const path = notifications[`${parentId}-${key}`];
  if (!path) {
    return [];
  }
  return Object.keys(notifications[`${parentId}-${key}`]);
}

export function getNotifsCount(notifications, parentId, key) {
  return getNotifs(notifications, parentId, key).length;
}

export function setLocale(app, event, user) {
  let newLocale = null;
  const currentLocale = localStorage.getItem('locale');

  if (event) {
    const eventPreferredLocale = localStorage.getItem(`locale_event_${event.id}`);
    if (eventPreferredLocale && event.available_locales.indexOf(eventPreferredLocale) !== -1) {
      newLocale = eventPreferredLocale;
    } else if (user && event.available_locales.indexOf(user.locale) !== -1) {
      newLocale = user.locale;
    } else {
      newLocale = event.default_locale;
    }
  } else if (app) {
    if (user && app.available_locales.indexOf(user.locale) !== -1) {
      newLocale = user.locale;
    } else {
      newLocale = app.default_locale;
    }
  }

  if (newLocale && newLocale !== currentLocale) {
    console.log('%c New locale: ', 'color: purple', newLocale);
    i18n.changeLanguage(newLocale);
    localStorage.setItem('locale', newLocale);
    return newLocale;
  }
  return currentLocale;
}
