import React from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
import FlexParent from '../../components/shared/flex/FlexParent';
import FlexItem from '../../components/shared/flex/FlexItem';
import Icon from '../../components/icons/Icon';
import IconPoi from '../../components/icons/IconPoi';
import IconChevron from '../../components/icons/IconChevron';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled(motion.div)`
  background-color: #fff;
  border-right: 1px solid ${props => props.theme.dustGrey};
  position: fixed;
  left: -50px;
  width: 300px;
  top: ${props => props.headerheight + 1 + 'px'};
  bottom: 0;
  z-index: 20;
  padding: 10px 10px 10px 60px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  #geocoder .mapboxgl-ctrl-geocoder {
    min-width: 100%;
    margin: 0;
    box-shadow: none;
    border: none;
  }

  .mapboxgl-ctrl-geocoder--input {
    width: 100%;
    margin: 3px 0 2px;
    padding: 0 35px 0 35px;
    background-color: #e4e4e4;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    height: 36px;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    position: absolute;
    top: 10px;
    left: 6px;
    width: 23px;
    height: 23px;
  }

  .mapboxgl-ctrl-geocoder--button {
    background-color: #757575;
    margin: 0;
    padding: 4px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 11px !important;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    fill: #e4e4e4;
    top: 0;
    margin: 0;
    display: block;
    width: 12px;
    height: 12px;
  }
`;

const MenuHeading = styled.div`
  padding: 20px 0;
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
`;

const MenuItem = styled(FlexParent)`
  padding: 15px 0;
  cursor: pointer;
`;

const MenuItemName = styled.div`
  font-size: 1.5rem;
`;

const MenuItemDesc = styled.div`
  padding-top: 3px;
  font-size: 1.2rem;
  color: ${props => props.theme.shadowGrey};
`;

class Menu extends React.Component {
  state = {
    showList: false
  };

  componentDidMount = () => {
    this.renderSearch();
  };

  onClickIndoorMap = event => {
    const currentIndoorMap = this.props.indoorMaps.find(indoorMap => {
      return indoorMap.id === +event.currentTarget.dataset.id;
    });
    this.props.openIndoorMap(currentIndoorMap);
    const poi = this.props.pois[currentIndoorMap.poi_id];
    this.props.jumpToPoi(poi);
  };

  onClickOutside = event => {
    if (!event.target.closest('#menu-control')) {
      this.props.closeMenu();
    }
  };

  onClickPoi = event => {
    const poi = this.props.pois[+event.currentTarget.dataset.id];
    this.props.jumpToPoi(poi);
  };

  renderSearch() {
    let geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: this.props.t('search')
    });

    geocoder.on('result', ev => {
      this.props.map.getSource('single-point').setData(ev.result.geometry);
      this.props.closeMenu();
    });

    document.getElementById('geocoder').appendChild(geocoder.onAdd(this.props.map));
  }

  render() {
    const { headerHeight, indoorMaps, poisIds, pois } = this.props;
    const { onClickOutside } = this;

    return (
      <ClickOutside onClickOutside={onClickOutside}>
        <AnimatePresence>
          <Container
            headerheight={headerHeight}
            key={'map_menu'}
            transition={{ type: 'spring', duration: 0.2, mass: 0.7, stiffness: 80 }}
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            exit={{ x: -300 }}
          >
            <div id="geocoder" />
            {poisIds && poisIds.length ? (
              <React.Fragment>
                <MenuHeading>{this.props.t('pois')}</MenuHeading>

                {poisIds.map(poiId => {
                  const poi = pois[poiId];
                  return (
                    <MenuItem
                      key={poi.id}
                      onClick={this.onClickPoi}
                      data-id={poi.id}
                      alignItems={'center'}
                    >
                      <FlexItem>
                        <Icon
                          margin={'0 15px 0 10px'}
                          backgroundColor={poi.color}
                          icon={IconPoi}
                          width={'25px'}
                          height={'25px'}
                          color={'#fff'}
                          borderRadius={'50%'}
                          padding={'5px'}
                        />
                      </FlexItem>
                      <FlexItem grow={'1'}>
                        <MenuItemName>{poi.name}</MenuItemName>
                        <MenuItemDesc>{poi.address}</MenuItemDesc>
                      </FlexItem>

                      <FlexItem>
                        <Icon
                          margin={'0 5px 0 15px'}
                          icon={IconChevron}
                          width={'10px'}
                          height={'10px'}
                          color={this.props.theme.mainColor}
                        />
                      </FlexItem>
                    </MenuItem>
                  );
                })}
              </React.Fragment>
            ) : null}
            {indoorMaps && indoorMaps.length ? (
              <React.Fragment>
                <MenuHeading>{this.props.t('indoor_maps')}</MenuHeading>

                {indoorMaps.map(indoorMap => {
                  return (
                    <MenuItem
                      key={indoorMap.id}
                      onClick={this.onClickIndoorMap}
                      data-id={indoorMap.id}
                      alignItems={'center'}
                    >
                      <FlexItem>
                        <Icon
                          margin={'0 15px 0 10px'}
                          backgroundColor={indoorMap.poi.color}
                          icon={IconPoi}
                          width={'25px'}
                          height={'25px'}
                          color={'#fff'}
                          borderRadius={'50%'}
                          padding={'5px'}
                        />
                      </FlexItem>
                      <FlexItem grow={'1'}>
                        <MenuItemName>{indoorMap.name}</MenuItemName>
                        <MenuItemDesc>{indoorMap.level_name}</MenuItemDesc>
                      </FlexItem>

                      <FlexItem>
                        <Icon
                          margin={'0 5px 0 15px'}
                          icon={IconChevron}
                          width={'10px'}
                          height={'10px'}
                          color={this.props.theme.mainColor}
                        />
                      </FlexItem>
                    </MenuItem>
                  );
                })}
              </React.Fragment>
            ) : null}
          </Container>
        </AnimatePresence>
      </ClickOutside>
    );
  }
}

export default Menu;
