import { connect } from 'react-redux';
import { updateNav } from '../../actions/local';
import { fetchMenuItems } from '../../actions/api';
import { updateGlobal } from '../../actions/local';
import ClickOutside from 'react-click-outside';
import Nav from './Nav';
import React from 'react';

import { selectUser } from '../../selectors/user';
import { selectNav } from '../../selectors/nav';
import { selectGlobal } from '../../selectors/global';

import { selectCurrentEvent } from '../../selectors/events';
import { selectMenuItems } from '../../selectors/menuItems';

class NavContainer extends React.Component {
  state = {
    itemVisible: null
  };

  componentDidMount() {
    if (this.props.event && (this.props.event.visibility === 'public' || this.props.event.visibility_mode === 'opened_public' || this.props.user)) {
      this.props.fetchMenuItems({ event_id: this.props.event.id });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.event &&
      (this.props.event.visibility === 'public' || this.props.event.visibility_mode === 'opened_public' || this.props.user) &&
      (this.userHasChange(this.props.user, prevProps.user) ||
        this.eventHasChange(this.props.event, prevProps.event))
    ) {
      this.props.fetchMenuItems({ event_id: this.props.event.id });
    }

    if (!this.props.global.offline && prevProps.global.offline && this.props.event) {
      this.props.fetchMenuItems({ event_id: this.props.event.id });
    }
  }

  userHasChange = (user, prevUser) => {
    return (user && !prevUser) || (user && prevUser && prevUser.id !== user.id);
  };

  eventHasChange = (event, prevEvent) => {
    return (
      (!event && prevEvent) ||
      (event && !prevEvent) ||
      (event && prevEvent && prevEvent.id !== event.id)
    );
  };

  onClickOutside = event => {
    if (this.props.nav.isOpened) {
      event.preventDefault();
      this.closeMenu();
    }
  };

  onClickItem = url => {
    this.closeMenu();
    this.props.history.push(url);
  };

  onClickNotification = event => {
    this.closeMenu();
  };

  // TODO refactoring: merge this 3 functions
  onClickQrCode = event => {
    const { appId, eventId } = this.props.match.params;
    const url = `/apps/${appId}/events/${eventId}/user-qr-code`;
    this.props.history.push(url);
    this.closeMenu();
  };

  onClickSettings = event => {
    const { appId, eventId } = this.props.match.params;
    const url = `/apps/${appId}/events/${eventId}/user-settings`;
    this.props.history.push(url);
    this.closeMenu();
  };

  onClickAnimator = event => {
    const { appId, eventId } = this.props.match.params;
    const url = `/apps/${appId}/events/${eventId}/animator`;
    this.props.history.push(url);
    this.closeMenu();
  };

  setItemVisible = itemId => {
    this.setState({
      itemVisible: itemId
    });
  };

  render() {
    const matchParams = this.props.match.params;
    const { theme, t, nav, items, user, event, global, updateGlobal } = this.props;
    const {
      onClickNotification,
      onClickQrCode,
      onClickSettings,
      onClickAnimator,
      onClickOutside,
      onClickItem,
      setItemVisible
    } = this;

    const itemVisible = this.state.itemVisible;

    return (
      items && (
        <ClickOutside onClickOutside={onClickOutside}>
          <Nav
            {...{
              matchParams,
              theme,
              t,
              nav,
              user,
              event,
              global,
              updateGlobal,
              items,
              itemVisible,
              setItemVisible,
              onClickItem,
              onClickNotification,
              onClickQrCode,
              onClickSettings,
              onClickAnimator
            }}
          />
        </ClickOutside>
      )
    );
  }

  closeMenu = () => {
    this.props.updateNav({
      isOpened: false
    });

    this.setItemVisible(null);
  };
}

const mapDispatchToProps = {
  updateNav: updateNav,
  fetchMenuItems: fetchMenuItems,
  updateGlobal: updateGlobal
};

const mapStateToProps = (state, ownProps) => {
  return {
    nav: selectNav(state, ownProps),
    user: selectUser(state, ownProps),
    global: selectGlobal(state, ownProps),
    items: selectMenuItems(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavContainer);
