import React from 'react';
import styled from 'styled-components';

const ErrorStyle = styled.div`
  color: red;
  font-size: 1.1rem;
  padding-left: 5px;
`;

const InputError = props => {
  return <ErrorStyle>{props.children}</ErrorStyle>;
};

export default InputError;
