import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M4.8,12C2.1,12,0,14.2,0,16.8c0,2.7,2.1,4.8,4.8,4.8s4.8-2.1,4.8-4.8C9.6,14.2,7.5,12,4.8,12z M7,17.5H5.5V19 c0,0.4-0.3,0.7-0.7,0.7S4.1,19.3,4.1,19v-1.5H2.6c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h1.5v-1.5c0-0.4,0.3-0.7,0.7-0.7 s0.7,0.3,0.7,0.7v1.5H7c0.4,0,0.7,0.3,0.7,0.7C7.6,17.2,7.3,17.5,7,17.5z" />
      <path d="M18.2,9.1c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4C16.6,12.5,18.2,11,18.2,9.1z" />
      <path d="M14.7,2.2C9.6,2.2,5.3,6.1,4.6,11c0.1,0,0.1,0,0.2,0c0.4,0,0.7,0,1.1,0.1c0.7-4.3,4.4-7.6,8.8-7.6 c4.9,0,8.9,4,8.9,8.9c0,2.5-1,4.7-2.6,6.3v-0.7c0-2.4-1.9-4.3-4.3-4.3h-4c-1,0-1.8,0.3-2.6,0.9c0.3,0.7,0.4,1.4,0.4,2.1 c0,1.6-0.7,3.1-1.7,4.1c0,0,0,0,0,0l0,0c1.7,1.2,3.7,1.8,5.9,1.8c5.7,0,10.3-4.6,10.3-10.3C25,6.8,20.4,2.2,14.7,2.2z" />
    </g>
  </svg>
);
