import React from 'react';
import styled from 'styled-components';
import IconSettings from '../../../components/icons/IconSettings';
import ServiceHeader from '../../../components/shared/ServiceHeader';
import Card from '../../../components/shared/card/Card';
import Switch from '../../../components/shared/form/Switch';
import FormGroup from '../../../components/shared/form/FormGroup';
import i18n from '../../../i18n';

const CardHeading = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  padding: 5px 0 20px;
  text-align: center;
`;

class Settings extends React.Component {
  state = {
    locale: 'en',
    allows_1: true,
    allows_2: true,
    allows_3: true,
    allows_4: false,
    allows_5: true,
    allows_6: true,
    allows_7: true,
    allows_8: true,
    allows_9: true,
    allows_10: true,
    allows_11: true,
    allows_12: true
  };

  onChangeLocale = event => {
    const locale = event.currentTarget.value;
    this.setState({
      locale
    });
    i18n.changeLanguage(locale);
  };

  onChangeSwitch = event => {
    const isChecked = event.currentTarget.checked;
    let value = {};
    value[event.currentTarget.name] = isChecked;
    this.setState(value);
  };

  render() {
    const { t, theme } = this.props;

    return (
      <React.Fragment>
        <ServiceHeader icon={IconSettings} name={t('settings')} theme={theme} t={t} />

        <Card margin={'15px 0'} padding={'15px'}>
          <CardHeading>{t('settings_push_notifications')}</CardHeading>

          <FormGroup>
            <Switch
              name={'allows_1'}
              textBefore={true}
              checked={this.state.allows_1}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsqu'on "like" mes publications</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_2'}
              textBefore={true}
              checked={this.state.allows_2}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsqu'on commente mes publications</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_3'}
              textBefore={true}
              checked={this.state.allows_3}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsqu'on m'identifie dans une publication</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_4'}
              textBefore={true}
              checked={this.state.allows_4}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsqu'on m'invite à un RDV</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_5'}
              textBefore={true}
              checked={this.state.allows_5}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsque je reçois un message</div>
            </Switch>
          </FormGroup>
        </Card>

        <Card margin={'15px 0'} padding={'15px'}>
          <CardHeading>{t('settings_email_notifications')}</CardHeading>
          <FormGroup>
            <Switch
              name={'allows_6'}
              textBefore={true}
              checked={this.state.allows_6}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsqu'on "like" mes publications</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_7'}
              textBefore={true}
              checked={this.state.allows_7}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsqu'on commente mes publications</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_8'}
              textBefore={true}
              checked={this.state.allows_8}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsqu'on m'identifie dans une publication</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_9'}
              textBefore={true}
              checked={this.state.allows_9}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsqu'on m'invite à un RDV</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_10'}
              textBefore={true}
              checked={this.state.allows_10}
              onChange={this.onChangeSwitch}
            >
              <div>M'alerter lorsque je reçois un message</div>
            </Switch>
          </FormGroup>
        </Card>

        <Card margin={'15px 0'} padding={'15px'}>
          <CardHeading>{t('settings_privacy')}</CardHeading>
          <FormGroup>
            <Switch
              name={'allows_11'}
              textBefore={true}
              checked={this.state.allows_11}
              onChange={this.onChangeSwitch}
            >
              <div>Autoriser les participants à m'envoyer un mail</div>
            </Switch>
          </FormGroup>

          <FormGroup>
            <Switch
              name={'allows_12'}
              textBefore={true}
              checked={this.state.allows_12}
              onChange={this.onChangeSwitch}
            >
              <div>
                Autoriser les participants à me contacter par téléphone, si mon numéro est renseigné
              </div>
            </Switch>
          </FormGroup>
        </Card>
      </React.Fragment>
    );
  }
}

export default Settings;
