import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M5.7,24.2H0.4V8.1h5.4V24.2z M3,5.9L3,5.9c-1.8,0-3-1.2-3-2.9s1.2-2.7,3-2.7s3,1.2,3,2.7C6.1,4.7,4.8,5.9,3,5.9z M25,24.2 h-5.4v-8.6c0-2.1-0.7-3.6-2.7-3.6c-1.4,0-2.3,1.1-2.7,2c-0.2,0.4-0.2,0.9-0.2,1.2v8.9H8.7c0,0,0-14.5,0-16.1h5.4v2.3 c0.7-1.1,2-2.7,4.8-2.7c3.6,0,6.1,2.3,6.1,7.1V24.2z" />
      <path d="M13.9,10.4L13.9,10.4L13.9,10.4L13.9,10.4z" />
    </g>
  </svg>
);
