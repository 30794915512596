import React from 'react';
import styled from 'styled-components';
import MessagesList from './MessagesList';
import MessageCreate from './MessageCreate';
import ModalOptions from './ModalOptions';
import ModalLeave from './ModalLeave';
import { CONVERSATION_EDIT } from '../../../constants/app';
import ConversationDetailsHeader from './ConversationDetailsHeader';

const Container = styled.div`
  margin-bottom: ${props => props.componentFixedBottomHeight + 15 + 'px'};
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

class ConversationDetails extends React.Component {
  state = {
    openOptionsModal: false,
    openLeaveModal: false
  };

  componentDidMount() {
    this.props.fetchConversation({ conversation_id: this.props.conversationId });
  }

  componentDidUpdate(prevProps) {
    if (this.props.conversationId !== prevProps.conversationId) {
      this.props.fetchConversation({ conversation_id: this.props.conversationId });
    }
  }

  onClickConversation = () => {
    this.openOptionsModal();
  };

  onClickOptionEdit = () => {
    this.props.updateView({
      view: CONVERSATION_EDIT,
      id: this.props.conversation.id
    });
  };

  onClickOptionLeave = () => {
    this.closeOptionsModal();
    this.openLeaveModal();
  };

  onClickLeave = ({ attendee_id }) => {
    this.props.postConversationLeave({
      conversation_id: this.props.conversation.id,
      attendee_id,
      done: () => {
        this.props.updateView({});
      }
    });
  };

  openOptionsModal = () => {
    this.setState({ openOptionsModal: true });
  };

  closeOptionsModal = () => {
    this.setState({ openOptionsModal: false });
  };

  openLeaveModal = () => {
    this.setState({ openLeaveModal: true });
  };

  closeLeaveModal = () => {
    this.setState({ openLeaveModal: false });
  };

  render() {
    if (!this.props.conversation) {
      return null;
    }
    const {
      conversation,
      postMessage,
      uploadPostFile,
      uploadCameraFile,
      messages,
      messagesIds,
      componentFixedBottomHeight,
      componentFixedTopHeight,
      setComponentFixedBottomHeight,
      setComponentFixedTopHeight,
      isLoadingMessages,
      hasMoreMessages,
      conversationId,
      fetchMessages,
      user,
      event,
      theme,
      t,
      headerHeight,
      offline,
      postWhosHereMessageRead,
      accessToken
    } = this.props;

    const {
      onClickOptionEdit,
      onClickOptionLeave,
      onClickLeave,
      onClickConversation,
      closeOptionsModal,
      closeLeaveModal
    } = this;

    return (
      <React.Fragment>
        <ConversationDetailsHeader
          {...{
            onClickConversation,
            headerHeight,
            setComponentFixedTopHeight,
            conversation,
            user,
            t,
            theme
          }}
        />

        <Container {...{ componentFixedBottomHeight, componentFixedTopHeight }}>
          <MessagesList
            {...{
              conversationId,
              isLoadingMessages,
              hasMoreMessages,
              fetchMessages,
              messages,
              messagesIds,
              user,
              event,
              t,
              postWhosHereMessageRead
            }}
          />
        </Container>
        <MessageCreate
          {...{
            t,
            conversation,
            postMessage,
            uploadPostFile,
            uploadCameraFile,
            setComponentFixedBottomHeight,
            offline,
            accessToken
          }}
        />

        <ModalOptions
          open={this.state.openOptionsModal}
          {...{
            onClickOptionEdit,
            onClickOptionLeave,
            user,
            conversation,
            theme,
            t
          }}
          onClose={closeOptionsModal}
        />

        <ModalLeave
          open={this.state.openLeaveModal}
          {...{
            onClickLeave,
            user,
            conversation,
            theme,
            t
          }}
          onClose={closeLeaveModal}
        />
      </React.Fragment>
    );
  }
}

export default ConversationDetails;
