import { getUniqId } from '../helpers/helpers';
import jwt_decode from 'jwt-decode';

let sessionId = localStorage.getItem('session_id');
if (!sessionId) {
  sessionId = getUniqId();
  localStorage.setItem('session_id', sessionId);
}
let accessToken = localStorage.getItem('access_token');
const attendeeToken = new URLSearchParams(window.location.search).get('attendeeToken');

let isInAppLive =
  window.location &&
  window.location.hostname &&
  window.location.hostname.split('.')[0] === 'applive';

if (isInAppLive && accessToken) {
  const decodedToken = jwt_decode(accessToken);
  if (new URLSearchParams(window.location.search).get('attendeeId') !== decodedToken.sub) {
    accessToken = null;
    localStorage.removeItem('access_token');
  }
}

const initialState = {
  agendaCategories: {},
  agendas: {},
  agendaSessions: {},
  // single object
  app: {},
  bonusLists: {},
  bonuses: {},
  countries: {},
  errors: {},
  events: {},
  exhibitorLists: {},
  exhibitors: {},
  favorites: {},
  // single object
  global: {
    locale: '',
    sessionId: sessionId,
    socketId: null,
    componentFixedTopHeight: 0,
    componentFixedBottomHeight: 0,
    agendaCurrentDate: null,
    agendaCategoriesFilters: [],
    smartqaIsAnonymous: accessToken ? false : true,
    surveysCurrentPage: {},
    userAvatarFileUrl: null,
    accessToken: accessToken,
    searchValue: '',
    presenceIds: [],
    offline: false,
    isInAppLive,
    attendeeToken: attendeeToken
  },
  // single object
  header: {
    logo: null,
    isVisible: true,
    leftBtn: 'nav',
    rightBtn: null,
    rightBtn2: null,
    showSearchBar: false,
    isCategoriesFiltersOpen: false,
    height: 50
  },
  loading: {},
  maps: {},
  mapPoi: {},
  menuItems: {},
  shortLinks: {},
  // single object
  nav: {
    isOpened: false
  },
  notifications: {},
  notifs: {},
  pagination: {},
  practicalInfo: {},
  smartqa: {},
  smartqaComments: {},
  smartqaPosts: {},
  socialMedia: {},
  socialMediaTweets: {},
  socialMediaYoutubeVideos: {},
  sortIds: {},
  speakerLists: {},
  speakers: {},
  sponsorLists: {},
  sponsors: {},
  surveyAnswers: {},
  surveyPages: {},
  success: {},
  surveys: {},
  timelines: {},
  timelineItems: {},
  // single object
  theme: {
    mainColor: '#b0b0b0',
    secondaryColor: '#003a70',
    lightGrey: '#ededed',
    dustGrey: '#d6d6d6',
    shadowGrey: '#b0b0b0',
    ashesGrey: '#979797',
    darkGrey: '#616161'
  },
  // single object
  user: {},
  voteAnswers: {},
  voteRounds: {},
  votes: {},
  whosHere: {},
  whosHereAttendees: {},
  whosHereConversations: {},
  whosHereMeetings: {},
  whosHereMessages: {}
};

export default initialState;
