import { createSelector } from 'reselect';
import { filterObjects, filterIds, hasMore, isLoading } from '../helpers/data';
import { API_FAVORITES } from '../constants/apiRoutes';

const currentEventId = (state, props) => props.match.params.eventId;
const currentFavoriteType = (state, props) => props.match.params.currentTab;
const favorites = (state, props) => state.favorites;
const favoritesIds = (state, props) =>
  state.sortIds[API_FAVORITES + '/' + props.match.params.currentTab] || [];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;

// --- Select filtered objects ------------
export const selectFilteredFavorites = createSelector(
  [currentFavoriteType, favorites],
  (currentFavoriteType, favorites) => {
    return filterObjects(favorites, favorite => {
      return favorite.model_type === currentFavoriteType;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredFavoritesIds = createSelector(
  [favoritesIds, selectFilteredFavorites],
  (favoritesIds, favorites) => {
    return filterIds(favoritesIds, favorites);
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMoreFavorites = createSelector(
  [currentEventId, pagination],
  (currentEventId, pagination) => {
    return hasMore(pagination, currentEventId, `${API_FAVORITES}/${currentFavoriteType}`);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingFavorites = createSelector(
  [loading],
  loading => {
    return isLoading(loading, `${API_FAVORITES}`);
  }
);
// ----------------------------------------
