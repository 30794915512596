import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 36.33 50'}>
    {/* <svg viewBox={props.viewBox || '0 0 36.33 50'} style={{ display: 'inline-block' }}> */}
    <g fill={props.color}>
      <path d="M31.59,45.1l.14,4.14a.61.61,0,0,1-.6.64L11.92,50a.63.63,0,0,1-.63-.62V44.26L.61,24.51c-2.2-4.06,2-7.57,5.81-4.36l4.45,3.76.06-20.29c0-4.83,7.29-4.82,7.29,0V13a3.67,3.67,0,0,1,6,2.65,3.69,3.69,0,0,1,6,2.2c2.28-1.9,6.2-.53,6.08,2.8l-.46,13.27a.46.46,0,0,1,0,.21Z" />
    </g>
  </svg>
);
