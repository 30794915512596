import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M9.7,2.7c1.2-2.2,4.4-2.2,5.6,0L24.6,19c1.2,2.2-0.3,4.9-2.8,4.9H3.2c-2.5,0-4.1-2.7-2.8-4.9L9.7,2.7z M13.4,3.7C13,3,12,3,11.6,3.7L2.3,20.1c-0.4,0.7,0.1,1.6,0.9,1.6h18.5c0.8,0,1.4-0.9,0.9-1.6L13.4,3.7z" />
      <path d="M11.5,9.3c0-0.6,0.5-1,1-1s1,0.5,1,1v6.2c0,0.6-0.5,1-1,1s-1-0.5-1-1V9.3z" />
      <path d="M12.5,17.7c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1s1-0.5,1-1C13.5,18.1,13.1,17.7,12.5,17.7" />
    </g>
  </svg>
);
