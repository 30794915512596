import { SUCCESS, FETCH_BONUSES, FETCH_BONUS } from '../constants/actionApi';
import { UPDATE_FAV_MODEL } from '../constants/actionLocal';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.bonuses, { type, payload }) => {
  switch (type) {
    case FETCH_BONUSES + SUCCESS:
    case FETCH_BONUS + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    case UPDATE_FAV_MODEL:
      if (payload.type === 'bonus') {
        return addOrUpdateItemsInState(state, payload.data);
      } else {
        return state;
      }
    default:
      return state;
  }
};
