import { FAILED, SUCCESS, FETCH_APP } from '../constants/actionApi';
import initialState from '../store/initialState';
import { updateObjectInState, resetObjectInState } from '../helpers/data';

export default (state = initialState.app, { type, payload }) => {
  switch (type) {
    case FETCH_APP + SUCCESS:
      return updateObjectInState(state, payload.data);
    case FETCH_APP + FAILED:
      return resetObjectInState(state, initialState.app);
    default:
      return state;
  }
};
