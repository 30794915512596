import React from 'react';
import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconChevron from '../../components/icons/IconChevron';
import IconAnimator from '../../components/icons/IconAnimator';
import ServiceHeader from '../../components/shared/ServiceHeader';

import { ListItemTitle } from '../../components/shared/ListItem';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class AnimatorServices extends React.Component {
  render() {
    const { t, theme, history, items, matchParams } = this.props;

    return (
      <React.Fragment>
        <ServiceHeader
          t={t}
          icon={IconAnimator}
          name={t('animator_name')}
          description={t('animator_desc')}
          theme={theme}
        />

        {Object.keys(items).map((key, index) => {
          const item = items[key];
          const { appId, eventId } = matchParams;
          const icon = getServiceIconByType(item.icon || item.type);

          return (
            <React.Fragment key={index}>
              {item.items.map((subItem, i) => {
                const itemUrl = `/apps/${appId}/events/${eventId}/animator/${subItem.id}`;

                return (
                  <Card
                    key={i}
                    margin={'0 0 20px'}
                    padding={'10px'}
                    onClick={event => {
                      history.push(itemUrl);
                    }}
                  >
                    <FlexParent justifyContent={'space-between'} alignItems={'center'}>
                      <FlexItem>
                        <Icon
                          icon={icon}
                          width={'32px'}
                          height={'32px'}
                          color={'#fff'}
                          padding={'8px'}
                          margin={'0 20px 0 0'}
                          backgroundColor={theme.mainColor}
                          borderRadius={'50%'}
                        />
                      </FlexItem>
                      <FlexItem grow={1}>
                        <ListItemTitle>{subItem.name}</ListItemTitle>
                      </FlexItem>

                      <FlexItem alignSelf={'center'}>
                        <Icon
                          icon={IconChevron}
                          width={'20px'}
                          height={'20px'}
                          padding={'5px'}
                          margin={'0 0 0 10px'}
                          color={theme.mainColor}
                        />
                      </FlexItem>
                    </FlexParent>
                  </Card>
                );
              })}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

export default AnimatorServices;
