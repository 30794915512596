import { SUCCESS, FETCH_AGENDA_CATEGORIES } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.agendaCategories, { type, payload }) => {
  switch (type) {
    case FETCH_AGENDA_CATEGORIES + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
