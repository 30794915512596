import React from 'react';
import Swipe from 'react-easy-swipe';
import { selectNav } from '../../selectors/nav';
import { connect } from 'react-redux';
import { updateNav } from '../../actions/local';
import styled from 'styled-components';

const SwipeStyle = styled(Swipe)`
  min-height: 50%;
  min-height: 50vh;
`;

class SwipeContainer extends React.Component {
  state = {
    startSwipeX: 0,
    startSwipeY: 0
  };

  onSwipeStart = event => {
    let clientX = event.touches[0].clientX;
    let clientY = event.touches[0].clientY;
    this.setState({
      startSwipeX: clientX,
      startSwipeY: clientY
    });
  };

  onSwipeMove = (position, event) => {
    this.setState({
      currentSwipeX: position.x,
      currentSwipeY: position.y
    });
  };

  onSwipeEnd = event => {
    if (this.state.startSwipeX < 25 && !this.props.nav.isOpened && this.props.event) {
      if (this.state.currentSwipeX > 150) {
        this.props.updateNav({
          isOpened: true
        });
      }
    } else if (this.props.nav.isOpened) {
      if (this.state.currentSwipeX < -150) {
        this.props.updateNav({
          isOpened: false
        });
      }
    } else if (!this.props.nav.isOpened) {
      if (this.props.onSwipeEnd) {
        this.props.onSwipeEnd({ x: this.state.currentSwipeX, y: this.state.currentSwipeY });
      }
    }

    this.setState({
      startSwipeX: 0,
      currentSwipeX: 0,
      startSwipeY: 0,
      currentSwipeY: 0
    });
  };

  render() {
    const { children } = this.props;
    return (
      <SwipeStyle
        onSwipeStart={this.onSwipeStart}
        onSwipeMove={this.onSwipeMove}
        onSwipeEnd={this.onSwipeEnd}
      >
        {children}
      </SwipeStyle>
    );
  }
}

const mapDispatchToProps = {
  updateNav: updateNav
};

const mapStateToProps = (state, ownProps) => {
  return {
    nav: selectNav(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwipeContainer);
