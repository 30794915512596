import { createSelector } from 'reselect';

const getTheme = (state, props) => state.theme;

export const selectTheme = createSelector(
  [getTheme],
  theme => {
    return theme;
  }
);
