import { put, takeLatest, takeEvery, all, call } from 'redux-saga/effects';
import * as actions from '../constants/actionApi';
import * as apiRoutes from '../constants/apiRoutes';
import {
  SUCCESS,
  PAGINATION_SUCCESS,
  FAILED,
  GET,
  DELETE,
  POST,
  PUT,
} from '../constants/actionApi';
import {
  sendRequestToApi,
  uploadCameraFile,
  getPagination,
  getCursorPagination
} from '../helpers/data';

const defaultPerPage = 50;

function* sendRequest(method, url, params, action, isUpload) {
  const { response, error } = yield call(sendRequestToApi, method, url, params, isUpload);

  if (response) {
    yield put({
      type: action + SUCCESS,
      payload: {
        data: response.data.data || response.data,
        params
      }
    });

    if (response.data.meta) {
      yield put({
        type: action + PAGINATION_SUCCESS,
        payload: {
          data: response.data.meta,
          params
        }
      });
    }

    if (params && params.done) {
      yield call(params.done, {
        data: response.data.data || response.data,
        params
      });
    }
  } else {
    yield put({
      type: action + FAILED,
      payload: {
        data: error
      }
    });
    if (params.fail) {
      yield call(params.fail, error);
    }
  }

  if (params && params.always) {
    yield call(params.always, {
      data: response && response.data ? response.data.data || response.data : null,
      params
    });
  }
}

export function* postCameraFile({ type, payload }) {
  const url = `${apiRoutes.API_UPLOAD}`;
  let options = new window.FileUploadOptions();
  options.chunkedMode = false;
  options.fileKey = 'file';
  options.mimeType = 'image/jpeg';
  options.fileName = payload.fileName;
  options.headers = {};
  options.params = payload.params;
  options.headers['X-Event-Group-Id'] =
    payload.app_id && payload.app_id !== '0' ? payload.app_id : null;
  options.headers['X-Event-Id'] = payload.event_id;
  options.headers['X-Session-Id'] = payload.session_id;
  options.headers.Authorization = payload.accessToken ? `Bearer ${payload.accessToken}` : null;

  const { response, error } = yield call(uploadCameraFile, url, payload.fileUri, options);
  if (response) {
    yield put({
      type: type + SUCCESS,
      payload: {
        data: response.data
      }
    });

    if (payload.params && payload.params.done) {
      yield call(payload.params.done, {
        data: response.data.data || response.data,
        params: payload.params
      });
    }

    if (payload.params && payload.params.always) {
      yield call(payload.params.always, {
        data: response && response.data ? response.data.data || response.data : null,
        params: payload.params
      });
    }
  } else if (error) {
    yield put({
      type: type + FAILED,
      payload: {
        data: error
      }
    });
    if (payload.params.fail) {
      yield call(payload.params.fail, error);
    }
  }
}

// FETCH MANY

export function* fetchEvents({ type, payload }) {
  let url = apiRoutes.API_EVENTS;
  if (payload.q) {
    url = `${url}/search`;
  }
  yield sendRequest(
    GET,
    url,
    { ...payload, ...getPagination(url, defaultPerPage, payload.group_id, payload.reset) },
    type
  );
}

export function* fetchMenuItems({ type, payload }) {
  const url = apiRoutes.API_MENU_ITEMS;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchShortLinks({ type, payload }) {
  const url = apiRoutes.API_SHORT_LINKS;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSessions({ type, payload }) {
  const url = apiRoutes.API_AGENDA_SESSIONS;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchBonuses({ type, payload }) {
  let url = apiRoutes.API_BONUSES;
  if (payload.q) {
    url = `${url}/search`;
  }
  yield sendRequest(
    GET,
    url,
    {
      ...payload,
      ...getPagination(apiRoutes.API_BONUSES, defaultPerPage, payload.list_id, payload.reset)
    },
    type
  );
}

export function* fetchExhibitors({ type, payload }) {
  let url = apiRoutes.API_EXHIBITORS;
  if (payload.q) {
    url = `${url}/search`;
  }
  yield sendRequest(
    GET,
    url,
    {
      ...payload,
      ...getPagination(apiRoutes.API_EXHIBITORS, defaultPerPage, payload.list_id, payload.reset)
    },
    type
  );
}

export function* fetchMapPois({ type, payload }) {
  const url = apiRoutes.API_MAP_POIS;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSpeakers({ type, payload }) {
  let url = apiRoutes.API_SPEAKERS;
  if (payload.q) {
    url = `${url}/search`;
  }
  yield sendRequest(
    GET,
    url,
    {
      ...payload,
      ...getPagination(apiRoutes.API_SPEAKERS, defaultPerPage, payload.list_id, payload.reset)
    },
    type
  );
}

export function* fetchSponsors({ type, payload }) {
  let url = apiRoutes.API_SPONSORS;
  if (payload.q) {
    url = `${url}/search`;
  }
  yield sendRequest(
    GET,
    url,
    {
      ...payload,
      ...getPagination(apiRoutes.API_SPONSORS, defaultPerPage, payload.list_id, payload.reset)
    },
    type
  );
}

export function* fetchSmartqaPosts({ type, payload }) {
  const url = apiRoutes.API_SMARTQA_QUESTIONS;
  yield sendRequest(
    GET,
    url,
    { ...payload, ...getCursorPagination(url, defaultPerPage, payload.smart_qa_id, payload.reset) },
    type
  );
}

export function* fetchSmartqaComments({ type, payload }) {
  const url = apiRoutes.API_SMARTQA_COMMENTS;
  yield sendRequest(
    GET,
    url,
    {
      ...payload,
      ...getCursorPagination(url, defaultPerPage, payload.commentable_id, payload.reset)
    },
    type
  );
}

export function* fetchAgendaCategories({ type, payload }) {
  const url = apiRoutes.API_AGENDA_CATEGORIES;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSurveyPages({ type, payload }) {
  const url = apiRoutes.API_SURVEY_PAGES;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchTimelineItems({ type, payload }) {
  const url = apiRoutes.API_TIMELINE_ITEMS;
  yield sendRequest(
    GET,
    url,
    {
      ...payload,
      ...getCursorPagination(url, defaultPerPage, payload.timeline_id, payload.reset)
    },
    type
  );
}

export function* fetchVoteRounds({ type, payload }) {
  const url = apiRoutes.API_VOTE_ROUNDS;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchCountries({ type, payload }) {
  const url = apiRoutes.API_COUNTRIES;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchTweets({ type, payload }) {
  const url = apiRoutes.API_TWEETS;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchYoutubeVideos({ type, payload }) {
  const url = apiRoutes.API_YOUTUBE_VIDEOS;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchAttendees({ type, payload }) {
  let url = apiRoutes.API_ATTENDEES;
  if (payload.q) {
    url = `${url}/search`;
  }
  yield sendRequest(
    GET,
    url,
    {
      ...payload,
      ...getPagination(apiRoutes.API_ATTENDEES, defaultPerPage, payload.event_id, payload.reset)
    },
    type
  );
}

export function* fetchWhosHereConversations({ type, payload }) {
  const url = apiRoutes.API_WHOS_HERE_CONVERSATIONS;
  yield sendRequest(
    GET,
    url,
    { ...payload, ...getPagination(url, defaultPerPage, payload.whos_here_id, payload.reset) },
    type
  );
}

export function* fetchWhosHereMessages({ type, payload }) {
  const url = apiRoutes.API_WHOS_HERE_MESSAGES;
  yield sendRequest(
    GET,
    url,
    {
      ...payload,
      ...getCursorPagination(url, defaultPerPage, payload.conversation_id, payload.reset)
    },
    type
  );
}

export function* fetchWhosHereMeetings({ type, payload }) {
  const url = apiRoutes.API_WHOS_HERE_MEETINGS;
  yield sendRequest(
    GET,
    url,
    { ...payload, ...getPagination(url, defaultPerPage, payload.whos_here_id, payload.reset) },
    type
  );
}

export function* fetchFavorites({ type, payload }) {
  const url = `${apiRoutes.API_FAVORITES}/${payload.type}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchDisclaimer({ type, payload }) {
  const url = `${apiRoutes.API_DISCLAIMER}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchOffline({ type, payload }) {
  const url = `${apiRoutes.API_OFFLINE}`;
  yield sendRequest(GET, url, payload, type);
}

// FETCH ONE

export function* searchApp({ type, payload }) {
  const url = `${apiRoutes.API_SUBDOMAIN}/${payload.subdomain}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchApp({ type, payload }) {
  const url = `${apiRoutes.API_APPS}/${payload.app_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchEvent({ type, payload }) {
  const url = `${apiRoutes.API_EVENTS}/${payload.event_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchPracticalInfo({ type, payload }) {
  const url = `${apiRoutes.API_PRACTICAL_INFO}/${payload.practical_info_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchAgenda({ type, payload }) {
  const url = `${apiRoutes.API_AGENDAS}/${payload.agenda_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchMap({ type, payload }) {
  const url = `${apiRoutes.API_MAPS}/${payload.map_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSmartqa({ type, payload }) {
  const url = `${apiRoutes.API_SMARTQA}/${payload.smart_qa_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchBonusList({ type, payload }) {
  const url = `${apiRoutes.API_BONUS_LISTS}/${payload.list_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchExhibitorList({ type, payload }) {
  const url = `${apiRoutes.API_EXHIBITOR_LISTS}/${payload.list_id}`;
  yield sendRequest(GET, url, payload, type);
}
export function* fetchSpeakerList({ type, payload }) {
  const url = `${apiRoutes.API_SPEAKER_LISTS}/${payload.list_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSponsorList({ type, payload }) {
  const url = `${apiRoutes.API_SPONSOR_LISTS}/${payload.list_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSession({ type, payload }) {
  const url = `${apiRoutes.API_AGENDA_SESSIONS}/${payload.session_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchExhibitor({ type, payload }) {
  const url = `${apiRoutes.API_EXHIBITORS}/${payload.exhibitor_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSpeaker({ type, payload }) {
  const url = `${apiRoutes.API_SPEAKERS}/${payload.speaker_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSponsor({ type, payload }) {
  const url = `${apiRoutes.API_SPONSORS}/${payload.sponsor_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSmartqaPost({ type, payload }) {
  const url = `${apiRoutes.API_SMARTQA_QUESTIONS}/${payload.post_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchTimeline({ type, payload }) {
  const url = `${apiRoutes.API_TIMELINES}/${payload.timeline_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchUser({ type, payload }) {
  const url = apiRoutes.API_ME;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSurvey({ type, payload }) {
  const url = `${apiRoutes.API_SURVEYS}/${payload.survey_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchVote({ type, payload }) {
  const url = `${apiRoutes.API_VOTES}/${payload.vote_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchSocialMedia({ type, payload }) {
  const url = `${apiRoutes.API_SOCIAL_MEDIA}/${payload.social_media_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchWhosHere({ type, payload }) {
  const url = `${apiRoutes.API_WHOS_HERE}/${payload.whos_here_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchWhosHereConversation({ type, payload }) {
  const url = `${apiRoutes.API_WHOS_HERE_CONVERSATIONS}/${payload.conversation_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchWhosHereMessagesUnread({ type, payload }) {
  const url = apiRoutes.API_WHOS_HERE_MESSAGES_UNREAD;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchWhosHereMeeting({ type, payload }) {
  const url = `${apiRoutes.API_WHOS_HERE_MEETINGS}/${payload.meeting_id}`;
  yield sendRequest(GET, url, payload, type);
}

export function* fetchAttendee({ type, payload }) {
  const url = `${apiRoutes.API_ATTENDEES}/${payload.attendee_id}`;
  yield sendRequest(GET, url, payload, type);
}

// POST

export function* postSmartqaPost({ type, payload }) {
  const url = `${apiRoutes.API_SMARTQA_QUESTIONS}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postSmartqaComment({ type, payload }) {
  const url = `${apiRoutes.API_SMARTQA_COMMENTS}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postSmartqaPostReport({ type, payload }) {
  const url = `${apiRoutes.API_SMARTQA_QUESTIONS}/${payload.post_id}/report`;
  yield sendRequest(POST, url, payload, type);
}

export function* postSmartqaCommentReport({ type, payload }) {
  const url = `${apiRoutes.API_SMARTQA_COMMENTS}/${payload.comment_id}/report`;
  yield sendRequest(POST, url, payload, type);
}

export function* postSmartqaPostReaction({ type, payload }) {
  const url = `${apiRoutes.API_REACTIONS}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postSmartqaCommentReaction({ type, payload }) {
  const url = `${apiRoutes.API_REACTIONS}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postSmartqaPostHideToAnimator({ type, payload }) {
  const url = `${apiRoutes.API_POST_SMARTQA_POST_HIDE_TO_ANIMATORS}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postFile({ type, payload }) {
  const url = `${apiRoutes.API_UPLOAD}`;
  yield sendRequest(POST, url, payload, type, true);
}

export function* postSurveyAnswers({ type, payload }) {
  const url = `${apiRoutes.API_SURVEY_QUESTION_ANSWERS}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postVoteAnswers({ type, payload }) {
  const url = `${apiRoutes.API_VOTE_ROUND_QUESTION_ANSWERS}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postWhosHereMessage({ type, payload }) {
  const url = `${apiRoutes.API_WHOS_HERE_MESSAGES}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postWhosHereMessagesRead({ type, payload }) {
  const url = apiRoutes.API_WHOS_HERE_MESSAGES_READ;
  yield sendRequest(POST, url, payload, type);
}

export function* postWhosHereConversation({ type, payload }) {
  let url = `${apiRoutes.API_WHOS_HERE_CONVERSATIONS}`;
  let method = POST;
  if (payload.id) {
    method = PUT;
    type = actions.PUT_WHOS_HERE_CONVERSATION;
    url = `${url}/${payload.id}`;
  }
  yield sendRequest(method, url, payload, type);
}

export function* postWhosHereMeeting({ type, payload }) {
  let url = `${apiRoutes.API_WHOS_HERE_MEETINGS}`;
  let method = POST;
  if (payload.id) {
    method = PUT;
    type = actions.PUT_WHOS_HERE_MEETING;
    url = `${url}/${payload.id}`;
  }
  yield sendRequest(method, url, payload, type);
}

export function* postUser({ type, payload }) {
  const url = `${apiRoutes.API_ATTENDEES}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postUserEmail({ type, payload }) {
  const url = `${apiRoutes.API_CHECK_USER_EMAIL}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postUserPwd({ type, payload }) {
  const url = `${apiRoutes.API_AUTH_TOKEN}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postForgotPwd({ type, payload }) {
  const url = `${apiRoutes.API_FORGOT_PASSWORD}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postResetPwd({ type, payload }) {
  const url = `${apiRoutes.API_RESET_PASSWORD}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postUserIncognito({ type, payload }) {
  const url = `${apiRoutes.API_ME}/toggle-incognito`;
  yield sendRequest(POST, url, payload, type);
}

export function* postUserAvatarFile({ type, payload }) {
  const url = `${apiRoutes.API_UPLOAD}`;
  yield sendRequest(POST, url, payload, type, true);
}

export function* postWhosHereMeetingStatus({ type, payload }) {
  const url = `${apiRoutes.API_WHOS_HERE_MEETINGS}/${payload.meeting_id}/set-status`;
  yield sendRequest(POST, url, payload, type);
}

export function* postWhosHereConversationLeave({ type, payload }) {
  const url = `${apiRoutes.API_WHOS_HERE_CONVERSATIONS}/${payload.conversation_id}/leave`;
  yield sendRequest(POST, url, payload, type);
}

export function* postFavorite({ type, payload }) {
  const url = `${apiRoutes.API_FAVORITES}`;
  yield sendRequest(POST, url, payload, type);
}

export function* postPushNotifSubscription({ type, payload }) {
  const url = `${apiRoutes.API_PUSH_NOTIF}`;
  yield sendRequest(POST, url, payload, type);
}

// PUT
export function* putUser({ type, payload }) {
  const url = `${apiRoutes.API_ME}`;
  yield sendRequest(PUT, url, payload, type);
}

// DELETE
export function* deleteSmartqaPostReaction({ type, payload }) {
  const url = `${apiRoutes.API_REACTIONS}`;
  yield sendRequest(DELETE, url, payload, type);
}

export function* deleteSmartqaCommentReaction({ type, payload }) {
  const url = `${apiRoutes.API_REACTIONS}`;
  yield sendRequest(DELETE, url, payload, type);
}

export function* deleteWhosHereMeeting({ type, payload }) {
  const url = `${apiRoutes.API_WHOS_HERE_MEETINGS}/${payload.meeting_id}`;
  yield sendRequest(DELETE, url, payload, type);
}

export function* deleteFavorite({ type, payload }) {
  const url = `${apiRoutes.API_FAVORITES}`;
  yield sendRequest(DELETE, url, payload, type);
}

// PUT
export function* deleteUser({ type, payload }) {
  const url = `${apiRoutes.API_DELETE_USER}`;
  yield sendRequest(DELETE, url, payload, type);
}


function* actionWatcher() {
  yield takeLatest(actions.FETCH_AGENDA_CATEGORIES, fetchAgendaCategories);
  yield takeLatest(actions.FETCH_AGENDA, fetchAgenda);
  yield takeLatest(actions.SEARCH_APP, searchApp);
  yield takeLatest(actions.FETCH_APP, fetchApp);
  yield takeLatest(actions.FETCH_ATTENDEE, fetchAttendee);
  yield takeLatest(actions.FETCH_ATTENDEES, fetchAttendees);
  yield takeLatest(actions.FETCH_BONUS_LIST, fetchBonusList);
  yield takeLatest(actions.FETCH_BONUSES, fetchBonuses);
  yield takeLatest(actions.FETCH_COUNTRIES, fetchCountries);
  yield takeLatest(actions.FETCH_EVENT, fetchEvent);
  yield takeLatest(actions.FETCH_EVENTS, fetchEvents);
  yield takeLatest(actions.FETCH_EXHIBITOR_LIST, fetchExhibitorList);
  yield takeLatest(actions.FETCH_EXHIBITOR, fetchExhibitor);
  yield takeLatest(actions.FETCH_EXHIBITORS, fetchExhibitors);
  yield takeLatest(actions.FETCH_MAP, fetchMap);
  yield takeLatest(actions.FETCH_MAP_POIS, fetchMapPois);
  yield takeLatest(actions.FETCH_MENU_ITEMS, fetchMenuItems);
  yield takeLatest(actions.FETCH_SHORT_LINKS, fetchShortLinks);
  yield takeLatest(actions.FETCH_PRACTICAL_INFO, fetchPracticalInfo);
  yield takeLatest(actions.FETCH_SESSION, fetchSession);
  yield takeLatest(actions.FETCH_SESSIONS, fetchSessions);
  yield takeLatest(actions.FETCH_SMARTQA_COMMENTS, fetchSmartqaComments);
  yield takeLatest(actions.FETCH_SMARTQA_POST, fetchSmartqaPost);
  yield takeLatest(actions.FETCH_SMARTQA_POSTS, fetchSmartqaPosts);
  yield takeLatest(actions.FETCH_SMARTQA, fetchSmartqa);
  yield takeLatest(actions.FETCH_SOCIAL_MEDIA, fetchSocialMedia);
  yield takeLatest(actions.FETCH_SPEAKER_LIST, fetchSpeakerList);
  yield takeLatest(actions.FETCH_SPEAKER, fetchSpeaker);
  yield takeLatest(actions.FETCH_SPEAKERS, fetchSpeakers);
  yield takeLatest(actions.FETCH_SPONSOR_LIST, fetchSponsorList);
  yield takeLatest(actions.FETCH_SPONSOR, fetchSponsor);
  yield takeLatest(actions.FETCH_SPONSORS, fetchSponsors);
  yield takeLatest(actions.FETCH_SURVEY_PAGES, fetchSurveyPages);
  yield takeLatest(actions.FETCH_SURVEY, fetchSurvey);
  yield takeLatest(actions.FETCH_TIMELINE, fetchTimeline);
  yield takeLatest(actions.FETCH_TIMELINE_ITEMS, fetchTimelineItems);
  yield takeLatest(actions.FETCH_TWEETS, fetchTweets);
  yield takeLatest(actions.FETCH_VOTE_ROUNDS, fetchVoteRounds);
  yield takeLatest(actions.FETCH_VOTE, fetchVote);
  yield takeLatest(actions.FETCH_WHOS_HERE_CONVERSATION, fetchWhosHereConversation);
  yield takeLatest(actions.FETCH_WHOS_HERE_CONVERSATIONS, fetchWhosHereConversations);
  yield takeLatest(actions.FETCH_WHOS_HERE_MEETING, fetchWhosHereMeeting);
  yield takeLatest(actions.FETCH_WHOS_HERE_MEETINGS, fetchWhosHereMeetings);
  yield takeLatest(actions.FETCH_WHOS_HERE_MESSAGES, fetchWhosHereMessages);
  yield takeLatest(actions.FETCH_WHOS_HERE, fetchWhosHere);
  yield takeLatest(actions.FETCH_WHOS_HERE_MESSAGES_UNREAD, fetchWhosHereMessagesUnread);
  yield takeLatest(actions.FETCH_YOUTUBE_VIDEOS, fetchYoutubeVideos);
  yield takeLatest(actions.FETCH_FAVORITES, fetchFavorites);
  yield takeLatest(actions.FETCH_DISCLAIMER, fetchDisclaimer);
  yield takeLatest(actions.FETCH_OFFLINE, fetchOffline);

  yield takeEvery(actions.POST_SMARTQA_COMMENT, postSmartqaComment);
  yield takeEvery(actions.POST_SMARTQA_POST, postSmartqaPost);
  yield takeEvery(actions.POST_SMARTQA_POST_REACTION, postSmartqaPostReaction);
  yield takeEvery(actions.POST_SMARTQA_COMMENT_REACTION, postSmartqaCommentReaction);
  yield takeEvery(actions.POST_SMARTQA_POST_REPORT, postSmartqaPostReport);
  yield takeEvery(actions.POST_SMARTQA_POST_HIDE_TO_ANIMATORS, postSmartqaPostHideToAnimator);
  yield takeEvery(actions.POST_SMARTQA_COMMENT_REPORT, postSmartqaCommentReport);
  yield takeEvery(actions.POST_FILE, postFile);
  yield takeEvery(actions.POST_CAMERA_FILE, postCameraFile);
  yield takeEvery(actions.POST_SURVEY_ANSWERS, postSurveyAnswers);
  yield takeEvery(actions.POST_VOTE_ANSWERS, postVoteAnswers);
  yield takeEvery(actions.POST_WHOS_HERE_MESSAGE, postWhosHereMessage);
  yield takeEvery(actions.POST_WHOS_HERE_CONVERSATION, postWhosHereConversation);
  yield takeEvery(actions.POST_WHOS_HERE_MEETING, postWhosHereMeeting);
  yield takeEvery(actions.POST_WHOS_HERE_MESSAGES_READ, postWhosHereMessagesRead);

  yield takeEvery(actions.POST_FAVORITE, postFavorite);

  yield takeLatest(actions.FETCH_USER, fetchUser);
  yield takeEvery(actions.POST_USER, postUser);
  yield takeEvery(actions.PUT_USER, putUser);
  yield takeEvery(actions.POST_USER_EMAIL, postUserEmail);
  yield takeEvery(actions.POST_USER_PWD, postUserPwd);
  yield takeEvery(actions.POST_FORGOT_PWD, postForgotPwd);
  yield takeEvery(actions.POST_RESET_PWD, postResetPwd);
  yield takeEvery(actions.POST_USER_AVATAR_FILE, postUserAvatarFile);
  yield takeEvery(actions.POST_USER_INCOGNITO, postUserIncognito);

  yield takeEvery(actions.DELETE_SMARTQA_POST_REACTION, deleteSmartqaPostReaction);
  yield takeEvery(actions.DELETE_SMARTQA_COMMENT_REACTION, deleteSmartqaCommentReaction);
  yield takeEvery(actions.DELETE_WHOS_HERE_MEETING, deleteWhosHereMeeting);
  yield takeEvery(actions.DELETE_FAVORITE, deleteFavorite);
  yield takeEvery(actions.POST_WHOS_HERE_MEETING_STATUS, postWhosHereMeetingStatus);
  yield takeEvery(actions.POST_WHOS_HERE_CONVERSATION_LEAVE, postWhosHereConversationLeave);

  yield takeEvery(actions.POST_PUSH_NOTIF_SUBSCRIPTION, postPushNotifSubscription);

  yield takeEvery(actions.DELETE_USER, deleteUser);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
