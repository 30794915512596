import FlexItem from '../shared/flex/FlexItem';
import FlexParent from '../shared/flex/FlexParent';
import Icon from '../icons/Icon';
import React from 'react';
import styled from 'styled-components';
import { getServiceIconByType } from '../icons/ServiceIcons';
import { typesToRoutes } from '../../constants/app';
import { motion, AnimatePresence } from 'framer-motion';

const Ul = styled(motion.ul)`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const Li = styled.li`
  list-style-type: none;

  cursor: pointer;
  text-transform: uppercase;
  & > div,
  & > a {
    padding: 15px 15px 15px;
  }
`;

const LiSubItem = styled.li`
  list-style-type: none;
  padding: 15px 15px 15px 30px;
  cursor: pointer;
  background-color: #f5f5f5;
`;

const Item = styled.div`
  color: black;
  font-size: 1.3rem;
  font-weight: ${props => (props.active ? 'bold' : 'initial')};
`;

const ItemLink = styled.a`
  color: black;
  font-size: 1.3rem;
  text-decoration: none;
  display: block;
`;

class NavItem extends React.Component {
  getItemUrl = (type, id) => {
    const { appId, eventId } = this.props.matchParams;
    if (type === 'eventgroup') {
      return `/apps/${appId}`;
    }

    if (type === 'signin') {
      return `/apps/${appId}/events/${eventId}/signin`;
    }

    if (type === 'home') {
      return `/apps/${appId}/events/${eventId}/home`;
    }

    if (type === 'star') {
      return `/apps/${appId}/events/${eventId}/favorites`;
    }

    return `/apps/${appId}/events/${eventId}/${typesToRoutes[type]}/${id ? id : ''}`;
  };

  isActive = (type, itemUrl, test) => {
    return window.location.hash.replace('#', '').includes(itemUrl) && type !== 'eventgroup';
  };

  render() {
    const { item, onClickItem, setItemVisible } = this.props;
    const icon = getServiceIconByType(item.icon || item.type);
    return (
      <React.Fragment>
        <Li
          onClick={event => {
            if ((!item.items || (item.items && item.items.length === 1)) && item.type !== 'link') {
              onClickItem(this.getItemUrl(item.type, item.items ? item.items[0].id : null));
            } else if (item.items && item.items.length > 1) {
              setItemVisible(this.props.itemVisible === this.props.id ? null : this.props.id);
            }
          }}
        >
          {item.type === 'link' ? (
            <ItemLink href={item.url} target="_blank">
              <FlexParent justifyContent={'center'} alignItems={'center'}>
                <Icon
                  icon={icon}
                  color={this.props.theme.mainColor}
                  width={'20px'}
                  height={'20px'}
                  margin={'0 10px 0 0'}
                />
                <FlexItem grow={1}>{item.name}</FlexItem>
              </FlexParent>
            </ItemLink>
          ) : (
            <React.Fragment>
              <FlexParent justifyContent={'center'} alignItems={'center'}>
                <Icon
                  icon={icon}
                  color={this.props.theme.mainColor}
                  width={'20px'}
                  height={'20px'}
                  margin={'0 10px 0 0'}
                />
                <FlexItem grow={1}>
                  <Item
                    active={this.isActive(
                      item.type,
                      this.getItemUrl(
                        item.type,
                        item.items && item.items.length === 1 ? item.items[0].id : ''
                      )
                    )}
                  >
                    {item.name ||
                      (item.items && item.items.length === 1 ? item.items[0].name : null) ||
                      this.props.t(`menu_${item.type}`)}
                  </Item>
                </FlexItem>
              </FlexParent>
            </React.Fragment>
          )}
        </Li>

        {item.items && item.items.length > 1 && (
          <AnimatePresence>
            {(this.isActive(item.type, this.getItemUrl(item.type, null)) ||
              this.props.itemVisible === this.props.id) && (
              <Ul
                key={`nav_item_${this.props.id}`}
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
              >
                {item.items.map((subItem, index) => {
                  return (
                    <LiSubItem
                      active={this.isActive(item.type, this.getItemUrl(item.type, subItem.id))}
                      key={index}
                      onClick={event => {
                        onClickItem(this.getItemUrl(item.type, subItem.id));
                      }}
                    >
                      <FlexParent justifyContent={'center'} alignItems={'center'}>
                        <FlexItem grow={1}>
                          <Item
                            active={this.isActive(
                              item.type,
                              this.getItemUrl(item.type, subItem.id)
                            )}
                          >
                            {subItem.name}
                          </Item>
                        </FlexItem>

                        {/* <Notif>4</Notif> */}
                      </FlexParent>
                    </LiSubItem>
                  );
                })}
              </Ul>
            )}
          </AnimatePresence>
        )}
      </React.Fragment>
    );
  }
}

export default NavItem;
