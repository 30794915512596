import * as action from '../constants/actionLocal';

export const updateGlobal = payload => ({
  payload,
  type: action.UPDATE_GLOBAL
});

export const updateHeader = payload => ({
  payload,
  type: action.UPDATE_HEADER
});

export const updateNav = payload => ({
  payload,
  type: action.UPDATE_NAV
});

export const updateTheme = payload => ({
  payload,
  type: action.UPDATE_THEME
});

export const updateErrors = payload => ({
  payload,
  type: action.UPDATE_ERRORS
});

export const updateSuccess = payload => ({
  payload,
  type: action.UPDATE_SUCCESS
});

export const resetNotif = payload => ({
  payload,
  type: action.RESET_NOTIF
});

export const resetEvent = payload => ({
  payload,
  type: action.RESET_EVENT
});

export const updateSmartqaPost = payload => ({
  payload,
  type: action.UPDATE_SMART_QA_POST
});

export const updateFavModel = payload => ({
  payload,
  type: action.UPDATE_FAV_MODEL
});

export const updateSmartqaComment = payload => ({
  payload,
  type: action.UPDATE_SMART_QA_COMMENT
});

export const userLogout = payload => ({
  payload,
  type: action.USER_LOGOUT
});

export const updateSurveyAnswers = payload => ({
  payload,
  type: action.UPDATE_SURVEY_ANSWERS
});

export const updateVoteAnswers = payload => ({
  payload,
  type: action.UPDATE_VOTE_ANSWERS
});

export const resetEvents = payload => ({
  payload,
  type: action.RESET_EVENTS
});

export const resetNotification = payload => ({
  payload,
  type: action.RESET_NOTIFICATION
});
