import React from 'react';
import styled from 'styled-components';
import FlexItem from './flex/FlexItem';
import FlexParent from './flex/FlexParent';
import Hr from './Hr';
import Scrollable from './Scrollable';
import { getFullName } from '../../helpers/format';
import Icon from '../../components/icons/Icon';
import IconDocumentAcrobat from '../../components/icons/IconDocumentAcrobat';
import IconDocumentImage from '../../components/icons/IconDocumentImage';
import IconDocumentText from '../../components/icons/IconDocumentText';
import IconDocumentVideo from '../../components/icons/IconDocumentVideo';

const ItemHeading = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 0 15px;
  text-align: center;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
  object-fit: cover;
  border-radius: ${props => (props.type === 'speakers' ? '50%' : 'initial')};
`;

const ItemName = styled.div`
  text-align: center;
  font-size: 1.2rem;
  padding: 5px 10px;
`;

const Filer = styled(FlexItem)`
  border-bottom: 3px solid #fff;
  padding: 15px 0 0;
`;

const ItemLink = styled.a`
  color: #000;
  text-decoration: none;
`;

const getTypeIcon = type => {
  if (type && type.includes('image')) {
    return IconDocumentImage;
  } else if (type && type.includes('video')) {
    return IconDocumentVideo;
  } else if (type && type.includes('pdf')) {
    return IconDocumentAcrobat;
  } else {
    return IconDocumentText;
  }
};

const LinkedItems = ({ heading, items, appId, eventId, history, type, theme }) => {
  return items.length > 0 ? (
    <React.Fragment>
      <Hr />
      <ItemHeading>{heading}</ItemHeading>
      <Items {...{ items, appId, eventId, history, type, theme }} />
    </React.Fragment>
  ) : null;
};

const Items = ({ items, appId, eventId, history, type, theme }) => {
  return (
    <Scrollable>
      <FlexParent>
        <Filer grow={1}>&nbsp;</Filer>
        {items.map(item => {
          let { avatar_url, logo_url } = item;
          let name = '';
          logo_url = avatar_url || logo_url;
          if (type === 'sponsors' || type === 'exhibitors') {
            name = item.company;
          } else if (type === 'speakers') {
            name = getFullName(item.first_name, item.last_name);
          } else if (type === 'bonuses') {
            name = item.name;
          }

          let url = '';
          if (['speakers', 'sponsors', 'exhibitors'].indexOf(type) !== -1) {
            const listType =
              type === 'speakers'
                ? 'speaker_lists'
                : type === 'sponsors'
                ? 'sponsor_lists'
                : type === 'exhibitors'
                ? 'exhibitor_lists'
                : '';

            url = `/apps/${appId}/events/${eventId}/${listType}/${item.list_id}/${item.id}`;
          } else if (type === 'bonuses') {
            url =
              item.type && item.type.includes('pdf') && window.cordova
                ? `https://docs.google.com/viewer?url=${item.url}&embedded=true`
                : item.url;
          }

          return (
            <React.Fragment key={`${item.type}-${item.id}`}>
              {type !== 'bonuses' ? (
                <FlexItem
                  width={window.matchMedia('(min-width: 480px)').matches ? '22%' : '40%'}
                  onClick={event => {
                    history.push(url);
                  }}
                >
                  {logo_url && <Logo src={logo_url} alt={''} type={type} />}
                  <ItemName>{name}</ItemName>
                </FlexItem>
              ) : (
                <FlexItem width={window.matchMedia('(min-width: 480px)').matches ? '22%' : '40%'}>
                  <ItemLink href={url} download target="_blank">
                    <Icon
                      icon={getTypeIcon(item.type)}
                      color={'#fff'}
                      padding={'12px'}
                      width={'60px'}
                      height={'60px'}
                      margin={'0 auto'}
                      borderRadius={'50%'}
                      backgroundColor={theme.mainColor}
                    />
                    <ItemName>{name}</ItemName>
                  </ItemLink>
                </FlexItem>
              )}
            </React.Fragment>
          );
        })}
        <Filer grow={1}>&nbsp;</Filer>
      </FlexParent>
    </Scrollable>
  );
};

export default LinkedItems;
