import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import React from 'react';
import styled from 'styled-components';
import { getTimeFromNow } from '../../helpers/date';
import { PostAuthorName, PostHour } from '../../components/shared/smartqa/Styled';

export const VideoThumbnail = styled.div`
  position: relative;
  height: 75px;
  width: 100px;
  margin: 0 10px 0 0;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

class VideoItem extends React.Component {
  onClickVideo = event => {
    this.props.setCurrentVideo(this.props.video);
    window.scrollTo(0, 0);
  };

  render() {
    const { video } = this.props;
    const { publishedAt, title, thumbnails } = video.snippet;
    return (
      <Card margin={'15px 0'} padding={'15px'} onClick={this.onClickVideo}>
        <FlexParent alignItems={'flex-start'}>
          <FlexItem>
            <VideoThumbnail>
              <img src={thumbnails.default.url} alt="" />
            </VideoThumbnail>
          </FlexItem>
          <FlexItem grow={1}>
            <PostAuthorName>{title}</PostAuthorName>
            <PostHour>{getTimeFromNow(publishedAt)}</PostHour>
          </FlexItem>
        </FlexParent>
      </Card>
    );
  }
}

export default VideoItem;
