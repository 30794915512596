import { getJobWithCompany } from '../../../helpers/format';
import CardIcon from '../../../components/shared/card/CardIcon';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/shared/Button';
import InfoLine from '../../../components/shared/InfoLine';
import SocialLinks from '../../../components/shared/SocialLinks';
import IconMail from '../../../components/icons/IconMail';
import IconPhone from '../../../components/icons/IconPhone';
import IconPoi from '../../../components/icons/IconPoi';
import IconWebsite from '../../../components/icons/IconWebsite';
import Disclaimer from '../../../components/shared/Disclaimer';

const UserContainer = styled.div`
  cursor: pointer;
  text-align: center;
`;

const UserName = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
`;

const UserJob = styled.div`
  padding: 5px 0;
  font-size: 1.4rem;
  color: ${props => props.theme.ashesGrey};
`;

class UserProfile extends React.Component {
  onClickLogout = event => {
    this.props.logout();
  };

  onClickEditProfile = event => {
    const { history, matchParams } = this.props;
    history.push(
      `/apps/${matchParams.appId}${
        matchParams.eventId ? `/events/${matchParams.eventId}` : ''
      }/profile-edit`
    );
  };

  render() {
    const { t, theme, matchParams, user } = this.props;
    return (
      <React.Fragment>
        <CardIcon
          padding={'50px 20px 20px 20px'}
          logoBorderRadius={'50%'}
          image={user.avatar_url}
          theme={theme}
          withShadow={true}
        >
          <UserContainer>
            <UserName>
              {user.first_name} {user.last_name}
            </UserName>
            <UserJob>{getJobWithCompany(user.job_title, user.company)}</UserJob>

            <SocialLinks
              linkedinUrl={user.linkedin_url}
              facebookUrl={user.facebook_url}
              twitterUrl={user.twitter_url}
            />

            <InfoLine icon={IconPoi} label={user.country_name} iconColor={theme.ashesGrey} />
            <InfoLine icon={IconMail} label={user.email} iconColor={theme.ashesGrey} type="email" />
            <InfoLine
              icon={IconPhone}
              label={user.phone_number}
              iconColor={theme.ashesGrey}
              type="phone"
            />
            <InfoLine
              icon={IconWebsite}
              label={user.website_url}
              iconColor={theme.ashesGrey}
              type="link"
            />

            {matchParams.eventId && (
              <Button
                onClick={this.onClickEditProfile}
                type={'button'}
                btntype={'linkMain'}
                margin={'15px auto 0'}
                padding={'10px 0'}
                width={'100%'}
              >
                {t('btn_edit_profile')}
              </Button>
            )}

            <Button
              onClick={this.onClickLogout}
              type={'button'}
              btntype={'linkGrey'}
              margin={'0 auto'}
              padding={'10px 0 0'}
              width={'100%'}
            >
              {t('btn_logout')}
            </Button>
          </UserContainer>
        </CardIcon>
        <Disclaimer label={t('read_cgv')} {...{ t, matchParams }} />
      </React.Fragment>
    );
  }
}

export default UserProfile;
