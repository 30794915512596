import { createSelector } from 'reselect';

const getNotif = state => state.notifs;
const getUser = state => state.user;

export const selectNotif = createSelector(
  [getNotif, getUser],
  (notif, user) => {
    if (notif.for_animator && !user.is_animator) {
      return {};
    }

    let validUrlPart = null;
    if (notif.service_ids) {
      validUrlPart = notif.service_ids.map(id => {
        return `/instaphoto/${id}|/smartqa/${id}`;
      });
      const reg = new RegExp(validUrlPart.join('|'), 'g');
      const found = window.location.hash.match(reg);
      if (!found) {
        return {};
      }
    }
    return notif;
  }
);
