import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import React from 'react';
import { nl2br } from '../../helpers/format';
import { getTimeFromNow, parseTweetDate } from '../../helpers/date';
import IconAvatar from '../../components/icons/IconAvatar';
import HtmlContent from '../../components/shared/HtmlContent';

import {
  PostAvatar,
  PostAuthorName,
  PostHour,
  PostMessage
} from '../../components/shared/smartqa/Styled';

const Avatar = props => {
  return props.profile_picture_url ? (
    <PostAvatar src={props.profile_picture_url.replace('_normal', '_200x200')} />
  ) : (
    <Icon
      icon={IconAvatar}
      color={'#fff'}
      padding={'10px'}
      margin={'0 10px 0 0'}
      width={'50px'}
      height={'50px'}
      borderRadius={'50%'}
      backgroundColor={props.theme.dustGrey}
    />
  );
};

class TweetItem extends React.Component {
  render() {
    const { theme, tweet } = this.props;
    const { created_at, user, text } = tweet;
    return (
      <Card margin={'15px 0'} padding={'15px'}>
        <FlexParent alignItems={'center'}>
          <FlexItem>
            <Avatar profile_picture_url={user.profile_image_url_https} theme={theme} />
          </FlexItem>
          <FlexItem grow={1}>
            <PostAuthorName>{user.name}</PostAuthorName>
            <PostHour>{getTimeFromNow(parseTweetDate(created_at))}</PostHour>
          </FlexItem>
        </FlexParent>

        {text && (
          <PostMessage>
            <HtmlContent>{nl2br(text)}</HtmlContent>
          </PostMessage>
        )}
      </Card>
    );
  }
}

export default TweetItem;
