export const typesToRoutes = {
  agenda: 'agendas',
  bonuslist: 'bonus_lists',
  exhibitorlist: 'exhibitor_lists',
  map: 'maps',
  practicalinfo: 'practical_infos',
  smartqa: 'smartqa',
  instaphoto: 'instaphoto',
  socialmedia: 'social_medias',
  speakerlist: 'speaker_lists',
  sponsorlist: 'sponsor_lists',
  survey: 'surveys',
  timeline: 'timelines',
  vote: 'votes',
  whoshere: 'whos_here'
};

export const SEARCH_DELAY = 1000;

export const SMILEY_ANGRY = '😠';
export const SMILEY_HAHA = '😆';
export const SMILEY_LIKE = '👍';
export const SMILEY_LOVE = '❤️';
export const SMILEY_WOW = '😮';

export const REACTION_ANGRY = 'angry';
export const REACTION_HAHA = 'haha';
export const REACTION_LIKE = 'like';
export const REACTION_LOVE = 'love';
export const REACTION_WOW = 'wow';

export const TAB_PAST = 'past';
export const TAB_CURRENT_WEEK = 'current_week';
export const TAB_UPCOMING = 'upcoming';

export const TAB_TWITTER = 'tab_twitter';
export const TAB_YOUTUBE = 'tab_youtube';

export const TAB_ATTENDEES = 'tab_attendees';
export const TAB_CONVERSATIONS = 'tab_conversations';
export const TAB_MEETINGS = 'tab_meetings';

export const TAB_FAV_SESSIONS = 'agenda_session';
export const TAB_FAV_SPEAKERS = 'speaker';
export const TAB_FAV_SPONSORS = 'sponsor';
export const TAB_FAV_EXHIBITORS = 'exhibitor';
export const TAB_FAV_ATTENDEES = 'attendee';
export const TAB_FAV_BONUSES = 'bonus';

export const ATTENDEE_DETAILS = 'attendee_details';

export const MEETING_CREATE = 'meeting_create';
export const MEETING_EDIT = 'meeting_edit';
export const MEETING_DETAILS = 'meeting_details';
export const MEETING_CREATE_ATTENDEES = 'meeting_create_attendees';
export const MEETING_EDIT_ATTENDEES = 'meeting_edit_attendees';

export const CONVERSATION_CREATE = 'conversation_create';
export const CONVERSATION_EDIT = 'conversation_edit';
export const CONVERSATION_DETAILS = 'conversation_details';
export const CONVERSATION_CREATE_ATTENDEES = 'conversation_create_attendees';
export const CONVERSATION_EDIT_ATTENDEES = 'conversation_edit_attendees';
