import { getJobWithCompany } from '../../helpers/format';
import FlexItem from '../shared/flex/FlexItem';
import FlexParent from '../shared/flex/FlexParent';
import Icon from '../icons/Icon';
//import IconNotifications from '../icons/IconNotifications';
import IconQRcode from '../icons/IconQRcode';
import IconAnimator from '../icons/IconAnimator';
import NavItem from './NavItem';
import React from 'react';
import styled from 'styled-components';
import Select from '../shared/form/Select';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled(motion.nav)`
  background-color: #fff;
  height: 100vh;
  left: -50px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0 15px 50px;
  position: fixed;
  top: 0;
  width: 300px;
  z-index: 35;
  box-shadow: 2px 2px 10px 0 ${props => props.theme.shadowGrey};
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
`;
const UserAvatar = styled.img`
  display: block;
  margin: 15px auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const UserContainer = styled.div`
  cursor: pointer;
  text-align: center;
  margin: 10px;
  position: relative;
`;

const UserName = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
`;
const UserJob = styled.div`
  font-size: 1.4rem;
  color: ${props => props.theme.ashesGrey};
`;

const ListItemShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 5;
  margin: 0 auto;
  width: 80px;
  height: 80px;
`;

class Nav extends React.Component {
  onChangeLocale = event => {
    const newLocale = event.currentTarget.value;
    localStorage.setItem(`locale_event_${this.props.event.id}`, newLocale);
    window.location.reload();
  };

  render() {
    const {
      items,
      user,
      theme,
      t,
      event,
      matchParams,
      onClickItem,
      itemVisible,
      setItemVisible,
      //onClickNotification,
      onClickQrCode,
      //onClickSettings,
      onClickAnimator,
      nav
    } = this.props;

    const itemSignin = {
      type: 'signin',
      icon: null,
      name: null,
      items: [{}]
    };

    return (
      <AnimatePresence>
        {nav.isOpened && (
          <Container
            key="nav"
            transition={{ type: 'spring', duration: 0.2, mass: 0.7, stiffness: 80 }}
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            exit={{ x: -300 }}
          >
            {user ? (
              <FlexParent alignItems={'center'}>
                {/* <Icon
              icon={IconNotifications}
              color={this.props.theme.mainColor}
              width={'40px'}
              height={'40px'}
              margin={'0 0 0 0'}
              padding={'10px'}
              onClick={onClickNotification}
            /> */}

                {user.barcode && (
                  <FlexItem grow={1}>
                    <Icon
                      icon={IconQRcode}
                      color={this.props.theme.mainColor}
                      width={'40px'}
                      height={'40px'}
                      margin={'0 0 0 5px'}
                      padding={'10px'}
                      onClick={onClickQrCode}
                    />
                  </FlexItem>
                )}

                {user && user.is_animator && (
                  <FlexItem>
                    <Icon
                      icon={IconAnimator}
                      color={this.props.theme.mainColor}
                      width={'40px'}
                      height={'40px'}
                      margin={'0 0 0 5px'}
                      padding={'10px'}
                      onClick={onClickAnimator}
                    />
                  </FlexItem>
                )}

                {/* <Icon
              icon={IconSettings}
              color={this.props.theme.mainColor}
              width={'40px'}
              height={'40px'}
              margin={'0 0 0 auto'}
              padding={'10px'}
              onClick={onClickSettings}
            /> */}
              </FlexParent>
            ) : null}

            {user && (
              <UserContainer
                onClick={event => {
                  onClickItem(
                    `/apps/${matchParams.appId}${
                      matchParams.eventId ? `/events/${matchParams.eventId}` : ''
                    }/profile`
                  );
                }}
              >
                <UserAvatar src={user.avatar_url} />
                <ListItemShadow></ListItemShadow>
                <UserName>
                  {user.first_name} {user.last_name}
                </UserName>
                <UserJob>{getJobWithCompany(user.job_title, user.company)}</UserJob>
              </UserContainer>
            )}

            <Ul>
              {!user && (
                <NavItem
                  id={items.length + 1}
                  key={items.length + 1}
                  item={itemSignin}
                  {...{ theme, t, onClickItem, matchParams }}
                >
                  {t('signin')}
                </NavItem>
              )}

              {Object.keys(items).map((key, index) => {
                const item = items[key];
                return (
                  <NavItem
                    id={index}
                    key={index}
                    {...{ theme, t, onClickItem, item, matchParams, itemVisible, setItemVisible }}
                  />
                );
              })}
            </Ul>

            {event && event.available_locales.length > 1 ? (
              <Select
                onChange={this.onChangeLocale}
                value={this.props.global.locale}
                margin={'5px 10px'}
              >
                {event.available_locales.map(locale => {
                  return (
                    <option key={locale} value={locale}>
                      {t(`lang_${locale}`)}
                    </option>
                  );
                })}
              </Select>
            ) : null}
          </Container>
        )}
      </AnimatePresence>
    );
  }
}

export default Nav;
