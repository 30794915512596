import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M21.6,23.2V8.9h-5.8c-0.4,0-0.7-0.1-0.9-0.4c-0.2-0.2-0.4-0.6-0.4-0.9V1.8H3.8v21.4L21.6,23.2L21.6,23.2z M16.3,1.9v5.2h5.2c-0.1-0.2-0.2-0.4-0.3-0.6l-4.4-4.4C16.8,2.1,16.6,2,16.3,1.9z M22.5,5.3c0.2,0.2,0.5,0.6,0.7,1.1 c0.2,0.4,0.3,0.9,0.3,1.2v16.1c0,0.4-0.1,0.7-0.4,0.9c-0.2,0.3-0.6,0.4-0.9,0.4H3.4c-0.4,0-0.7-0.1-0.9-0.4 C2.2,24.4,2,24.1,2,23.7V1.3c0-0.4,0.1-0.7,0.4-0.9S2.9,0,3.3,0h12.5c0.4,0,0.8,0.1,1.2,0.2c0.4,0.2,0.8,0.4,1.1,0.7L22.5,5.3z" />
      <path d="M16.8,10.6H8.6c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h8.2c0.2,0,0.4-0.2,0.4-0.4V11 C17.2,10.8,17,10.6,16.8,10.6z M13.1,19.6H8.6c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h4.4c0.2,0,0.4-0.2,0.4-0.4v-0.8 C13.4,19.8,13.3,19.6,13.1,19.6z M16.8,13.6H8.6c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h8.2c0.2,0,0.4-0.2,0.4-0.4V14 C17.2,13.8,17,13.6,16.8,13.6z M16.8,16.6H8.6c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h8.2c0.2,0,0.4-0.2,0.4-0.4v-0.8 C17.2,16.8,17,16.6,16.8,16.6z" />
    </g>
  </svg>
);
