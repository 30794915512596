import { createSelector } from 'reselect';
import {
  filterObjects,
  filterIds,
  hasMoreCursor,
  isLoading,
  getNotifsCount,
  getNotifs
} from '../helpers/data';
import { API_SMARTQA_QUESTIONS, API_SMARTQA_COMMENTS } from '../constants/apiRoutes';

const currentSmartqaId = (state, props) => props.match.params.smartqaId;
const currentPostId = (state, props) => props.match.params.postId;
const smartqas = (state, props) => state.smartqa;
const posts = (state, props) => state.smartqaPosts;
const comments = (state, props) => state.smartqaComments;
const postsIds = (state, props) => state.sortIds[API_SMARTQA_QUESTIONS] || [];
const postCommentsIds = (state, props) => state.sortIds[API_SMARTQA_COMMENTS] || [];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;
const notifications = (state, props) => state.notifications;

// --- Select current object --------------
export const selectCurrentSmartqa = createSelector(
  [smartqas, currentSmartqaId],
  (smartqas, currentSmartqaId) => {
    return smartqas[currentSmartqaId] || null;
  }
);

export const selectCurrentPost = createSelector([posts, currentPostId], (posts, postId) => {
  return posts[postId] || null;
});
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredPosts = createSelector([currentSmartqaId, posts], (smartqaId, posts) => {
  return filterObjects(posts, post => {
    return post.smart_qa_id === +smartqaId && post.is_archived === false;
  });
});

export const selectFilteredAnimatorPosts = createSelector(
  [currentSmartqaId, posts],
  (smartqaId, posts) => {
    return filterObjects(posts, post => {
      return post.smart_qa_id === +smartqaId && post.is_displayed_to_animators === true;
    });
  }
);

export const selectFilteredComments = createSelector(
  [currentPostId, comments],
  (postId, comments) => {
    return filterObjects(comments, comment => {
      return comment.commentable_id === +postId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredPostsIds = createSelector(
  [postsIds, selectFilteredPosts],
  (postsIds, posts) => {
    return filterIds(postsIds, posts);
  }
);

export const selectFilteredAnimatorPostsIds = createSelector(
  [postsIds, selectFilteredAnimatorPosts],
  (postsIds, posts) => {
    return filterIds(postsIds, posts);
  }
);

export const selectFilteredCommentsIds = createSelector(
  [postCommentsIds, selectFilteredComments],
  (postCommentsIds, comments) => {
    return filterIds(postCommentsIds, comments);
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMorePosts = createSelector(
  [selectFilteredPostsIds, currentSmartqaId, pagination],
  (postsIds, smartqaId, pagination) => {
    return hasMoreCursor(pagination, smartqaId, API_SMARTQA_QUESTIONS, postsIds.length);
  }
);

export const selectHasMoreComments = createSelector(
  [selectFilteredCommentsIds, currentPostId, pagination],
  (commentsIds, postId, pagination) => {
    return hasMoreCursor(pagination, postId, API_SMARTQA_COMMENTS, commentsIds.length);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingPosts = createSelector([loading], loading => {
  return isLoading(loading, API_SMARTQA_QUESTIONS);
});

export const selectIsLoadingComments = createSelector([loading], loading => {
  return isLoading(loading, API_SMARTQA_COMMENTS);
});
// ----------------------------------------

// --- Notifications ----------------------
export const selectNotifsPostsCount = createSelector(
  [currentSmartqaId, notifications],
  (smartqaId, notifications) => {
    let notifs = getNotifs(notifications, smartqaId, API_SMARTQA_QUESTIONS);
    return notifs.length;
  }
);

export const selectNotifsCommentsCount = createSelector(
  [currentPostId, notifications],
  (postId, notifications) => {
    return getNotifsCount(notifications, postId, API_SMARTQA_COMMENTS);
  }
);
// ----------------------------------------
