import { SUCCESS, FETCH_EXHIBITORS, FETCH_EXHIBITOR } from '../constants/actionApi';
import { UPDATE_FAV_MODEL } from '../constants/actionLocal';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.exhibitors, { type, payload }) => {
  switch (type) {
    case FETCH_EXHIBITORS + SUCCESS:
    case FETCH_EXHIBITOR + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    case UPDATE_FAV_MODEL:
      if (payload.type === 'exhibitor') {
        return addOrUpdateItemsInState(state, payload.data);
      } else {
        return state;
      }
    default:
      return state;
  }
};
