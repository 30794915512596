import React from 'react';
import { connect } from 'react-redux';
import UserQrCode from './UserQrCode';
import { updateHeader } from '../../../actions/local';

import { selectUser } from '../../../selectors/user';
import Mixpanel from '../../../Mixpanel';
class UserQrCodeContainer extends React.Component {
  componentDidMount() {
    const { appId, eventId } = this.props.match.params;
    this.props.updateHeader({
      leftBtn: window.history.length > 1 ? 'back' : 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    document.addEventListener('deviceready', this.onDeviceReady, false);

    Mixpanel.track('view', {
      viewableType: 'user-qrcode',
      viewableId: null,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentWillUnmount() {
    if (
      window.cordova &&
      window.cordova.plugins &&
      window.cordova.plugins.brightness &&
      this.originalBrightness
    ) {
      let brightness = window.cordova.plugins.brightness;
      brightness.setBrightness(this.originalBrightness);
      brightness.setKeepScreenOn(false);
    }
    document.removeEventListener('deviceready', this.onDeviceReady);
  }

  onDeviceReady = () => {
    if (window.cordova && window.cordova.plugins && window.cordova.plugins.brightness) {
      let brightness = window.cordova.plugins.brightness;
      brightness.getBrightness(originalBrightness => {
        this.originalBrightness = originalBrightness;
      });

      // value should be float in range from 0 to 1.
      brightness.setBrightness(1);
      brightness.setKeepScreenOn(true);
    }
  };

  render() {
    if (!this.props.user) {
      return null;
    }
    const { t, theme, history, user } = this.props;
    const matchParams = this.props.match.params;
    return <UserQrCode {...{ t, theme, history, matchParams, user }} />;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserQrCodeContainer);
