import { getJobWithCompany } from '../../../helpers/format';
import CardIcon from '../../../components/shared/card/CardIcon';
import Modal from '../../../components/modal/Modal';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/shared/Button';
import FormGroup from '../../../components/shared/form/FormGroup';
import Label from '../../../components/shared/form/Label';
import Input from '../../../components/shared/form/Input';
import Select from '../../../components/shared/form/Select';
import IconPencil from '../../../components/icons/IconPencil';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import InputError from '../../../components/shared/form/InputError';
import Loader from '../../../components/shared/Loader';
import Switch from '../../../components/shared/form/Switch';

const UserContainer = styled.div`
  cursor: pointer;
  text-align: center;
`;

const UserName = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
`;

const UserJob = styled.div`
  padding: 5px 0;
  font-size: 1.4rem;
  color: ${props => props.theme.ashesGrey};
`;

const FileInput = styled.input`
  display: none;
`;
const DeleteAccountContainer = styled.div`
  text-align: right;
`;

const DeleteAccount = styled.a`
  color: ${props => props.theme.ashesGrey};
  cursor: pointer;
`;

const ConfirmMessage = styled.div`
  margin: 0 0 10px;
  font-weight: bold;
`;

class UserProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.phoneInputRef = React.createRef();
    const user = this.props.user;
    this.state = {
      uploading: false,
      email: user.email || '',
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      phone_number: user.phone_number || '',
      company: user.company || '',
      job_title: user.job_title || '',
      biography: user.biography || '',
      locale: user.locale || '',
      location: user.location || '',
      website_url: user.website_url || '',
      facebook_url: user.facebook_url || '',
      linkedin_url: user.linkedin_url || '',
      twitter_url: user.twitter_url || '',
      country_code: user.country_code || '',
      avatar_url: user.avatar_url || '',
      email_notifications: user.email_notifications || false,
      push_notifications: user.push_notifications || false,
      is_shareable: user.is_shareable || false,
      modal_open: false
    };
  }

  componentDidMount() {
      this.setState({ show_account_deletion: window.cordova });
  }

  onChangeInput = event => {
    const data = {};
    let value = event.target.value;
    data[event.target.name] = value;
    this.setState(data);
  };

  onChangeSwitch = event => {
    const isChecked = event.currentTarget.checked;
    let value = {};
    value[event.currentTarget.name] = isChecked;
    this.setState(value);
  };

  onChangePhone = phone => {
    this.setState({
      phone_number: phone
    });
  };

  onChangeFile = event => {
    const file = event.target.files[0];
    this.setState({
      uploading: true
    });
    this.props.uploadUserAvatarFile({
      file,
      always: () => {
        this.setState({
          uploading: false
        });
      }
    });
    event.target.value = null;
  };

  onSubmitForm = event => {
    event.preventDefault();
    this.props.saveUserProfile({
      ...this.props.user,
      ...{
        first_name: this.state.first_name || null,
        last_name: this.state.last_name || null,
        phone_number: this.state.phone_number || null,
        company: this.state.company || null,
        job_title: this.state.job_title || null,
        biography: this.state.biography || null,
        locale: this.state.locale && this.state.locale !== '0' ? this.state.locale : null,
        location: this.state.location || null,
        website_url: this.state.website_url || null,
        facebook_url: this.state.facebook_url || null,
        linkedin_url: this.state.linkedin_url || null,
        twitter_url: this.state.twitter_url || null,
        country_code: this.state.country_code || null,
        country_name: null,
        avatar_url: this.props.userAvatarFileUrl || this.props.user.avatar_url,
        email_notifications: this.state.email_notifications,
        push_notifications: this.state.push_notifications,
        is_shareable: this.state.is_shareable
      }
    });
  };

  onClickUserAvatar = event => {
    this.fileInputRef.current.click();
  };

  onDeleteAccount = e => {
    this.props.deleteUserAccount();
  }

  toggleModal = () => {
    this.setState({modal_open: !this.state.modal_open});
  }

  renderFormGroup(t, type, key, isRequired) {
    return (
      <FormGroup>
        {type === 'text' ? (
          <React.Fragment>
            <Label htmlFor={key}>
              {t(`user_${key}`)}
              {isRequired ? '*' : ''}
            </Label>
            <Input
              id={key}
              name={key}
              type={type}
              placeholder={t(`user_${key}`)}
              value={this.state[key]}
              onChange={this.onChangeInput}
            />
          </React.Fragment>
        ) : type === 'switch' ? (
          <Switch name={key} checked={this.state[key]} onChange={this.onChangeSwitch}>
            <div>{t(`user_${key}`)}</div>
          </Switch>
        ) : (
          ''
        )}

        {this.props.inputErrors[key] ? (
          <InputError>{this.props.inputErrors[key]}</InputError>
        ) : null}
      </FormGroup>
    );
  }

  render() {
    const {
      t,
      theme,
      history,
      matchParams,
      user,
      countries,
      countriesIds,
      isSaving,
      offline
    } = this.props;
    const langs = ['ar', 'de', 'en', 'es', 'fr', 'it', 'ja', 'pt', 'ru', 'sv', 'zh', 'lb'];
    return (
      <CardIcon
        padding={'50px 20px 20px 20px'}
        theme={theme}
        image={this.props.userAvatarFileUrl || user.avatar_url}
        subIcon={IconPencil}
        subIconAction={this.onClickUserAvatar}
        logoBorderRadius={'50%'}
        withShadow={true}
      >
        <UserContainer>
          {this.state.uploading ? <Loader /> : null}
          <UserName>
            {user.first_name} {user.last_name}
          </UserName>
          <UserJob>{getJobWithCompany(user.job_title, user.company)}</UserJob>
        </UserContainer>

        <form onSubmit={this.onSubmitForm}>
          <FileInput
            accept={'image/*'}
            ref={this.fileInputRef}
            type="file"
            onChange={this.onChangeFile}
          />

          <input
            type="hidden"
            name="avatar_url"
            value={this.props.userAvatarFileUrl || user.avatar_url}
          />
          {this.renderFormGroup(t, 'text', 'first_name', true)}
          {this.renderFormGroup(t, 'text', 'last_name', true)}
          {this.renderFormGroup(t, 'text', 'job_title', false)}
          {this.renderFormGroup(t, 'text', 'company', false)}
          {this.renderFormGroup(t, 'text', 'facebook_url', false)}
          {this.renderFormGroup(t, 'text', 'linkedin_url', false)}
          {this.renderFormGroup(t, 'text', 'twitter_url', false)}
          {this.renderFormGroup(t, 'text', 'website_url', false)}
          {this.renderFormGroup(t, 'text', 'location', false)}

          <FormGroup>
            <Label htmlFor={'phone_number'}>{t(`user_${'phone_number'}`)}</Label>
            <PhoneInput
              placeholder={t(`user_phone_number`)}
              value={this.state.phone_number}
              onChange={this.onChangePhone}
              displayInitialValueAsLocalNumber={true}
              defaultCountry="FR"
              className={'phone-input'}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={'country_code'}>{t(`user_country_name`)}</Label>
            <Select
              id={'country_code'}
              name={'country_code'}
              onChange={this.onChangeInput}
              value={this.state.country_code}
            >
              {countriesIds.map((key, index) => {
                const country = countries[key];
                return (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                );
              })}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor={'locale'}>{t(`user_locale`)}</Label>
            <Select
              id={'locale'}
              name={'locale'}
              value={this.state.locale}
              onChange={this.onChangeInput}
            >
              <option key={'default'} value={'0'}></option>
              {langs.map(lang => {
                return (
                  <option key={lang} value={lang}>
                    {t(`lang_${lang}`)}
                  </option>
                );
              })}
            </Select>
          </FormGroup>

          <Label>{t(`user_notifications`)}</Label>
          {this.renderFormGroup(t, 'switch', 'email_notifications', true)}
          {this.renderFormGroup(t, 'switch', 'push_notifications', true)}

          <Label>{t(`user_privacy`)}</Label>
          {this.renderFormGroup(t, 'switch', 'is_shareable', true)}

          <Button
            type={'submit'}
            btntype={'primary'}
            margin={'3px auto'}
            padding={'12px 0'}
            width={'100%'}
            disabled={
              !this.state.email ||
              !this.state.first_name ||
              !this.state.last_name ||
              this.state.uploading ||
              offline
            }
          >
            {isSaving ? t('btn_saving') : t('btn_save')}
          </Button>
          <Button
            onClick={event => {
              history.push(
                `/apps/${matchParams.appId}${
                  matchParams.eventId ? `/events/${matchParams.eventId}` : ''
                }/profile`
              );
            }}
            type={'button'}
            btntype={'linkGrey'}
            margin={'3px auto'}
            padding={'12px 0'}
            width={'100%'}
          >
            Cancel
          </Button>
        </form>
        {this.state.show_account_deletion ? (
          <DeleteAccountContainer>
            <DeleteAccount onClick={this.toggleModal}>
              {t('btn_delete_account')}
            </DeleteAccount>
          </DeleteAccountContainer>
        ) : null
        }
        <Modal open={this.state.modal_open}>
          <ConfirmMessage>{t('confirm_delete_account')}</ConfirmMessage>

          <Button btntype={'secondary'} onClick={this.toggleModal} display="block" width="100%">
            {t('btn_cancel')}
          </Button>

          <Button btntype={'danger'} onClick={this.onDeleteAccount} display="block" width="100%" margin="30px 0 0 0">
            {t('btn_delete_account')}
          </Button>
        </Modal>
      </CardIcon>


    );
  }
}

export default UserProfileEdit;
