import { FAILED, SUCCESS, FETCH_USER, PUT_USER, POST_USER_INCOGNITO, DELETE_USER } from '../constants/actionApi';
import { USER_LOGOUT } from '../constants/actionLocal';
import initialState from '../store/initialState';
import { updateObjectInState, resetObjectInState } from '../helpers/data';
import { SC_ATTENDEE, UPDATED } from '../constants/socket';

export default (state = initialState.user, { type, payload }) => {
  switch (type) {
    case FETCH_USER + SUCCESS:
    case PUT_USER + SUCCESS:
      return updateObjectInState(state, payload.data);
    case FETCH_USER + FAILED:
    case USER_LOGOUT:
    case DELETE_USER + SUCCESS:
      return resetObjectInState(state, initialState.user);
    case SC_ATTENDEE + UPDATED:
      if (payload.user.id === payload.data.id) {
        return updateObjectInState(state, payload.data);
      } else {
        return state;
      }
    case POST_USER_INCOGNITO: // optimistic update
      return updateObjectInState(state, { is_incognito: !state.is_incognito });
    default:
      return state;
  }
};
