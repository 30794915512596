import { createSelector } from 'reselect';

const getHeader = (state, props) => state.header;

export const selectHeader = createSelector(
  [getHeader],
  header => {
    return header;
  }
);
