import React from 'react';
import styled from 'styled-components';
import { getJobWithCompany } from '../../../helpers/format';
import CardIcon from '../../../components/shared/card/CardIcon';
import InfoLine from '../../../components/shared/InfoLine';
import SocialLinks from '../../../components/shared/SocialLinks';
import IconPoi from '../../../components/icons/IconPoi';
import IconWebsite from '../../../components/icons/IconWebsite';
import { DownloadVCard, FavItem } from '../../../components/shared/ListItemDetails';
import Star from '../../../components/shared/fav/Star';

const UserContainer = styled.div`
  cursor: pointer;
  text-align: center;
`;

const UserName = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
`;

const UserJob = styled.div`
  padding: 5px 0;
  font-size: 1.4rem;
  color: ${props => props.theme.ashesGrey};
`;

class AttendeeDetails extends React.Component {
  state = {
    hasContactPermission: false
  };

  componentDidMount() {
    if (this.props.attendeeId) {
      this.props.fetchAttendee({ attendee_id: this.props.attendeeId });
    }

    if (window.cordova && window.cordova.plugins && navigator.contacts) {
      const permissions = window.cordova.plugins.permissions;
      this.checkPermission(permissions.READ_CONTACTS, () => {
        this.checkPermission(permissions.WRITE_CONTACTS, () => {
          this.setState({ hasContactPermission: true });
        });
      });
    }
  }

  checkPermission = (permission, cb) => {
    const permissions = window.cordova.plugins.permissions;
    permissions.checkPermission(permission, status => {
      if (status.hasPermission) {
        cb();
      } else {
        permissions.requestPermission(permission, status => {
          if (status.hasPermission) {
            cb();
          }
        });
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.attendeeId !== prevProps.attendeeId) {
      this.props.fetchAttendee({ attendee_id: this.props.attendeeId });
    }
  }

  onClickDownloadVCard = event => {
    const { t, attendee } = this.props;

    if (window.cordova && navigator.contacts) {
      event.stopPropagation();
      event.preventDefault();

      let contact = navigator.contacts.create();
      contact.displayName = attendee.name;
      let name = new window.ContactName();
      name.givenName = attendee.first_name;
      name.familyName = attendee.last_name;
      contact.name = name;

      let emails = [new window.ContactField('work', attendee.email, false)];
      contact.emails = emails;

      if (attendee.phone_number) {
        let phoneNumbers = [new window.ContactField('work', attendee.phone_number, false)];
        contact.phoneNumbers = phoneNumbers;
      }

      let urls = [];
      if (attendee.website_url) {
        urls.push(new window.ContactField('work', attendee.website_url, false));
      }
      if (attendee.linkedin_url) {
        urls.push(new window.ContactField('work', attendee.linkedin_url, false));
      }
      if (urls.length) {
        contact.urls = urls;
      }

      if (attendee.company) {
        let organizations = [
          new window.ContactOrganization(false, 'work', attendee.company, null, attendee.job_title)
        ];
        contact.organizations = organizations;
      }

      contact.save(
        () => {
          alert(t('added_to_contacts'));
        },
        () => { }
      );
    }
  };

  render() {
    const { t, theme, attendee, matchParams, user, whosHere } = this.props;

    if (attendee) {
      console.log(
        attendee.is_shareable &&
        (!window.cordova ||
          (window.cordova && navigator.contacts && this.state.hasContactPermission))
      );
    }

    return (
      <React.Fragment>
        {attendee ? (
          <CardIcon
            padding={'50px 20px 20px 20px'}
            logoBorderRadius={'50%'}
            image={attendee.avatar_url}
            theme={theme}
            withShadow={true}
          >
            {attendee.id !== user.id && whosHere.is_starable && (
              <FavItem>
                <Star
                  {...{ theme }}
                  size={'40px'}
                  model={attendee}
                  type={'attendee'}
                  matchParams={matchParams}
                />
              </FavItem>
            )}

            <UserContainer>
              <UserName>
                {attendee.first_name} {attendee.last_name}
              </UserName>
              <UserJob>{getJobWithCompany(attendee.job_title, attendee.company)}</UserJob>

              <SocialLinks
                linkedinUrl={attendee.linkedin_url}
                facebookUrl={attendee.facebook_url}
                twitterUrl={attendee.twitter_url}
              />

              <InfoLine icon={IconPoi} label={attendee.country_name} iconColor={theme.ashesGrey} />
              <InfoLine
                icon={IconWebsite}
                label={attendee.website_url}
                iconColor={theme.ashesGrey}
                type="link"
              />

              {attendee.is_shareable &&
                (!window.cordova ||
                  (window.cordova && navigator.contacts && this.state.hasContactPermission)) ? (
                  <DownloadVCard
                    href={attendee.vcard_url}
                    onClick={this.onClickDownloadVCard}
                    download
                  >
                    {t('add_to_contact')}
                  </DownloadVCard>
                ) : null}
            </UserContainer>
          </CardIcon>
        ) : null}
      </React.Fragment>
    );
  }
}

export default AttendeeDetails;
