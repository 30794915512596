import { SUCCESS, FETCH_YOUTUBE_VIDEOS } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.socialMediaYoutubeVideos, { type, payload }) => {
  switch (type) {
    case FETCH_YOUTUBE_VIDEOS + SUCCESS:
      return addOrUpdateItemsInState(
        state,
        payload.data.items,
        payload.params.reset,
        initialState.socialMediaYoutubeVideos
      );
    default:
      return state;
  }
};
