import React from 'react';
import Card from '../../../components/shared/card/Card';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import { getJobWithCompany } from '../../../helpers/format';
import { ATTENDEE_DETAILS, TAB_ATTENDEES } from '../../../constants/app';
import {
  ListItemLogo,
  ListItemShadow,
  ListItemTitle,
  ListItemSubTitle
} from '../../../components/shared/ListItem';
import Star from '../../../components/shared/fav/Star';
import styled from 'styled-components';

const Online = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7ed321;
  position: absolute;
  right: 8px;
  bottom: 2px;
  border: 2px solid white;
`;

class AttendeeItem extends React.Component {
  onClickAttendee = () => {
    this.props.updateView({
      tab: TAB_ATTENDEES,
      view: ATTENDEE_DETAILS,
      id: this.props.attendee.id
    });
  };
  render() {
    const { t, theme, attendee, user, matchParams, isOnline, withoutFav, whosHere } = this.props;
    const { avatar_url, name, job_title, company, country_name } = attendee;
    const desc = getJobWithCompany(job_title, company, country_name);

    if (attendee.is_incognito) {
      return null;
    }
    return (
      <Card margin={'0 0 20px'} padding={'10px'} onClick={this.onClickAttendee}>
        <FlexParent justifyContent={'space-between'}>
          <FlexItem position={'relative'}>
            {avatar_url && (
              <React.Fragment>
                <ListItemLogo src={avatar_url} alt={name} />
                <ListItemShadow />
              </React.Fragment>
            )}
            {isOnline && <Online />}
          </FlexItem>

          <FlexItem grow={1}>
            <ListItemTitle>
              {attendee.id === user.id ? t('me') : attendee.name}{' '}
              {this.props.admin ? `(${t('admin')})` : ''}
            </ListItemTitle>
            <ListItemSubTitle>{desc}</ListItemSubTitle>
          </FlexItem>

          <FlexItem alignSelf={'center'}>
            {!withoutFav && attendee.id !== user.id && whosHere.is_starable && (
              <Star {...{ theme }} model={attendee} type={'attendee'} matchParams={matchParams} />
            )}
          </FlexItem>
        </FlexParent>
      </Card>
    );
  }
}

export default AttendeeItem;
