import React from 'react';
import { connect } from 'react-redux';
import UserSignin from './UserSignin';
import { updateHeader, updateGlobal } from '../../../actions/local';
import { postUserEmail, postUserPwd, postForgotPwd } from '../../../actions/api';
import { Redirect } from 'react-router-dom';
import { selectUser } from '../../../selectors/user';
import { selectCurrentApp } from '../../../selectors/app';
import { selectCurrentEvent } from '../../../selectors/events';
import { selectErrors } from '../../../selectors/errors';
import SuccessContainer from '../../../components/alert/SuccessContainer';
import { updateSuccess } from '../../../actions/local';
import Mixpanel from '../../../Mixpanel';
class UserSigninContainer extends React.Component {
  componentDidMount() {
    const { appId, eventId } = this.props.match.params;
    this.props.updateHeader({
      leftBtn: window.history.length > 1 ? 'back' : 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    // SSO
    const { status, token } = this.props.match.params;
    if (status === 'success' && token) {
      this.setAccessToken(token);
    }

    Mixpanel.track('view', {
      viewableType: 'user-signin',
      viewableId: null,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  setAccessToken = token => {
    localStorage.setItem('access_token', token);
    this.props.updateGlobal({
      accessToken: token
    });
  };

  testUserEmail = data => {
    const { appId, eventId } = this.props.match.params;
    this.props.postUserEmail({
      ...data,
      ...{
        app_id: appId,
        event_id: eventId
      }
    });
  };

  testUserPwd = data => {
    const { appId, eventId } = this.props.match.params;
    this.props.postUserPwd({
      ...data,
      ...{
        app_id: appId,
        event_id: eventId,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        scope: '*',
        grant_type: 'password'
      }
    });
  };

  navigateToRegister = () => {
    const { appId, eventId } = this.props.match.params;
    const url = `/apps/${appId}${eventId ? `/events/${eventId}` : ''}/register`;
    this.props.history.replace(url);
  };

  resetPassword = data => {
    const { appId, eventId } = this.props.match.params;
    const callbackUrl = `${process.env.REACT_APP_WEB_APP_URL}#/apps/${appId}${
      eventId ? `/events/${eventId}` : ''
    }/reset-password/:token`;

    this.props.postForgotPwd({
      ...data,
      ...{
        app_id: appId,
        event_id: eventId,
        callback_url: callbackUrl,
        done: () => {
          this.props.updateSuccess({
            message: this.props.t('success_forgot_pwd')
          });
        }
      }
    });
  };

  singleSignOn = event => {
    event.preventDefault();
    let callbackUrl = '';
    if (!window.cordova) {
      callbackUrl = window.location.href;
      callbackUrl = callbackUrl.replace('/error', '');
      window.location.href = `${process.env.REACT_APP_ED_API_URL}sso?event_group_id=${
        this.props.app.id
      }&callback_url=${encodeURIComponent(callbackUrl)}`;
    } else {
      document.addEventListener(
        'deviceready',
        () => {
          callbackUrl = `${process.env.REACT_APP_ED_API_URL}native-app-sso-login-callback`;
          let ref = window.cordova.InAppBrowser.open(
            `${process.env.REACT_APP_ED_API_URL}sso?event_group_id=${
              this.props.app.id
            }&callback_url=${encodeURIComponent(callbackUrl)}`,
            '_blank',
            `useWideViewPort=yes,usewkwebview=yes,location=no,closebuttoncaption=${this.props.t(
              'close'
            )}`
          );

          ref.addEventListener('loadstart', e => {
            if (e.url.indexOf(callbackUrl) !== -1) {
              let re = /\/(success|error)(\/.*)?$/;
              let result = re.exec(e.url);
              ref.close();
              if (result && result[1] === 'success') {
                let accessToken = result[2].slice(1).replace(/#+$/, '');
                this.setAccessToken(accessToken);
              } else {
                let errorMessage;
                if (result && result[2] && result[2].slice(1) === 'unauthorized') {
                  errorMessage = this.props.t('error_sso_unauthorized');
                } else {
                  errorMessage = this.props.t('error_generic');
                }
                this.setState({
                  errorMessage
                });
              }
            }
          });
        },
        false
      );
    }
  };

  redirect = () => {
    const { appId, eventId } = this.props.match.params;
    const state = this.props.location.state;
    let redirectUrl = `/apps/${appId}${eventId ? `/events/${eventId}/home` : ''}`;

    if (state && state.from && state.from.pathname) {
      redirectUrl = state.from.pathname;
    }

    return (
      <Redirect
        to={{
          pathname: redirectUrl,
          state: { from: this.props.location }
        }}
      />
    );
  };

  render() {
    const { t, theme, user, event, app } = this.props;
    if (!this.props.match.params.eventId && !this.props.app) {
      return null;
    }

    return !user ? (
      <React.Fragment>
        <SuccessContainer />
        <UserSignin
          t={t}
          testUserEmail={this.testUserEmail}
          testUserPwd={this.testUserPwd}
          navigateToRegister={this.navigateToRegister}
          resetPassword={this.resetPassword}
          app={app}
          event={event}
          theme={theme}
          singleSignOn={this.singleSignOn}
        />
      </React.Fragment>
    ) : (
      this.redirect()
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  updateGlobal: updateGlobal,
  postUserEmail: postUserEmail,
  postUserPwd: postUserPwd,
  postForgotPwd: postForgotPwd,
  updateSuccess: updateSuccess
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    errors: selectErrors(state, ownProps),
    app: selectCurrentApp(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSigninContainer);
