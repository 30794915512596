import React from 'react';
import styled from 'styled-components';
import { isDarkBgColor } from '../../helpers/helpers';
import Icon from '../../components/icons/Icon';
import IconClickExpressYourself from '../../components/icons/IconClickExpressYourself';

const Notif = styled.div`
  background-color: ${props => props.theme.mainColor};
  box-shadow: 0px 4px 8px -4px rgb(0 0 0 / 38%);
  text-align: center;
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 40px;
  color: ${props => {
    return isDarkBgColor(props.theme.mainColor) ? '#fff' : '#000';
  }};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

class Notification extends React.Component {
  onClickNotif = () => {
    this.props.fetchSmartqaPosts({
      reset: true,
      done: () => {
        window.scrollTo(0, 0);
      }
    });
  };

  render() {
    const { t, theme } = this.props;
    return (
      <Notif
        textAlign={'center'}
        color={isDarkBgColor(theme.mainColor) ? 'white' : 'black'}
        backgroundColor={theme.mainColor}
        onClick={this.onClickNotif}
      >
        <Icon
          icon={IconClickExpressYourself}
          width={'15px'}
          color={isDarkBgColor(theme.mainColor) ? 'white' : 'black'}
          backgroundColor={theme.mainColor}
          margin={'0 5px'}
          top={'2px'}
        />
        {t('new_content_available')}
      </Notif>
    );
  }
}

export default Notification;
