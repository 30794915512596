import { createSelector } from 'reselect';
import { filterObjects, filterIds } from '../helpers/data';
import { API_MAP_POIS } from '../constants/apiRoutes';

const currentMapId = (state, props) => props.match.params.mapId;
const maps = (state, props) => state.maps;
const pois = (state, props) => state.mapPois;
const poisIds = (state, props) => state.sortIds[API_MAP_POIS] || [];

// --- Select current object --------------
export const selectCurrentMap = createSelector(
  [maps, currentMapId],
  (maps, currentMapId) => {
    return maps[currentMapId] || null;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredPois = createSelector(
  [currentMapId, pois],
  (mapId, pois) => {
    return filterObjects(pois, poi => {
      return poi.map_id === +mapId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredPoisIds = createSelector(
  [poisIds, selectFilteredPois],
  (poisIds, pois) => {
    return filterIds(poisIds, pois);
  }
);
// ----------------------------------------
