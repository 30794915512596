import React from 'react';
import { I18nextProvider } from 'react-i18next';
import ThemeContainer from './components/theme/ThemeContainer';
import i18n from './i18n';
import Router from './Router';
import store from './store';
import ErrorContainer from './components/alert/ErrorContainer';
import NotifContainer from './components/alert/NotifContainer';
import { Provider } from 'react-redux';

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ErrorContainer />
        <NotifContainer />
        <I18nextProvider i18n={i18n}>
          <ThemeContainer>
            <Router />
          </ThemeContainer>
        </I18nextProvider>
      </Provider>
    );
  }
}

export default App;
