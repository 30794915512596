import {
  SUCCESS,
  FETCH_SMARTQA_POSTS,
  FETCH_SMARTQA_POST,
  POST_SMARTQA_POST,
  POST_SMARTQA_POST_HIDE_TO_ANIMATORS
} from '../constants/actionApi';
import { UPDATE_SMART_QA_POST } from '../constants/actionLocal';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_SMART_QA_POST, UPDATED } from '../constants/socket';

export default (state = initialState.smartqaPosts, { type, payload }) => {
  switch (type) {
    case FETCH_SMARTQA_POSTS + SUCCESS:
    case FETCH_SMARTQA_POST + SUCCESS:
    case POST_SMARTQA_POST + SUCCESS:
    case UPDATE_SMART_QA_POST:
      return addOrUpdateItemsInState(state, payload.data);
    case SC_SMART_QA_POST + UPDATED:
      if (payload.data.is_displayed_to_attendees) {
        delete payload.data.is_displayed_to_attendees;
      }
      if (payload.data.is_displayed_to_animators) {
        delete payload.data.is_displayed_to_animators;
      }
      return addOrUpdateItemsInState(state, payload.data);
    case POST_SMARTQA_POST_HIDE_TO_ANIMATORS:
      return addOrUpdateItemsInState(state, { is_displayed_to_animators: false });
    default:
      return state;
  }
};
