import React from 'react';
import FlexParent from '../../components/shared/flex/FlexParent';
import Button from '../../components/shared/Button';

const Page = props => {
  const { currentPage, pageIndex, pagesCount, isSending, t, offline } = props;

  return currentPage === pageIndex ? (
    <React.Fragment>
      {props.children}
      <FlexParent justifyContent={'center'}>
        {pagesCount > 1 ? (
          <Button
            btntype={'primary'}
            disabled={pageIndex === 0 || offline}
            onClick={props.onClickPrev}
          >
            {t('btn_previous')}
          </Button>
        ) : null}
        <Button
          btntype={'primary'}
          onClick={props.onClickNext}
          disabled={!props.isPageValid() || isSending || offline}
        >
          {pagesCount - 1 === pageIndex
            ? isSending
              ? t('btn_saving')
              : t('btn_validate')
            : t('btn_next')}
        </Button>
      </FlexParent>
    </React.Fragment>
  ) : null;
};

export default Page;
