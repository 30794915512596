import CardIcon from '../../components/shared/card/CardIcon';
import React from 'react';
import styled from 'styled-components';
import Card from '../../components/shared/card/Card';
import QuestionResponse from './QuestionResponse';
import FlexParent from '../../components/shared/flex/FlexParent';
import Button from '../../components/shared/Button';
import HtmlContent from '../../components/shared/HtmlContent';

const Message = styled.div`
  margin: 20px 0 0;
  font-weight: 300;
  font-size: 1.4rem;
`;

const ServiceName = styled.div`
  padding: 40px 10px 0;
  font-size: 1.8rem;
  font-weight: 500;
`;

const Score = styled.span`
  font-size: 4.8rem;
  font-weight: 700;
  color: ${props => props.theme.mainColor};
`;

const Label = styled.div`
  font-size: 1.6rem;
  padding: 0 0 15px 0;
  text-align: center;
`;

class Answered extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <CardIcon
          icon={this.props.icon}
          textAlign={'center'}
          padding={'20px'}
          logoBorderRadius={'50%'}
          theme={this.props.theme}
        >
          <ServiceName>{this.props.name}</ServiceName>
          <Message>
            <p>{t('thanks')}</p>
          </Message>
          {this.props.vote.is_quiz ? (
            <Message>
              {this.props.roundCount > 1 ? (
                <div>
                  <p>{t('vote_score')}</p>
                  <Score>{this.props.currentRound.points}</Score>
                </div>
              ) : (
                ''
              )}

              <div>
                <p>{t('total_score')}</p>
                <Score>{this.props.totalScore}</Score>
              </div>
            </Message>
          ) : null}

          {this.props.canVoteAgain ? (
            <Button btntype={'primary'} onClick={this.props.voteAgain} margin={'10px 0 0'}>
              {t('btn_vote_again')}
            </Button>
          ) : null}
        </CardIcon>

        {this.props.vote.is_quiz && this.props.currentRound.max_answers_per_attendee > 0
          ? this.props.currentRound.questions.map(question => {
              const { id, label } = question;
              return label ? (
                <Card key={id} padding={'15px'} margin={'0 0 15px 0'}>
                  <Label>
                    <HtmlContent>{label}</HtmlContent>
                  </Label>
                  <React.Fragment>
                    <FlexParent justifyContent={'space-evenly'} wrap={'wrap'}>
                      {question.options
                        ? question.options.map(option => {
                            return (
                              <QuestionResponse
                                option={option}
                                key={option.id}
                                currentRoundType={this.props.currentRound.type}
                              />
                            );
                          })
                        : null}
                    </FlexParent>
                  </React.Fragment>
                </Card>
              ) : null;
            })
          : null}
      </React.Fragment>
    );
  }
}

export default Answered;
