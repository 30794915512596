import Button from '../../../components/shared/Button';
import Modal from '../../../components/modal/Modal';
import React from 'react';
import FormGroup from '../../../components/shared/form/FormGroup';
import Select from '../../../components/shared/form/Select';
import FlexParent from '../../../components/shared/flex/FlexParent';
import styled from 'styled-components';

const ConfirmMessage = styled.div`
  margin: 0 0 10px;
  font-weight: bold;
`;

const ConfirmSubMessage = styled.div`
  color: ${props => props.theme.ashesGrey};
  margin: 0 0 10px;
  font-size: 1.2rem;
`;

class ModalLeave extends React.Component {
  state = {
    attendee_id: null
  };

  componentDidMount() {
    this.setState({
      attendee_id: this.props.conversation.attendees.length
        ? this.props.conversation.attendees[0].id
        : null
    });
  }

  onClickValidate = event => {
    const { conversation, user } = this.props;
    this.props.onClickLeave({
      attendee_id: conversation.owner.id === user.id ? this.state.attendee_id : null
    });
  };

  onChangeOwner = event => {
    this.setState({
      attendee_id: +event.currentTarget.value
    });
  };

  render() {
    const { onClose, open, t, conversation, user } = this.props;
    return (
      <Modal onClose={onClose} open={open}>
        {conversation.owner.id === user.id && conversation.attendees.length ? (
          <React.Fragment>
            <ConfirmMessage>{t('select_conversation_owner')}</ConfirmMessage>
            <FormGroup>
              <Select
                id={'attendee_id'}
                name={'attendee_id'}
                onChange={this.onChangeOwner}
                value={this.state.attendee_id}
              >
                {conversation.attendees.map((attendee, index) => {
                  return (
                    <option key={attendee.id} value={attendee.id}>
                      {attendee.name}
                    </option>
                  );
                })}
              </Select>
            </FormGroup>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ConfirmMessage>{t('confirm_leave_conversation')}</ConfirmMessage>
            <ConfirmSubMessage>{t('confirm_leave_conversation_sub')}</ConfirmSubMessage>
          </React.Fragment>
        )}

        <FlexParent>
          <Button btntype={'secondary'} onClick={onClose} display="block" width="100%">
            {t('btn_cancel')}
          </Button>

          <Button btntype={'danger'} onClick={this.onClickValidate} display="block" width="100%">
            {t('btn_leave')}
          </Button>
        </FlexParent>
      </Modal>
    );
  }
}

export default ModalLeave;
