import { connect } from 'react-redux';
import { fetchTimeline, fetchTimelineItems } from '../../actions/api';
import { updateHeader, updateGlobal } from '../../actions/local';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';
import Timeline from './Timeline';
import { SEARCH_DELAY } from '../../constants/app';
import { selectCurrentEvent } from '../../selectors/events';
import {
  selectCurrentTimeline,
  selectFilteredTimelineItems,
  selectFilteredTimelineItemsIds,
  selectIsLoadingTimelineItems,
  selectHasMoreTimelineItems,
  selectNotifsItemsCount
} from '../../selectors/timeline';
import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';
import { selectHeader } from '../../selectors/header';
import Mixpanel from '../../Mixpanel';

import Socket from '../../socket/Socket';

import { CREATED, DELETED, SC_TIMELINE, SC_TIMELINE_ITEM } from '../../constants/socket';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class TimelineContainer extends React.Component {
  state = {
    socketIsListening: false
  };

  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    const { appId, eventId, timelineId } = this.props.match.params;
    this.props.fetchTimeline({ event_id: eventId, timeline_id: timelineId });
    this.props.updateHeader({
      leftBtn: this.props.match.params.timelineItemId && window.history.length > 1 ? 'back' : 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'timeline',
      viewableId: +timelineId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });

    if (this.props.timeline && this.props.event && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }
  }

  componentDidUpdate(prevProps) {
    const { eventId, timelineId } = this.props.match.params;
    const oldTimelineId = prevProps.match.params.timelineId;
    if (timelineId !== oldTimelineId) {
      this.props.fetchTimeline({ event_id: eventId, timeline_id: timelineId });
      this.unsubscribeSocketEvents(oldTimelineId);
    }

    if (this.props.global.searchValue !== prevProps.global.searchValue) {
      this.search(this.props.global.searchValue);
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }

    if (this.props.timeline && this.props.event && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }
  }

  componentWillUnmount() {
    const { timelineId } = this.props.match.params;
    this.unsubscribeSocketEvents(timelineId);
  }

  subscribeToSocketEvents = () => {
    const { timelineId } = this.props.match.params;
    this.socket = Socket.subscribeChannel(
      `${SC_TIMELINE}.${timelineId}`,
      this.props.event.visibility === 'public' ? true : false
    );
    Socket.listenEvents(this.socket, [SC_TIMELINE_ITEM + CREATED, SC_TIMELINE_ITEM + DELETED]);

    this.setState({
      socketIsListening: true
    });
  };

  unsubscribeSocketEvents = timelineId => {
    Socket.unsubscribeChannel(`${SC_TIMELINE}.${timelineId}`);
    this.setState({
      socketIsListening: false
    });
  };

  refreshData = () => {
    const { eventId, timelineId } = this.props.match.params;
    this.props.fetchTimeline({ event_id: eventId, timeline_id: timelineId });
  };

  fetchTimeline = () => {
    const { appId, eventId, timelineId } = this.props.match.params;
    this.props.fetchTimeline({
      app_id: appId,
      event_id: eventId,
      timeline_id: timelineId
    });
  };

  fetchTimelineItems = data => {
    const { appId, eventId, timelineId } = this.props.match.params;
    this.props.fetchTimelineItems({
      ...data,
      q: this.props.global.searchValue,
      app_id: appId,
      event_id: eventId,
      timeline_id: timelineId
    });
  };

  search = () => {
    if (this.searchTimeout) {
      this.searchTimeout = clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchTimelineItems({ reset: true });
    }, SEARCH_DELAY);
  };

  navigateToPostDetails = (timelineId, postId) => {
    const { appId, eventId } = this.props.match.params;
    const url = `/apps/${appId}/events/${eventId}/smartqa/${timelineId}/posts/${postId}`;
    this.props.history.push(url);
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  render() {
    if (!this.props.timeline) {
      return null;
    }
    const {
      refreshData,
      fetchTimelineItems,
      navigateToPostDetails,
      setComponentFixedTopHeight
    } = this;
    const {
      timeline,
      timelineItems,
      timelineItemsIds,
      t,
      history,
      theme,
      event,
      global,
      header,
      isLoadingTimelinesItems,
      hasMoreTimelinesItems,
      notifsItemsCount
    } = this.props;

    const { componentFixedTopHeight } = global;
    const { height: headerHeight } = header;

    const matchParams = this.props.match.params;
    const { timelineItemId } = matchParams;

    return timeline.is_open && !timelineItemId ? (
      <Timeline
        {...{
          matchParams,
          timeline,
          timelineItems,
          timelineItemsIds,
          isLoadingTimelinesItems,
          hasMoreTimelinesItems,
          t,
          history,
          theme,
          event,
          notifsItemsCount,
          componentFixedTopHeight,
          headerHeight
        }}
        {...{
          fetchTimelineItems,
          navigateToPostDetails,
          setComponentFixedTopHeight
        }}
      />
    ) : !timeline.is_open ? (
      <ClosedService
        icon={getServiceIconByType(timeline.custom_icon || 'timeline')}
        name={timeline.name}
        theme={theme}
        t={t}
        onRefresh={refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchTimeline: fetchTimeline,
  fetchTimelineItems: fetchTimelineItems,
  updateGlobal: updateGlobal
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    timeline: selectCurrentTimeline(state, ownProps),
    timelineItems: selectFilteredTimelineItems(state, ownProps),
    timelineItemsIds: selectFilteredTimelineItemsIds(state, ownProps),
    isLoadingTimelinesItems: selectIsLoadingTimelineItems(state, ownProps),
    hasMoreTimelinesItems: selectHasMoreTimelineItems(state, ownProps),
    global: selectGlobal(state, ownProps),
    notifsItemsCount: selectNotifsItemsCount(state, ownProps),
    header: selectHeader(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineContainer);
