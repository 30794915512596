import FlexParent from '../shared/flex/FlexParent';
import FlexItem from '../shared/flex/FlexItem';
import Icon from '../icons/Icon';
import IconBack from '../icons/IconBack';
import IconCloseCross from '../icons/IconCloseCross';
import IconMenuBurger from '../icons/IconMenuBurger';
import IconSearch from '../icons/IconSearch';
import IconAvatar from '../icons/IconAvatar';
import IconFilter from '../icons/IconFilter';
import IconInvisible from '../../components/icons/IconInvisible';
import IconVisible from '../../components/icons/IconVisible';
import React from 'react';
import SearchBar from './SearchBar';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  text-align: center;
  z-index: 15;
  user-select: none;
  min-height: 50px;
`;

const Filler = styled(FlexItem)`
  width: 40px;
  min-width: 40px;
  height: 40px;
`;

export const HeaderLogo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
  max-height: 50px;
  display: flex;
  padding: 5px;
`;

export const LeftZone = styled(FlexItem)`
  width: 80px;
  min-width: 80px;
  margin: 5px 0 5px 15px;
`;

export const RightZone = styled(FlexItem)`
  width: 80px;
  min-width: 80px;
  margin: 5px 15px 5px 0;
`;

export const CenterZone = styled(FlexItem)``;

const Badge = styled.div`
  border-radius: 5px;
  background-color: ${props => props.theme.mainColor};
  width: 20px;
  height: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
  text-align: center;
`;

const UserAvatar = styled.img`
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
`;

const LeftBtn = props => {
  const type = props.type;
  let onClick = null;
  let icon = null;
  let padding = null;

  switch (type) {
    case 'nav':
      icon = IconMenuBurger;
      onClick = props.onClickNavBtn;
      padding = '7px 14px 7px 0';
      break;
    case 'back':
      icon = IconBack;
      onClick = props.onClickBackBtn;
      padding = '10px 20px 10px 0';
      break;
    case 'user':
      icon = IconAvatar;
      onClick = props.onClickUserBtn;
      padding = '10px 20px 10px 0';
      break;
    default:
      return null;
  }

  return (
    <Icon
      icon={icon}
      color={props.theme.mainColor}
      width={'40px'}
      height={'40px'}
      padding={padding}
      onClick={onClick}
    />
  );
};

const RightBtn = props => {
  const type = props.type;
  let onClick = null;
  let icon = null;
  let padding = null;
  let width = '40px';
  let height = '40px';
  let color = null;

  switch (type) {
    case 'search':
      icon = IconSearch;
      onClick = props.onClickSearchBtn;
      padding = '8px 0 8px 16px';
      break;
    case 'filter':
      icon = IconFilter;
      onClick = props.onClickFilterBtn;
      padding = '8px 0 8px 16px';
      break;
    case 'closeSearch':
      icon = IconCloseCross;
      onClick = props.onClickCloseSearchBtn;
      padding = '10px 0 10px 20px';
      break;
    case 'closeFilter':
      icon = IconCloseCross;
      onClick = props.onClickCloseFilterBtn;
      padding = '10px 0 10px 20px';
      break;
    case 'incognitoTrue':
      icon = IconInvisible;
      onClick = props.onClickIncognitoBtn;
      padding = '7px 0 7px 14px';
      color = props.theme.shadowGrey;
      break;
    case 'incognitoFalse':
      icon = IconVisible;
      onClick = props.onClickNotIncognitoBtn;
      padding = '7px 0 7px 14px';
      break;
    case 'badge': {
      return props.agendaCategoriesFiltersCount ? (
        <Badge alignSelf={'center'}>{props.agendaCategoriesFiltersCount}</Badge>
      ) : (
        <Filler basis={'0'} />
      );
    }
    default:
      return <Filler basis={'0'} />;
  }

  return (
    <Icon
      icon={icon}
      color={color || props.theme.mainColor}
      width={width}
      height={height}
      padding={padding}
      onClick={onClick}
    />
  );
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.props.setHeaderHeight(this.containerRef.current.clientHeight);
  }

  componentDidUpdate() {
    if (this.props.header.height !== this.containerRef.current.clientHeight) {
      this.props.setHeaderHeight(this.containerRef.current.clientHeight);
    }
  }

  render() {
    // functions
    const {
      onClickNavBtn,
      onClickBackBtn,
      onClickSearchBtn,
      onClickFilterBtn,
      onClickCloseSearchBtn,
      onClickCloseFilterBtn,
      onClickIncognitoBtn,
      onClickNotIncognitoBtn,
      onClickUserBtn,
      onChangeSearchValue
    } = this.props;

    // properties
    const { theme, t, header, global, user, agendaCategoriesFiltersCount } = this.props;
    const { searchValue } = global;

    return (
      <Container ref={this.containerRef}>
        <FlexParent alignItems={'center'} justifyContent={'space-between'}>
          <LeftZone>
            {header.leftBtn === 'user' && user ? (
              <UserAvatar src={user.avatar_url} onClick={onClickUserBtn} />
            ) : (
              <LeftBtn
                type={header.leftBtn}
                {...{ theme, onClickNavBtn, onClickBackBtn, onClickUserBtn }}
              />
            )}
          </LeftZone>

          <CenterZone grow={1}>{header.logo ? <HeaderLogo src={header.logo} /> : ''}</CenterZone>

          <RightZone>
            <FlexParent alignItems={'center'} justifyContent={'flex-end'}>
              <RightBtn
                type={header.rightBtn2}
                {...{
                  theme,
                  onClickFilterBtn,
                  onClickCloseFilterBtn,
                  onClickIncognitoBtn,
                  onClickNotIncognitoBtn,
                  agendaCategoriesFiltersCount
                }}
              />
              <RightBtn
                type={header.rightBtn}
                {...{
                  theme,
                  onClickSearchBtn,
                  onClickCloseSearchBtn,
                  onClickFilterBtn,
                  onClickCloseFilterBtn,
                  onClickIncognitoBtn,
                  onClickNotIncognitoBtn
                }}
              />
            </FlexParent>
          </RightZone>
        </FlexParent>
        {header.showSearchBar &&
        (header.rightBtn === 'search' || header.rightBtn === 'closeSearch') ? (
          <SearchBar {...{ searchValue, t, theme }} {...{ onChangeSearchValue }} />
        ) : null}
      </Container>
    );
  }
}

export default Header;
