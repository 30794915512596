import React from 'react';
import Loader from '../../components/shared/Loader';
import TweetItem from './TweetItem';
import Card from '../../components/shared/card/Card';

class Twitter extends React.Component {
  componentDidMount() {
    this.props.fetchTweets();
  }

  render() {
    const { t, theme, tweets, isLoadingTweets } = this.props;
    return (
      <React.Fragment>
        {Object.keys(tweets).length && !isLoadingTweets ? (
          Object.keys(tweets).map((key, index) => {
            const tweet = tweets[key];
            return <TweetItem key={tweet.id} tweet={tweet} {...{ t, theme }} />;
          })
        ) : !Object.keys(tweets).length && !isLoadingTweets ? (
          <Card padding={'15px'} textAlign={'center'}>
            {t('no_tweet_found')}
          </Card>
        ) : (
          <Loader />
        )}
      </React.Fragment>
    );
  }
}

export default Twitter;
