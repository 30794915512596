import React from 'react';
import Card from '../../../components/shared/card/Card';
import MessageItem from './MessageItem';
import InfiniteScroll from '../../../components/shared/InfiniteScroll';
import FullImage from '../../../components/shared/FullImage';
import styled from 'styled-components';
import { isSameDay, applyTimezone, formatDate, diffFromNow } from '../../../helpers/date';

const NoMessage = styled.div`
  text-align: center;
  padding: 50px 0;
`;

const DateSeparator = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
  font-size: 1.2rem;
  color: ${props => props.theme.ashesGrey};
`;

class MessagesList extends React.Component {
  state = {
    fullScreenPictureUrl: null,
    lastMessageId: null,
    scrolling: false
  };

  componentDidMount() {
    this.prevDate = null;
    document.addEventListener('scroll', this.onScroll, true);
    this.props.fetchMessages({ conversation_id: +this.props.conversationId, reset: true });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conversationId !== prevProps.conversationId) {
      this.props.fetchMessages({ conversation_id: +this.props.conversationId, reset: true });
    }

    if (this.props.messagesIds.length) {
      const lastMessageId = this.props.messagesIds[this.props.messagesIds.length - 1];
      if (lastMessageId && lastMessageId !== this.state.lastMessageId) {
        this.setState({
          lastMessageId: lastMessageId
        });
        const lastMessage = this.props.messages[lastMessageId];
        if (
          (lastMessage.attendee.id === this.props.user.id && window.scrollY !== 0) ||
          !this.state.scrolling
        ) {
          window.scrollTo(0, document.body.scrollHeight);
        } else if (
          !(lastMessage.attendee.id === this.props.user.id && window.scrollY !== 0) &&
          this.state.scrolling &&
          window.navigator.vibrate
        ) {
          window.navigator.vibrate(200);
        }

        this.props.postWhosHereMessageRead({ conversation_id: this.props.conversationId });
      }
    }
  }

  onScroll = () => {
    if (!this.state.scrolling) {
      this.setState(
        {
          scrolling: true
        },
        () => {
          setTimeout(() => {
            this.setState({
              scrolling: false
            });
          }, 1000);
        }
      );
    }
  };

  fetchMessages = () => {
    this.props.fetchMessages({ conversation_id: +this.props.conversationId });
  };

  showFullScreenPicture = ({ url }) => {
    this.setState({
      fullScreenPictureUrl: url
    });
  };

  closeFullScreenPicture = () => {
    this.setState({
      fullScreenPictureUrl: null
    });
  };

  render() {
    const {
      messages,
      messagesIds,
      isLoadingMessages,
      hasMoreMessages,
      user,
      event,
      t
    } = this.props;

    let previousItemDate = null;
    const { showFullScreenPicture } = this;

    return (
      <React.Fragment>
        <FullImage
          url={this.state.fullScreenPictureUrl}
          onClickClose={this.closeFullScreenPicture}
        ></FullImage>
        <Card margin={'0 0 20px'} padding={'10px'}>
          <InfiniteScroll
            loadMore={this.fetchMessages}
            hasMore={hasMoreMessages}
            isLoading={isLoadingMessages}
            reverse={true}
          >
            {!messagesIds.length && !isLoadingMessages ? (
              <NoMessage>{t('whos_here_no_message')}</NoMessage>
            ) : (
              <React.Fragment>
                {messagesIds.map((key, index) => {
                  let displayDate;
                  const message = messages[key];
                  const date = applyTimezone(message.created_at, event.timezone);
                  const isSame = previousItemDate && isSameDay(previousItemDate, date);
                  if (!isSame) {
                    const diffInDays = diffFromNow(date);
                    if (diffInDays === 0) {
                      displayDate = t('today');
                    } else if (diffInDays === 1) {
                      displayDate = t('yesterday');
                    } else if (diffInDays > 1 && diffInDays <= 7) {
                      displayDate = formatDate(date, 'dddd');
                    } else if (diffInDays > 7 && diffInDays <= 365) {
                      displayDate = formatDate(date, 'MMMM Do');
                    } else {
                      displayDate = formatDate(date, 'MMMM Do YY');
                    }
                    previousItemDate = date;
                  }

                  return (
                    <React.Fragment key={message.id}>
                      {!isSame ? <DateSeparator>{displayDate}</DateSeparator> : null}
                      <MessageItem {...{ message, user, event, showFullScreenPicture }} />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            )}
          </InfiniteScroll>
        </Card>
      </React.Fragment>
    );
  }
}

export default MessagesList;
