import React from 'react';
import styled from 'styled-components';
import FlexParent from '../../components/shared/flex/FlexParent';
import FlexItem from '../../components/shared/flex/FlexItem';
import Icon from '../../components/icons/Icon';
import IconAnswerRight from '../../components/icons/IconAnswerRight';
import IconAnswerWrong from '../../components/icons/IconAnswerWrong';

const Container = styled(FlexParent)`
  margin: 0 0 20px 0;
  width: 100%;
`;

const ImageContainer = styled(FlexItem)`
  max-width: 45%;
  margin: 0 1% 20px;
  cursor: pointer;
`;

const ImageLabel = styled.div`
  position: relative;
  div {
    padding-top: 10px;
    text-align: center;
    font-weight: 300;
    font-size: 1.4rem;
  }
`;

const ImageIcon = styled.label`
  position: absolute;
  top: 3px;
  right: 3px;
`;

const Image = styled.img`
  width: 100%;
  margin: 0;
  display: block;
  border: ${props => (props.isRight || props.isWrong ? '5px' : '0')} solid
    ${props => (props.isRight ? '#7ED321' : props.isWrong ? '#D0021B' : 'initial')};
  border-radius: 8px;
`;

const QuestionResponse = props => {
  const { option } = props;
  const label =
    ['value', 'picture+value'].indexOf(props.currentRoundType) !== -1 && option.value
      ? option.value
      : null;

  const picture =
    ['picture', 'picture+value'].indexOf(props.currentRoundType) !== -1 && option.picture_url
      ? option.picture_url
      : null;

  return !picture ? (
    <Container>
      <FlexItem grow={1}>{label}</FlexItem>
      <FlexItem>
        {option.is_right ? (
          <Icon
            icon={IconAnswerRight}
            color={'#7ED321'}
            width={'20px'}
            height={'20px'}
            margin={'0 10px 0 0'}
          />
        ) : null}

        {!option.is_right && option.selected ? (
          <Icon
            icon={IconAnswerWrong}
            color={'#D0021B'}
            width={'20px'}
            height={'20px'}
            margin={'0 10px 0 0'}
          />
        ) : null}
      </FlexItem>
    </Container>
  ) : (
    <ImageContainer grow={'1'} shrink={'0'}>
      <ImageLabel>
        {picture ? (
          <Image
            src={picture}
            alt=""
            isRight={option.is_right}
            isWrong={!option.is_right && option.selected}
          />
        ) : null}
        <ImageIcon>
          {option.is_right ? (
            <Icon
              icon={IconAnswerRight}
              color={'#7ED321'}
              width={'20px'}
              height={'20px'}
              margin={'0 10px 0 0'}
            />
          ) : null}

          {!option.is_right && option.selected ? (
            <Icon
              icon={IconAnswerWrong}
              color={'#D0021B'}
              width={'20px'}
              height={'20px'}
              margin={'0 10px 0 0'}
            />
          ) : null}
        </ImageIcon>
        {label && <div>{label}</div>}
      </ImageLabel>
    </ImageContainer>
  );
};

export default QuestionResponse;
