import { createSelector } from 'reselect';

const getGlobal = state => state.global;

export const selectGlobal = createSelector(
  [getGlobal],
  global => {
    return global;
  }
);
