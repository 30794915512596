import { connect } from 'react-redux';
import { fetchAgenda, fetchSession } from '../../actions/api';
import { updateHeader, updateGlobal } from '../../actions/local';
import Session from './Session';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';

import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import { selectCurrentEvent } from '../../selectors/events';
import { selectCurrentAgenda, selectCurrentSession } from '../../selectors/agendas';
import { selectUser } from '../../selectors/user';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class SessionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    const { appId, eventId, sessionId } = this.props.match.params;
    this.getAPiData(this.props.match.params);

    this.props.updateHeader({
      leftBtn: window.history.length > 1 ? 'back' : 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'session',
      viewableId: +sessionId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { sessionId } = this.props.match.params;
    const oldSessionId = prevProps.match.params.sessionId;
    if (sessionId !== oldSessionId) {
      this.getAPiData(this.props.match.params);
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }
  }

  getAPiData({ eventId, agendaId, sessionId }) {
    this.props.fetchAgenda({ event_id: eventId, agenda_id: agendaId });

    this.props.fetchSession({
      event_id: eventId,
      agenda_id: agendaId,
      session_id: sessionId
    });
  }

  refreshData = () => {
    this.getAPiData(this.props.match.params);
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  render() {
    if (!this.props.agenda) {
      return null;
    }
    // properties
    const { event, agenda, session, header, t, theme, history, global } = this.props;

    const { height: headerHeight } = header;
    const { componentFixedTopHeight, isInAppLive } = global;
    const matchParams = this.props.match.params;
    const timezone = event.timezone;

    // functions
    const { setComponentFixedTopHeight } = this;

    return agenda.is_open && session ? (
      <Session
        {...{
          setComponentFixedTopHeight
        }}
        {...{
          matchParams,
          event,
          session,
          t,
          theme,
          history,
          timezone,
          headerHeight,
          componentFixedTopHeight,
          isInAppLive
        }}
      />
    ) : !agenda.is_open ? (
      <ClosedService
        icon={getServiceIconByType(agenda.custom_icon || 'agenda')}
        name={agenda.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  updateGlobal: updateGlobal,
  fetchSession: fetchSession,
  fetchAgenda: fetchAgenda
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    agenda: selectCurrentAgenda(state, ownProps),
    session: selectCurrentSession(state, ownProps),
    header: selectHeader(state, ownProps),
    global: selectGlobal(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionContainer);
