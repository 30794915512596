import { connect } from 'react-redux';
import { fetchAgenda, fetchSessions, fetchAgendaCategories } from '../../actions/api';
import { getAgendaDefaultDate } from '../../helpers/date';
import { updateHeader, updateGlobal } from '../../actions/local';
import Agenda from './Agenda';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';
import { selectUser } from '../../selectors/user';
import { selectCurrentEvent } from '../../selectors/events';
import {
  selectCurrentAgenda,
  selectAgendaCurrentDate,
  selectFilteredCategories,
  selectFilteredSessions,
  selectFilteredSessionsIds,
  selectFilteredCategoriesIds
} from '../../selectors/agendas';
import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class AgendaContainer extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    const { appId, eventId, agendaId } = this.props.match.params;
    this.getAPiData(this.props.match.params);
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: this.props.agenda && this.props.agenda.categories_count ? 'filter' : null,
      rightBtn2: 'badge',
      isCategoriesFiltersOpen: false
    });

    Mixpanel.track('view', {
      viewableType: 'agenda',
      viewableId: +agendaId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { agendaId } = this.props.match.params;
    const oldAgendaId = prevProps.match.params.agendaId;

    if (!this.props.currentDate && this.props.agenda) {
      const date = getAgendaDefaultDate(
        this.props.agenda.dates[0],
        this.props.agenda.dates[this.props.agenda.dates.length - 1]
      );
      this.props.updateGlobal({
        agendaCurrentDate: date
      });
    }

    if (oldAgendaId && agendaId !== oldAgendaId) {
      this.getAPiData(this.props.match.params);
    }

    if (!prevProps.agenda && this.props.agenda) {
      this.props.updateHeader({
        rightBtn: this.props.agenda.categories_count ? 'filter' : null,
        isCategoriesFiltersOpen: false
      });
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }

    const scrollY = window.localStorage.getItem(`scrollY_agenda_${agendaId}`);
    if (scrollY) {
      window.scrollTo({ top: scrollY });
      window.localStorage.removeItem(`scrollY_agenda_${agendaId}`);
    }
  }

  getAPiData({ eventId, agendaId }) {
    this.props.fetchAgenda({
      event_id: eventId,
      agenda_id: agendaId
    });

    this.props.fetchAgendaCategories({
      event_id: eventId,
      agenda_id: agendaId,
      reset: true
    });
  }

  fetchSessions = reset => {
    const { eventId, agendaId } = this.props.match.params;
    if (this.props.currentDate) {
      this.props.fetchSessions({
        event_id: +eventId,
        agenda_id: +agendaId,
        date: this.props.currentDate,
        reset
      });
    }
  };

  refreshData = () => {
    this.getAPiData(this.props.match.params);
  };

  setDate = date => {
    this.props.updateGlobal({
      agendaCurrentDate: date
    });
  };

  filterCategories = (id, unChecked) => {
    let newCategoriesFilter = [...this.props.global.agendaCategoriesFilters];
    if (unChecked) {
      newCategoriesFilter.push(id);
    } else {
      const index = this.props.global.agendaCategoriesFilters.indexOf(id);
      newCategoriesFilter.splice(index, 1);
    }

    this.props.updateGlobal({ agendaCategoriesFilters: newCategoriesFilter });
  };

  filterAllCategories = categoriesFilter => {
    this.props.updateGlobal({ agendaCategoriesFilters: categoriesFilter });
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  render() {
    if (!this.props.event || !this.props.agenda) {
      return null;
    }

    // functions
    const {
      setDate,
      filterCategories,
      filterAllCategories,
      setComponentFixedTopHeight,
      fetchSessions
    } = this;

    // properties
    const {
      currentDate,
      agenda,
      header,
      t,
      theme,
      history,
      event,
      categories,
      global,
      sessions,
      sessionsIds,
      categoriesIds
    } = this.props;
    const { height: headerHeight, isCategoriesFiltersOpen } = header;
    const { agendaCategoriesFilters: categoriesFilters, componentFixedTopHeight } = global;

    const matchParams = this.props.match.params;
    const timezone = event.timezone;

    return agenda.is_open ? (
      <Agenda
        {...{
          setDate,
          filterCategories,
          filterAllCategories,
          setComponentFixedTopHeight,
          fetchSessions
        }}
        {...{
          matchParams,
          agenda,
          sessions,
          headerHeight,
          componentFixedTopHeight,
          currentDate,
          t,
          theme,
          history,
          timezone,
          isCategoriesFiltersOpen,
          categories,
          categoriesFilters,
          sessionsIds,
          categoriesIds
        }}
      />
    ) : !agenda.is_open ? (
      <ClosedService
        icon={getServiceIconByType(agenda.custom_icon || 'agenda')}
        name={agenda.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateGlobal: updateGlobal,
  updateHeader: updateHeader,
  fetchAgenda: fetchAgenda,
  fetchSessions: fetchSessions,
  fetchAgendaCategories: fetchAgendaCategories
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    agenda: selectCurrentAgenda(state, ownProps),
    categories: selectFilteredCategories(state, ownProps),
    sessions: selectFilteredSessions(state, ownProps),
    sessionsIds: selectFilteredSessionsIds(state, ownProps),
    categoriesIds: selectFilteredCategoriesIds(state, ownProps),
    header: selectHeader(state, ownProps),
    global: selectGlobal(state, ownProps),
    currentDate: selectAgendaCurrentDate(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgendaContainer);
