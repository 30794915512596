import { createSelector } from 'reselect';

const getSuccess = state => state.success;

export const selectSuccess = createSelector(
  [getSuccess],
  success => {
    return success;
  }
);
