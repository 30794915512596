import { SUCCESS, FETCH_BONUS_LIST } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_BONUS_LIST, UPDATED } from '../constants/socket';

export default (state = initialState.bonusLists, { type, payload }) => {
  switch (type) {
    case FETCH_BONUS_LIST + SUCCESS:
    case SC_BONUS_LIST + UPDATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
