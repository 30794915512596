import React from 'react';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import ServiceHeader from '../../components/shared/ServiceHeader';
import AgendaSession from './AgendaSession';
import SmartqaPost from './SmartqaPost';
import Message from './Message';
import VoteResult from './VoteResult';
import { isSameDay, applyTimezone, formatDate } from '../../helpers/date';
import Separator from '../../components/shared/Separator';
import Notification from './Notification';
import styled from 'styled-components';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

class Timeline extends React.Component {
  componentDidMount() {
    this.props.fetchTimelineItems({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.timeline.id !== prevProps.timeline.id) {
      this.props.fetchTimelineItems({ reset: true });
    }
  }
  render() {
    const {
      t,
      theme,
      event,
      timeline,
      timelineItems,
      timelineItemsIds,
      fetchTimelineItems,
      isLoadingTimelineItems,
      hasMoreTimelineItems,
      history,
      matchParams,
      navigateToPostDetails,
      notifsItemsCount,
      componentFixedTopHeight,
      setComponentFixedTopHeight,
      headerHeight
    } = this.props;

    const timezone = event.timezone;

    let previousItemDate = null;

    return (
      <React.Fragment>
        {notifsItemsCount ? (
          <Notification
            {...{
              t,
              theme,
              fetchTimelineItems,
              setComponentFixedTopHeight,
              headerHeight,
              notifsItemsCount
            }}
          />
        ) : null}

        <Container componentFixedTopHeight={componentFixedTopHeight}>
          <ServiceHeader
            t={t}
            icon={getServiceIconByType(timeline.custom_icon || 'timeline')}
            name={timeline.name}
            description={timeline.description}
            theme={theme}
          />

          <InfiniteScroll
            loadMore={fetchTimelineItems}
            hasMore={hasMoreTimelineItems}
            isLoading={isLoadingTimelineItems}
          >
            {timelineItemsIds.map(key => {
              const item = timelineItems[key];

              const { id, model_type, model } = item;
              const date = applyTimezone(item.created_at, event.timezone);
              const isSame = previousItemDate && isSameDay(previousItemDate, date);
              previousItemDate = date;

              return (
                <React.Fragment key={id}>
                  {!isSame ? <Separator>{formatDate(date, 'dddd, MMMM Do')}</Separator> : null}
                  {model_type === 'agenda_session' ? (
                    <AgendaSession
                      {...{ session: model, matchParams, theme, t, timezone, history }}
                    />
                  ) : model_type === 'smart_qa_question' ? (
                    <SmartqaPost
                      {...{
                        t,
                        matchParams,
                        post: model,
                        theme,
                        navigateToPostDetails
                      }}
                    />
                  ) : model_type === 'timeline_message' ? (
                    <Message
                      {...{
                        t,
                        matchParams,
                        message: model,
                        event,
                        theme
                      }}
                    />
                  ) : model_type === 'vote_result' ? (
                    <VoteResult
                      {...{
                        t,
                        matchParams,
                        voteResult: model,
                        event,
                        theme
                      }}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </InfiniteScroll>
        </Container>
      </React.Fragment>
    );
  }
}

export default Timeline;
