import React from 'react';
import styled from 'styled-components';

/*
PROPS
----------------------------------------------------------------------------------------------
order: <string>;
grow: <string>;
shrink: <string>;
basis: <length> | auto;
alignSelf: auto | flex-start | flex-end | center | baseline | stretch;
----------------------------------------------------------------------------------------------
*/

const FlexItemStyle = styled.div`
  order: ${props => props.order || 0};
  flex-grow: ${props => props.grow || 0};
  flex-shrink: ${props => props.shrink || 1};
  flex-basis: ${props => props.basis || 'auto'};
  align-self: ${props => props.alignSelf || 'auto'};
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  min-width: ${props => props.width || 'initial'};
  width: ${props => props.width || 'initial'};
  margin: ${props => props.margin || 'initial'};
  position: ${props => props.position || 'initial'};
`;

const FlexItem = props => {
  return <FlexItemStyle {...props}>{props.children}</FlexItemStyle>;
};

export default FlexItem;
