import React from 'react';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import styled from 'styled-components';
import Icon from '../../../components/icons/Icon';
import IconAddPeople from '../../../components/icons/IconAddPeople';
import IconMinus from '../../../components/icons/IconMinus';
import { ListItemShadow } from '../../../components/shared/ListItem';
import Label from '../../../components/shared/form/Label';

const Container = styled.div`
  padding: 15px 0 0;
`;

const AttendeeAvatarWithIcon = styled.div`
  position: relative;
`;

const AttendeeAvatar = styled.img`
  display: block;
  width: 50px;
  min-width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0 10px 0 0;
  border-radius: 50%;
`;

const AttendeeName = styled.div`
  font-size: 1.2rem;
  padding: 5px 0;
  text-align: center;
`;

class SelectedAttendees extends React.Component {
  render() {
    const { selectedAttendees, onClickAttendee, onClickAddAttendee, t } = this.props;
    return (
      <Container>
        <Label htmlFor="">{t('attendees')}</Label>
        <FlexParent alignItems={'center'} wrap={'wrap'} justifyContent={'center'}>
          {selectedAttendees.map(attendee => {
            return (
              <FlexItem
                data-id={attendee.id}
                key={attendee.id}
                onClick={onClickAttendee}
                margin={'0 11px 15px 11px'}
              >
                <AttendeeAvatarWithIcon>
                  <AttendeeAvatar src={attendee.avatar_url} alt="" />
                  <Icon
                    icon={IconMinus}
                    color={'#fff'}
                    backgroundColor={'#D0021B'}
                    width={'20px'}
                    height={'20px'}
                    position={'absolute'}
                    bottom={'0'}
                    right={'5px'}
                    borderRadius={'50%'}
                    padding={'4px'}
                  />
                  <ListItemShadow />
                </AttendeeAvatarWithIcon>

                <AttendeeName>{attendee.first_name}</AttendeeName>
              </FlexItem>
            );
          })}

          <FlexItem basis={'0'}>
            <Icon
              icon={IconAddPeople}
              color={'#b0b0b0'}
              width={'50px'}
              height={'50px'}
              margin={'0 15px'}
              onClick={onClickAddAttendee}
            />
            <AttendeeName>{t('add_attendees')}</AttendeeName>
          </FlexItem>
        </FlexParent>
      </Container>
    );
  }
}

export default SelectedAttendees;
