import CardIcon from '../../components/shared/card/CardIcon';
import Button from '../../components/shared/Button';
import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  padding: 20px 0 0;
  font-weight: 300;
  font-size: 1.4rem;
`;

const ServiceName = styled.div`
  padding: 40px 10px 0;
  font-size: 1.8rem;
  font-weight: 500;
`;

const Answered = props => {
  return (
    <CardIcon
      icon={props.icon}
      textAlign={'center'}
      padding={'20px'}
      logoBorderRadius={'50%'}
      theme={props.theme}
    >
      <ServiceName>{props.name}</ServiceName>
      <Message>
        <p>{props.t('thanks')}</p>
      </Message>

      {props.canVoteAgain ? (
        <Button btntype={'primary'} onClick={props.voteAgain}>
          {props.t('btn_vote_again')}
        </Button>
      ) : null}
    </CardIcon>
  );
};

export default Answered;
