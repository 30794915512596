export default class MenuControl {
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.insertAdjacentHTML(
      'beforeend',
      `<button id="menu-control" style="padding:4px" aria-label="Indoor Maps" type="button"><svg viewBox="0 0 25 25" style="display: block, margin: 0 auto">
      <g fill="#000">
      <path d="M25,23l-7.2-7.2c0.1-0.2,0.3-0.4,0.4-0.6c1-1.5,1.5-3.4,1.5-5.2c0-5.5-4.4-9.9-9.9-9.9C6.4,0,3.4,1.6,1.6,4.2 C0.6,5.9,0,7.8,0,9.9c0,5.5,4.4,9.9,9.9,9.9c2.2,0,4.2-0.8,5.9-2L23,25L25,23z M1.4,9.9C1.4,8,2,6.1,3.1,4.8C4.5,3,6.6,1.8,9,1.5 c0.2,0,0.6,0,0.9,0c1.4,0,2.6,0.4,3.8,0.9c2.9,1.4,4.7,4.2,4.7,7.6c0,4.6-3.8,8.5-8.5,8.5c-2.4,0-4.4-1-6-2.5l0,0l0,0 C2.4,14.2,1.4,12.3,1.4,9.9z" />
    </g>
    </svg></button>`
    );
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-menu';

    this._container.addEventListener('click', () => {
      if (this.onClick) {
        this.onClick();
      }
    });
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  setActive = isActive => {
    if (isActive) {
      this._container.classList.add('active');
    } else {
      this._container.classList.remove('active');
    }
  };
}
