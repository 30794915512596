import React from 'react';
import { render } from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/browser';
import 'modern-normalize/modern-normalize.css';
import './fonts/styles.css';

console.log(`Version : ${process.env.REACT_APP_CURRENT_GIT_SHA || 'Not specified'}`);

if (process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_CURRENT_GIT_SHA || 'Not specified'
  });
}

render(<App />, document.getElementById('root'));
