import FlexParent from './flex/FlexParent';
import FlexItem from './flex/FlexItem';
import styled from 'styled-components';
import React from 'react';
import HtmlContent from './HtmlContent';

const Container = styled(FlexParent)`
  margin: 25px 0;
`;

const Label = styled.div`
  font-size: 1.3rem;
  padding: 0 8px;
  font-weight: 500;
  text-transform: uppercase;
  p {
    margin: 0;
  }
`;

const Filer = styled(FlexItem)`
  background-color: ${props => props.theme.mainColor};
  height: 2px;
`;

const Separator = props => {
  return props.children ? (
    <Container alignItems={'center'}>
      <Filer grow={1}>&nbsp;</Filer>
      <Label>
        <HtmlContent>{props.children}</HtmlContent>{' '}
      </Label>
      <Filer grow={1}>&nbsp;</Filer>
    </Container>
  ) : null;
};

export default Separator;
