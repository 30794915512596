import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M12.2,7.6c1.3,1.4,0,3.4-1.5,3.4c-3.2,0-0.9,0-2.4,0c0,0,0,9.1,0,10.6c0,2.5-4,2.5-4,0c0-2.1,0-9.6,0-10.6c0,0,0.1,0-2.4,0 c-1.3,0-2.6-2-1.3-3.4C2,6,3.7,3.4,5.2,1.9C6,1.2,7,1.2,7.8,1.9C9.2,3.4,10.8,6,12.2,7.6z" />
      <path d="M12.6,18.2c-1.3-1.4,0-3.4,1.5-3.4c3.2,0,0.9,0,2.4,0c0,0,0-9.1,0-10.6c0-2.5,4-2.5,4,0c0,2.1,0,9.6,0,10.6c0,0-0.1,0,2.4,0 c1.5,0,2.9,2,1.5,3.4c-1.5,1.6-3.2,4-4.7,5.7c-0.8,0.8-1.8,0.8-2.5,0C15.7,22.4,14.1,19.8,12.6,18.2z" />
    </g>
  </svg>
);
