import React from 'react';
import styled from 'styled-components';
import { isDarkBgColor } from '../../helpers/helpers';

const Notif = styled.div`
  background-color: ${props => props.theme.mainColor};
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  text-align: center;
  padding: 3px;
  font-size: 1.2rem;
  color: ${props => {
    return isDarkBgColor(props.theme.mainColor) ? '#fff' : '#000';
  }};
  cursor: pointer;
`;

const Container = styled.div`
  position: fixed;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  left: 0;
  right: 0;
  top: ${props => props.headerHeight - 1 + 'px'};
  z-index: 20;
`;

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  onClickNotif = () => {
    this.props.fetchSmartqaPosts({
      reset: true,
      done: () => {
        window.scrollTo(0, 0);
      }
    });
  };

  componentDidMount() {
    this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
  }

  componentWillUnmount() {
    this.props.setComponentFixedTopHeight(0);
  }

  render() {
    const { t, theme, headerHeight } = this.props;
    return (
      <Container headerHeight={headerHeight} ref={this.containerRef}>
        <Notif
          textAlign={'center'}
          color={isDarkBgColor(theme.mainColor) ? 'white' : 'black'}
          backgroundColor={theme.mainColor}
          onClick={this.onClickNotif}
        >
          {t('new_content_available')}
        </Notif>
      </Container>
    );
  }
}

export default Notification;
