import React from 'react';
import styled from 'styled-components';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconAvatar from '../../components/icons/IconAvatar';
import { getTimeFromNow } from '../../helpers/date';
import IconReport from '../../components/icons/IconReport';
import { nl2br } from '../../helpers/format';
import HtmlContent from '../../components/shared/HtmlContent';

import { Reactions, ViewerReaction } from '../../components/shared/smartqa/Reactions';
import PopupReaction from '../../components/shared/smartqa/PopupReaction';

const Container = styled.div`
  padding: 15px 0;
  border-top: 1px solid ${props => props.theme.dustGrey};
  z-index: 40;
`;

const AvatarStyle = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 0 10px 0 0;
  z-index: 40;
`;

const Name = styled.div`
  font-size: 1.5rem;
  padding-bottom: 3px;
`;

const Message = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  padding: 10px 0;
`;

const Hour = styled.div`
  color: ${props => props.theme.ashesGrey};
  font-size: 1.1rem;
`;

const CommentLike = styled.div`
  color: ${props => props.theme.ashesGrey};
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
`;

const Bullet = styled.div`
  color: ${props => props.theme.ashesGrey};
  font-size: 1.1rem;
  padding: 0 6px;
`;

export const CommentPicture = styled.img`
  display: block;
  width: 100%;
  margin: 0 0 15px;
`;

const Avatar = props => {
  return props.profile_picture_url ? (
    <AvatarStyle src={props.profile_picture_url} />
  ) : (
    <Icon
      icon={IconAvatar}
      color={'#fff'}
      padding={'10px'}
      margin={'0 10px 0 0'}
      width={'40px'}
      height={'40px'}
      borderRadius={'50%'}
      backgroundColor={props.theme.dustGrey}
    />
  );
};

class Comment extends React.Component {
  state = {
    openReactionModal: false
  };

  onClickLike = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openReactionModal: true });
  };

  onClickUnlike = event => {
    event.preventDefault();
    event.stopPropagation();
    const type = event.currentTarget.dataset.type;
    this.setState({ openReactionModal: false });
    this.props.deleteReactionComment({ comment: this.props.comment, type });
  };

  sendReactionComment = type => {
    this.props.sendReactionComment({ comment: this.props.comment, type });
  };

  closeReactionModal = event => {
    event.preventDefault();
    this.setState({ openReactionModal: false });
  };

  onClickReportComment = event => {
    this.props.sendReportComment({ comment: this.props.comment });
  };

  render() {
    const { comment, theme, t, user } = this.props;
    const { angry, haha, like, love, wow, total } = comment.reactions;
    const { viewer_reaction } = comment;
    return (
      <Container>
        <FlexParent alignItems={'center'}>
          <FlexItem style={{ zIndex: 0 }}>
            <Avatar profile_picture_url={comment.author.profile_picture_url} theme={theme} />
          </FlexItem>
          <FlexItem grow={1}>
            <Name>{comment.author.name || t('smartqa_anonymous')}</Name>
            <Hour>{getTimeFromNow(comment.created_at)}</Hour>
          </FlexItem>
          <Icon
            icon={IconReport}
            width={'25px'}
            height={'25px'}
            color={'#b0b0b0'}
            margin={'0 0 0 5px'}
            padding={'4px'}
            onClick={this.onClickReportComment}
          />
        </FlexParent>

        <Message>
          <HtmlContent>{nl2br(comment.text)}</HtmlContent>
        </Message>
        {comment.picture_url && <CommentPicture src={comment.picture_url} />}

        <FlexParent alignItems={'center'}>
          <FlexItem>
            {viewer_reaction ? (
              <ViewerReaction
                onClick={this.onClickUnlike}
                reaction={viewer_reaction}
                t={t}
                isComment={true}
              />
            ) : (
              <React.Fragment>
                <PopupReaction
                  open={this.state.openReactionModal}
                  onClose={this.closeReactionModal}
                  onValidate={this.sendReactionComment}
                  theme={theme}
                  t={t}
                />
                {user ? (
                  <CommentLike onClick={this.onClickLike}>{t('smartqa_like')}</CommentLike>
                ) : null}
              </React.Fragment>
            )}
          </FlexItem>
          {total && user ? <Bullet>•</Bullet> : null}
          <Reactions {...{ angry, haha, like, love, wow }} />
        </FlexParent>
      </Container>
    );
  }
}

export default Comment;
