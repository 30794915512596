import styled from 'styled-components';
import React from 'react';
import Input from '../form/Input';
import Textarea from '../form/Textarea';
import Select from '../form/Select';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Card from '../card/Card';
import FlexParent from '../flex/FlexParent';
import HtmlContent from '../HtmlContent';

const Label = styled.div`
  font-size: 1.7rem;
  font-weight: 600;
  padding: 0 0 15px 0;
  text-align: center;
  p {
    display: inline-block;
  }
`;

const SubLabel = styled.div`
  font-size: 1.2rem;
  padding: 0 0 15px 0;
  text-align: center;
  color: ${props => (props.hasError ? 'red' : props.theme.ashesGrey)};
`;

class Question extends React.Component {
  onChangeInputValue = event => {
    this.props.onChangeInputValue(
      this.props.question.id,
      event.target.value,
      this.props.question.type
    );
  };

  render() {
    const { t, question, alignment } = this.props;
    const { type, label, is_required } = question;

    return label ? (
      <Card padding={'15px'} margin={'0 0 15px 0'}>
        <Label>
          <HtmlContent>{label + (is_required ? ' *' : '')}</HtmlContent>
        </Label>
        {type === 'inputtext' ? (
          <Input
            placeholder={t('survey_input_ph')}
            name={question.id}
            onChange={this.onChangeInputValue}
            value={this.props.answer.value}
          />
        ) : type === 'textarea' ? (
          <Textarea
            placeholder={t('survey_input_ph')}
            name={question.id}
            onChange={this.onChangeInputValue}
            value={this.props.answer.value}
          />
        ) : type === 'radio' ? (
          <React.Fragment>
            <SubLabel>
              {is_required ? t('mandatory') + ', ' : ''}
              {t('max_choices', { count: 1 })}
            </SubLabel>
            <FlexParent
              justifyContent={alignment === 'horizontal' ? 'left' : 'space-evenly'}
              wrap={'wrap'}
            >
              {question.options
                ? question.options.map(option => {
                    return (
                      <Radio
                        id={option.id}
                        name={question.id}
                        key={option.id}
                        value={option.id}
                        alignment={alignment}
                        label={
                          (!this.props.type ||
                            ['value', 'picture+value'].indexOf(this.props.type) !== -1) &&
                          option.value
                            ? option.value
                            : null
                        }
                        picture={
                          ['picture', 'picture+value'].indexOf(this.props.type) !== -1 &&
                          option.picture_url
                            ? option.picture_url
                            : null
                        }
                        onChange={this.onChangeInputValue}
                        checked={
                          this.props.answer.options &&
                          this.props.answer.options.indexOf(+option.id) !== -1
                        }
                      />
                    );
                  })
                : null}
            </FlexParent>
          </React.Fragment>
        ) : type === 'checkbox' ? (
          <React.Fragment>
            {question.max_choices ? (
              <SubLabel
                hasError={
                  question.max_choices &&
                  this.props.answer.options &&
                  this.props.answer.options.length > question.max_choices
                }
              >
                {is_required ? t('mandatory') + ', ' : ''}
                {t('max_choices', { count: question.max_choices })}
              </SubLabel>
            ) : question.type === 'checkbox' ? (
              <SubLabel>{t('multiple_choices')}</SubLabel>
            ) : null}

            <FlexParent justifyContent={'space-evenly'} wrap={'wrap'}>
              {question.options
                ? question.options.map(option => {
                    return (
                      <Checkbox
                        id={option.id}
                        name={question.id}
                        key={option.id}
                        value={option.id}
                        label={
                          (!this.props.type ||
                            ['value', 'picture+value'].indexOf(this.props.type) !== -1) &&
                          option.value
                            ? option.value
                            : null
                        }
                        picture={
                          ['picture', 'picture+value'].indexOf(this.props.type) !== -1 &&
                          option.picture_url
                            ? option.picture_url
                            : null
                        }
                        changeInputValue={this.onChangeInputValue}
                        checked={
                          this.props.answer.options &&
                          this.props.answer.options.indexOf(+option.id) !== -1
                        }
                        isLocked={
                          question.max_choices &&
                          this.props.answer.options.length >= question.max_choices
                        }
                      />
                    );
                  })
                : null}
            </FlexParent>
          </React.Fragment>
        ) : type === 'select' ? (
          <Select
            value={(this.props.answer.options && this.props.answer.options[0]) || ''}
            className={this.props.answer.options.length ? '' : 'select_option'}
            onChange={this.onChangeInputValue}
          >
            <option disabled hidden value="">
              {t('select_option')}
            </option>
            {question.options
              ? question.options.map(option => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.value}
                    </option>
                  );
                })
              : null}
          </Select>
        ) : null}
      </Card>
    ) : null;
  }
}

export default Question;
