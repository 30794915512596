import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

let Mixpanel = {
  identify: id => {
    mixpanel.identify(id);
  },
  alias: id => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    const { viewableId, viewableType, appId, eventId, userId } = props;
    let platform = 'Web';
    if (window.cordova && window.device) {
      if (window.device.platform.toLowerCase().indexOf('android') !== -1) {
        platform = 'Android';
      } else if (window.device.platform.toLowerCase().indexOf('ios') !== -1) {
        platform = 'iOS';
      }
    }

    mixpanel.track(name, {
      viewable_id: viewableId,
      viewable_type: viewableType,
      app_id: appId,
      event_id: eventId,
      attendee_id: userId,
      env: process.env.REACT_APP_ENV,
      platform,
      user_agent: navigator.userAgent,
      date_now: Date.now()
    });
  },
  people: {
    set: props => {
      mixpanel.people.set(props);
    }
  }
};

export default Mixpanel;
