import { SUCCESS, FETCH_TIMELINE_ITEMS } from '../constants/actionApi';
import { SC_TIMELINE_ITEM, DELETED } from '../constants/socket';
import { addOrUpdateItemsInState, removeItemInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.timelineItems, { type, payload }) => {
  switch (type) {
    case FETCH_TIMELINE_ITEMS + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    case SC_TIMELINE_ITEM + DELETED:
      return removeItemInState(state, payload.data.id);
    default:
      return state;
  }
};
