import { UPDATE_HEADER } from '../constants/actionLocal';
import initialState from '../store/initialState';
import { updateObjectInState } from '../helpers/data';

export default (state = initialState.header, { type, payload }) => {
  switch (type) {
    case UPDATE_HEADER:
      return updateObjectInState(state, payload);
    default:
      return state;
  }
};
