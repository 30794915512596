import {
  SUCCESS,
  FETCH_WHOS_HERE_MEETINGS,
  FETCH_WHOS_HERE_MEETING,
  DELETE_WHOS_HERE_MEETING,
  PUT_WHOS_HERE_MEETING,
  POST_WHOS_HERE_MEETING_STATUS
} from '../constants/actionApi';
import { addOrUpdateItemsInState, removeItemInState } from '../helpers/data';
import initialState from '../store/initialState';
import produce from 'immer';
import { SC_WHOS_HERE_MEETING, UPDATED, CREATED } from '../constants/socket';

export default (state = initialState.whosHereMeetings, { type, payload }) => {
  let userInConversation;
  switch (type) {
    case FETCH_WHOS_HERE_MEETINGS + SUCCESS:
    case FETCH_WHOS_HERE_MEETING + SUCCESS:
    case PUT_WHOS_HERE_MEETING + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);

    case SC_WHOS_HERE_MEETING + CREATED:
      userInConversation =
        payload.data.attendees.find(attendee => attendee.id === payload.user.id) ||
        payload.data.owner.id === payload.user.id;
      if (userInConversation) {
        return addOrUpdateItemsInState(state, payload.data);
      } else {
        return state;
      }
    case SC_WHOS_HERE_MEETING + UPDATED:
      userInConversation =
        payload.data.attendees.find(attendee => attendee.id === payload.user.id) ||
        payload.data.owner.id === payload.user.id;
      if (userInConversation) {
        return addOrUpdateItemsInState(state, payload.data);
      } else if (!userInConversation && state[payload.data.id]) {
        return removeItemInState(state, payload.data.id);
      } else {
        return state;
      }

    case DELETE_WHOS_HERE_MEETING + SUCCESS:
      return removeItemInState(state, payload.params.meeting_id);
    case POST_WHOS_HERE_MEETING_STATUS:
      const nextState = produce(state, draftState => {
        draftState[payload.meeting_id].viewer_status = payload.status;
        return draftState;
      });
      return nextState;
    default:
      return state;
  }
};
