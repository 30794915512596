import { SUCCESS, FETCH_SPONSORS, FETCH_SPONSOR } from '../constants/actionApi';
import { UPDATE_FAV_MODEL } from '../constants/actionLocal';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.sponsors, { type, payload }) => {
  switch (type) {
    case FETCH_SPONSORS + SUCCESS:
    case FETCH_SPONSOR + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    case UPDATE_FAV_MODEL:
      if (payload.type === 'sponsor') {
        return addOrUpdateItemsInState(state, payload.data);
      } else {
        return state;
      }
    default:
      return state;
  }
};
