import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M24.3,2.8h-1.9h-4.3c0-1.2-1.1-2.3-2.5-2.3h-6c-1.4,0-2.5,1-2.5,2.3H2.8H0.9v2h1.8L4,22.2c0,1.3,1.1,2.3,2.5,2.3h12.1 c0.7,0,1.4-0.3,1.9-0.8c0.4-0.4,0.6-0.9,0.6-1.4l1.3-17.5h1.8V2.8z M7.1,4.7h11h2.4l-1.3,17.4c0,0.1-0.2,0.3-0.6,0.3H6.5 c-0.3,0-0.6-0.2-0.6-0.4L4.7,4.7H7.1z M9.1,2.8c0-0.1,0.2-0.3,0.6-0.3h6c0.3,0,0.5,0.2,0.6,0.3H9.1z" />
      <rect x="11.6" y="6.2" width="2" height="14.7" />
      <polygon points="10.3,20.9 9.1,6.2 7.2,6.3 8.4,21 " />
      <polygon points="18,6.3 16.1,6.2 14.9,20.9 16.9,21 " />
    </g>
  </svg>
);
