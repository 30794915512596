import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M21.3,10.5c-0.1-1.7-0.8-3.2-2-4.4C18,4.7,16.2,4,14.3,4c-1.8,0-3.4,0.7-4.7,1.8L11,7.1c0.9-0.8,2.1-1.2,3.4-1.2 c1.4,0,2.7,0.5,3.7,1.5s1.5,2.3,1.5,3.7c0,0.1,0,0.1,0,0.2v0.8l0.8,0.1c1.6,0.2,2.8,1.6,2.8,3.2c0,1-0.5,1.9-1.2,2.4l1.3,1.3 c1-0.9,1.7-2.2,1.7-3.7C25,13.1,23.5,11.1,21.3,10.5z" />
      <path d="M4.8,3.6c-0.4-0.4-0.9-0.4-1.3,0C3.1,4,3.1,4.5,3.5,4.9l2.4,2.3c-1.4,0.4-2.5,1.6-2.9,3C1.2,11,0,12.9,0,15 c0,3,2.4,5.4,5.4,5.4h14.1l2.5,2.4c0.3,0.4,0.9,0.3,1.3,0c0.4-0.4,0.4-0.9,0-1.3L4.8,3.6z M5.4,18.5c-2,0-3.5-1.6-3.5-3.5 c0-1.5,1-2.8,2.4-3.3l0.6-0.2v-0.6c0.1-1.1,1.1-2,2.2-2c0.2,0,0.4,0,0.6,0.1l9.8,9.5H5.4z" />
    </g>
  </svg>
);
