import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M11.76,16.83l0.42,0.06c0.19,0.01,0.39,0.01,0.58-0.01l0.35-0.06 c1.58-0.23,2.91-1.23,3.57-2.67l0.07-0.15c0.08-0.21,0.14-0.4,0.19-0.59l0.04-0.14c0.09-0.38,0.12-0.7,0.13-1.02l0.01-0.08 c0-0.08-0.01-0.16-0.01-0.23l-0.06-0.38c-0.07-0.46-0.17-0.83-0.31-1.16l-6.11,6.1C10.97,16.66,11.35,16.77,11.76,16.83" />
      <path d="M1.62,15.83l0.1,0.11c0.28,0.29,0.57,0.57,0.87,0.83 c0.17,0.15,0.26,0.23,0.35,0.31c0.41,0.34,0.79,0.63,1.16,0.88l1.29-1.29c-0.39-0.25-0.78-0.53-1.2-0.87 c-0.12-0.1-0.24-0.2-0.36-0.3c-0.54-0.47-0.96-0.89-1.34-1.32l-0.26-0.34l0.25-0.36c0.44-0.51,0.96-1.01,1.62-1.56 c0.13-0.11,0.26-0.21,0.39-0.31c0.62-0.48,1.22-0.87,1.83-1.19c0.47-0.25,0.9-0.45,1.32-0.62L8.88,9.3l-0.54,1.2 c-0.15,0.34-0.26,0.75-0.34,1.24l-0.07,0.45c0,0.07-0.01,0.14-0.01,0.21c0,0.34,0.04,0.67,0.11,0.99l0.06,0.2 c0.03,0.1,0.06,0.19,0.09,0.29l6.63-6.62l-0.15-0.03c-0.15-0.03-0.31-0.05-0.46-0.07c-1.2-0.13-2.09-0.13-3.24,0 c-0.16,0.02-0.31,0.04-0.46,0.07L10.07,7.3c-0.01,0-0.02,0-0.03,0L9.72,7.37C8,7.71,6.37,8.33,4.87,9.23L4.5,9.45l0,0 c-0.2,0.13-0.4,0.26-0.59,0.4c-0.25,0.18-0.49,0.36-0.73,0.55c-0.23,0.18-0.4,0.32-0.56,0.46c-0.26,0.23-0.51,0.47-0.75,0.72 L1.7,11.74c-0.09,0.09-0.18,0.17-0.26,0.27c-0.37,0.4-0.71,0.82-1.02,1.24L0,13.82l0.38,0.52c0.29,0.4,0.63,0.81,1.04,1.26 C1.48,15.68,1.55,15.75,1.62,15.83" />
      <path d="M24.54,13.08c-0.36-0.48-0.69-0.89-1.06-1.29c-0.09-0.1-0.18-0.18-0.27-0.27 l-0.16-0.16c-0.25-0.25-0.49-0.49-0.75-0.72c-0.17-0.15-0.34-0.29-0.51-0.42c-0.29-0.23-0.53-0.42-0.78-0.59 c-0.21-0.15-0.42-0.29-0.64-0.43l-0.32-0.19c-0.45-0.27-0.91-0.5-1.38-0.72l-1.32,1.32c0.39,0.16,0.79,0.35,1.23,0.58 c0.61,0.33,1.21,0.72,1.83,1.19c0.11,0.09,0.23,0.18,0.34,0.27c0.71,0.58,1.22,1.08,1.67,1.6l0.28,0.32L22.37,14 c-0.33,0.39-0.75,0.81-1.29,1.28c-0.11,0.1-0.23,0.2-0.35,0.3c-0.52,0.42-1.01,0.77-1.48,1.06c-1.66,1.01-3.55,1.66-5.45,1.87 c-0.46,0.05-0.93,0.08-1.41,0.08h-0.02h-0.11c-0.46,0-0.94-0.03-1.39-0.08c-0.67-0.08-1.34-0.22-2-0.4l-1.43,1.43 c0.39,0.13,0.78,0.25,1.18,0.35l0.29,0.07c0.45,0.1,0.86,0.17,1.19,0.22l0.2,0.04c0.06,0.01,0.12,0.03,0.18,0.03 c1.27,0.16,2.51,0.15,3.7,0c0.06-0.01,0.12-0.02,0.18-0.03l0.2-0.04c0.37-0.05,0.83-0.13,1.31-0.24l0.18-0.05 c1.67-0.43,3.24-1.12,4.65-2.06l0.14-0.09c0.39-0.27,0.76-0.55,1.14-0.87c0.09-0.08,0.17-0.15,0.26-0.22l0.1-0.09 c0.3-0.26,0.59-0.54,0.87-0.84l0.31-0.33c0.41-0.45,0.75-0.87,1.04-1.26l0.38-0.52L24.54,13.08z" />
      <rect
        x="-0.48"
        y="11.77"
        transform="matrix(0.7065 -0.7077 0.7077 0.7065 -5.5525 12.0703)"
        width="24.5"
        height="1.92"
      />
    </g>
  </svg>
);
