import React from 'react';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import styled from 'styled-components';
import HtmlContent from '../../../components/shared/HtmlContent';
import { nl2br } from '../../../helpers/format';
import { getTime, applyTimezone } from '../../../helpers/date';
import { motion, AnimatePresence } from 'framer-motion';

const Message = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;
  padding: 10px 15px;
  border-radius: 15px;
  background-color: ${props => (props.userIsOwner ? '#0078e3' : '#ededed')};
  margin-right: ${props => (props.userIsOwner ? '' : '15px')};
  margin-left: ${props => (props.userIsOwner ? '15px' : '')};
  margin-top: 15px;
  color: ${props => (props.userIsOwner ? 'white' : 'black')};
`;

const NameAndTime = styled.div`
  font-size: 1.2rem;
  color: ${props => props.theme.ashesGrey};
  padding: 3px;
  text-align: ${props => (props.userIsOwner ? 'right' : 'left')};
`;

const MessageContainer = styled(motion.div)`
  transform-origin: center;
`;

const PictureContainer = styled.div`
  height: 180px;
  margin-right: ${props => (props.userIsOwner ? '' : '15px')};
  margin-left: ${props => (props.userIsOwner ? '15px' : '')};
  margin-top: 15px;
`;

const Picture = styled.img`
  display: block;
  margin: 10px 0 10px 0;
  border-radius: 15px;
  object-fit: contain;
  max-height: 100%;
`;

class MessageItem extends React.Component {
  onClickPicture = event => {
    event.stopPropagation();
    this.props.showFullScreenPicture({ url: this.props.message.picture_url });
  };

  render() {
    const { message, user, event } = this.props;
    const userIsOwner = message.attendee.id === user.id;
    const time = getTime(applyTimezone(message.created_at, event.timezone));
    return (
      <React.Fragment>
        {message.picture_url && (
          <FlexParent>
            {userIsOwner ? <FlexItem grow={1} /> : null}
            <FlexItem>
              <AnimatePresence>
                <MessageContainer
                  key={`message_${this.props.id}`}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                >
                  <PictureContainer userIsOwner={userIsOwner}>
                    <Picture src={message.picture_url} onClick={this.onClickPicture} />
                  </PictureContainer>
                </MessageContainer>
              </AnimatePresence>
            </FlexItem>
            {userIsOwner ? null : <FlexItem grow={1} />}
          </FlexParent>
        )}

        {message.text && (
          <FlexParent>
            {userIsOwner ? <FlexItem grow={1} /> : null}
            <FlexItem>
              <AnimatePresence>
                <MessageContainer
                  key={`message_${this.props.id}`}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                >
                  <Message userIsOwner={userIsOwner}>
                    <HtmlContent>{nl2br(message.text)}</HtmlContent>
                  </Message>
                </MessageContainer>
              </AnimatePresence>
            </FlexItem>
            {userIsOwner ? null : <FlexItem grow={1} />}
          </FlexParent>
        )}

        <NameAndTime userIsOwner={userIsOwner}>
          {userIsOwner ? '' : `${message.attendee.name} - `}
          {time}
        </NameAndTime>
      </React.Fragment>
    );
  }
}

export default MessageItem;
