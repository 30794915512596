import React from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import AgendaContainer from './pages/agenda/AgendaContainer';
import AgendaSessionContainer from './pages/agenda-session/SessionContainer';
import BonusListContainer from './pages/bonus-list/BonusListContainer';
import ExhibitorListContainer from './pages/exhibitor-list/ExhibitorListContainer';
import FavoriteContainer from './pages/favorite/FavoriteContainer';
import EventContainer from './pages/event/EventContainer';
import AppContainer from './pages/app/AppContainer';
import SandboxContainer from './pages/_sandbox/SandboxContainer';
import MapContainer from './pages/map/MapContainer';
import NotFound from './pages/not-found/NotFoundContainer';
import PracticalInfoContainer from './pages/practical-info/PracticalInfoContainer';
import SmartqaContainer from './pages/smartqa/SmartqaContainer';
import SmartqaPostContainer from './pages/smartqa-post/SmartqaPostContainer';
import SocialMediaContainer from './pages/social-media/SocialMediaContainer';
import SpeakerListContainer from './pages/speaker-list/SpeakerListContainer';
import SponsorListContainer from './pages/sponsor-list/SponsorListContainer';
import SurveyContainer from './pages/survey/SurveyContainer';
import TimelineContainer from './pages/timeline/TimelineContainer';
import VoteContainer from './pages/vote/VoteContainer';
import WhosHereContainer from './pages/whos-here/WhosHereContainer';

import UserSigninContainer from './pages/user/signin/UserSigninContainer';
import UserProfileContainer from './pages/user/profile/UserProfileContainer';
import UserProfileEditContainer from './pages/user/profile-edit/UserProfileEditContainer';
import UserRegisterContainer from './pages/user/register/UserRegisterContainer';
import UserSettingsContainer from './pages/user/settings/SettingsContainer';
import UserQrCodeContainer from './pages/user/qr-code/UserQrCodeContainer';
import UserResetPasswordContainer from './pages/user/reset-password/ResetPasswordContainer';
import RedirectContainer from './components/redirect/RedirectContainer';
import AnimatorContainer from './pages/animator/AnimatorContainer';

import Layout from './components/layout/Layout';

const Router = () => (
  <HashRouter>
    <Switch>
      <Layout path={'/apps/:appId'} exact={true} component={AppContainer} />
      <Layout path={'/apps/:appId/tab/:currentTab?'} exact={true} component={AppContainer} />
      <Layout
        path={'/apps/:appId/signin/:status?/:token?'}
        exact={true}
        component={UserSigninContainer}
      />

      <Layout path={'/apps/:appId/register'} exact={true} component={UserRegisterContainer} />
      <Layout
        path={'/apps/:appId/profile'}
        exact={true}
        component={UserProfileContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/profile-edit'}
        exact={true}
        component={UserProfileEditContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/user-settings'}
        exact={true}
        component={UserSettingsContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/reset-password/:token'}
        exact={true}
        component={UserResetPasswordContainer}
        protected={false}
        notProtected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/signin'}
        exact={true}
        component={UserSigninContainer}
        notProtected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/register'}
        exact={true}
        component={UserRegisterContainer}
        notProtected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/profile'}
        exact={true}
        component={UserProfileContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/profile-edit'}
        exact={true}
        component={UserProfileEditContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/user-settings'}
        exact={true}
        component={UserSettingsContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/user-qr-code'}
        exact={true}
        component={UserQrCodeContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/reset-password/:token'}
        exact={true}
        component={UserResetPasswordContainer}
        protected={false}
        notProtected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/animator/:smartqaId?'}
        exact={true}
        component={AnimatorContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId'}
        exact={true}
        component={EventContainer}
        notProtected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/home'}
        exact={true}
        component={EventContainer}
        notProtected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/sandbox'}
        exact={true}
        component={SandboxContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/agendas/:agendaId'}
        exact={true}
        component={AgendaContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/agendas/:agendaId/sessions/:sessionId'}
        exact={true}
        component={AgendaSessionContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/bonus_lists/:bonusListId'}
        exact={true}
        component={BonusListContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/exhibitor_lists/:exhibitorListId/:exhibitorId?'}
        exact={true}
        component={ExhibitorListContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/maps/:mapId'}
        exact={true}
        component={MapContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/practical_infos/:practicalInfoId'}
        exact={true}
        component={PracticalInfoContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/smartqa/:smartqaId'}
        exact={true}
        component={SmartqaContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/instaphoto/:smartqaId'}
        exact={true}
        component={SmartqaContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/smartqa/:smartqaId/posts/:postId'}
        exact={true}
        component={SmartqaPostContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/social_medias/:socialMediaId/:currentTab?'}
        exact={true}
        component={SocialMediaContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/speaker_lists/:speakerListId/:speakerId?'}
        exact={true}
        component={SpeakerListContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/sponsor_lists/:sponsorListId/:sponsorId?'}
        exact={true}
        component={SponsorListContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/surveys/:surveyId'}
        exact={true}
        component={SurveyContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/timelines/:timelineId'}
        exact={true}
        component={TimelineContainer}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/votes/:voteId'}
        exact={true}
        component={VoteContainer}
      />
      <Layout
        path={
          '/apps/:appId/events/:eventId/whos_here/:whosHereId/:currentTab?/:currentView?/:currentId?'
        }
        exact={true}
        component={WhosHereContainer}
        protected={true}
      />
      <Layout
        path={'/apps/:appId/events/:eventId/favorites/:currentTab?'}
        exact={true}
        component={FavoriteContainer}
      />

      <Layout path={'/apps/:appId/events/:eventId'} exact={false} component={NotFound} />
      <Route path={''} exact={true} component={RedirectContainer} />
      <Layout exact={true} component={NotFound} />
    </Switch>
  </HashRouter>
);

export default Router;
