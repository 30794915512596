import PracticalInfoPart from './PracticalInfoPart';
import React from 'react';
import ServiceHeader from '../../components/shared/ServiceHeader';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class PracticalInfo extends React.Component {
  render() {
    const { t, theme } = this.props;
    const parts = this.props.practicalInfo.parts || [];
    return (
      <React.Fragment>
        <ServiceHeader
          t={t}
          icon={getServiceIconByType(this.props.practicalInfo.custom_icon || 'practicalinfo')}
          name={this.props.practicalInfo.name}
          description={this.props.practicalInfo.description}
          theme={theme}
        />
        {parts.map((part, index) => {
          return (
            <PracticalInfoPart
              key={part.id}
              part={part}
              theme={this.props.theme}
              onClickItem={this.props.onClickItem}
              openedId={this.props.openedId}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

export default PracticalInfo;
