import { createSelector } from 'reselect';
import { filterObjects, filterIds, hasMore, isLoading } from '../helpers/data';
import { API_SPONSORS } from '../constants/apiRoutes';

const currentSponsorListId = (state, props) => props.match.params.sponsorListId;
const currentSponsorId = (state, props) => props.match.params.sponsorId;
const sponsorLists = (state, props) => state.sponsorLists;
const sponsors = (state, props) => state.sponsors;
const sponsorsIds = (state, props) => state.sortIds[API_SPONSORS] || [];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;
// --- Select current object --------------
export const selectCurrentSponsorList = createSelector(
  [sponsorLists, currentSponsorListId],
  (sponsorLists, currentSponsorListId) => {
    return sponsorLists[currentSponsorListId] || null;
  }
);

export const selectCurrentSponsor = createSelector(
  [sponsors, currentSponsorId],
  (sponsors, sponsorId) => {
    return sponsors[sponsorId] || null;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredSponsors = createSelector(
  [currentSponsorListId, sponsors],
  (sponsorListId, sponsors) => {
    return filterObjects(sponsors, sponsor => {
      return sponsor.list_id === +sponsorListId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredSponsorsIds = createSelector(
  [sponsorsIds, selectFilteredSponsors],
  (sponsorsIds, sponsors) => {
    return filterIds(sponsorsIds, sponsors);
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMoreSponsors = createSelector(
  [currentSponsorListId, pagination],
  (listId, pagination) => {
    return hasMore(pagination, listId, API_SPONSORS);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingSponsors = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_SPONSORS);
  }
);
// ----------------------------------------
