import { getEventFormatedDate, getEventVenue } from '../../helpers/format';
import { getEventSplitedDate } from '../../helpers/date';
import { getEventIconBySize } from '../../helpers/format';
import { isDarkBgColor } from '../../helpers/helpers';
import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import React from 'react';
import styled from 'styled-components';
import Disclaimer from '../../components/shared/Disclaimer';
import ShortLink from './ShortLink';
import ShowMore from '../../components/shared/ShowMore';
import Button from '../../components/shared/Button';

const Top = styled.div`
  background-color: ${props => props.eventColor};
  min-height: ${props => (props.cover ? `300px` : 'initial')};
  background-image: ${props => (props.cover ? `url(${props.cover})` : 'initial')};
  background-size: cover;
  background-position: center;
`;

const Bottom = styled(FlexParent)`
  background-color: ${props => props.eventColor};
  color: ${props => {
    return isDarkBgColor(props.eventColor) ? 'white' : props.theme.darkGrey;
  }};
  padding: 15px;
`;

const EventLogo = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background-color: #ffffff;
  display: block;
  padding: 3px;
`;

const Overlay = styled.div`
  background-image: ${props =>
    props.cover ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 95%)' : 'none'};
`;

const EventName = styled.div`
  position: relative;
  color: #efefef;
  text-shadow: 0 1px 0 black;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 15px 15px 8px;
`;

const EventTagline = styled.div`
  position: relative;
  color: white;
  text-shadow: 0 1px 0 black;
  font-size: 2.4rem;
  line-height: 2.5rem;
  font-weight: bold;
  padding: 0 15px 15px;
`;

const EventDate = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  padding: 2px 0;
`;
const EventVenue = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 0;
`;

const EventDescription = styled.div`
  text-align: justify;
`;

class Event extends React.Component {
  onClickLogin = () => {
    const { history, matchParams } = this.props;
    const { appId, eventId } = matchParams;
    history.push(`/apps/${appId}/events/${eventId}/signin`);
  };

  render() {
    const { event, t, theme, matchParams, shortLinks } = this.props;
    const eventSplitedDate = getEventSplitedDate(event.start_date, event.end_date);
    const eventFormatedDate = getEventFormatedDate(eventSplitedDate);

    return (
      <React.Fragment>
        <Card margin={'0 -10px 20px -10px'}>
          <Top cover={event.cover_url} eventColor={event.color}>
            <Overlay cover={event.cover_url}>
              <EventName>{event.name}</EventName>
              {event.tagline && <EventTagline>{event.tagline}</EventTagline>}
            </Overlay>
          </Top>
          <Bottom alignItems={'center'} justifyContent={'space-between'} eventColor={event.color}>
            {event.icon_url && (
              <FlexItem width={'48px'} margin={'0 15px 0 0'}>
                <EventLogo src={getEventIconBySize(event.icon_url, '144')} />
              </FlexItem>
            )}
            <FlexItem grow={1}>
              <EventDate>{eventFormatedDate}</EventDate>
              {event.venue && <EventVenue>{getEventVenue(event.venue)}</EventVenue>}
            </FlexItem>
          </Bottom>
        </Card>

        {['unauthorized', 'forbidden'].indexOf(event.access_status) !== -1 && (
          <Card
            margin={'0 10px 20px'}
            padding={'15px'}
            textAlign={'center'}
            backgroundColor={event.color}
            color={isDarkBgColor(event.color) ? 'white' : theme.darkGrey}
            fontWeight={'bold'}
            position={'relative'}
          >
            {event.access_status === 'unauthorized' ? (
              <React.Fragment>
                {t('draft_not_logged_in')}
                <Button
                  btntype={'white'}
                  margin={'10px auto 0 auto'}
                  display={'block'}
                  onClick={this.onClickLogin}
                >
                  {t('signin')}
                </Button>
              </React.Fragment>
            ) : event.access_status === 'forbidden' ? (
              <React.Fragment>{t('draft_logged_in')}</React.Fragment>
            ) : null}
          </Card>
        )}

        {event.description && (
          <Card margin={'0 10px 20px'} padding={'15px'}>
            <EventDescription>
              <ShowMore t={t}>{event.description}</ShowMore>
            </EventDescription>
          </Card>
        )}

        {shortLinks
          ? Object.keys(shortLinks).map((key, index) => {
              const shortLink = shortLinks[key];
              return <ShortLink shortLink={shortLink} key={key} matchParams={matchParams} />;
            })
          : null}

        <Disclaimer label={t('read_cgv')} {...{ t, matchParams }} />
      </React.Fragment>
    );
  }
}

export default Event;
