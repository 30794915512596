import React from 'react';
import styled from 'styled-components';
import FlexItem from '../flex/FlexItem';
import { motion } from 'framer-motion';
import HtmlContent from '../HtmlContent';

const Container = styled.div`
  margin: 0 0 20px 0;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled(motion.label)`
  padding-left: 5px;
  display: block;
  text-align: center;
  background-color: ${props => props.theme.lightGrey};
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: normal;
  padding: 8px 5px;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

const Input = styled.input`
  display: none;
`;

const ImageContainer = styled(FlexItem)`
  max-width: 45%;
  margin: 0 1% 20px;
  cursor: pointer;
`;

const ImageLabel = styled.label`
  img {
    width: 100%;
    margin: 0;
    display: block;
    border-radius: 8px;
  }

  div {
    padding-top: 10px;
    text-align: center;
    font-weight: 300;
    font-size: 1.4rem;
  }
`;

class Checkbox extends React.Component {
  state = {
    showLocked: false
  };

  onChange = event => {
    if (this.props.isLocked && !this.props.checked) {
      this.setState({
        showLocked: true
      });
      return false;
    }
    this.props.changeInputValue(event);
  };

  onAnimationComplete = () => {
    this.setState({
      showLocked: false
    });
  };

  render() {
    const { id, label, picture, checked, isLocked } = this.props;
    return !picture ? (
      <Container>
        <Input type="checkbox" onChange={this.onChange} {...this.props} />
        <Label
          htmlFor={id}
          key={`checkbox_${id}`}
          initial={false}
          animate={
            isLocked && this.state.showLocked ? 'showLocked' : checked ? 'selected' : 'unselected'
          }
          variants={{
            showLocked: {
              backgroundColor: ['rgb(200,50,50)', 'rgb(237,237,237)'],
              x: [4, -3, 2, -1, 1, 0]
            },
            selected: {
              backgroundColor: 'rgb(176,176,176)'
            },
            unselected: {
              backgroundColor: 'rgb(237,237,237)'
            }
          }}
          onAnimationComplete={this.onAnimationComplete}
        >
          <HtmlContent>{label}</HtmlContent>
        </Label>
      </Container>
    ) : (
      <ImageContainer grow={'1'} shrink={'0'}>
        <Input type="checkbox" onChange={this.onChange} {...this.props} />
        <ImageLabel htmlFor={id}>
          {picture && (
            <motion.img
              src={picture}
              alt=""
              key={`checkbox_${id}`}
              initial={false}
              animate={
                isLocked && this.state.showLocked
                  ? 'showLocked'
                  : checked
                  ? 'selected'
                  : 'unselected'
              }
              onAnimationComplete={this.onAnimationComplete}
              variants={{
                showLocked: {
                  rotate: ['3deg', '-3deg', '3deg', '-3deg', '3deg', '0'],
                  transition: { duration: 0.3 }
                },
                selected: {
                  scale: 0.9,
                  opacity: 0.75,
                  transition: { type: 'spring', duration: 0.2, mass: 1, stiffness: 100 }
                },
                unselected: {
                  scale: 1,
                  opacity: 1,
                  transition: { type: 'spring', duration: 0.2, mass: 1, stiffness: 100 }
                }
              }}
            />
          )}
          {label && <div>{label}</div>}
        </ImageLabel>
      </ImageContainer>
    );
  }
}

export default Checkbox;
