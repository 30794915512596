import React from 'react';
import styled from 'styled-components';
import FlexItem from './flex/FlexItem';
import FlexParent from './flex/FlexParent';
import Hr from './Hr';
import Scrollable from './Scrollable';
import { typesToRoutes } from '../../constants/app';
import { getServiceIconByType } from '../icons/ServiceIcons';
import Icon from '../icons/Icon';

const ItemHeading = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 0 15px;
  text-align: center;
`;

const ServiceName = styled.div`
  text-align: center;
  font-size: 1.2rem;
  padding: 5px 10px;
`;

const Filer = styled(FlexItem)`
  border-bottom: 3px solid #fff;
  padding: 15px 0 0;
`;

const LinkedServices = ({ heading, servicesTypes, appId, eventId, history, theme }) => {
  return servicesTypes ? (
    <React.Fragment>
      <Hr />
      <ItemHeading>{heading}</ItemHeading>
      <Services {...{ servicesTypes, appId, eventId, history, theme }} />
    </React.Fragment>
  ) : null;
};

const Services = ({ servicesTypes, appId, eventId, history, theme }) => {
  const types = {
    instaphotos: 'instaphoto',
    smart_qa: 'smartqa',
    surveys: 'survey',
    votes: 'vote'
  };

  return (
    <Scrollable>
      <FlexParent>
        <Filer grow={1}>&nbsp;</Filer>

        {Object.entries(servicesTypes).map(([type, services]) => {
          return services
            ? services.map(service => {
                const url = `/apps/${appId}/events/${eventId}/${typesToRoutes[types[type]]}/${
                  service.id
                }`;
                return (
                  <FlexItem
                    width={window.matchMedia('(min-width: 480px)').matches ? '22%' : '40%'}
                    key={`${service.type}-${service.id}`}
                    onClick={event => {
                      history.push(url);
                    }}
                  >
                    <Icon
                      icon={getServiceIconByType(service.custom_icon || types[type])}
                      color={'#fff'}
                      padding={'12px'}
                      width={'60px'}
                      height={'60px'}
                      margin={'0 auto'}
                      borderRadius={'50%'}
                      backgroundColor={theme.mainColor}
                    />

                    <ServiceName>{service.name}</ServiceName>
                  </FlexItem>
                );
              })
            : null;
        })}

        <Filer grow={1}>&nbsp;</Filer>
      </FlexParent>
    </Scrollable>
  );
};

export default LinkedServices;
