export const SUCCESS = '_SUCCESS';
export const PAGINATION_SUCCESS = '_PAGINATION_SUCCESS';
export const FAILED = '_FAILED';

export const GET = 'get';
export const DELETE = 'delete';
export const POST = 'post';
export const PUT = 'put';
export const UPLOAD = 'upload';

// App
export const SEARCH_APP = 'SEARCH_APP';
export const FETCH_APP = 'FETCH_APP';

// Event
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENT = 'FETCH_EVENT';

// Menu
export const FETCH_MENU_ITEMS = 'FETCH_MENU_ITEMS';
export const FETCH_SHORT_LINKS = 'FETCH_SHORT_LINKS';

// Agenda
export const FETCH_AGENDA = 'FETCH_AGENDA';
export const FETCH_SESSIONS = 'FETCH_SESSIONS';
export const FETCH_SESSION = 'FETCH_SESSION';
export const FETCH_AGENDA_CATEGORIES = 'FETCH_AGENDA_CATEGORIES';

// Bonus
export const FETCH_BONUS_LIST = 'FETCH_BONUS_LIST';
export const FETCH_BONUSES = 'FETCH_BONUSES';
export const FETCH_BONUS = 'FETCH_BONUS';

// Practical
export const FETCH_PRACTICAL_INFO = 'FETCH_PRACTICAL_INFO';

// Exhibitor
export const FETCH_EXHIBITOR_LIST = 'FETCH_EXHIBITOR_LIST';
export const FETCH_EXHIBITORS = 'FETCH_EXHIBITORS';
export const FETCH_EXHIBITOR = 'FETCH_EXHIBITOR';

// Speaker
export const FETCH_SPEAKER_LIST = 'FETCH_SPEAKER_LIST';
export const FETCH_SPEAKERS = 'FETCH_SPEAKERS';
export const FETCH_SPEAKER = 'FETCH_SPEAKER';

// Sponsor
export const FETCH_SPONSORS = 'FETCH_SPONSORS';
export const FETCH_SPONSOR = 'FETCH_SPONSOR';
export const FETCH_SPONSOR_LIST = 'FETCH_SPONSOR_LIST';

// Map
export const FETCH_MAP = 'FETCH_MAP';
export const FETCH_MAP_POIS = 'FETCH_MAP_POIS';

// Survey
export const FETCH_SURVEY = 'FETCH_SURVEY';
export const FETCH_SURVEY_PAGES = 'FETCH_SURVEY_PAGES';
export const POST_SURVEY_ANSWERS = 'POST_SURVEY_ANSWERS';

// Vote
export const FETCH_VOTE = 'FETCH_VOTE';
export const FETCH_VOTE_ROUNDS = 'FETCH_VOTE_ROUNDS';
export const POST_VOTE_ANSWERS = 'POST_VOTE_ANSWERS';

// Social Media
export const FETCH_SOCIAL_MEDIA = 'FETCH_SOCIAL_MEDIA';
export const FETCH_TWEETS = 'FETCH_TWEETS';
export const FETCH_YOUTUBE_VIDEOS = 'FETCH_YOUTUBE_VIDEOS';

// Smartqa
export const FETCH_SMARTQA = 'FETCH_SMARTQA';
export const FETCH_SMARTQA_POSTS = 'FETCH_SMARTQA_POSTS';
export const FETCH_SMARTQA_POST = 'FETCH_SMARTQA_POST';
export const POST_SMARTQA_POST = 'POST_SMARTQA_POST';
export const POST_SMARTQA_POST_HIDE_TO_ANIMATORS = 'POST_SMARTQA_POST_HIDE_TO_ANIMATORS';
export const FETCH_SMARTQA_COMMENTS = 'FETCH_SMARTQA_COMMENTS';
export const POST_SMARTQA_COMMENT = 'POST_SMARTQA_COMMENT';
export const POST_SMARTQA_POST_REACTION = 'POST_SMARTQA_POST_REACTION';
export const POST_SMARTQA_COMMENT_REACTION = 'POST_SMARTQA_COMMENT_REACTION';
export const POST_SMARTQA_POST_REPORT = 'POST_SMARTQA_POST_REPORT';
export const POST_SMARTQA_COMMENT_REPORT = 'POST_SMARTQA_COMMENT_REPORT';
export const POST_FILE = 'POST_FILE';
export const POST_CAMERA_FILE = 'POST_CAMERA_FILE';
export const DELETE_SMARTQA_POST_REACTION = 'DELETE_SMARTQA_POST_REACTION';
export const DELETE_SMARTQA_COMMENT_REACTION = 'DELETE_SMARTQA_COMMENT_REACTION';

// Who's here
export const FETCH_WHOS_HERE = 'FETCH_WHOS_HERE';
export const FETCH_ATTENDEES = 'FETCH_ATTENDEES';
export const FETCH_ATTENDEE = 'FETCH_ATTENDEE';
export const FETCH_WHOS_HERE_CONVERSATIONS = 'FETCH_WHOS_HERE_CONVERSATIONS';
export const FETCH_WHOS_HERE_CONVERSATION = 'FETCH_WHOS_HERE_CONVERSATION';
export const FETCH_WHOS_HERE_MESSAGES = 'FETCH_WHOS_HERE_MESSAGES';
export const FETCH_WHOS_HERE_MEETINGS = 'FETCH_WHOS_HERE_MEETINGS';
export const FETCH_WHOS_HERE_MEETING = 'FETCH_WHOS_HERE_MEETING';
export const POST_WHOS_HERE_MESSAGE = 'POST_WHOS_HERE_MESSAGE';
export const POST_WHOS_HERE_CONVERSATION = 'POST_WHOS_HERE_CONVERSATION';
export const PUT_WHOS_HERE_CONVERSATION = 'PUT_WHOS_HERE_CONVERSATION';
export const POST_WHOS_HERE_MEETING = 'POST_WHOS_HERE_MEETING';
export const PUT_WHOS_HERE_MEETING = 'PUT_WHOS_HERE_MEETING';
export const DELETE_WHOS_HERE_MEETING = 'DELETE_WHOS_HERE_MEETING';
export const POST_WHOS_HERE_MEETING_STATUS = 'POST_WHOS_HERE_MEETING_STATUS';
export const POST_WHOS_HERE_CONVERSATION_LEAVE = 'POST_WHOS_HERE_CONVERSATION_LEAVE';
export const FETCH_WHOS_HERE_MESSAGES_UNREAD = 'FETCH_WHOS_HERE_MESSAGES_UNREAD';
export const POST_WHOS_HERE_MESSAGES_READ = 'POST_WHOS_HERE_MESSAGES_READ';

// countries
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';

// user
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USER = 'FETCH_USER';
export const POST_USER = 'POST_USER';
export const PUT_USER = 'PUT_USER';
export const POST_USER_EMAIL = 'POST_USER_EMAIL';
export const POST_USER_PWD = 'POST_USER_PWD';
export const POST_USER_AVATAR_FILE = 'POST_USER_AVATAR_FILE';
export const POST_USER_INCOGNITO = 'POST_USER_INCOGNITO';
export const POST_FORGOT_PWD = 'POST_FORGOT_PWD';
export const POST_RESET_PWD = 'POST_RESET_PWD';

// Timeline
export const FETCH_TIMELINE = 'FETCH_TIMELINE';
export const FETCH_TIMELINE_ITEMS = 'FETCH_TIMELINE_ITEMS';

// Favorite
export const FETCH_FAVORITES = 'FETCH_FAVORITES';
export const POST_FAVORITE = 'POST_FAVORITE';
export const DELETE_FAVORITE = 'DELETE_FAVORITE';

// Push
export const POST_PUSH_NOTIF_SUBSCRIPTION = 'POST_PUSH_NOTIF_SUBSCRIPTION';

// Disclaimer
export const FETCH_DISCLAIMER = 'FETCH_DISCLAIMER';

// Offline
export const FETCH_OFFLINE = 'FETCH_OFFLINE';
