import React from 'react';
import styled from 'styled-components';

/*
PROPS
----------------------------------------------------------------------------------------------
margin: <string>
padding: <string>
textAlign: <string>
onClick : <function>
----------------------------------------------------------------------------------------------
*/
const CardStyle = styled.div`
  position: relative;
  border-radius: ${props => props.borderRadius || '8px'};
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.38);
  background-color: ${props => props.backgroundColor || 'white'};
  color: ${props => props.color || 'initial'};
  font-weight: ${props => props.fontWeight || 'initial'};
  min-height: 20px;
  margin: ${props => props.margin || '0'};
  padding: ${props => props.padding || '0'};
  text-align: ${props => props.textAlign || 'initial'};
  cursor: ${props => (props.onClick ? 'pointer' : 'initial')};
  word-break: break-word;
  border: ${props => props.border || 'initial'};
`;

const Card = props => {
  return <CardStyle {...props}>{props.children}</CardStyle>;
};

export default Card;
