import Button from '../../components/shared/Button';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconCamera from '../../components/icons/IconCamera';
import IconCloseCross from '../../components/icons/IconCloseCross';
import IconInvisible from '../../components/icons/IconInvisible';
import IconPictures from '../../components/icons/IconPictures';
import IconVisible from '../../components/icons/IconVisible';
import React from 'react';
import styled from 'styled-components';
import { FileInput } from '../../components/shared/smartqa/Styled';
import Textarea from '../../components/shared/form/Textarea';
import Loader from '../../components/shared/Loader';
import { motion, AnimatePresence } from 'framer-motion';
import Card from '../../components/shared/card/Card';

const Container = styled.div`
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  position: fixed;
  ${props => (props.isInAppLive ? 'max-width:600px;margin:0 auto' : '')};
  top: ${props => (props.isInAppLive ? '0' : props.headerHeight - 1) + 'px'};
  -webkit-overflow-scrolling: touch;
  left: 0;
  right: 0;
  z-index: 20;
`;

const Form = styled.form`
  padding: 5px 15px 15px 15px;
`;

const PreviewImageContainer = styled(motion.div)`
  position: relative;
  height: 120px;
  width: 120px;
  display: block;
  margin: 10px auto;
  background-color: black;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const OfflineMessage = styled.div`
  text-align: center;
  font-size: 1.1rem;
  color: ${props => props.theme.darkGrey};
`;

class AddPost extends React.Component {
  state = {
    message: '',
    selectedFile: '',
    pictureUrl: '',
    uploading: false
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.notifsPostsCount && !prevProps.notifsPostsCount) ||
      (!this.props.notifsPostsCount && prevProps.notifsPostsCount) ||
      this.props.offline !== prevProps.offline
    ) {
      this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
    }
  }

  onChangeInput = event => {
    this.setState({ message: event.target.value });
  };

  onResizeInput = event => {
    this.setState({ message: event.target.value });
    this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
  };

  onFormSubmit = event => {
    event.preventDefault();
    const message = this.state.message;
    const pictureUrl = this.state.pictureUrl;
    this.props.postSmartqaPost({
      text: message,
      picture_url: pictureUrl
    });
    this.setState({ message: '', pictureUrl: '' });
  };

  onClickCamera = event => {
    if (window.cordova) {
      this.getPictureFromCamera();
    }
  };

  onClickVisibility = event => {
    this.props.setVisibility();
  };

  onClickPicture = event => {
    this.fileInputRef.current.click();
  };

  onChangeFile = event => {
    const file = event.target.files[0];
    this.setState({
      uploading: true
    });
    this.props.uploadPostFile({
      file,
      type: 'smartqaquestion',
      done: response => {
        if (response && response.data && response.data.url) {
          this.setState({
            pictureUrl: response.data.url
          });
        }
      },
      always: () => {
        this.setState({
          uploading: false
        });
      }
    });
    event.target.value = null;
  };

  onClickRemovePostFile = event => {
    this.setState({
      pictureUrl: ''
    });
  };

  getPictureFromCamera = () => {
    navigator.camera.getPicture(
      fileUri => {
        this.setState({
          uploading: true
        });

        this.props.uploadCameraFile({
          fileUri,
          fileName: fileUri.substr(fileUri.lastIndexOf('/') + 1),
          accessToken: this.props.accessToken,
          params: {
            type: 'smartqaquestion',
            smart_qa_id: this.props.smartqa.id,
            done: response => {
              if (response && response.data && response.data.url) {
                this.setState({
                  pictureUrl: response.data.url
                });
              }
            },
            always: () => {
              this.setState({
                uploading: false
              });
            }
          }
        });
      },
      error => {
        console.warn(error);
      },
      {
        sourceType: navigator.camera.PictureSourceType.CAMERA,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        correctOrientation: true,
        saveToPhotoAlbum: navigator.camera.PictureSourceType.CAMERA
      }
    );
  };

  render() {
    const { t, theme, user, headerHeight, smartqa, isAnonymous, isSaving, offline } = this.props;

    return (
      <Container
        headerHeight={headerHeight}
        ref={this.containerRef}
        isInAppLive={this.props.isInAppLive}
      >
        {smartqa.allows_to_ask_question ? (
          <React.Fragment>
            {offline ? (
              <Card borderRadius={'0'} padding={'10px'}>
                <OfflineMessage>{t('offline_post_message')}</OfflineMessage>{' '}
              </Card>
            ) : (
              <Form onSubmit={this.onFormSubmit}>
                <FileInput
                  accept={'image/*'}
                  ref={this.fileInputRef}
                  type="file"
                  onChange={this.onChangeFile}
                />
                <Textarea
                  type="text"
                  placeholder={
                    smartqa.question_field_placeholder || t('smartqa_add_post_placeholder')
                  }
                  value={this.state.message}
                  onResize={this.onResizeInput}
                  onChange={this.onChangeInput}
                  maxRows={5}
                />

                <AnimatePresence>
                  {this.state.pictureUrl && (
                    <PreviewImageContainer
                      key={`preview_image`}
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                    >
                      <Icon
                        icon={IconCloseCross}
                        position={'absolute'}
                        top={'5px'}
                        right={'5px'}
                        width={'20px'}
                        height={'20px'}
                        color={'#fff'}
                        padding={'5px'}
                        onClick={this.onClickRemovePostFile}
                      />
                      <PreviewImage src={this.state.pictureUrl} />
                    </PreviewImageContainer>
                  )}
                </AnimatePresence>

                {this.state.uploading && !this.state.pictureUrl ? <Loader /> : null}

                <FlexParent justifyContent={'space-between'} alignItems={'center'}>
                  <FlexParent justifyContent={'space-between'}>
                    {smartqa.allows_to_upload_picture ? (
                      <React.Fragment>
                        {navigator.camera ? (
                          <Icon
                            icon={IconCamera}
                            width={'25px'}
                            height={'25px'}
                            color={'#b0b0b0'}
                            margin={'0 6px'}
                            onClick={this.onClickCamera}
                          />
                        ) : (
                          ''
                        )}

                        <Icon
                          icon={IconPictures}
                          width={'25px'}
                          height={'25px'}
                          color={'#b0b0b0'}
                          margin={'0 6px'}
                          onClick={this.onClickPicture}
                        />
                      </React.Fragment>
                    ) : null}

                    {user && smartqa.anonymous === 'enabled' ? (
                      <Icon
                        icon={isAnonymous ? IconInvisible : IconVisible}
                        width={'25px'}
                        height={'25px'}
                        color={isAnonymous ? theme.mainColor : '#b0b0b0'}
                        margin={'0 6px'}
                        onClick={this.onClickVisibility}
                      />
                    ) : null}
                  </FlexParent>
                  <Button
                    type={'submit'}
                    btntype={'primary'}
                    margin={'3px 0'}
                    disabled={
                      (this.state.message || this.state.pictureUrl) && !this.state.uploading
                        ? false
                        : 'disabled'
                    }
                  >
                    {isSaving ? t('btn_publishing') : t('btn_publish')}
                  </Button>
                </FlexParent>
              </Form>
            )}
          </React.Fragment>
        ) : null}
      </Container>
    );
  }
}

export default AddPost;
