import { connect } from 'react-redux';
import { fetchSocialMedia, fetchTweets, fetchYoutubeVideos } from '../../actions/api';
import { updateHeader, updateGlobal } from '../../actions/local';
import SocialMedia from './SocialMedia';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';
import { TAB_TWITTER, TAB_YOUTUBE } from '../../constants/app';

import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';
import {
  selectCurrentSocialMedia,
  selectTweets,
  selectYoutubeVideos,
  selectIsLoadingTweets,
  selectIsLoadingYoutube
} from '../../selectors/socialMedia';

import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class SocialMediaContainer extends React.Component {
  state = {
    currentVideo: null
  };

  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    const { appId, eventId, socialMediaId, currentTab } = this.props.match.params;
    this.getAPiData(this.props.match.params);

    if (!currentTab && this.props.socialMedia) {
      this.props.history.replace(
        `/apps/${appId}/events/${eventId}/social_medias/${socialMediaId}/${
          this.props.socialMedia.twitter ? TAB_TWITTER : TAB_YOUTUBE
        }`
      );
    }

    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'socialmedia',
      viewableId: +socialMediaId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { appId, eventId, socialMediaId, currentTab } = this.props.match.params;
    const oldSocialMediaId = prevProps.match.params.socialMediaId;

    if (socialMediaId !== oldSocialMediaId) {
      this.getAPiData(this.props.match.params);
    }

    if (!currentTab && this.props.socialMedia) {
      this.props.history.replace(
        `/apps/${appId}/events/${eventId}/social_medias/${socialMediaId}/${
          this.props.socialMedia.twitter ? TAB_TWITTER : TAB_YOUTUBE
        }`
      );
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }
  }

  getAPiData({ eventId, socialMediaId }) {
    this.props.fetchSocialMedia({ event_id: eventId, social_media_id: socialMediaId });
  }

  refreshData = () => {
    this.getAPiData(this.props.match.params);
  };

  updateTab = ({ tab }) => {
    const { appId, eventId, socialMediaId } = this.props.match.params;
    this.props.history.push(
      `/apps/${appId}/events/${eventId}/social_medias/${socialMediaId}/${tab}`
    );
  };

  setCurrentVideo = video => {
    this.setState({ currentVideo: video });
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  fetchTweets = () => {
    const { eventId } = this.props.match.params;
    this.props.fetchTweets({ event_id: eventId, q: this.props.socialMedia.twitter });
  };

  fetchYoutubeVideos = nextToken => {
    const { eventId } = this.props.match.params;
    this.props.fetchYoutubeVideos({
      event_id: eventId,
      playlistId: this.props.socialMedia.youtube,
      pageToken: nextToken,
      reset: nextToken ? false : true,
      maxResults: 50,
      done: response => {
        if (response.data.nextPageToken) {
          this.fetchYoutubeVideos(response.data.nextPageToken);
        }
      }
    });
  };

  render() {
    if (
      !this.props.socialMedia ||
      (!this.props.socialMedia.twitter && !this.props.socialMedia.youtube)
    ) {
      return null;
    }
    const currentTab = this.props.match.params.currentTab;
    const { updateTab, setCurrentVideo, fetchTweets, fetchYoutubeVideos } = this;
    const {
      refreshData,
      t,
      history,
      theme,
      header,
      tweets,
      youtubeVideos,
      socialMedia,
      global,
      isLoadingTweets
    } = this.props;
    const { height: headerHeight } = header;
    const { componentFixedTopHeight } = global;
    const { setComponentFixedTopHeight } = this;

    const currentVideo = this.state.currentVideo;
    const matchParams = this.props.match.params;
    return socialMedia.is_open ? (
      <SocialMedia
        {...{
          matchParams,
          socialMedia,
          t,
          history,
          theme,
          updateTab,
          setCurrentVideo,
          currentTab,
          headerHeight,
          tweets,
          youtubeVideos,
          currentVideo,
          componentFixedTopHeight,
          setComponentFixedTopHeight,
          fetchTweets,
          fetchYoutubeVideos,
          isLoadingTweets
        }}
      />
    ) : !socialMedia.is_open ? (
      <ClosedService
        icon={getServiceIconByType(socialMedia.custom_icon || 'socialmedia')}
        name={socialMedia.name}
        theme={theme}
        t={t}
        onRefresh={refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  updateGlobal: updateGlobal,
  fetchSocialMedia: fetchSocialMedia,
  fetchTweets: fetchTweets,
  fetchYoutubeVideos: fetchYoutubeVideos
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    socialMedia: selectCurrentSocialMedia(state, ownProps),
    header: selectHeader(state, ownProps),
    global: selectGlobal(state, ownProps),
    tweets: selectTweets(state, ownProps),
    youtubeVideos: selectYoutubeVideos(state, ownProps),
    isLoadingTweets: selectIsLoadingTweets(state, ownProps),
    isLoadingYoutube: selectIsLoadingYoutube(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialMediaContainer);
