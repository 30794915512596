import { getExhibitorStandLabel } from '../../helpers/format';
import ListItem from '../../components/shared/ListItem';
import React from 'react';
import ServiceHeader from '../../components/shared/ServiceHeader';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import Card from '../../components/shared/card/Card';
import { Filler } from '../../components/shared/ListItem';
import { motion, AnimatePresence } from 'framer-motion';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class ExhibitorList extends React.Component {
  componentDidMount() {
    this.props.fetchExhibitors({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.exhibitorList.id !== prevProps.exhibitorList.id) {
      this.props.fetchExhibitors({ reset: true });
    }
  }

  render() {
    const {
      t,
      theme,
      exhibitorList,
      exhibitors,
      exhibitorsIds,
      showItemDetails,
      fetchExhibitors,
      isLoadingExhibitors,
      hasMoreExhibitors,
      matchParams,
      global,
      header,
      ...props
    } = this.props;

    return (
      <React.Fragment>
        <AnimatePresence initial={false}>
          {!header.showSearchBar ? (
            <motion.div
              key="exhibitor_desc"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0, transition: { duration: 0 } }}
            >
              <ServiceHeader
                t={t}
                icon={getServiceIconByType(exhibitorList.custom_icon || 'exhibitorlist')}
                name={exhibitorList.name}
                description={exhibitorList.description}
                theme={theme}
              />
            </motion.div>
          ) : (
            <Filler key="exhibitor_desc" />
          )}
        </AnimatePresence>

        {global.searchValue && !exhibitorsIds.length ? (
          <Card padding={'15px'} textAlign={'center'}>
            {t('nothing_found')} {global.searchValue}
          </Card>
        ) : (
          <InfiniteScroll
            loadMore={fetchExhibitors}
            hasMore={hasMoreExhibitors}
            isLoading={isLoadingExhibitors}
          >
            {exhibitorsIds.map(key => {
              const exhibitor = exhibitors[key];
              const {
                id,
                company,
                logo_url,
                aisle,
                floor,
                stand_area,
                stand_number,
                is_vip
              } = exhibitor;

              const desc = getExhibitorStandLabel(t, aisle, floor, stand_area, stand_number);

              return (
                <ListItem
                  type={'exhibitor'}
                  model={exhibitor}
                  key={id}
                  id={id}
                  heading={company}
                  desc={desc}
                  logoUrl={logo_url}
                  isVip={is_vip}
                  onClickItem={showItemDetails}
                  matchParams={matchParams}
                  theme={theme}
                  {...props}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </React.Fragment>
    );
  }
}

export default ExhibitorList;
