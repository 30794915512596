import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M5.7,21.2c0,0.2,0.2,0.4,0.4,0.4h12.3c0.2,0,0.4-0.2,0.4-0.4l0.5-2H5L5.7,21.2z" />
      <path d="M23,6.7c-1.1,0-2,0.7-2,1.8c0,0.5,0.2,0.9,0.5,1.3l-3.7,3.7l-1.2-6.4c1-0.1,1.5-0.9,1.5-1.8c0-0.9-0.9-1.8-2-1.8 c-1.1,0-2,0.7-2,1.8c0,0.9,0.5,1.4,1.3,1.8l-3,5.7L9.1,7.1c0.8-0.3,1.3-1.1,1.3-1.8c0-0.9-0.9-1.8-2-1.8c-1.1,0-2,0.7-2,1.8 C6.4,6.2,7.1,7,8,7.2l-0.9,6.5l-3.9-3.6c0.4-0.4,0.7-0.9,0.7-1.4c0-1.1-0.9-1.9-2-1.9c-1.1,0-2,0.8-2,1.9c0,1.1,0.9,1.8,2,1.8h0.2 l2.7,7.3h15l2.5-7.5c0.1,0,0.5,0.2,0.7,0.2c1.3,0.1,2-1.1,2-2S24.1,6.7,23,6.7z M1.9,9.8c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1 C2.5,7.5,3,8,3,8.7S2.5,9.8,1.9,9.8z M7.3,5.4c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C9.5,6,9,6.5,8.4,6.5S7.3,6,7.3,5.4z M15,5.4 c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1C15.5,6.5,15,6,15,5.4z M23,9.7c-0.6,0-1.1-0.5-1.1-1.1 s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S23.6,9.7,23,9.7z" />
    </g>
  </svg>
);
