import React from 'react';
import FlexParent from '../flex/FlexParent';
import FlexItem from '../flex/FlexItem';
import styled from 'styled-components';
import Scrollable from '../Scrollable';

const Container = styled.div`
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  position: fixed;
  left: 0;
  right: 0;
  top: ${props => props.headerHeight - 1 + 'px'};
  z-index: 20;
`;

const Tab = styled.div`
  border-bottom: 3px solid ${props => (props.selected ? props.theme.mainColor : '#fff')};
  font-weight: ${props => (props.selected ? 700 : 'initial')};
  padding: 15px;
  cursor: pointer;
`;

const Notif = styled.span`
  border-radius: 50%;
  background-color: #d0021b;
  color: white;
  font-weight: 700;
  padding: 0;
  font-size: 1rem;
  padding-top: 1px;
  width: 21px;
  height: 21px;
  display: inline-block;
  text-align: center;
`;

const Filer = styled(FlexItem)`
  border-bottom: 3px solid #fff;
  padding: 15px 0 0;
`;

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
  }

  onTabClick = event => {
    const tab = event.currentTarget.dataset.tab;
    if (this.props.updateTab) {
      this.props.updateTab({ tab });
    }
  };

  render() {
    const { headerHeight, currentTab, tabs } = this.props;
    return (
      <Container headerHeight={headerHeight} ref={this.containerRef}>
        <Scrollable>
          <FlexParent alignItems={'center'}>
            <Filer grow={1}>&nbsp;</Filer>
            {tabs
              ? tabs.map(tab => (
                  <Tab
                    key={tab.slug}
                    onClick={this.onTabClick}
                    data-tab={tab.slug}
                    selected={currentTab === tab.slug}
                  >
                    {tab.label}{' '}
                    {tab.notif ? <Notif>{tab.notif > 9 ? '9+' : tab.notif}</Notif> : null}
                  </Tab>
                ))
              : null}
            <Filer grow={1}>&nbsp;</Filer>
          </FlexParent>
        </Scrollable>
      </Container>
    );
  }
}

export default Tabs;
