import { UPDATE_VOTE_ANSWERS } from '../constants/actionLocal';
import initialState from '../store/initialState';
import { updateObjectInState } from '../helpers/data';

export default (state = initialState.voteAnswers, { type, payload }) => {
  switch (type) {
    case UPDATE_VOTE_ANSWERS:
      return updateObjectInState(state, payload);
    default:
      return state;
  }
};
