import { SUCCESS, FETCH_SURVEY } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_SURVEY, UPDATED } from '../constants/socket';

export default (state = initialState.surveys, { type, payload }) => {
  switch (type) {
    case FETCH_SURVEY + SUCCESS:
    case SC_SURVEY + UPDATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
