import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

const TextareaStyle = styled(TextareaAutosize)`
  border: 1px solid ${props => props.theme.dustGrey};
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  resize: none;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  :focus {
    outline: none;
  }
`;

const Textarea = props => {
  return <TextareaStyle {...props} />;
};

export default Textarea;
