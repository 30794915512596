import React from 'react';
import styled from 'styled-components';
import Tabs from '../../components/shared/tabs/Tabs';
import { TAB_TWITTER, TAB_YOUTUBE } from '../../constants/app';
import Youtube from './Youtube';
import Twitter from './Twitter';
import SwipeContainer from '../../components/swipe/SwipeContainer';

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

class App extends React.Component {
  onSwipeEnd = event => {
    const pos = this.tabs
      .map(function(e) {
        return e.slug;
      })
      .indexOf(this.props.currentTab);

    if (event.x > 150 && this.tabs[pos - 1]) {
      this.props.updateTab({ tab: this.tabs[pos - 1].slug });
    } else if (event.x < -150 && this.tabs[pos + 1]) {
      this.props.updateTab({ tab: this.tabs[pos + 1].slug });
    }
  };

  render() {
    const {
      t,
      theme,
      headerHeight,
      tweets,
      youtubeVideos,
      currentTab,
      currentVideo,
      updateTab,
      setCurrentVideo,
      componentFixedTopHeight,
      setComponentFixedTopHeight,
      fetchTweets,
      fetchYoutubeVideos,
      isLoadingTweets
    } = this.props;

    this.tabs = [
      {
        slug: TAB_TWITTER,
        label: t('tab_twitter')
      },
      {
        slug: TAB_YOUTUBE,
        label: t('tab_youtube')
      }
    ];

    return (
      <SwipeContainer onSwipeEnd={this.onSwipeEnd}>
        {this.props.socialMedia.twitter && this.props.socialMedia.youtube ? (
          <Tabs
            {...{
              t,
              theme,
              headerHeight,
              currentTab,
              updateTab,
              setComponentFixedTopHeight
            }}
            tabs={this.tabs}
          />
        ) : null}

        <Container componentFixedTopHeight={componentFixedTopHeight}>
          {currentTab === TAB_TWITTER ? (
            <Twitter {...{ tweets, t, theme, fetchTweets, isLoadingTweets }} />
          ) : currentTab === TAB_YOUTUBE ? (
            <Youtube {...{ t, youtubeVideos, setCurrentVideo, currentVideo, fetchYoutubeVideos }} />
          ) : null}
        </Container>
      </SwipeContainer>
    );
  }
}

export default App;
