import Card from '../../components/shared/card/Card';
import React from 'react';
import styled from 'styled-components';
import { getTime, applyTimezone, toDate } from '../../helpers/date';
import FlexParent from '../../components/shared/flex/FlexParent';
import LinkedSessions from '../../components/shared/LinkedSessions';
import LinkedItems from '../../components/shared/LinkedItems';
import LinkedServices from '../../components/shared/LinkedServices';
import HtmlContent from '../../components/shared/HtmlContent';
import Hr from '../../components/shared/Hr';
import Star from '../../components/shared/fav/Star';
import { FavItem } from '../../components/shared/ListItemDetails';
import { API_URL } from '../../constants/apiRoutes.js';
import striptags from 'striptags';
import Date from '../../components/shared/agenda/Date';

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 20 + 'px'};
`;

const SessionName = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  padding: 0 15px 15px;
  text-align: center;
  padding-bottom: 10px;
`;

const SessionTime = styled.div`
  text-align: center;
  font-size: 1.5rem;
  color: ${props => props.theme.darkGrey};
  padding-bottom: 10px;
`;

const SessionLocation = styled.div`
  text-align: center;
  font-size: 1.4rem;
  color: ${props => props.theme.ashesGrey};
  padding-bottom: 10px;
`;

const SessionCategory = styled(FlexParent)`
  font-size: 1.2rem;
  padding: 2px;
`;

const SessionCategoryCircle = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin: 5px;
`;

const SessionDesc = styled.div`
  font-size: 1.4rem;
  line-height: 1.4em;
  font-weight: 300;
  text-align: justify;
`;

export const DownloadICS = styled.a`
  display: block;
  text-align: center;
  color: ${props => props.theme.mainColor};
  padding: 9px 12px;
  margin: 10px 3px;
  text-transform: uppercase;
  border-radius: 8px;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  text-decoration: none;
`;

class Session extends React.Component {
  state = {
    hasCalendarPermission: false
  };

  componentDidMount() {
    if (window.cordova && window.plugins.calendar) {
      window.plugins.calendar.hasReadWritePermission(result => {
        this.setState({ hasCalendarPermission: result });
      });
    }
  }

  onClickDownloadICS = event => {
    const { session, timezone } = this.props;

    if (window.cordova && window.plugins.calendar) {
      event.preventDefault();
      event.stopPropagation();

      window.plugins.calendar.hasReadWritePermission(result => {
        if (result) {
          window.plugins.calendar.createEventInteractively(
            session.name,
            session.location,
            striptags(session.description),
            toDate(applyTimezone(session.start_date, timezone)),
            toDate(applyTimezone(session.end_date, timezone)),
            () => {},
            () => {}
          );
        } else {
          window.plugins.calendar.requestReadWritePermission();
        }
      });
    }
  };

  render() {
    const {
      event,
      session,
      timezone,
      history,
      t,
      matchParams,
      theme,
      headerHeight,
      componentFixedTopHeight,
      setComponentFixedTopHeight,
      isInAppLive
    } = this.props;

    const { appId, eventId } = matchParams;
    const {
      children,
      smart_qa,
      instaphotos,
      speakers,
      sponsors,
      exhibitors,
      bonuses,
      surveys,
      votes
    } = session;
    return session ? (
      <React.Fragment>
        {!isInAppLive &&
          <Date
            headerHeight={headerHeight}
            date={session.start_date}
            timezone={timezone}
            setComponentFixedTopHeight={setComponentFixedTopHeight}
          />
      }

        <Container componentFixedTopHeight={componentFixedTopHeight}>
          <Card margin={'20px 0'} padding={'25px 20px'}>
            <FavItem>
              <Star
                {...{ theme }}
                size={'40px'}
                model={session}
                type={'agenda_session'}
                matchParams={matchParams}
              />
            </FavItem>
            <SessionName>{session.name}</SessionName>
            <SessionTime>
              {getTime(applyTimezone(session.start_date, timezone))} -{' '}
              {getTime(applyTimezone(session.end_date, timezone))}
            </SessionTime>

            {session.location ? <SessionLocation>{session.location}</SessionLocation> : null}

            {session.categories.map(category => {
              return (
                <SessionCategory key={category.id} alignItems={'center'} justifyContent={'center'}>
                  <SessionCategoryCircle style={{ backgroundColor: category.color }} />{' '}
                  {category.name}
                </SessionCategory>
              );
            })}

            {session.description ? (
              <React.Fragment>
                <Hr />
                <SessionDesc>
                  <HtmlContent>{session.description}</HtmlContent>
                </SessionDesc>
              </React.Fragment>
            ) : null}

            {!window.cordova ||
            (window.cordova && window.plugins.calendar && this.state.hasCalendarPermission) ? (
              <DownloadICS
                href={`${API_URL}sessions/${session.id}/ics?event_id=${eventId}`}
                onClick={this.onClickDownloadICS}
              >
                {t('add_to_calendar')}
              </DownloadICS>
            ) : null}

            {children && children.length ? (
              <LinkedSessions
                sessions={children}
                heading={t('session_children')}
                appId={appId}
                eventId={eventId}
                history={history}
                timezone={event.timezone}
              />
            ) : null}

            {sponsors && sponsors.length ? (
              <LinkedItems
                items={sponsors}
                type={'sponsors'}
                heading={t('session_sponsors')}
                appId={appId}
                eventId={eventId}
                history={history}
                theme={theme}
              />
            ) : null}

            {speakers && speakers.length ? (
              <LinkedItems
                items={speakers}
                type={'speakers'}
                heading={t('session_speakers')}
                appId={appId}
                eventId={eventId}
                history={history}
                theme={theme}
              />
            ) : null}

            {exhibitors && exhibitors.length ? (
              <LinkedItems
                items={exhibitors}
                type={'exhibitors'}
                heading={t('session_exhibitors')}
                appId={appId}
                eventId={eventId}
                history={history}
                theme={theme}
              />
            ) : null}

            {bonuses && bonuses.length ? (
              <LinkedItems
                items={bonuses}
                type={'bonuses'}
                heading={t('session_bonuses')}
                appId={appId}
                eventId={eventId}
                history={history}
                theme={theme}
              />
            ) : null}

            {(smart_qa && smart_qa.length) ||
            (instaphotos && instaphotos.length) ||
            (surveys && surveys.length) ||
            (votes && votes.length) ? (
              <LinkedServices
                servicesTypes={{ smart_qa, surveys, votes, instaphotos }}
                heading={t('session_services')}
                appId={appId}
                eventId={eventId}
                history={history}
                theme={theme}
              />
            ) : null}
          </Card>
        </Container>
      </React.Fragment>
    ) : null;
  }
}

export default Session;
