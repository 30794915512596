//import * as moment from 'moment';
import * as moment from 'moment-timezone';
import * as m from 'moment';
import 'moment/locale/ar';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/nl';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/sv';
import 'moment/locale/zh-cn';
import 'moment/locale/lb';
import store from '../store';

export const setLocale = () => {
  const s = store.getState();
  const locale = s.global.locale;
  m.locale(locale);
  moment.updateLocale(locale, m.localeData()._config);
  moment.locale(locale);
  return locale;
};

export function applyTimezone(date, timezone, format) {
  setLocale();
  return moment
    .utc(date)
    .tz(timezone)
    .format(format || 'YYYY-MM-DD HH:mm:ss');
}

export function getAgendaDefaultDate(startDate, endDate) {
  setLocale();
  const now = moment();
  if (now.isBetween(startDate, endDate)) {
    return now.format('YYYY-MM-DD');
  } else {
    if (now.isAfter(endDate, 'day')) {
      return moment(endDate).format('YYYY-MM-DD');
    } else {
      return moment(startDate).format('YYYY-MM-DD');
    }
  }
}

export function now(format) {
  setLocale();
  return moment().format(format || 'YYYY-MM-DD');
}

export function toDate(date) {
  setLocale();
  return moment(date).toDate();
}

export function formatDate(day, format) {
  const locale = setLocale();
  if ((format === 'dddd, MMMM Do' || format === 'dddd MMMM DD, YYYY') && locale === 'fr') {
    format = 'dddd Do MMMM YYYY';
  }
  return moment(day).format(format);
}

export function isSameDay(day1, day2) {
  setLocale();
  return moment(day1).isSame(day2, 'day');
}

export function isPast(date, delta, precision) {
  setLocale();
  const deltaDate = moment().add(delta, 'd');
  return moment(date).isBefore(deltaDate, precision);
}

export function diffFromNow(date, precision) {
  setLocale();
  precision = precision || 'days';
  return moment()
    .startOf('day')
    .diff(moment(date, 'YYYY-MM-DD'), precision);
}

export function getTime(date) {
  const locale = setLocale();
  let format = 'HH:mm';
  if (locale === 'en') {
    format = 'h:mm a';
  }
  const time = moment(date).format(format);
  return time;
}

export function getEventSplitedDate(startDate, endDate) {
  setLocale();

  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  const startDay = momentStartDate.format('D');
  const startMonth = momentStartDate.format('MMMM');
  const startYear = momentStartDate.format('gggg');
  const endDay = momentEndDate.format('D');
  const endMonth = momentEndDate.format('MMMM');
  const endYear = momentEndDate.format('gggg');

  return {
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear
  };
}

export function getTimeFromNow(date) {
  setLocale();
  date = moment.utc(date);
  let now = moment.utc();
  let timeFromNow = null;

  if (date.isAfter(now)) {
    timeFromNow = now.from(now);
  } else {
    timeFromNow = date.from(now);
  }

  return timeFromNow;
}

export function parseTweetDate(date) {
  return moment(date, 'ddd MMM D HH:mm:ss ZZ YYYY', 'en');
}
