import React from 'react';
import Sandbox from './Sandbox';
import { connect } from 'react-redux';

class SandboxContainer extends React.Component {
  onClickTest = event => {};

  render() {
    const { t, theme, history } = this.props;
    return (
      <Sandbox onClickTest={this.onClickTest} {...{ t, theme, history }}>
        <div>{JSON.stringify(this.props.match.params)}</div>
      </Sandbox>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SandboxContainer);
