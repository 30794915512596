import React from 'react';
import Card from '../../../components/shared/card/Card';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import styled from 'styled-components';
import { formatDate, getTime, applyTimezone } from '../../../helpers/date';
import { MEETING_DETAILS } from '../../../constants/app';
import {
  ListItemTitle,
  ListItemSubTitle,
  ListItemShadow
} from '../../../components/shared/ListItem';

const MeetingStatusCount = styled.div`
  font-size: 1.1rem;
`;

const MeetingStatusCountCircle = styled(FlexItem)`
  width: 9px;
  height: 9px;
  margin: 0 2px 0 7px;
  border-radius: 50%;
  background-color: ${props => (props.color ? props.color : '#000')};
`;

const Description = styled.div`
  margin-top: 5px;
  font-size: 1.2rem;
`;

const Btn = styled(FlexItem)`
  padding: 10px 0 5px;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${props => (props.color ? props.color : props.theme.ashesGrey)};
  cursor: pointer;
  text-transform: uppercase;
  flex-grow: 1;
  text-align: center;
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const Avatar = styled.img`
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
`;

const SubAvatar = styled.img`
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  bottom: -4px;
  right: -4px;
  border: 1px solid white;
  border-radius: 50%;
  z-index: 6;
`;

const CircleContainer = styled.div`
  margin-left: 10px;
  padding-top: 8px;
`;

class MeetingItem extends React.Component {
  onClickMeeting = () => {
    this.props.updateView({ view: MEETING_DETAILS, id: this.props.meeting.id });
  };

  onClickUpdateStatus = event => {
    event.stopPropagation();
    this.props.postMeetingStatus({
      meeting_id: this.props.meeting.id,
      status: event.currentTarget.dataset.status
    });
  };

  render() {
    const { meeting, user, event, t } = this.props;
    const {
      start_date,
      location,
      description,
      accepted_count,
      tentative_count,
      declined_count,
      viewer_status
    } = meeting;

    const date = `${formatDate(start_date, 'dddd DD MMMM YYYY')} ${t('at')} ${getTime(
      applyTimezone(start_date, event.timezone)
    )}${location ? ` • ${location}` : ''}`;

    let title = '';
    let avatarUrl = '';
    let subAvatarUrl = '';
    let attendeeName = '';
    if (!meeting.attendees.length) {
      avatarUrl = meeting.owner.avatar_url;
    } else if (meeting.owner.id === user.id) {
      avatarUrl = meeting.attendees[0].avatar_url;
      attendeeName = meeting.attendees[0].name;
    } else {
      avatarUrl = meeting.owner.avatar_url;
      attendeeName = meeting.owner.name;
    }

    if (meeting.attendees.length > 1) {
      title = `${attendeeName} ${t('and')} ${meeting.attendees.length - 1} ${t('other_attendees')}`;
      subAvatarUrl = meeting.attendees[1].avatar_url;
    } else {
      title = `${attendeeName}`;
    }

    return (
      <Card margin={'0 0 20px'} padding={'10px'} onClick={this.onClickMeeting}>
        <FlexParent justifyContent={'space-between'}>
          <AvatarContainer>
            <Avatar src={avatarUrl} alt={attendeeName} />
            {subAvatarUrl ? <SubAvatar src={subAvatarUrl} /> : null}
            <ListItemShadow />
          </AvatarContainer>
          <FlexItem grow={1}>
            <FlexParent alignItems={'flex-start'}>
              <ListItemTitle>{title}</ListItemTitle>
              <CircleContainer>
                <FlexParent alignItems={'center'}>
                  <MeetingStatusCountCircle color={'#7ED721'} />
                  <MeetingStatusCount>{accepted_count}</MeetingStatusCount>
                  <MeetingStatusCountCircle color={'#F5A623'} />
                  <MeetingStatusCount>{tentative_count}</MeetingStatusCount>
                  <MeetingStatusCountCircle color={'#D0021B'} />
                  <MeetingStatusCount>{declined_count}</MeetingStatusCount>
                </FlexParent>
              </CircleContainer>
            </FlexParent>

            <ListItemSubTitle>{date}</ListItemSubTitle>
            <Description>{description}</Description>

            {meeting.owner.id !== this.props.user.id ? (
              <FlexParent justifyContent={'space-evenly'}>
                <Btn
                  data-status={'declined'}
                  onClick={this.onClickUpdateStatus}
                  color={viewer_status === 'declined' ? '#D0021B' : null}
                >
                  {t('btn_refuse')}
                </Btn>
                <Btn
                  data-status={'tentative'}
                  onClick={this.onClickUpdateStatus}
                  color={viewer_status === 'tentative' ? '#F2A900' : null}
                >
                  {t('btn_tentative')}
                </Btn>
                <Btn
                  data-status={'accepted'}
                  onClick={this.onClickUpdateStatus}
                  color={viewer_status === 'accepted' ? '#7ED321' : null}
                >
                  {t('btn_accept')}
                </Btn>
              </FlexParent>
            ) : null}
          </FlexItem>
        </FlexParent>
        {this.props.meeting.name}
      </Card>
    );
  }
}

export default MeetingItem;
