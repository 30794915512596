import React from 'react';
import styled from 'styled-components';
import { getTime, applyTimezone } from '../../helpers/date';
import { isDarkBgColor } from '../../helpers/helpers';
import FlexItem from './flex/FlexItem';
import FlexParent from './flex/FlexParent';
import Hr from './Hr';
import Scrollable from './Scrollable';

const ItemHeading = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 0 15px;
  text-align: center;
`;

const SessionTime = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${props => props.theme.mainColor};
  border-radius: 50%;
  text-align: center;
  font-size: 1rem;
  margin: 0 auto;
  font-weight: bold;
  padding-top: 18px;
  line-height: 1.3rem;
  color: ${props => {
    return isDarkBgColor(props.theme.mainColor) ? '#fff' : '#000';
  }};

  div:nth-child(2) {
    opacity: 0.7;
  }
`;

const SessionName = styled.div`
  text-align: center;
  font-size: 1.2rem;
  padding: 5px 10px;
`;

const Filer = styled(FlexItem)`
  border-bottom: 3px solid #fff;
  padding: 15px 0 0;
`;

const LinkedSessions = ({ heading, sessions, appId, eventId, history, timezone, isInAppLive }) => {
  return sessions.length > 0 ? (
    <React.Fragment>
      <Hr />
      <ItemHeading>{heading}</ItemHeading>
      <Sessions {...{ sessions, appId, eventId, history, timezone, isInAppLive }} />
    </React.Fragment>
  ) : null;
};

const Sessions = ({ sessions, appId, eventId, history, timezone, isInAppLive }) => {
  return (
    <Scrollable>
      <FlexParent>
        <Filer grow={1}>&nbsp;</Filer>
        {sessions.map(session => {
          const url = `/apps/${appId}/events/${eventId}/agendas/${session.agenda_id}/sessions/${session.id}`;
          return (
            <FlexItem
              width={window.matchMedia('(min-width: 480px)').matches ? '22%' : '40%'}
              key={session.id}
              onClick={event => {
                if (!isInAppLive) {
                  history.push(url);
                }
              }}
            >
              <SessionTime>
                <div>{getTime(applyTimezone(session.start_date, timezone))}</div>
                <div>{getTime(applyTimezone(session.end_date, timezone))}</div>
              </SessionTime>
              <SessionName>{session.name}</SessionName>
            </FlexItem>
          );
        })}
        <Filer grow={1}>&nbsp;</Filer>
      </FlexParent>
    </Scrollable>
  );
};

export default LinkedSessions;
