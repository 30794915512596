import React from 'react';
import styled from 'styled-components';
import { formatDate, getTime, applyTimezone } from '../../../helpers/date';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import IconOption from '../../../components/icons/IconOption';
import Icon from '../../../components/icons/Icon';

const Container = styled.div`
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  position: fixed;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  left: 0;
  right: 0;
  top: ${props => props.headerHeight - 1 + 'px'};
  z-index: 20;
  padding: 10px;
`;

const Name = styled.div`
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
`;

const Date = styled.div`
  text-align: center;
  color: ${props => props.theme.ashesGrey};
  font-size: 1.2rem;
  padding: 10px 5px 0;
`;

const Location = styled.div`
  text-align: center;
  color: ${props => props.theme.ashesGrey};
  font-size: 1.4rem;
`;

const FlexIcon = styled(FlexItem)`
  width: 20px;
  min-width: 20px;
`;

class MeetingDetailsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
  }

  render() {
    const { headerHeight, onClickMeeting, meeting, event, t, theme } = this.props;

    const date = `${formatDate(meeting.start_date, 'dddd DD MMMM YYYY')} ${t('at')} ${getTime(
      applyTimezone(meeting.start_date, event.timezone)
    )}`;

    return (
      <Container headerHeight={headerHeight} onClick={onClickMeeting} ref={this.containerRef}>
        <FlexParent>
          <FlexIcon />
          <FlexItem grow={1}>
            <Name>{meeting.description}</Name>
            <Date>{date}</Date>
            <Location>{meeting.location}</Location>
          </FlexItem>
          <FlexIcon>
            <Icon
              icon={IconOption}
              width={'20px'}
              height={'20px'}
              padding={'0'}
              color={theme.mainColor}
            />
          </FlexIcon>
        </FlexParent>
      </Container>
    );
  }
}

export default MeetingDetailsHeader;
