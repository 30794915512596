import React from 'react';
import linkifyHtml from 'linkifyjs/html';
import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconAvatar from '../../components/icons/IconAvatar';
import IconReport from '../../components/icons/IconReport';
import { getTimeFromNow } from '../../helpers/date';
import AddComment from './AddComment';
import Comment from './Comment';
import { nl2br } from '../../helpers/format';
import { Reactions, ViewerReaction } from '../../components/shared/smartqa/Reactions';
import PopupReaction from '../../components/shared/smartqa/PopupReaction';
import FullImage from '../../components/shared/FullImage';
import {
  PostAvatar,
  PostAuthorName,
  PostHour,
  PostMessage,
  PostPicture,
  PostCommentsCount,
  PostLike,
  PostActionContainer,
  PostReactionContainer
} from '../../components/shared/smartqa/Styled';
import HtmlContent from '../../components/shared/HtmlContent';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import styled from 'styled-components';

const Avatar = props => {
  return props.profile_picture_url ? (
    <PostAvatar src={props.profile_picture_url} />
  ) : (
    <Icon
      icon={IconAvatar}
      color={'#fff'}
      padding={'10px'}
      margin={'0 10px 0 0'}
      width={'50px'}
      height={'50px'}
      borderRadius={'50%'}
      backgroundColor={props.theme.dustGrey}
    />
  );
};

const Back = styled.div`
  padding-top: 20px;
  color: ${props => props.theme.mainColor};
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

class Post extends React.Component {
  state = {
    openReactionModal: false,
    fullScreenPictureUrl: null
  };

  componentDidMount() {
    this.props.fetchSmartqaComments(true);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.smartqa.id !== prevProps.smartqa.id ||
      this.props.post.id !== prevProps.post.id
    ) {
      this.props.fetchSmartqaComments(true);
    }
  }

  onClickLike = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openReactionModal: true });
  };

  onClickUnlike = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openReactionModal: false });
    const type = event.currentTarget.dataset.type;
    this.props.deleteReaction({ post: this.props.post, type });
  };

  sendReaction = type => {
    this.props.sendReaction({ post: this.props.post, type });
  };

  closeReactionModal = event => {
    event.preventDefault();
    this.setState({ openReactionModal: false });
  };

  onClickReport = event => {
    this.props.sendReport({ post: this.props.post });
  };

  onClickPicture = () => {
    this.setState({
      fullScreenPictureUrl: this.props.post.picture_url
    });
  };

  closeFullScreenPicture = () => {
    this.setState({
      fullScreenPictureUrl: null
    });
  };

  onClickBack = event => {
    window.history.back();
  };

  render() {
    const {
      smartqa,
      post,
      comments,
      theme,
      t,
      user,
      postSmartqaComment,
      matchParams,
      sendReportComment,
      sendReactionComment,
      deleteReactionComment,
      setVisibility,
      uploadCommentFile,
      isAnonymous,
      commentsIds,
      isLoadingComments,
      hasMoreComments,
      fetchSmartqaComments,
      isSaving,
      uploadCameraFile,
      offline,
      accessToken,
      isInAppLive
    } = this.props;

    const { angry, haha, like, love, wow } = post.reactions;
    const { viewer_reaction } = post;

    return (
      <React.Fragment>
        {isInAppLive ? <Back onClick={this.onClickBack}>{t('back')}</Back> : null}

        <FullImage
          url={this.state.fullScreenPictureUrl}
          onClickClose={this.closeFullScreenPicture}
        ></FullImage>
        <Card margin={'15px 0'} padding={'15px'}>
          <FlexParent alignItems={'center'}>
            <FlexItem>
              <Avatar profile_picture_url={post.author.profile_picture_url} theme={theme} />
            </FlexItem>
            <FlexItem grow={1}>
              <PostAuthorName>{post.author.name || t('smartqa_anonymous')}</PostAuthorName>
              <PostHour>{getTimeFromNow(post.created_at)}</PostHour>
            </FlexItem>
          </FlexParent>

          {post.text && (
            <PostMessage>
              <HtmlContent>
                {linkifyHtml(nl2br(post.text), {
                  target: '_blank',
                  attributes: {
                    rel: 'nofollow'
                  }
                })}
              </HtmlContent>
            </PostMessage>
          )}
          {post.picture_url && <PostPicture src={post.picture_url} onClick={this.onClickPicture} />}

          <PostReactionContainer alignContent={'center'} alignItems={'center'}>
            {smartqa.is_likable ? <Reactions {...{ angry, haha, like, love, wow }} /> : null}

            <FlexItem grow={1}>
              {smartqa.is_commentable ? (
                <PostCommentsCount>
                  {t('smartqa_comments_count', { count: post.comments_count })}
                </PostCommentsCount>
              ) : null}
            </FlexItem>
          </PostReactionContainer>

          {user && smartqa.is_likable && !offline ? (
            <PostActionContainer
              alignContent={'center'}
              alignItems={'center'}
              paddingBottom={'12px'}
            >
              <FlexItem grow={1}>
                {viewer_reaction ? (
                  <ViewerReaction onClick={this.onClickUnlike} reaction={viewer_reaction} t={t} />
                ) : (
                  <React.Fragment>
                    <PopupReaction
                      open={this.state.openReactionModal}
                      onClose={this.closeReactionModal}
                      onValidate={this.sendReaction}
                      theme={theme}
                      t={t}
                    />
                    <PostLike onClick={this.onClickLike}>{t('smartqa_like')}</PostLike>
                  </React.Fragment>
                )}
              </FlexItem>

              {!offline && (
                <Icon
                  icon={IconReport}
                  width={'25px'}
                  height={'25px'}
                  color={'#b0b0b0'}
                  margin={'0 0 0 5px'}
                  padding={'2px'}
                  onClick={this.onClickReport}
                />
              )}

              {/* {
            smartqa.is_shareable ||
              (false && (
                <Icon
                  icon={IconShare}
                  width={'25px'}
                  height={'25px'}
                  color={'#b0b0b0'}
                  padding={'2px'}
                  margin={'0 0 0 5px'}
                  onClick={this.onClickShare}
                />
              ))} */}
            </PostActionContainer>
          ) : null}

          {smartqa.is_commentable ? (
            <AddComment
              {...{
                t,
                theme,
                user,
                smartqa,
                isSaving,
                postSmartqaComment,
                setVisibility,
                isAnonymous,
                uploadCommentFile,
                uploadCameraFile,
                post,
                offline,
                accessToken
              }}
              commentsCount={post.comments_count}
            />
          ) : null}

          <InfiniteScroll
            loadMore={fetchSmartqaComments}
            hasMore={hasMoreComments}
            isLoading={isLoadingComments}
          >
            {commentsIds.map(key => {
              const comment = comments[key];
              return (
                <Comment
                  {...{
                    key,
                    t,
                    user,
                    matchParams,
                    comment,
                    theme,
                    sendReportComment,
                    sendReactionComment,
                    deleteReactionComment
                  }}
                />
              );
            })}
          </InfiniteScroll>
        </Card>
      </React.Fragment>
    );
  }
}

export default Post;
