import React from 'react';
import { connect } from 'react-redux';
import UserProfile from './UserProfile';
import { updateHeader, userLogout } from '../../../actions/local';

import { selectUser } from '../../../selectors/user';
import Mixpanel from '../../../Mixpanel';

class UserProfileContainer extends React.Component {
  componentDidMount() {
    const { appId, eventId } = this.props.match.params;
    this.props.updateHeader({
      leftBtn: window.history.length > 1 ? 'back' : 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'user-profile',
      viewableId: null,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  logout = () => {
    this.props.userLogout();
  };

  render() {
    if (!this.props.user) {
      return null;
    }

    const { logout } = this;
    const { t, theme, history, user } = this.props;
    const matchParams = this.props.match.params;
    return <UserProfile {...{ logout, t, theme, history, matchParams, user }} />;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  userLogout: userLogout
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);
