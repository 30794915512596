import BonusItem from './BonusItem';
import React from 'react';
import ServiceHeader from '../../components/shared/ServiceHeader';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import Card from '../../components/shared/card/Card';
import { Filler } from '../../components/shared/ListItem';
import { motion, AnimatePresence } from 'framer-motion';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class BonusList extends React.Component {
  componentDidMount() {
    this.props.fetchBonuses({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.bonusList.id !== prevProps.bonusList.id) {
      this.props.fetchBonuses({ reset: true });
    }
  }

  render() {
    const {
      bonusList,
      theme,
      bonuses,
      bonusesIds,
      isLoadingBonuses,
      hasMoreBonuses,
      fetchBonuses,
      matchParams,
      global,
      header,
      t
    } = this.props;

    return (
      <React.Fragment>
        <AnimatePresence initial={false}>
          {!header.showSearchBar ? (
            <motion.div
              key="bonus_desc"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0, transition: { duration: 0 } }}
            >
              <ServiceHeader
                t={t}
                icon={getServiceIconByType(bonusList.custom_icon || 'bonuslist')}
                name={bonusList.name}
                description={bonusList.description}
                theme={theme}
              />
            </motion.div>
          ) : (
            <Filler key="bonus_desc" />
          )}
        </AnimatePresence>

        {global.searchValue && !bonusesIds.length ? (
          <Card padding={'15px'} textAlign={'center'}>
            {t('nothing_found')} {global.searchValue}
          </Card>
        ) : (
          <InfiniteScroll
            loadMore={fetchBonuses}
            hasMore={hasMoreBonuses}
            isLoading={isLoadingBonuses}
          >
            {bonusesIds.map(key => {
              const bonus = bonuses[key];
              return (
                <BonusItem key={bonus.id} bonus={bonus} theme={theme} matchParams={matchParams} />
              );
            })}
          </InfiniteScroll>
        )}
      </React.Fragment>
    );
  }
}

export default BonusList;
