export const API_URL = process.env.REACT_APP_ED_API_URL;
export const API_URL_2 = process.env.REACT_APP_ED_API_URL_2;

// GET ONE
export const API_AGENDAS = `${API_URL_2}agendas`;
export const API_SUBDOMAIN = `${API_URL_2}subdomain`;
export const API_APPS = `${API_URL_2}event-groups`;
export const API_BONUS_LISTS = `${API_URL_2}bonus-lists`;
export const API_EXHIBITOR_LISTS = `${API_URL_2}exhibitor-lists`;
export const API_MAPS = `${API_URL_2}maps`;
export const API_ME = `${API_URL_2}me`;
export const API_DELETE_USER = `${API_URL_2}me`;
export const API_PRACTICAL_INFO = `${API_URL_2}practical-info`;
export const API_SMARTQA = `${API_URL_2}smart-qa`;
export const API_SOCIAL_MEDIA = `${API_URL_2}social-media`;
export const API_SPEAKER_LISTS = `${API_URL_2}speaker-lists`;
export const API_SPONSOR_LISTS = `${API_URL_2}sponsor-lists`;
export const API_SURVEYS = `${API_URL_2}surveys`;
export const API_TIMELINES = `${API_URL_2}timelines`;
export const API_VOTES = `${API_URL_2}votes`;
export const API_WHOS_HERE = `${API_URL_2}whos-here`;
export const API_WHOS_HERE_MESSAGES_UNREAD = `${API_URL_2}whos-here-conversation-messages/unread`;

// GET MANY
export const API_AGENDA_CATEGORIES = `${API_URL_2}agenda-categories`;
export const API_COUNTRIES = `${API_URL_2}countries`;
export const API_MENU_ITEMS = `${API_URL_2}menu`;
export const API_SHORT_LINKS = `${API_URL_2}short-links`;
export const API_SURVEY_PAGES = `${API_URL_2}survey-questions`;
export const API_TWEETS = `${API_URL_2}twitter/search/tweets`;
export const API_VOTE_ROUNDS = `${API_URL_2}vote-rounds`;
export const API_YOUTUBE_VIDEOS = `${API_URL_2}youtube/playlistitems`;
export const API_TIMELINE_ITEMS = `${API_URL_2}timeline-items`;
export const API_MAP_POIS = `${API_URL_2}poi`;

// GET MANY + GET ONE
export const API_AGENDA_SESSIONS = `${API_URL_2}agenda-sessions`;
export const API_EVENTS = `${API_URL_2}events`;
export const API_EXHIBITORS = `${API_URL_2}exhibitors`;
export const API_SPEAKERS = `${API_URL_2}speakers`;
export const API_SPONSORS = `${API_URL_2}sponsors`;
export const API_BONUSES = `${API_URL_2}bonuses`;
export const API_WHOS_HERE_CONVERSATIONS = `${API_URL_2}whos-here-conversations`;

// GET MANY + GET ONE + POST
export const API_SMARTQA_QUESTIONS = `${API_URL_2}smart-qa-questions`;

// GET MANY + GET ONE + POST + PUT
export const API_ATTENDEES = `${API_URL_2}attendees`;

// GET MANY + POST
export const API_SMARTQA_COMMENTS = `${API_URL_2}comments`;
export const API_WHOS_HERE_MESSAGES = `${API_URL_2}whos-here-conversation-messages`;

export const API_FAVORITES = `${API_URL_2}favorites`;
export const API_DISCLAIMER = `${API_URL_2}disclaimer`;
export const API_OFFLINE = `${API_URL_2}offline`;

// GET MANY + POST + DELETE
export const API_WHOS_HERE_MEETINGS = `${API_URL_2}whos-here-meetings`;

// POST
export const API_UPLOAD = `${API_URL_2}uploads`;
export const API_SURVEY_QUESTION_ANSWERS = `${API_URL_2}survey-question-answers`;
export const API_VOTE_ROUND_QUESTION_ANSWERS = `${API_URL_2}vote-round-question-answers`;
export const API_CHECK_USER_EMAIL = `${API_URL_2}check-user-email`;
export const API_AUTH_TOKEN = `${API_URL_2}oauth/token`;
export const API_PUSH_NOTIF = `${API_URL_2}pushnotifications/subscribe`;
export const API_FORGOT_PASSWORD = `${API_URL}password/email`;
export const API_RESET_PASSWORD = `${API_URL}password/reset`;
export const API_WHOS_HERE_MESSAGES_READ = `${API_URL_2}whos-here-conversation-messages/read`;
export const API_POST_SMARTQA_POST_HIDE_TO_ANIMATORS = `${API_URL}smartqaquestions/hidetoanimators`;

// POST + DELETE
export const API_REACTIONS = `${API_URL_2}reactions`; // +DELETE
