import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 30%;
`;

const LoaderStyle = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  max-width: ${props => props.maxWidth || '100%'};
  margin: ${props => props.margin || '10px auto'};

  :before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: ${props => props.theme.mainColor};
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    from {
      left: -200px;
      width: 30%;
    }
    50% {
      width: 30%;
    }
    70% {
      width: 70%;
    }
    80% {
      left: 50%;
    }
    95% {
      left: 120%;
    }
    to {
      left: 100%;
    }
  }
`;

const Loader = props => {
  return (
    <React.Fragment>
      {props.overlay ? (
        <Overlay>
          <LoaderStyle />
        </Overlay>
      ) : (
        <LoaderStyle />
      )}
    </React.Fragment>
  );
};

export default Loader;
