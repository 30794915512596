import { getJobWithCompany } from '../../../helpers/format';
import CardIcon from '../../../components/shared/card/CardIcon';
import React from 'react';
import styled from 'styled-components';

const UserContainer = styled.div`
  cursor: pointer;
  text-align: center;
`;

const UserName = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
`;

const UserJob = styled.div`
  padding: 5px 0;
  font-size: 1.4rem;
  color: ${props => props.theme.ashesGrey};
`;

const QrCodeHeading = styled.div`
  margin: 20px 0;
  font-weight: 600;
  font-size: 1.6rem;
`;

const QrCode = styled.img`
  width: 100%;
  max-width: 150px;
  margin: 15px auto 25px;
  display: block;
`;

const HelpText = styled.div`
  font-size: 1.2rem;
  margin: 10px 0 0;
  color: ${props => props.theme.ashesGrey};
`;

class UserQrCode extends React.Component {
  render() {
    const { t, theme, user } = this.props;
    return (
      <CardIcon
        padding={'50px 20px 20px 20px'}
        logoBorderRadius={'50%'}
        image={user.avatar_url}
        theme={theme}
      >
        <UserContainer>
          <UserName>
            {user.first_name} {user.last_name}
          </UserName>
          <UserJob>{getJobWithCompany(user.job_title, user.company)}</UserJob>

          <QrCodeHeading>{t('my_qr_code')}</QrCodeHeading>
          <QrCode src={`data:image/svg+xml;base64,${user.barcode}`} />
          <HelpText>{t('qr_code_helper')}</HelpText>
        </UserContainer>
      </CardIcon>
    );
  }
}

export default UserQrCode;
