import { SUCCESS, FETCH_AGENDA } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';
import { SC_AGENDA, UPDATED } from '../constants/socket';

export default (state = initialState.agendas, { type, payload }) => {
  switch (type) {
    case FETCH_AGENDA + SUCCESS:
    case SC_AGENDA + UPDATED:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
