import { connect } from 'react-redux';
import {
  fetchWhosHere,
  fetchAttendees,
  fetchAttendee,
  fetchWhosHereConversations,
  fetchWhosHereConversation,
  fetchWhosHereMessages,
  fetchWhosHereMeetings,
  fetchWhosHereMeeting,
  fetchFavorites,
  postWhosHereMessage,
  postWhosHereConversation,
  postWhosHereMeeting,
  postWhosHereMeetingStatus,
  postWhosHereConversationLeave,
  postFile,
  postCameraFile,
  deleteWhosHereMeeting,
  fetchWhosHereMessageUnread,
  postWhosHereMessageRead
} from '../../actions/api';
import { updateHeader, updateGlobal, resetNotification } from '../../actions/local';
import React from 'react';
import WhosHere from './WhosHere';
import ClosedService from '../../components/shared/ClosedService';
import { TAB_ATTENDEES, TAB_CONVERSATIONS, TAB_MEETINGS, SEARCH_DELAY } from '../../constants/app';

import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';
import { selectCurrentEvent } from '../../selectors/events';

import Socket from '../../socket/Socket';
import {
  CREATED,
  UPDATED,
  DELETED,
  SC_WHOS_HERE,
  SC_WHOS_HERE_CONVERSATION,
  SC_WHOS_HERE_MEETING,
  SC_WHOS_HERE_MESSAGE,
  SC_ATTENDEE
} from '../../constants/socket';

import {
  selectCurrentWhosHereId,
  selectFilteredAttendees,
  selectFilteredAttendeesIds,
  selectFilteredFavoritesAttendees,
  selectFilteredFavoritesAttendeesIds,
  selectFilteredConversations,
  selectFilteredConversationsIds,
  selectFilteredMeetings,
  selectFilteredMeetingsIds,
  selectFilteredMessages,
  selectFilteredMessagesIds,
  selectCurrentConversation,
  selectCurrentAttendee,
  selectCurrentMeeting,
  selectIsLoadingAttendees,
  selectIsLoadingConversations,
  selectIsLoadingMeetings,
  selectIsLoadingMessages,
  selectHasMoreAttendees,
  selectHasMoreConversations,
  selectHasMoreMeetings,
  selectHasMoreMessages,
  selectNotifsMessagesCount,
  selectNotifsMeetingsCount
} from '../../selectors/whosHere';

import {
  ATTENDEE_DETAILS,
  MEETING_CREATE_ATTENDEES,
  MEETING_EDIT_ATTENDEES,
  CONVERSATION_CREATE_ATTENDEES,
  CONVERSATION_EDIT_ATTENDEES
} from '../../constants/app';

import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class WhosHereContainer extends React.Component {
  state = {
    socketIsListening: false,
    scrollY: null
  };

  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    const { appId, eventId, whosHereId, currentTab, currentView } = this.props.match.params;
    this.getAPiData(this.props.match.params);

    if (!currentTab) {
      this.props.history.replace(
        `/apps/${appId}/events/${eventId}/whos_here/${whosHereId}/${TAB_ATTENDEES}`
      );
    }

    this.props.updateHeader({
      leftBtn: currentView && window.history.length > 1 ? 'back' : 'nav',
      rightBtn2:
        currentTab === TAB_ATTENDEES && !currentView
          ? this.props.user && this.props.user.is_incognito
            ? 'incognitoTrue'
            : 'incognitoFalse'
          : false,
      rightBtn:
        (currentTab === TAB_ATTENDEES && currentView !== ATTENDEE_DETAILS) ||
        [
          MEETING_CREATE_ATTENDEES,
          MEETING_EDIT_ATTENDEES,
          CONVERSATION_CREATE_ATTENDEES,
          CONVERSATION_EDIT_ATTENDEES
        ].indexOf(currentView) !== -1
          ? 'search'
          : null
    });

    if (this.props.user && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }

    Mixpanel.track('view', {
      viewableType: 'whoshere',
      viewableId: +whosHereId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentWillUnmount() {
    const { whosHereId } = this.props.match.params;
    this.unsubscribeSocketEvents(whosHereId);
  }

  componentDidUpdate(prevProps) {
    const { appId, eventId, whosHereId, currentTab, currentView } = this.props.match.params;
    const headerData = {};
    const oldWhosHereId = prevProps.match.params.whosHereId;

    if (whosHereId !== oldWhosHereId) {
      this.getAPiData(this.props.match.params);
      this.unsubscribeSocketEvents(oldWhosHereId);
    }

    if (this.props.user && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }

    if (!currentTab) {
      this.props.history.replace(
        `/apps/${appId}/events/${eventId}/whos_here/${whosHereId}/${TAB_ATTENDEES}`
      );
    }

    if (currentView && prevProps.match.params.currentView !== currentView) {
      headerData['leftBtn'] = 'back';
    } else if (!currentView && prevProps.match.params.currentView) {
      headerData['leftBtn'] = 'nav';
    }

    if (
      currentTab !== prevProps.match.params.currentTab ||
      prevProps.match.params.currentView !== currentView ||
      (this.props.user &&
        prevProps.user &&
        prevProps.user.is_incognito !== this.props.user.is_incognito) ||
      (this.props.user && !prevProps.user)
    ) {
      if (currentTab === TAB_ATTENDEES && !currentView) {
        headerData['rightBtn2'] =
          this.props.user && this.props.user.is_incognito ? 'incognitoTrue' : 'incognitoFalse';
      } else {
        headerData['rightBtn2'] = null;
      }
    }

    if (
      currentTab !== prevProps.match.params.currentTab ||
      currentView !== prevProps.match.params.currentView
    ) {
      if (
        (currentTab === TAB_ATTENDEES && !currentView) ||
        [
          MEETING_CREATE_ATTENDEES,
          MEETING_EDIT_ATTENDEES,
          CONVERSATION_CREATE_ATTENDEES,
          CONVERSATION_EDIT_ATTENDEES
        ].indexOf(currentView) !== -1
      ) {
        headerData['rightBtn'] = 'search';
      } else {
        headerData['rightBtn'] = null;
      }
    }

    if (!currentView && prevProps.match.params.currentView) {
      this.refreshData();
    }

    if (Object.keys(headerData).length) {
      this.props.updateHeader(headerData);
    }

    if (this.props.global.searchValue !== prevProps.global.searchValue) {
      this.searchAttendees();
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }

    if (
      this.state.scrollY &&
      [TAB_ATTENDEES, TAB_CONVERSATIONS, TAB_MEETINGS].indexOf(currentTab) !== -1 &&
      !currentView &&
      prevProps.match.params.currentView
    ) {
      this.restoreScroll();
    }

    if (
      prevProps.global.mustRefetchWhosHereMessagesUnread &&
      this.props.global.mustRefetchWhosHereMessagesUnread
    ) {
      this.props.updateGlobal({
        mustRefetchWhosHereMessagesUnread: false
      });
      this.props.fetchWhosHereMessageUnread({ event_id: eventId, whos_here_id: whosHereId });
    }
  }

  subscribeToSocketEvents = () => {
    const { whosHereId } = this.props.match.params;
    this.socket = Socket.subscribeChannel(`${SC_WHOS_HERE}.${whosHereId}`);
    Socket.listenEvents(this.socket, [
      SC_WHOS_HERE_CONVERSATION + CREATED,
      SC_WHOS_HERE_CONVERSATION + UPDATED,
      SC_WHOS_HERE_CONVERSATION + DELETED,
      SC_WHOS_HERE_MESSAGE + CREATED,
      SC_WHOS_HERE_MEETING + CREATED,
      SC_WHOS_HERE_MEETING + UPDATED,
      SC_WHOS_HERE_MEETING + DELETED,
      SC_ATTENDEE + CREATED,
      SC_ATTENDEE + UPDATED,
      SC_ATTENDEE + DELETED
    ]);
    this.setState({
      socketIsListening: true
    });
  };

  unsubscribeSocketEvents = whosHereId => {
    Socket.unsubscribeChannel(`${SC_WHOS_HERE}.${whosHereId}`);
    this.setState({
      socketIsListening: false
    });
  };

  getAPiData = ({ eventId, whosHereId }) => {
    this.props.fetchWhosHere({ event_id: eventId, whos_here_id: whosHereId });
    this.props.fetchWhosHereMessageUnread({ event_id: eventId, whos_here_id: whosHereId });
  };

  saveScroll = () => {
    this.setState({ scrollY: window.pageYOffset });
  };

  restoreScroll = () => {
    window.scrollTo({ top: this.state.scrollY });
    // Recursive to fix infine scroll with ios
    setTimeout(() => {
      if (window.pageYOffset < this.state.scrollY) {
        this.restoreScroll();
      }
    }, 500);
  };

  refreshData = () => {
    this.getAPiData(this.props.match.params);
  };

  fetchAttendees = data => {
    this.props.fetchAttendees({
      ...data,
      q: this.props.global.searchValue,
      ...this.getSharedApiParams()
    });
  };

  fetchConversations = data => {
    this.props.fetchWhosHereConversations({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  fetchMeetings = data => {
    this.props.fetchWhosHereMeetings({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  fetchMessages = data => {
    this.props.fetchWhosHereMessages({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  fetchAttendee = data => {
    this.props.fetchAttendee({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  fetchFavorites = data => {
    this.props.fetchFavorites({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  fetchConversation = data => {
    this.props.fetchWhosHereConversation({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  fetchMeeting = data => {
    this.props.fetchWhosHereMeeting({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  postConversation = data => {
    this.props.postWhosHereConversation({
      ...data,
      ...this.getSharedApiParams(),
      type: 'many'
    });
  };

  postMessage = data => {
    this.props.postWhosHereMessage({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  postMeeting = data => {
    this.props.postWhosHereMeeting({
      ...data,
      ...this.getSharedApiParams(),
      type: 'many'
    });
  };

  postMeetingStatus = data => {
    this.props.postWhosHereMeetingStatus({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  postConversationLeave = data => {
    this.props.postWhosHereConversationLeave({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  postWhosHereMessageRead = data => {
    this.props.postWhosHereMessageRead({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  //  FILE UPLOAD
  uploadPostFile = data => {
    this.props.postFile({
      ...this.getSharedApiParams(),
      ...data
    });
  };

  uploadCameraFile = data => {
    this.props.postCameraFile({
      ...this.getSharedApiParams(),
      ...data,
      ...{
        session_id: this.props.global.sessionId
      }
    });
  };

  deleteMeeting = data => {
    this.props.deleteWhosHereMeeting({
      ...data,
      ...this.getSharedApiParams()
    });
  };

  getSharedApiParams = () => {
    const { appId, eventId, whosHereId } = this.props.match.params;
    return {
      app_id: +appId,
      event_id: +eventId,
      whos_here_id: +whosHereId
    };
  };

  updateTab = ({ tab }) => {
    const { appId, eventId, whosHereId } = this.props.match.params;
    // Refresh data
    if (tab === this.props.match.params.currentTab) {
      if (tab === TAB_CONVERSATIONS) {
        this.fetchConversations({ reset: true });
      } else if (tab === TAB_MEETINGS) {
        this.fetchMeetings({ reset: true });
      } else if (tab === TAB_ATTENDEES) {
        this.fetchAttendees({ reset: true });
        this.fetchFavorites({ reset: true });
      }
    } else {
      this.props.history.push(`/apps/${appId}/events/${eventId}/whos_here/${whosHereId}/${tab}`);
    }
  };

  updateView = ({ tab, view, id, replace }) => {
    const { appId, eventId, whosHereId, currentTab } = this.props.match.params;
    this.saveScroll();
    const baseUrl = `/apps/${appId}/events/${eventId}/whos_here/${whosHereId}`;
    const url = `${baseUrl}/${tab || currentTab}${view ? `/${view}` : ''}${id ? `/${id}` : ''}`;
    if (replace) {
      this.props.history.replace(url);
    } else {
      this.props.history.push(url);
    }
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  setComponentFixedBottomHeight = height => {
    this.props.updateGlobal({
      componentFixedBottomHeight: height
    });
  };

  searchAttendees = () => {
    if (this.searchTimeout) {
      this.searchTimeout = clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.fetchAttendees({ reset: true });
    }, SEARCH_DELAY);
  };

  render() {
    if (!this.props.user || !this.props.whosHere) {
      return null;
    }

    const { currentTab, currentView } = this.props.match.params;
    const currentId = +this.props.match.params.currentId;
    // props
    const {
      attendees,
      attendeesIds,
      favoritesAttendees,
      favoritesAttendeesIds,
      conversations,
      conversationsIds,
      currentAttendee,
      currentConversation,
      currentMeeting,
      event,
      global,
      hasMoreAttendees,
      hasMoreConversations,
      hasMoreMeetings,
      hasMoreMessages,
      header,
      history,
      isLoadingAttendees,
      isLoadingConversations,
      isLoadingMeetings,
      isLoadingMessages,
      meetings,
      meetingsIds,
      messages,
      messagesIds,
      notifsMeetingsCount,
      notifsMessagesCount,
      refreshData,
      t,
      theme,
      user,
      whosHere,
      resetNotification
    } = this.props;

    const { height: headerHeight } = header;
    const {
      componentFixedTopHeight,
      componentFixedBottomHeight,
      presenceIds,
      locale,
      offline,
      accessToken
    } = global;

    // functions
    const {
      deleteMeeting,
      fetchAttendee,
      fetchAttendees,
      fetchConversation,
      fetchConversations,
      fetchMeeting,
      fetchMeetings,
      fetchMessages,
      fetchFavorites,
      postConversation,
      postConversationLeave,
      postMeeting,
      postMeetingStatus,
      postMessage,
      uploadPostFile,
      uploadCameraFile,
      setComponentFixedBottomHeight,
      setComponentFixedTopHeight,
      updateTab,
      updateView,
      postWhosHereMessageRead
    } = this;

    const matchParams = this.props.match.params;

    return whosHere.is_open ? (
      <WhosHere
        {...{
          attendees,
          attendeesIds,
          favoritesAttendees,
          favoritesAttendeesIds,
          componentFixedBottomHeight,
          componentFixedTopHeight,
          conversations,
          conversationsIds,
          currentAttendee,
          currentConversation,
          currentId,
          currentMeeting,
          currentTab,
          currentView,
          event,
          hasMoreAttendees,
          hasMoreConversations,
          hasMoreMeetings,
          hasMoreMessages,
          headerHeight,
          history,
          isLoadingAttendees,
          isLoadingConversations,
          isLoadingMeetings,
          isLoadingMessages,
          locale,
          matchParams,
          meetings,
          meetingsIds,
          messages,
          messagesIds,
          notifsMessagesCount,
          notifsMeetingsCount,
          t,
          theme,
          user,
          whosHere,
          presenceIds,
          offline,
          resetNotification,
          accessToken
        }}
        {...{
          deleteMeeting,
          fetchAttendee,
          fetchAttendees,
          fetchFavorites,
          fetchConversation,
          fetchConversations,
          fetchMeeting,
          fetchMeetings,
          fetchMessages,
          postConversation,
          postConversationLeave,
          postMeeting,
          postMeetingStatus,
          postMessage,
          postWhosHereMessageRead,
          uploadPostFile,
          uploadCameraFile,
          setComponentFixedBottomHeight,
          setComponentFixedTopHeight,
          updateTab,
          updateView
        }}
      />
    ) : !whosHere.is_open ? (
      <ClosedService
        icon={getServiceIconByType(whosHere.custom_icon || 'whoshere')}
        name={whosHere.name}
        theme={theme}
        t={t}
        onRefresh={refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  deleteWhosHereMeeting: deleteWhosHereMeeting,
  fetchAttendee: fetchAttendee,
  fetchAttendees: fetchAttendees,
  fetchWhosHere: fetchWhosHere,
  fetchWhosHereConversation: fetchWhosHereConversation,
  fetchWhosHereConversations: fetchWhosHereConversations,
  fetchWhosHereMeeting: fetchWhosHereMeeting,
  fetchWhosHereMeetings: fetchWhosHereMeetings,
  fetchWhosHereMessages: fetchWhosHereMessages,
  fetchFavorites: fetchFavorites,
  postWhosHereConversation: postWhosHereConversation,
  postWhosHereConversationLeave: postWhosHereConversationLeave,
  postFile: postFile,
  postWhosHereMeeting: postWhosHereMeeting,
  postWhosHereMeetingStatus: postWhosHereMeetingStatus,
  postWhosHereMessage: postWhosHereMessage,
  postCameraFile: postCameraFile,
  updateGlobal: updateGlobal,
  updateHeader: updateHeader,
  resetNotification: resetNotification,
  fetchWhosHereMessageUnread: fetchWhosHereMessageUnread,
  postWhosHereMessageRead: postWhosHereMessageRead
};

const mapStateToProps = (state, ownProps) => {
  return {
    attendees: selectFilteredAttendees(state, ownProps),
    attendeesIds: selectFilteredAttendeesIds(state, ownProps),
    favoritesAttendees: selectFilteredFavoritesAttendees(state, ownProps),
    favoritesAttendeesIds: selectFilteredFavoritesAttendeesIds(state, ownProps),
    conversations: selectFilteredConversations(state, ownProps),
    conversationsIds: selectFilteredConversationsIds(state, ownProps),
    currentAttendee: selectCurrentAttendee(state, ownProps),
    currentConversation: selectCurrentConversation(state, ownProps),
    currentMeeting: selectCurrentMeeting(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    global: selectGlobal(state, ownProps),
    hasMoreAttendees: selectHasMoreAttendees(state, ownProps),
    hasMoreConversations: selectHasMoreConversations(state, ownProps),
    hasMoreMeetings: selectHasMoreMeetings(state, ownProps),
    hasMoreMessages: selectHasMoreMessages(state, ownProps),
    header: selectHeader(state, ownProps),
    isLoadingAttendees: selectIsLoadingAttendees(state, ownProps),
    isLoadingConversations: selectIsLoadingConversations(state, ownProps),
    isLoadingMeetings: selectIsLoadingMeetings(state, ownProps),
    isLoadingMessages: selectIsLoadingMessages(state, ownProps),
    meetings: selectFilteredMeetings(state, ownProps),
    meetingsIds: selectFilteredMeetingsIds(state, ownProps),
    messages: selectFilteredMessages(state, ownProps),
    messagesIds: selectFilteredMessagesIds(state, ownProps),
    notifsMessagesCount: selectNotifsMessagesCount(state, ownProps),
    notifsMeetingsCount: selectNotifsMeetingsCount(state, ownProps),
    user: selectUser(state, ownProps),
    whosHere: selectCurrentWhosHereId(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhosHereContainer);
