import {
  SUCCESS,
  FETCH_AGENDA_CATEGORIES,
  FETCH_ATTENDEES,
  FETCH_BONUSES,
  FETCH_COUNTRIES,
  FETCH_EVENTS,
  FETCH_EXHIBITORS,
  FETCH_MAP_POIS,
  FETCH_SHORT_LINKS,
  FETCH_SESSIONS,
  FETCH_SMARTQA_COMMENTS,
  FETCH_SMARTQA_POSTS,
  FETCH_SPEAKERS,
  FETCH_SPONSORS,
  FETCH_SURVEY_PAGES,
  FETCH_TIMELINE_ITEMS,
  FETCH_TWEETS,
  FETCH_VOTE_ROUNDS,
  FETCH_WHOS_HERE_CONVERSATIONS,
  FETCH_WHOS_HERE_MEETINGS,
  FETCH_WHOS_HERE_MESSAGES,
  FETCH_YOUTUBE_VIDEOS,
  FETCH_FAVORITES,
  POST_SMARTQA_POST,
  POST_FAVORITE
} from '../constants/actionApi';
import { addOrUpdateIdsInState, removeIdInState } from '../helpers/data';
import initialState from '../store/initialState';
import {
  API_AGENDA_CATEGORIES,
  API_AGENDA_SESSIONS,
  API_ATTENDEES,
  API_BONUSES,
  API_COUNTRIES,
  API_EVENTS,
  API_EXHIBITORS,
  API_SHORT_LINKS,
  API_SMARTQA_COMMENTS,
  API_SMARTQA_QUESTIONS,
  API_SPEAKERS,
  API_SPONSORS,
  API_SURVEY_PAGES,
  API_TIMELINE_ITEMS,
  API_TWEETS,
  API_VOTE_ROUNDS,
  API_WHOS_HERE_CONVERSATIONS,
  API_WHOS_HERE_MEETINGS,
  API_WHOS_HERE_MESSAGES,
  API_YOUTUBE_VIDEOS,
  API_FAVORITES,
  API_MAP_POIS
} from '../constants/apiRoutes';
import {
  SC_WHOS_HERE_MESSAGE,
  SC_SMART_QA_COMMENT,
  SC_WHOS_HERE_MEETING,
  SC_WHOS_HERE_CONVERSATION,
  CREATED,
  UPDATED
} from '../constants/socket';

export default (state = initialState.sortIds, { type, payload }) => {
  let userInConversation;
  switch (type) {
    case FETCH_EXHIBITORS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_EXHIBITORS, 'list_id');
    case FETCH_SPEAKERS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_SPEAKERS, 'list_id');
    case FETCH_SPONSORS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_SPONSORS, 'list_id');
    case FETCH_SMARTQA_POSTS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_SMARTQA_QUESTIONS, 'smart_qa_id');
    case FETCH_SMARTQA_COMMENTS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_SMARTQA_COMMENTS, 'commentable_id');
    case FETCH_ATTENDEES + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_ATTENDEES, null);
    case FETCH_WHOS_HERE_CONVERSATIONS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_WHOS_HERE_CONVERSATIONS, 'whos_here_id');
    case FETCH_WHOS_HERE_MESSAGES + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_WHOS_HERE_MESSAGES, 'conversation_id');
    case FETCH_WHOS_HERE_MEETINGS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_WHOS_HERE_MEETINGS, 'whos_here_id');
    case FETCH_AGENDA_CATEGORIES + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_AGENDA_CATEGORIES, 'agenda_id');
    case FETCH_SESSIONS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_AGENDA_SESSIONS, 'agenda_id');
    case FETCH_COUNTRIES + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_COUNTRIES, null);
    case FETCH_BONUSES + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_BONUSES, 'group_id');
    case FETCH_SHORT_LINKS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_SHORT_LINKS, null);
    case FETCH_SURVEY_PAGES + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_SURVEY_PAGES, 'survey_id');
    case FETCH_TWEETS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_TWEETS, null);
    case FETCH_YOUTUBE_VIDEOS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_YOUTUBE_VIDEOS, null);
    case FETCH_VOTE_ROUNDS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_VOTE_ROUNDS, 'vote_id');
    case FETCH_EVENTS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_EVENTS, 'group_id');
    case FETCH_TIMELINE_ITEMS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_TIMELINE_ITEMS, 'timeline_id');
    case FETCH_MAP_POIS + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_MAP_POIS, 'map_id');
    case FETCH_FAVORITES + SUCCESS:
      return addOrUpdateIdsInState(state, payload, API_FAVORITES + '/' + payload.params.type, null);
    case POST_FAVORITE + SUCCESS:
      return addOrUpdateIdsInState(
        state,
        payload,
        API_FAVORITES + '/' + payload.params.favorite_type,
        null
      );
    case POST_SMARTQA_POST + SUCCESS:
      if (payload.data.is_displayed_to_attendees) {
        return addOrUpdateIdsInState(state, payload, API_SMARTQA_QUESTIONS, 'smart_qa_id', true);
      } else {
        return state;
      }

    case SC_SMART_QA_COMMENT + CREATED:
      return addOrUpdateIdsInState(state, payload, API_SMARTQA_COMMENTS, 'commentable_id', true);

    case SC_WHOS_HERE_MESSAGE + CREATED:
      return addOrUpdateIdsInState(state, payload, API_WHOS_HERE_MESSAGES, 'conversation_id', true);

    case SC_WHOS_HERE_MEETING + CREATED:
      userInConversation =
        payload.data.attendees.find(attendee => attendee.id === payload.user.id) ||
        payload.data.owner.id === payload.user.id;
      if (userInConversation) {
        return addOrUpdateIdsInState(state, payload, API_WHOS_HERE_MEETINGS, 'whos_here_id', true);
      } else {
        return state;
      }

    case SC_WHOS_HERE_MEETING + UPDATED:
      userInConversation =
        payload.data.attendees.find(attendee => attendee.id === payload.user.id) ||
        payload.data.owner.id === payload.user.id;
      if (userInConversation) {
        return addOrUpdateIdsInState(state, payload, API_WHOS_HERE_MEETINGS, 'whos_here_id', true);
      } else if (!userInConversation) {
        return removeIdInState(state, payload, API_WHOS_HERE_MEETINGS);
      } else {
        return state;
      }

    case SC_WHOS_HERE_CONVERSATION + CREATED:
      userInConversation =
        payload.data.attendees.find(attendee => attendee.id === payload.user.id) ||
        payload.data.owner.id === payload.user.id;
      if (userInConversation) {
        return addOrUpdateIdsInState(
          state,
          payload,
          API_WHOS_HERE_CONVERSATIONS,
          'whos_here_id',
          true
        );
      } else {
        return state;
      }

    case SC_WHOS_HERE_CONVERSATION + UPDATED:
      userInConversation =
        payload.data.attendees.find(attendee => attendee.id === payload.user.id) ||
        payload.data.owner.id === payload.user.id;
      if (userInConversation) {
        return addOrUpdateIdsInState(
          state,
          payload,
          API_WHOS_HERE_CONVERSATIONS,
          'whos_here_id',
          true
        );
      } else if (!userInConversation) {
        return removeIdInState(state, payload, API_WHOS_HERE_CONVERSATIONS);
      } else {
        return state;
      }

    default:
      return state;
  }
};
