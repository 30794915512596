import React from 'react';
import FlexParent from '../../components/shared/flex/FlexParent';
import Button from '../../components/shared/Button';

const Round = props => {
  const { t, onClickValidate, isRoundValid, isSending, offline } = props;

  return (
    <React.Fragment>
      {props.children}
      <FlexParent justifyContent={'center'}>
        <Button
          btntype={'primary'}
          onClick={onClickValidate}
          disabled={!isRoundValid() || isSending || offline}
        >
          {!isSending ? t('btn_validate') : t('btn_saving')}
        </Button>
      </FlexParent>
    </React.Fragment>
  );
};

export default Round;
