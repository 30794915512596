import React from 'react';
import styled from 'styled-components';
import Card from './Card';
import Icon from '../../icons/Icon';

const Container = styled.div`
  margin: 20px 0;
`;

const CardContainer = styled.div`
  margin-top: -40px;
`;

const IconContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  z-index: 10;
  cursor: ${props => (props.onClick ? 'pointer' : 'initial')};
`;

const Logo = styled.img`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: block;
  object-fit: cover;
  z-index: 5;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  background-color: ${props => (props.hasShadow ? 'white' : 'transparent')};
`;

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  height: 80px;
  left: 0;
  width: 80px;
  border-radius: 50%;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 5;
`;

class CardIcon extends React.Component {
  render() {
    return (
      <Container>
        <IconContainer
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.props.subIconAction}
        >
          {this.props.icon ? (
            <React.Fragment>
              <Icon
                icon={this.props.icon}
                color={'#fff'}
                padding={'20px'}
                width={'80px'}
                height={'80px'}
                borderRadius={this.props.logoBorderRadius ? this.props.logoBorderRadius : '0'}
                backgroundColor={this.props.theme.mainColor}
              />
              {this.props.withShadow && <Shadow />}
            </React.Fragment>
          ) : this.props.image ? (
            <React.Fragment>
              <Logo
                src={this.props.image}
                borderRadius={this.props.logoBorderRadius ? this.props.logoBorderRadius : '0'}
                hasShadow={this.props.withShadow}
              />
              {this.props.withShadow && <Shadow />}
            </React.Fragment>
          ) : null}

          {this.props.subIcon ? (
            <Icon
              icon={this.props.subIcon}
              position={'absolute'}
              bottom={'-2px'}
              right={'-2px'}
              color={'#fff'}
              padding={'7px'}
              width={'26px'}
              height={'26px'}
              borderRadius={this.props.logoBorderRadius ? this.props.logoBorderRadius : '0'}
              backgroundColor={this.props.theme.mainColor}
            />
          ) : null}
        </IconContainer>

        <CardContainer>
          <Card {...this.props}>{this.props.children}</Card>
        </CardContainer>
      </Container>
    );
  }
}

export default CardIcon;
