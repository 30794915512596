import React from 'react';
import FlexParent from '../../components/shared/flex/FlexParent';
import FlexItem from '../../components/shared/flex/FlexItem';
import styled from 'styled-components';
import Card from '../../components/shared/card/Card';
import { getEventIconBySize } from '../../helpers/format';
import { getTimeFromNow } from '../../helpers/date';
import { nl2br } from '../../helpers/format';
import HtmlContent from '../../components/shared/HtmlContent';
import { ListItemLogo, ListItemTitle, ListItemSubTitle } from '../../components/shared/ListItem';

const Description = styled.div`
  margin: 15px 0 0;
  text-align: justify;
`;

export const PostPicture = styled.img`
  display: block;
  width: 100%;
  margin: 15px 0;
`;

class Message extends React.Component {
  render() {
    const { message, event } = this.props;
    return (
      <Card margin={`15px 0`} padding={'15px'}>
        <FlexParent alignItems={'center'}>
          <FlexItem>
            {event.icon_url ? (
              <ListItemLogo src={getEventIconBySize(event.icon_url, '144')} borderRadius={'0'} />
            ) : null}
          </FlexItem>
          <FlexItem grow={1}>
            <ListItemTitle>{event.name}</ListItemTitle>
            <ListItemSubTitle>{getTimeFromNow(message.created_at)}</ListItemSubTitle>
          </FlexItem>
        </FlexParent>

        {message.text && (
          <Description>
            <HtmlContent>{nl2br(message.text)}</HtmlContent>
          </Description>
        )}

        {message.picture_url && <PostPicture src={message.picture_url} />}
      </Card>
    );
  }
}

export default Message;
