import { createSelector } from 'reselect';

const user = state => state.user;

export const selectUser = createSelector(
  [user],
  user => {
    if (!Object.keys(user).length) {
      return null;
    }
    return user;
  }
);
