import { connect } from 'react-redux';
import { fetchPracticalInfo } from '../../actions/api';
import { updateHeader } from '../../actions/local';
import PracticalInfo from './PracticalInfo';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';
import { selectCurrentPracticalInfo } from '../../selectors/practicalInfo';
import { selectUser } from '../../selectors/user';
import { selectGlobal } from '../../selectors/global';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class PracticalInfoContainer extends React.Component {
  state = {
    openedId: null
  };

  componentDidMount() {
    const { appId, eventId, practicalInfoId } = this.props.match.params;
    this.getAPiData(this.props.match.params);
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'practicalinfo',
      viewableId: +practicalInfoId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });

    if (
      this.props.practicalInfo &&
      this.props.practicalInfo.parts &&
      this.props.practicalInfo.parts.length === 1
    ) {
      this.setState({
        openedId: this.props.practicalInfo.parts[0].id
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { practicalInfoId } = this.props.match.params;
    const oldPracticalInfoId = prevProps.match.params.practicalInfoId;
    if (practicalInfoId !== oldPracticalInfoId) {
      this.getAPiData(this.props.match.params);
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }

    if (
      !prevProps.practicalInfo &&
      this.props.practicalInfo &&
      this.props.practicalInfo.parts &&
      this.props.practicalInfo.parts.length === 1
    ) {
      this.setState({
        openedId: this.props.practicalInfo.parts[0].id
      });
    }
  }

  getAPiData({ eventId, practicalInfoId }) {
    this.props.fetchPracticalInfo({
      event_id: eventId,
      practical_info_id: practicalInfoId
    });
  }

  refreshData = event => {
    this.getAPiData(this.props.match.params);
  };

  onClickItem = itemId => {
    if (itemId === this.state.openedId) {
      this.setState({
        openedId: null
      });
    } else {
      this.setState({
        openedId: itemId
      });
    }
  };

  render() {
    if (!this.props.practicalInfo) {
      return null;
    }
    const { practicalInfo, theme, t } = this.props;
    return practicalInfo.is_open ? (
      <PracticalInfo
        practicalInfo={practicalInfo}
        theme={theme}
        t={t}
        onClickItem={this.onClickItem}
        openedId={this.state.openedId}
      />
    ) : !practicalInfo.is_open ? (
      <ClosedService
        icon={getServiceIconByType(practicalInfo.custom_icon || 'practicalinfo')}
        name={practicalInfo.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchPracticalInfo: fetchPracticalInfo
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    practicalInfo: selectCurrentPracticalInfo(state, ownProps),
    global: selectGlobal(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticalInfoContainer);
