import Post from './Post';
import React from 'react';
import AddPost from './AddPost';
import Card from '../../components/shared/card/Card';
import styled from 'styled-components';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import { motion, AnimatePresence } from 'framer-motion';
import ShowMore from '../../components/shared/ShowMore';
import Notification from './Notification';

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

class Smartqa extends React.Component {
  componentDidMount() {
    this.props.fetchSmartqaPosts({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.smartqa.id !== prevProps.smartqa.id) {
      this.props.fetchSmartqaPosts({ reset: true });
    }
  }

  render() {
    const {
      isInAppLive,
      t,
      theme,
      smartqa,
      user,
      headerHeight,
      componentFixedTopHeight,
      matchParams,
      posts,
      postsIds,
      postSmartqaPost,
      sendReport,
      sendReaction,
      deleteReaction,
      uploadPostFile,
      uploadCameraFile,
      setVisibility,
      isAnonymous,
      navigateToPostDetails,
      setComponentFixedTopHeight,
      fetchSmartqaPosts,
      hasMoreSmartqaPosts,
      isLoadingSmartqaPosts,
      notifsPostsCount,
      isSaving,
      accessToken,
      showFullScreenPicture,
      offline
    } = this.props;
    return (
      <React.Fragment>
        <AddPost
          {...{
            isInAppLive,
            t,
            theme,
            user,
            headerHeight,
            smartqa,
            postSmartqaPost,
            setVisibility,
            isAnonymous,
            uploadPostFile,
            uploadCameraFile,
            setComponentFixedTopHeight,
            isSaving,
            matchParams,
            accessToken,
            notifsPostsCount,
            offline
          }}
        />

        <Container componentFixedTopHeight={componentFixedTopHeight}>
          {smartqa.description && (
            <AnimatePresence>
              {
                <motion.div
                  key="desc_card"
                  initial={false}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <Card
                    margin={'0 0 15px 0'}
                    padding={'15px 20px'}
                    textAlign={'center'}
                    position={'relative'}
                    color={theme.darkGrey}
                  >
                    <ShowMore t={t}>{smartqa.description}</ShowMore>
                  </Card>
                </motion.div>
              }
            </AnimatePresence>
          )}

          <InfiniteScroll
            loadMore={fetchSmartqaPosts}
            hasMore={hasMoreSmartqaPosts}
            isLoading={isLoadingSmartqaPosts}
          >
            <div
              style={{
                position: 'fixed',
                minWidth: '185px',
                zIndex: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                whiteSpace : 'nowrap'
              }}
            >
              {notifsPostsCount ? (
                <Notification
                  {...{
                    t,
                    theme,
                    fetchSmartqaPosts
                  }}
                />
              ) : null}
            </div>

            {postsIds.map(key => {
              const post = posts[key];
              return (
                <Post
                  {...{
                    key,
                    t,
                    matchParams,
                    post,
                    theme,
                    user,
                    smartqa,
                    sendReport,
                    sendReaction,
                    deleteReaction,
                    navigateToPostDetails,
                    showFullScreenPicture,
                    offline
                  }}
                />
              );
            })}
          </InfiniteScroll>
        </Container>
      </React.Fragment>
    );
  }
}

export default Smartqa;
