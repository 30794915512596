import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import React from 'react';

import { selectTheme } from '../../selectors/theme';

class ThemeContainer extends React.Component {
  render() {
    return <ThemeProvider theme={this.props.theme || {}}>{this.props.children}</ThemeProvider>;
  }
}

const mapDispatchToProps = null;

const mapStateToProps = (state, ownProps) => {
  return {
    theme: selectTheme(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemeContainer);
