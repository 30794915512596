import { getFullName, getJobWithCompany } from '../../helpers/format';
import ListItem from '../../components/shared/ListItem';
import React from 'react';
import ServiceHeader from '../../components/shared/ServiceHeader';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import Card from '../../components/shared/card/Card';
import { Filler } from '../../components/shared/ListItem';
import { motion, AnimatePresence } from 'framer-motion';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

class SpeakerList extends React.Component {
  componentDidMount() {
    this.props.fetchSpeakers({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.speakerList.id !== prevProps.speakerList.id) {
      this.props.fetchSpeakers({ reset: true });
    }
  }
  render() {
    const {
      t,
      theme,
      speakerList,
      speakers,
      speakersIds,
      isLoadingSpeakers,
      hasMoreSpeakers,
      showItemDetails,
      fetchSpeakers,
      matchParams,
      global,
      header,
      ...props
    } = this.props;
    return (
      <React.Fragment>
        <AnimatePresence initial={false}>
          {!header.showSearchBar ? (
            <motion.div
              key="speaker_desc"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0, transition: { duration: 0 } }}
            >
              <ServiceHeader
                t={t}
                icon={getServiceIconByType(speakerList.custom_icon || 'speakerlist')}
                name={speakerList.name}
                description={speakerList.description}
                theme={theme}
              />
            </motion.div>
          ) : (
            <Filler key="speaker_desc" />
          )}
        </AnimatePresence>

        {global.searchValue && !speakersIds.length ? (
          <Card padding={'15px'} textAlign={'center'}>
            {t('nothing_found')} {global.searchValue}
          </Card>
        ) : (
          <InfiniteScroll
            loadMore={fetchSpeakers}
            hasMore={hasMoreSpeakers}
            isLoading={isLoadingSpeakers}
          >
            {speakersIds.map(key => {
              const speaker = speakers[key];
              const {
                id,
                avatar_url,
                first_name,
                last_name,
                title,
                company,
                job_title,
                is_vip
              } = speaker;

              return (
                <ListItem
                  type={'speaker'}
                  model={speaker}
                  key={id}
                  id={id}
                  heading={getFullName(first_name, last_name, title)}
                  desc={getJobWithCompany(company, job_title)}
                  logoUrl={avatar_url}
                  isVip={is_vip}
                  onClickItem={showItemDetails}
                  matchParams={matchParams}
                  theme={theme}
                  {...props}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </React.Fragment>
    );
  }
}

export default SpeakerList;
