import Question from '../../components/shared/survey/Question';
import Page from './Page';
import React from 'react';
import ServiceHeader from '../../components/shared/ServiceHeader';
import Separator from '../../components/shared/Separator';
import styled from 'styled-components';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';

const PageIndicator = styled.div`
  height: 4px;
  width: ${props => ((props.currentPage + 1) / props.pagesCount) * 100}%;
  background-color: ${props => props.theme.mainColor};
  margin-bottom: 20px;
`;

class Survey extends React.Component {
  onClickNext = () => {
    if (this.props.currentPage === this.props.pages.length - 1) {
      this.props.sendResults();
    } else {
      this.props.goToNextPage();
    }
  };

  onClickPrev = () => {
    this.props.goToPrevPage();
  };

  onChangeInputValue = (questionId, value, type) => {
    this.props.updateAnswer(questionId, value, type);
  };

  render() {
    const {
      survey,
      pages,
      t,
      theme,
      answers,
      currentPage,
      isPageValid,
      isSending,
      offline
    } = this.props;

    const { onClickNext, onClickPrev } = this;

    return (
      <React.Fragment>
        <ServiceHeader
          t={t}
          icon={getServiceIconByType(survey.custom_icon || 'survey')}
          name={survey.name}
          description={survey.description}
          theme={theme}
        />

        {pages.length > 1 ? (
          <PageIndicator currentPage={currentPage} pagesCount={pages.length} />
        ) : null}

        {pages.map((page, index) => {
          return (
            <Page
              key={page.id}
              pagesCount={pages.length}
              pageIndex={index}
              {...{ t, currentPage, onClickNext, onClickPrev, isPageValid, isSending, offline }}
            >
              {page.data.map(question => {
                return question.type === 'sectionbreak' ? (
                  <Separator key={question.id}>{question.label}</Separator>
                ) : (
                  <Question
                    key={question.id}
                    t={t}
                    question={question}
                    alignment={question.option_alignment}
                    onChangeInputValue={this.onChangeInputValue}
                    answer={answers[question.id] || { options: [], value: '' }}
                  />
                );
              })}
            </Page>
          );
        })}
      </React.Fragment>
    );
  }
}

export default Survey;
