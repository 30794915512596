import { SUCCESS, FETCH_EVENTS, FETCH_EVENT } from '../constants/actionApi';
import { RESET_EVENTS } from '../constants/actionLocal';
import { addOrUpdateItemsInState, resetObjectInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.events, { type, payload }) => {
  switch (type) {
    case FETCH_EVENTS + SUCCESS:
    case FETCH_EVENT + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    case RESET_EVENTS:
      return resetObjectInState(state, initialState.events);
    default:
      return state;
  }
};
