import { createSelector } from 'reselect';
import {
  filterObjects,
  filterIds,
  hasMore,
  hasMoreCursor,
  isLoading,
  getNotifsCount
} from '../helpers/data';
import {
  API_ATTENDEES,
  API_WHOS_HERE_CONVERSATIONS,
  API_WHOS_HERE_MEETINGS,
  API_WHOS_HERE_MESSAGES,
  API_FAVORITES
} from '../constants/apiRoutes';
import {
  ATTENDEE_DETAILS,
  CONVERSATION_DETAILS,
  CONVERSATION_EDIT,
  CONVERSATION_EDIT_ATTENDEES,
  MEETING_DETAILS,
  MEETING_EDIT,
  MEETING_EDIT_ATTENDEES
} from '../constants/app';

const currentEventId = (state, props) => props.match.params.eventId;
const currentWhosHereId = (state, props) => props.match.params.whosHereId;
const currentConversationId = (state, props) =>
  props.match.params.currentId &&
  [CONVERSATION_DETAILS, CONVERSATION_EDIT, CONVERSATION_EDIT_ATTENDEES].indexOf(
    props.match.params.currentView
  ) !== -1
    ? props.match.params.currentId
    : null;

const currentAttendeeId = (state, props) =>
  props.match.params.currentId && props.match.params.currentView === ATTENDEE_DETAILS
    ? props.match.params.currentId
    : null;

const currentMeetingId = (state, props) =>
  props.match.params.currentId &&
  [MEETING_DETAILS, MEETING_EDIT, MEETING_EDIT_ATTENDEES].indexOf(
    props.match.params.currentView
  ) !== -1
    ? props.match.params.currentId
    : null;

const whosHere = (state, props) => state.whosHere;
const attendees = (state, props) => state.whosHereAttendees;
const attendeesIds = (state, props) => state.sortIds[API_ATTENDEES] || [];
const conversations = (state, props) => state.whosHereConversations;
const conversationsIds = (state, props) => state.sortIds[API_WHOS_HERE_CONVERSATIONS] || [];
const meetings = (state, props) => state.whosHereMeetings;
const meetingsIds = (state, props) => state.sortIds[API_WHOS_HERE_MEETINGS] || [];
const messages = (state, props) => state.whosHereMessages;
const messagesIds = (state, props) => state.sortIds[API_WHOS_HERE_MESSAGES] || [];
const favorites = (state, props) => state.favorites;
const favoritesIds = (state, props) => state.sortIds[`${API_FAVORITES}/attendee`] || [];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;
const notifications = (state, props) => state.notifications;
const messagesUnread = (state, props) => state.whosHereMessagesNotifications;

// --- Select current object --------------
export const selectCurrentWhosHereId = createSelector(
  [whosHere, currentWhosHereId],
  (whosHere, currentWhosHereId) => {
    return whosHere[currentWhosHereId] || null;
  }
);

export const selectCurrentConversation = createSelector(
  [conversations, currentConversationId],
  (conversations, conversationId) => {
    return conversations[conversationId] || null;
  }
);

export const selectCurrentAttendee = createSelector(
  [attendees, currentAttendeeId],
  (attendees, attendeeId) => {
    return attendees[attendeeId] || null;
  }
);

export const selectCurrentMeeting = createSelector(
  [meetings, currentMeetingId],
  (meetings, meetingId) => {
    return meetings[meetingId] || null;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredAttendees = createSelector(
  [attendees],
  attendees => {
    return attendees;
  }
);

export const selectFilteredFavoritesAttendees = createSelector(
  [favorites],
  favorites => {
    return filterObjects(favorites, favorite => {
      return favorite.model_type === 'attendee';
    });
  }
);

export const selectFilteredConversations = createSelector(
  [currentWhosHereId, conversations],
  (whosHereId, conversations) => {
    return filterObjects(conversations, conversation => {
      return conversation.whos_here_id === +whosHereId;
    });
  }
);

export const selectFilteredMeetings = createSelector(
  [currentWhosHereId, meetings],
  (whosHereId, meetings) => {
    return filterObjects(meetings, meeting => {
      return meeting.whos_here_id === +whosHereId;
    });
  }
);

export const selectFilteredMessages = createSelector(
  [currentConversationId, messages],
  (conversationId, messages) => {
    return filterObjects(messages, message => {
      return message.conversation_id === +conversationId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredAttendeesIds = createSelector(
  [attendeesIds, selectFilteredAttendees],
  (attendeesIds, attendees) => {
    return filterIds(attendeesIds, attendees);
  }
);

export const selectFilteredFavoritesAttendeesIds = createSelector(
  [favoritesIds, selectFilteredFavoritesAttendees],
  (favoritesIds, favorites) => {
    return filterIds(favoritesIds, favorites);
  }
);

export const selectFilteredConversationsIds = createSelector(
  [conversationsIds, selectFilteredConversations],
  (conversationsIds, conversations) => {
    return filterIds(conversationsIds, conversations);
  }
);

export const selectFilteredMeetingsIds = createSelector(
  [meetingsIds, selectFilteredMeetings],
  (meetingsIds, meetings) => {
    return filterIds(meetingsIds, meetings);
  }
);

export const selectFilteredMessagesIds = createSelector(
  [messagesIds, selectFilteredMessages],
  (messagesIds, messages) => {
    return filterIds(messagesIds, messages).reverse();
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMoreAttendees = createSelector(
  [currentEventId, pagination],
  (eventId, pagination) => {
    return hasMore(pagination, eventId, API_ATTENDEES);
  }
);

export const selectHasMoreConversations = createSelector(
  [currentWhosHereId, pagination],
  (whosHereId, pagination) => {
    return hasMore(pagination, whosHereId, API_WHOS_HERE_CONVERSATIONS);
  }
);

export const selectHasMoreMeetings = createSelector(
  [currentWhosHereId, pagination],
  (whosHereId, pagination) => {
    return hasMore(pagination, whosHereId, API_WHOS_HERE_MEETINGS);
  }
);

export const selectHasMoreMessages = createSelector(
  [selectFilteredMessagesIds, currentConversationId, pagination],
  (messagesIds, conversationId, pagination) => {
    return hasMoreCursor(pagination, conversationId, API_WHOS_HERE_MESSAGES, messagesIds.length);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingAttendees = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_ATTENDEES);
  }
);

export const selectIsLoadingConversations = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_WHOS_HERE_CONVERSATIONS);
  }
);

export const selectIsLoadingMeetings = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_WHOS_HERE_MEETINGS);
  }
);

export const selectIsLoadingMessages = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_WHOS_HERE_MESSAGES);
  }
);
// ----------------------------------------

// --- Notifications ----------------------
export const selectNotifsMessagesCount = createSelector(
  [currentWhosHereId, messagesUnread],
  (whosHereId, messagesUnread) => {
    return messagesUnread[whosHereId] || null;
  }
);

export const selectNotifsMeetingsCount = createSelector(
  [currentWhosHereId, notifications],
  (whosHereId, notifications) => {
    return getNotifsCount(notifications, whosHereId, API_WHOS_HERE_MEETINGS);
  }
);
// ----------------------------------------
