import { connect } from 'react-redux';
import {
  fetchApp,
  fetchEvent,
  fetchUser,
  fetchMenuItems,
  postPushNotifSubscription,
  postUserPwd
} from '../../actions/api';
import {
  SC_EVENT,
  SC_SMART_QA,
  SC_AGENDA,
  SC_BONUS_LIST,
  SC_EXHIBITOR_LIST,
  SC_SPONSOR_LIST,
  SC_SPEAKER_LIST,
  SC_TIMELINE,
  SC_SURVEY,
  SC_VOTE,
  SC_PRACTICAL_INFO,
  SC_MAP,
  SC_VOTE_ROUND,
  SC_SOCIAL_MEDIA,
  SC_NOTIF,
  SC_CLIENT_NOTIF,
  UPDATED
} from '../../constants/socket';
import CustomFont from '../styles/CustomFont';
import { resetEvent, userLogout } from '../../actions/local';
import { Route, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import HeaderContainer from '../header/HeaderContainer';
import NavContainer from '../nav/NavContainer';
import React from 'react';
import styled from 'styled-components';
import { selectHeader } from '../../selectors/header';
import { selectGlobal } from '../../selectors/global';
import { selectUser } from '../../selectors/user';
import { selectCurrentEvent } from '../../selectors/events';
import { updateTheme, updateHeader, updateGlobal } from '../../actions/local';
import { selectCurrentApp } from '../../selectors/app';
import { setLocale } from '../../helpers/data';
import Socket from '../../socket/Socket';
import SwipeContainer from '../swipe/SwipeContainer';

import smoothscroll from 'smoothscroll-polyfill';
// eslint-disable-next-line
import lazysizes from 'lazysizes';
import OfflineContainer from '../offline/OfflineContainer';
import { createGlobalStyle } from 'styled-components';

import {
  TAB_CONVERSATIONS,
  TAB_ATTENDEES,
  TAB_MEETINGS,
  CONVERSATION_DETAILS,
  ATTENDEE_DETAILS,
  MEETING_DETAILS
} from '../../constants/app';

const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: #e0e0e0;
  }
`;

const StyledApp = styled.div`
  background-color: ${props => (props.offline ? '#e0e0e0' : props.theme.lightGrey)};
  background-image: ${props =>
    props.offline
      ? `url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8zIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjUgMjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1IDI1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnIGZpbGw9IiNlZGVkZWQiPgoJPHBhdGggZD0iTTIxLjMsMTAuNWMtMC4xLTEuNy0wLjgtMy4yLTItNC40QzE4LDQuNywxNi4yLDQsMTQuMyw0Yy0xLjgsMC0zLjQsMC43LTQuNywxLjhMMTEsNy4xYzAuOS0wLjgsMi4xLTEuMiwzLjQtMS4yCgkJYzEuNCwwLDIuNywwLjUsMy43LDEuNXMxLjUsMi4zLDEuNSwzLjdjMCwwLjEsMCwwLjEsMCwwLjJ2MC44bDAuOCwwLjFjMS42LDAuMiwyLjgsMS42LDIuOCwzLjJjMCwxLTAuNSwxLjktMS4yLDIuNGwxLjMsMS4zCgkJYzEtMC45LDEuNy0yLjIsMS43LTMuN0MyNSwxMy4xLDIzLjUsMTEuMSwyMS4zLDEwLjV6Ii8+Cgk8cGF0aCBkPSJNNC44LDMuNmMtMC40LTAuNC0wLjktMC40LTEuMywwQzMuMSw0LDMuMSw0LjUsMy41LDQuOWwyLjQsMi4zYy0xLjQsMC40LTIuNSwxLjYtMi45LDNDMS4yLDExLDAsMTIuOSwwLDE1CgkJYzAsMywyLjQsNS40LDUuNCw1LjRoMTQuMWwyLjUsMi40YzAuMywwLjQsMC45LDAuMywxLjMsMGMwLjQtMC40LDAuNC0wLjksMC0xLjNMNC44LDMuNnogTTUuNCwxOC41Yy0yLDAtMy41LTEuNi0zLjUtMy41CgkJYzAtMS41LDEtMi44LDIuNC0zLjNsMC42LTAuMnYtMC42YzAuMS0xLjEsMS4xLTIsMi4yLTJjMC4yLDAsMC40LDAsMC42LDAuMWw5LjgsOS41SDUuNHoiLz4KPC9nPgo8L3N2Zz4K")`
      : 'none'};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 200px;
  min-height: 100%;
  min-height: 100vh;
`;

const StyledContent = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: ${props => (!props.isInAppLive ? props.headerHeight : '0') + 'px'} 10px 10px;
`;

class Layout extends React.Component {
  state = {
    deviceToken: null
  };

  componentDidMount() {
    const { appId, eventId } = this.props.computedMatch.params;

    if (appId && !eventId) {
      this.props.fetchApp({ app_id: appId });
    } else if (eventId) {
      this.props.fetchEvent({ event_id: eventId });
    }

    if (this.props.global.attendeeToken && !this.props.global.accessToken && (appId || eventId)) {
      this.props.postUserPwd({
        ...{
          app_id: appId,
          event_id: eventId,
          client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
          scope: '*',
          grant_type: 'attendee_token',
          attendee_token: this.props.global.attendeeToken,
          done: () => {
            setTimeout(() => {
              window.location.reload(true);
            }, 3000);
          }
        }
      });
    }

    if (this.props.global.accessToken && (appId || eventId)) {
      this.props.fetchUser({
        app_id: appId,
        event_id: eventId,
        fail: this.onFetchUserFail
      });
    }

    document.addEventListener('deviceready', this.onDeviceReady, false);
    smoothscroll.polyfill();
  }

  componentDidUpdate(prevProps) {
    const { appId, eventId } = this.props.computedMatch.params;
    const prevAppId = prevProps.computedMatch.params.appId;
    const prevEventId = prevProps.computedMatch.params.eventId;

    if (
      this.props.global.accessToken &&
      (appId || eventId) &&
      (this.props.global.accessToken !== prevProps.global.accessToken ||
        prevAppId !== appId ||
        prevEventId !== eventId)
    ) {
      this.props.fetchUser({
        app_id: appId,
        event_id: eventId,
        fail: this.onFetchUserFail
      });
      this.subscribeToPushNotif();
    }

    if (appId && !eventId && (prevAppId !== appId || prevEventId)) {
      this.props.fetchApp({ app_id: +appId });
    }

    if (eventId && prevEventId !== eventId) {
      this.props.fetchEvent({ event_id: +eventId });
    }

    if (!eventId && prevEventId) {
      this.props.resetEvent();
    }

    if (this.props.location !== prevProps.location) {
      if (this.mustScrollTop(prevProps)) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }

    if (this.props.user && !prevProps.user) {
      this.props.updateGlobal({
        locale: setLocale(this.props.app, this.props.event, this.props.user)
      });
      if (!this.props.global.isInAppLive && this.props.event) {
        Socket.joinEvent(this.props.event.id, this.props.user.id);
      }
    }

    if (!this.props.user && prevProps.user) {
      if (!this.props.global.isInAppLive && this.props.event) {
        Socket.leaveEvent(this.props.event.id);
      }
    }

    if ((this.props.user && !prevProps.user) || (!this.props.user && prevProps.user)) {
      if (appId && !eventId) {
        this.props.fetchApp({ app_id: appId });
      } else if (eventId) {
        this.props.fetchEvent({ event_id: eventId });
      }
    }

    if (
      this.props.event &&
      (!prevProps.event || (prevProps.event && this.props.event.id !== prevProps.event.id))
    ) {
      this.props.updateTheme({
        mainColor: this.props.event.color
      });
      this.props.updateHeader({
        logo: this.props.event.logo_url
      });
      if (prevProps.event && prevProps.event.id) {
        this.unsubscribeSocketEvents(prevProps.event.id);
      }
      this.subscribeToPushNotif();
      this.subscribeToSocketEvents(this.props.event);
      this.props.updateGlobal({
        locale: setLocale(this.props.app, this.props.event, this.props.user)
      });
      if (!this.props.global.isInAppLive && this.props.user) {
        Socket.joinEvent(this.props.event.id, this.props.user.id);
      }
      this.setMeta(this.props.event.icon_url, this.props.event.name);
    } else if (
      this.props.app &&
      (!prevProps.app ||
        (prevProps.app && this.props.app.id !== prevProps.app.id) ||
        (prevEventId && !this.props.computedMatch.params.eventId))
    ) {
      this.props.updateTheme({
        mainColor: this.props.app.color
      });
      this.props.updateHeader({
        logo: this.props.app.logo_url
      });
      this.props.updateGlobal({
        locale: setLocale(this.props.app, this.props.event, this.props.user)
      });
      if (!this.props.global.isInAppLive && prevEventId && this.props.user) {
        Socket.leaveEvent(prevEventId);
      }
      this.setMeta(this.props.app.icon_url, this.props.app.name);
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      if (appId && !eventId) {
        this.props.fetchApp({ app_id: appId });
      } else if (eventId) {
        this.props.fetchEvent({ event_id: eventId });
      }

      if (this.props.global.accessToken && (appId || eventId)) {
        this.props.fetchUser({
          app_id: appId,
          event_id: eventId,
          fail: this.onFetchUserFail
        });
      }
    }
  }

  mustScrollTop = prevProps => {
    const currentMatchParams = this.props.computedMatch.params;
    const prevMatchParams = prevProps.computedMatch.params;

    if (
      (currentMatchParams.currentTab === TAB_CONVERSATIONS &&
        prevMatchParams.currentView === CONVERSATION_DETAILS &&
        !currentMatchParams.currentView) ||
      (currentMatchParams.currentTab === TAB_ATTENDEES &&
        prevMatchParams.currentView === ATTENDEE_DETAILS &&
        !currentMatchParams.currentView) ||
      (currentMatchParams.currentTab === TAB_MEETINGS &&
        prevMatchParams.currentView === MEETING_DETAILS &&
        !currentMatchParams.currentView) ||
      (currentMatchParams.exhibitorListId &&
        prevMatchParams.exhibitorId &&
        !currentMatchParams.exhibitorId) ||
      (currentMatchParams.speakerListId &&
        prevMatchParams.speakerId &&
        !currentMatchParams.speakerId) ||
      (currentMatchParams.sponsorListId &&
        prevMatchParams.sponsorId &&
        !currentMatchParams.sponsorId)
    ) {
      return false;
    }
    return true;
  };

  onFetchUserFail = error => {
    if (error.status >= 400 && error.status < 500) {
      this.props.userLogout();
    }
  };

  onDeviceReady = () => {
    if (window.cordova) {
      if (window.cordova.InAppBrowser) {
        this.initInAppBrowser();
      }

      if (window.StatusBar) {
        this.initStatusBar();
      }

      if (window.PushNotification) {
        this.initPush();
      }

      if (window.Keyboard) {
        this.initKeyboard();
      }
    }
  };

  redirect = () => {
    const { appId, eventId } = this.props.computedMatch.params;
    const redirectUrl = `/apps/${appId}${eventId ? `/events/${eventId}` : ''}/signin`;
    return (
      <Redirect
        to={{
          pathname: redirectUrl,
          state: { from: this.props.location }
        }}
      />
    );
  };

  initPush = () => {
    this.push = window.PushNotification.init({
      android: {},
      ios: {
        alert: 'true',
        badge: 'true',
        clearBadge: 'true',
        sound: 'true'
      }
    });

    this.push.off('notification');
    this.push.on('notification', data => {
      if (data.additionalData.foreground) {
        if (!data.additionalData.url) {
          window.plugins.toast.showWithOptions({
            message: data.message,
            duration: 'long',
            position: 'top',
            addPixelsY: 120
          });
        } else {
          window.plugins.toast.showWithOptions(
            {
              message: data.message,
              duration: 'long',
              position: 'top',
              addPixelsY: 44,
              data: {
                url: data.additionalData.url
              }
            },
            result => {
              if (
                result &&
                result.data &&
                result.data.url &&
                result.event &&
                result.event === 'touch'
              ) {
                if (
                  '#' + this.props.location.pathname === result.data.url &&
                  this.props.computedMatch.params.currentTab === TAB_CONVERSATIONS
                ) {
                  window.scrollTo(0, document.body.scrollHeight);
                } else {
                  window.location.href = result.data.url;
                }
              }
            }
          );
        }
      } else {
        if (data.additionalData.url) {
          window.location.href = data.additionalData.url;
        }
      }
    });

    this.push.on('registration', data => {
      this.setState({ deviceToken: data.registrationId });
      this.subscribeToPushNotif();
    });
  };

  initInAppBrowser = () => {
    document.addEventListener('click', event => {
      const link = event.target.closest('a');
      if (
        link &&
        link.href &&
        (link.href.startsWith('http') ||
          link.href.startsWith('mailto') ||
          link.href.startsWith('tel')) &&
        !link.href.startsWith(process.env.REACT_APP_ED_API_URL_2) &&
        !link.href.startsWith(process.env.REACT_APP_ED_API_URL)
      ) {
        event.preventDefault();

        let url = link.href;
        const target =
          url.startsWith('mailto') ||
          url.startsWith('tel') ||
          url.startsWith('https://maps.apple.com')
            ? '_system'
            : '_blank';

        url = url.startsWith('https://maps.apple.com') ? url : encodeURI(url);
        window.cordova.InAppBrowser.open(
          url,
          target,
          `useWideViewPort=yes,usewkwebview=yes,location=no,closebuttoncaption=${this.props.t(
            'close'
          )}`
        );
      }
    });
  };

  initStatusBar = () => {
    window.addEventListener('statusTap', function() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  initKeyboard = () => {
    window.Keyboard.setResizeMode('native');
    window.Keyboard.hideFormAccessoryBar(false);
  };

  setMeta = (baseUrl, name) => {
    if (baseUrl) {
      document
        .getElementById('js-favicon')
        .setAttribute('href', baseUrl.replace(/(\.[a-z]{3,4})$/, '_32x32$1'));
      document
        .getElementById('js-appleTouchIcon')
        .setAttribute('href', baseUrl.replace(/(\.[a-z]{3,4})$/, '_180x180$1'));
      document
        .getElementById('js-icon')
        .setAttribute('href', baseUrl.replace(/(\.[a-z]{3,4})$/, '_192x192$1'));
    } else {
      document.getElementById('js-favicon').setAttribute('href', '/images/icon_32x32.png');
      document.getElementById('js-appleTouchIcon').setAttribute('href', '/images/icon_180x180.png');
      document.getElementById('js-icon').setAttribute('href', '/images/icon_192x192.png');
    }

    if (name) {
      document.title = name;
      document
        .querySelectorAll('meta[name="apple-mobile-web-app-title"]')[0]
        .setAttribute('content', name);
    }
  };

  removeCustomIcon = () => {};

  subscribeToPushNotif = () => {
    const { appId, eventId } = this.props.computedMatch.params;
    if (!window.device || !eventId || !this.state.deviceToken) {
      return false;
    }

    let deviceType = null;
    if (window.device.platform.toLowerCase().indexOf('android') !== -1) {
      deviceType = 'android';
    } else if (window.device.platform.toLowerCase().indexOf('ios') !== -1) {
      deviceType = 'ios';
    }

    if (!deviceType) {
      return false;
    }

    this.props.postPushNotifSubscription({
      app_id: appId,
      event_id: eventId,
      token: this.state.deviceToken,
      type: deviceType
    });
  };

  subscribeToSocketEvents = event => {
    this.socket = Socket.subscribeChannel(
      `${SC_EVENT}.${event.id}`,
      event.visibility === 'public' ? true : false
    );
    Socket.listenEvents(this.socket, [SC_SMART_QA + UPDATED]);
    Socket.listenEvents(this.socket, [SC_AGENDA + UPDATED]);
    Socket.listenEvents(this.socket, [SC_BONUS_LIST + UPDATED]);
    Socket.listenEvents(this.socket, [SC_EXHIBITOR_LIST + UPDATED]);
    Socket.listenEvents(this.socket, [SC_SPONSOR_LIST + UPDATED]);
    Socket.listenEvents(this.socket, [SC_SPEAKER_LIST + UPDATED]);
    Socket.listenEvents(this.socket, [SC_TIMELINE + UPDATED]);
    Socket.listenEvents(this.socket, [SC_SURVEY + UPDATED]);
    Socket.listenEvents(this.socket, [SC_VOTE + UPDATED]);
    Socket.listenEvents(this.socket, [SC_PRACTICAL_INFO + UPDATED]);
    Socket.listenEvents(this.socket, [SC_MAP + UPDATED]);
    Socket.listenEvents(this.socket, [SC_VOTE_ROUND + UPDATED]);
    Socket.listenEvents(this.socket, [SC_SOCIAL_MEDIA + UPDATED]);

    this.socket2 = Socket.subscribeChannel(`${SC_NOTIF}.${event.id}`, true);
    Socket.listenEvents(this.socket2, [SC_CLIENT_NOTIF]);
  };

  unsubscribeSocketEvents = eventId => {
    Socket.unsubscribeChannel(`${SC_EVENT}.${eventId}`);
  };

  render() {
    const { component: Component, header, t, theme, ...props } = this.props;
    const { height: headerHeight } = header;

    if (this.props.global.accessToken && !this.props.user) {
      return null;
    }

    if (this.props.computedMatch.params.eventId && !this.props.event) {
      return null;
    }

    return this.props.event &&
      (this.props.event.visibility !== 'public' && this.props.event.visibility_mode !== 'opened_public') &&
      !this.props.global.accessToken &&
      !this.props.notProtected ? (
      this.redirect()
    ) : this.props.protected && !this.props.global.accessToken ? (
      this.redirect()
    ) : (
      <Route
        {...props}
        render={matchProps => (
          <React.Fragment>
            <CustomFont app={this.props.app} event={this.props.event}></CustomFont>
            <OfflineContainer {...matchProps} {...{ t, theme }} />
            <SwipeContainer event={this.props.event}>
              {this.props.global.offline ? <GlobalStyle /> : null}
              <StyledApp offline={this.props.global.offline}>
                {!this.props.global.isInAppLive ? (
                  <React.Fragment>
                    <HeaderContainer {...matchProps} {...{ t, theme }} />
                    <NavContainer {...matchProps} {...{ t, theme }} />
                  </React.Fragment>
                ) : null}
                <StyledContent {...{ headerHeight }} isInAppLive={this.props.global.isInAppLive}>
                  <Component {...matchProps} {...{ t, theme }} />
                </StyledContent>
              </StyledApp>
            </SwipeContainer>
          </React.Fragment>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  fetchEvent: fetchEvent,
  fetchApp: fetchApp,
  fetchUser: fetchUser,
  fetchMenuItems: fetchMenuItems,
  postPushNotifSubscription: postPushNotifSubscription,
  resetEvent: resetEvent,
  userLogout: userLogout,
  updateTheme: updateTheme,
  updateHeader: updateHeader,
  updateGlobal: updateGlobal,
  postUserPwd: postUserPwd
};

const mapStateToProps = (state, ownProps) => {
  return {
    app: selectCurrentApp(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.computedMatch.params.eventId }),
    header: selectHeader(state, ownProps),
    user: selectUser(state, ownProps),
    global: selectGlobal(state, ownProps)
  };
};

export default withTheme(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Layout)));
