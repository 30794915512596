import React from 'react';
import styled from 'styled-components';

const SelectStyle = styled.div`
  padding: 5px 0 5px 10px;
  background-color: #fff;
  border: 1px solid ${props => props.theme.dustGrey};
  border-radius: 18px;
  margin: ${props => props.margin || '0'};
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);

  :after {
    /* Le pointeur du select */
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 0.75em;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-top-color: ${props => props.theme.mainColor};
    border-width: 6px;
    border-style: solid;
    pointer-events: none;
  }
`;

const OriginalSelectStyle = styled.select`
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  font-size: 16px;
  border: none;
  height: 24px;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &.select_option {
    color: #999999 !important;
  }

  :focus {
    outline: none;
  }
`;

const Select = props => {
  return (
    <SelectStyle margin={props.margin}>
      <OriginalSelectStyle {...props}>{props.children}</OriginalSelectStyle>
    </SelectStyle>
  );
};

export default Select;
