import initialState from '../store/initialState';
import {
  SC_WHOS_HERE_MEETING,
  SC_SMART_QA_POST,
  SC_TIMELINE_ITEM,
  CREATED,
  APPROVED,
  APPROVED_ANIMATOR
} from '../constants/socket';
import { addOrUpdateNotifInState, resetNotifInState } from '../helpers/data';
import {
  API_WHOS_HERE_MEETINGS,
  API_SMARTQA_QUESTIONS,
  API_TIMELINE_ITEMS
} from '../constants/apiRoutes';
import {
  SUCCESS,
  FETCH_WHOS_HERE_MEETINGS,
  FETCH_SMARTQA_POSTS,
  FETCH_TIMELINE_ITEMS
} from '../constants/actionApi';

import { RESET_NOTIFICATION } from '../constants/actionLocal';

export default (state = initialState.notifications, { type, payload }) => {
  switch (type) {
    case SC_WHOS_HERE_MEETING + CREATED: // Broadcasted to all
      if (payload.data.attendees.find(attendee => attendee.id === payload.user.id)) {
        return addOrUpdateNotifInState(
          state,
          payload.data,
          payload.data.whos_here_id,
          API_WHOS_HERE_MEETINGS
        );
      } else {
        return state;
      }

    case SC_SMART_QA_POST + CREATED: // Broadcasted with ->toOthers
      if (payload.data.is_displayed_to_attendees) {
        return addOrUpdateNotifInState(
          state,
          payload.data,
          payload.data.smart_qa_id,
          API_SMARTQA_QUESTIONS
        );
      } else {
        return state;
      }

    case SC_TIMELINE_ITEM + CREATED: // Broadcasted to all
      return addOrUpdateNotifInState(
        state,
        payload.data,
        payload.data.timeline_id,
        API_TIMELINE_ITEMS
      );

    case SC_SMART_QA_POST + APPROVED: // Broadcasted to all
      return addOrUpdateNotifInState(
        state,
        payload.data,
        payload.data.smart_qa_id,
        API_SMARTQA_QUESTIONS
      );

    case SC_SMART_QA_POST + APPROVED_ANIMATOR: // Broadcasted to all
      return addOrUpdateNotifInState(
        state,
        payload.data,
        payload.data.smart_qa_id,
        API_SMARTQA_QUESTIONS
      );

    case FETCH_WHOS_HERE_MEETINGS + SUCCESS:
      return payload.params.reset
        ? resetNotifInState(state, payload.params.whos_here_id, API_WHOS_HERE_MEETINGS)
        : state;

    case FETCH_SMARTQA_POSTS + SUCCESS:
      return payload.params.reset
        ? resetNotifInState(state, payload.params.smart_qa_id, API_SMARTQA_QUESTIONS)
        : state;

    case FETCH_TIMELINE_ITEMS + SUCCESS:
      return payload.params.reset
        ? resetNotifInState(state, payload.params.timeline_id, API_TIMELINE_ITEMS)
        : state;

    case RESET_NOTIFICATION:
      return resetNotifInState(state, payload.parent_id, payload.api_route);

    default:
      return state;
  }
};
