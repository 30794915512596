import React from 'react';
import FlexParent from '../../components/shared/flex/FlexParent';
import FlexItem from '../../components/shared/flex/FlexItem';
import styled from 'styled-components';
import Card from '../../components/shared/card/Card';
import { getTime, applyTimezone } from '../../helpers/date';
import Star from '../../components/shared/fav/Star';

const Times = styled(FlexItem)`
  text-align: center;
  margin-right: 15px;
  font-size: 1.5rem;
`;

const TimeStart = styled.div`
  color: ${props => props.theme.darkGrey};
  padding-bottom: 4px;
`;

const TimeEnd = styled.div`
  color: ${props => props.theme.shadowGrey};
`;

const CategoryCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin: 5px;
`;

const SessionName = styled.div`
  font-size: 1.5rem;
  padding: 3px 0 3px 0;
`;

const SessionLocation = styled.div`
  padding: 0 10px 0 0;
  font-size: 1.2rem;
  color: ${props => props.theme.shadowGrey};
`;

class AgendaSession extends React.Component {
  nowIsRendered = false;

  onClickSession = () => {
    const { matchParams, history, model } = this.props;
    const { appId, eventId } = matchParams;
    const sessionUrl = `/apps/${appId}/events/${eventId}/agendas/${model.agenda_id}/sessions/${model.id}`;
    window.localStorage.setItem(`scrollY_fav`, window.pageYOffset);
    history.push(sessionUrl);
  };

  render() {
    this.nowIsRendered = false;
    const { matchParams, model, timezone, theme, favorite_id, type } = this.props;

    return (
      <Card margin={`15px 0 15px 0`} padding={'10px'} onClick={this.onClickSession}>
        <FlexParent alignItems={'center'}>
          <Times shrink={'0'}>
            <TimeStart>{getTime(applyTimezone(model.start_date, timezone))}</TimeStart>
            <TimeEnd>{getTime(applyTimezone(model.end_date, timezone))}</TimeEnd>
          </Times>
          <FlexItem grow={1}>
            <SessionName>{model.name}</SessionName>
            <FlexParent alignItems={'center'}>
              {model.location ? <SessionLocation>{model.location}</SessionLocation> : null}
              {model.categories.map(category => {
                return (
                  <CategoryCircle key={category.id} style={{ backgroundColor: category.color }} />
                );
              })}
            </FlexParent>
          </FlexItem>

          <FlexItem alignSelf={'start'}>
            <Star
              {...{ theme }}
              model={model}
              type={type}
              matchParams={matchParams}
              favorite_id={favorite_id}
            />
          </FlexItem>
        </FlexParent>
      </Card>
    );
  }
}

export default AgendaSession;
