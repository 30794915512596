import CardIcon from './card/CardIcon';
import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const Message = styled.div`
  padding: 20px 0 0;
  font-weight: 300;
  font-size: 1.4rem;
`;

const ServiceName = styled.div`
  padding: 40px 10px 0;
  font-size: 1.8rem;
  font-weight: 500;
`;

const ClosedService = props => {
  // properties
  const { t, theme, icon, name } = props;
  // functions
  const { onRefresh } = props;
  return (
    <CardIcon
      icon={icon}
      textAlign={'center'}
      padding={'20px'}
      logoBorderRadius={'50%'}
      theme={theme}
    >
      <ServiceName>{name}</ServiceName>
      <Message>
        <p>{t('service_closed')}</p>
      </Message>

      <Button btntype={'primary'} onClick={onRefresh} margin={'30px auto 0'}>
        {t('btn_refresh')}
      </Button>
    </CardIcon>
  );
};

export default ClosedService;
