import Card from '../../components/shared/card/Card';
import FlexParent from '../../components/shared/flex/FlexParent';
import IconMail from '../../components/icons/IconMail';
import IconPhone from '../../components/icons/IconPhone';
import IconPoi from '../../components/icons/IconPoi';
import IconWebsite from '../../components/icons/IconWebsite';
import React from 'react';
import InfoLine from '../../components/shared/InfoLine';
import SocialLinks from '../../components/shared/SocialLinks';
import LinkedSessions from '../../components/shared/LinkedSessions';
import { Logo, DownloadVCard } from '../../components/shared/ListItemDetails';
import Star from '../../components/shared/fav/Star';

import {
  ItemName,
  ItemSubName,
  FavItem,
  Description,
  VipIcon
} from '../../components/shared/ListItemDetails';

class Sponsor extends React.Component {
  componentDidMount() {
    this.props.fetchSponsor();
  }

  componentDidUpdate(prevProps) {
    if (this.props.sponsorId !== prevProps.sponsorId) {
      this.props.fetchSponsor();
    }
  }

  onClickDownloadVCard = event => {
    const { t, sponsor } = this.props;

    if (window.cordova && navigator.contacts) {
      event.stopPropagation();
      event.preventDefault();

      let contact = navigator.contacts.create();
      contact.displayName = sponsor.company;
      if (sponsor.contact_name) {
        let name = new window.ContactName();
        name.givenName = sponsor.contact_name;
        contact.name = name;
      }

      if (sponsor.contact_email) {
        let emails = [new window.ContactField('work', sponsor.contact_email, false)];
        contact.emails = emails;
      }

      if (sponsor.contact_phone_number) {
        let phoneNumbers = [new window.ContactField('work', sponsor.contact_phone_number, false)];
        contact.phoneNumbers = phoneNumbers;
      }

      let urls = [];
      if (sponsor.website_url) {
        urls.push(new window.ContactField('work', sponsor.website_url, false));
      }
      if (sponsor.linkedin_url) {
        urls.push(new window.ContactField('work', sponsor.linkedin_url, false));
      }
      if (sponsor.facebook_url) {
        urls.push(new window.ContactField('work', sponsor.facebook_url, false));
      }
      if (sponsor.twitter_url) {
        urls.push(new window.ContactField('work', sponsor.twitter_url, false));
      }
      if (urls.length) {
        contact.urls = urls;
      }

      if (sponsor.company) {
        let organizations = [
          new window.ContactOrganization(false, 'work', sponsor.company, null, null)
        ];
        contact.organizations = organizations;
      }

      contact.save(
        function() {
          alert(t('added_to_contacts'));
        },
        function() {}
      );
    }
  };

  render() {
    if (!this.props.sponsor) {
      return null;
    }
    const { history, theme, t, timezone, sponsor, matchParams } = this.props;
    const { appId, eventId } = matchParams;

    const {
      company,
      contact_email,
      contact_name,
      contact_phone_number,
      country_name,
      description,
      facebook_url,
      is_vip,
      linkedin_url,
      logo_url,
      sessions,
      twitter_url,
      website_url,
      vcard_url
    } = sponsor || {};

    return (
      <div>
        <Card margin={'15px 0'} padding={'15px 20px 15px'} theme={theme}>
          <FavItem>
            <Star
              {...{ theme }}
              size={'40px'}
              model={sponsor}
              type={'sponsor'}
              matchParams={matchParams}
            />
          </FavItem>

          <Logo src={logo_url}></Logo>

          <ItemName>
            <FlexParent justifyContent={'center'} alignItems={'center'}>
              <div>{company}</div>
              <VipIcon isVip={is_vip} width={'25px'} height={'25px'} theme={theme} />
            </FlexParent>
          </ItemName>

          {contact_name && <ItemSubName>{contact_name}</ItemSubName>}

          <SocialLinks
            linkedinUrl={linkedin_url}
            facebookUrl={facebook_url}
            twitterUrl={twitter_url}
          />

          <InfoLine icon={IconPoi} label={country_name} iconColor={theme.ashesGrey} />
          <InfoLine
            icon={IconMail}
            label={contact_email}
            iconColor={theme.ashesGrey}
            type="email"
          />
          <InfoLine
            icon={IconPhone}
            label={contact_phone_number}
            iconColor={theme.ashesGrey}
            type="phone"
          />
          <InfoLine
            icon={IconWebsite}
            label={website_url}
            iconColor={theme.ashesGrey}
            type="link"
          />

          <DownloadVCard href={vcard_url} onClick={this.onClickDownloadVCard} download>
            {t('add_to_contact')}
          </DownloadVCard>

          <Description description={description} heading={t('description')} />
          <LinkedSessions
            sessions={sessions || []}
            heading={t('sessions')}
            appId={appId}
            eventId={eventId}
            history={history}
            timezone={timezone}
          />
        </Card>
      </div>
    );
  }
}

export default Sponsor;
