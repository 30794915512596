import React from 'react';
import Card from '../../../components/shared/card/Card';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import { getFullName, getJobWithCompany } from '../../../helpers/format';
import Icon from '../../../components/icons/Icon';
import IconAnswerRight from '../../../components/icons/IconAnswerRight';
import {
  ListItemLogo,
  ListItemTitle,
  ListItemSubTitle,
  ListItemShadow
} from '../../../components/shared/ListItem';
import InfiniteScroll from '../../../components/shared/InfiniteScroll';

class SelectAttendees extends React.Component {
  componentDidMount() {
    this.props.fetchAttendees({ reset: true });
  }

  onClickAttendee = event => {
    const attendeeId = +event.currentTarget.dataset.id;
    const selectedAttendeesIds = this.props.selectedAttendees.map(attendee => attendee.id);
    const selectedAttendee = this.props.attendees[attendeeId];

    if (selectedAttendeesIds.indexOf(attendeeId) !== -1) {
      this.props.unselectAttendee(selectedAttendee);
    } else {
      this.props.selectAttendee(selectedAttendee);
    }
  };

  render() {
    const {
      user,
      attendees,
      attendeesIds,
      selectedAttendees,
      hasMoreAttendees,
      isLoadingAttendees,
      fetchAttendees
    } = this.props;
    const selectedAttendeesIds = selectedAttendees.map(attendee => attendee.id);
    return (
      <InfiniteScroll
        loadMore={fetchAttendees}
        hasMore={hasMoreAttendees}
        isLoading={isLoadingAttendees}
      >
        {attendeesIds.map((key, index) => {
          const attendee = attendees[key];
          const {
            id,
            avatar_url,
            first_name,
            last_name,
            job_title,
            company,
            country_name
          } = attendee;

          const name = getFullName(first_name, last_name);
          const desc = getJobWithCompany(job_title, company, country_name);

          return (
            <React.Fragment key={id}>
              {attendee.id !== user.id ? (
                <Card
                  data-id={id}
                  margin={'0 0 20px'}
                  padding={'10px'}
                  onClick={this.onClickAttendee}
                >
                  <FlexParent justifyContent={'space-between'}>
                    {avatar_url && (
                      <FlexItem position={'relative'}>
                        <ListItemLogo src={avatar_url} alt={name} />
                        <ListItemShadow />
                      </FlexItem>
                    )}
                    <FlexItem grow={1}>
                      <ListItemTitle>{name}</ListItemTitle>
                      <ListItemSubTitle>{desc}</ListItemSubTitle>
                    </FlexItem>

                    {selectedAttendeesIds.indexOf(id) !== -1 ? (
                      <FlexItem alignSelf={'center'}>
                        <Icon
                          icon={IconAnswerRight}
                          width={'20px'}
                          height={'20px'}
                          margin={'0 0 0 10px'}
                          color={'#7ED321'}
                        />
                      </FlexItem>
                    ) : null}
                  </FlexParent>
                </Card>
              ) : null}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    );
  }
}

export default SelectAttendees;
