import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../../helpers/date';
import { isDarkBgColor } from '../../../helpers/helpers';

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: ${props => props.headerHeight - 1 + 'px'};
  z-index: 20;
`;

const DateStyle = styled.div`
  background-color: ${props => props.theme.mainColor};
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  text-align: center;
  padding: 3px;
  font-size: 1.2rem;
  color: ${props => {
    return isDarkBgColor(props.theme.mainColor) ? '#fff' : '#000';
  }};
`;

class Date extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
  }

  render() {
    const { headerHeight, date } = this.props;
    return (
      <Container headerHeight={headerHeight} ref={this.containerRef}>
        <DateStyle>{formatDate(date, 'dddd MMMM DD, YYYY')}</DateStyle>
      </Container>
    );
  }
}

export default Date;
