import { withRouter } from 'react-router-dom';
import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconChevron from '../../components/icons/IconChevron';
import React from 'react';
import { withTheme } from 'styled-components';
import { ListItemTitle, ListItemSubTitle } from '../../components/shared/ListItem';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';
import { typesToRoutes } from '../../constants/app';

const ShortLink = props => {
  const { shortLink, matchParams } = props;
  const { appId, eventId } = matchParams;

  let itemUrl;
  let hrefUrl;
  if (shortLink.type === 'link') {
    hrefUrl = shortLink.url;
  } else if (shortLink.items && shortLink.items[0]) {
    itemUrl = `/apps/${appId}/events/${eventId}/${typesToRoutes[shortLink.type]}/${
      shortLink.items[0].id
    }`;
  }

  const icon = getServiceIconByType(shortLink.icon || shortLink.type);

  return (
    <Card
      margin={'0 10px 20px'}
      padding={'10px'}
      onClick={event => {
        if (itemUrl) {
          props.history.push(itemUrl);
        } else {
          window.open(hrefUrl, '_blank');
        }
      }}
    >
      <FlexParent justifyContent={'space-between'} alignItems={'center'}>
        <FlexItem>
          <Icon
            icon={icon}
            width={'32px'}
            height={'32px'}
            color={'#fff'}
            padding={'8px'}
            margin={'0 20px 0 0'}
            backgroundColor={props.theme.mainColor}
            borderRadius={'50%'}
          />
        </FlexItem>
        <FlexItem grow={1}>
          <ListItemTitle>{shortLink.name}</ListItemTitle>
          {shortLink.items && shortLink.items[0] && shortLink.items[0].description && (
            <ListItemSubTitle>{shortLink.items[0].description}</ListItemSubTitle>
          )}
        </FlexItem>

        <FlexItem alignSelf={'center'}>
          <Icon
            icon={IconChevron}
            width={'20px'}
            height={'20px'}
            padding={'5px'}
            margin={'0 0 0 10px'}
            color={props.theme.mainColor}
          />
        </FlexItem>
      </FlexParent>
    </Card>
  );
};
export default withRouter(withTheme(ShortLink));
