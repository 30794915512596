import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M18.6,25h1.8v-1.8h-1.8V25z M23.2,25H25v-1.8h-1.8V25z M4.6,20.4h1.8v-1.8H4.6V20.4z M23.2,14v2.4h-3V14h-6.4v11h1.8v-7h3 v2.4H25V14C25,14,23.2,14,23.2,14z M4.6,6.4h1.8V4.6H4.6V6.4z M18.6,6.4h1.8V4.6h-1.8V6.4z" />
      <path d="M0,14v11h11V14H0z M9.4,23.2H1.8v-7.6h7.6V23.2z" />
      <path d="M0,0v11h11V0H0z M9.4,9.4H1.8V1.8h7.6V9.4z" />
      <path d="M14,0v11h11V0H14z M23.2,9.4h-7.6V1.8h7.6V9.4z" />
    </g>
  </svg>
);
