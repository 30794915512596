import { getExhibitorStandLabel } from '../../helpers/format';
import Card from '../../components/shared/card/Card';
import FlexParent from '../../components/shared/flex/FlexParent';
import IconMail from '../../components/icons/IconMail';
import IconPhone from '../../components/icons/IconPhone';
import IconPoi from '../../components/icons/IconPoi';
import IconWebsite from '../../components/icons/IconWebsite';
import React from 'react';
import InfoLine from '../../components/shared/InfoLine';
import SocialLinks from '../../components/shared/SocialLinks';
import LinkedSessions from '../../components/shared/LinkedSessions';
import { Logo, DownloadVCard } from '../../components/shared/ListItemDetails';
import Star from '../../components/shared/fav/Star';
import {
  ItemName,
  ItemSubName,
  FavItem,
  Description,
  VipIcon
} from '../../components/shared/ListItemDetails';

class Exhibitor extends React.Component {
  componentDidMount() {
    this.props.fetchExhibitor();
  }

  componentDidUpdate(prevProps) {
    if (this.props.exhibitorId !== prevProps.exhibitorId) {
      this.props.fetchExhibitor();
    }
  }

  onClickDownloadVCard = event => {
    const { t, exhibitor } = this.props;

    if (window.cordova && navigator.contacts) {
      event.stopPropagation();
      event.preventDefault();

      let contact = navigator.contacts.create();

      contact.displayName = exhibitor.company;

      if (exhibitor.contact_name) {
        let name = new window.ContactName();
        name.givenName = exhibitor.contact_name;
        contact.name = name;
      }

      if (exhibitor.contact_email) {
        let emails = [new window.ContactField('work', exhibitor.contact_email, false)];
        contact.emails = emails;
      }

      if (exhibitor.contact_phone_number) {
        let phoneNumbers = [new window.ContactField('work', exhibitor.contact_phone_number, false)];
        contact.phoneNumbers = phoneNumbers;
      }

      let urls = [];
      if (exhibitor.website_url) {
        urls.push(new window.ContactField('work', exhibitor.website_url, false));
      }
      if (exhibitor.linkedin_url) {
        urls.push(new window.ContactField('work', exhibitor.linkedin_url, false));
      }
      if (exhibitor.facebook_url) {
        urls.push(new window.ContactField('work', exhibitor.facebook_url, false));
      }
      if (exhibitor.twitter_url) {
        urls.push(new window.ContactField('work', exhibitor.twitter_url, false));
      }
      if (urls.length) {
        contact.urls = urls;
      }

      if (exhibitor.company) {
        let organizations = [
          new window.ContactOrganization(false, 'work', exhibitor.company, null, null)
        ];
        contact.organizations = organizations;
      }

      contact.save(
        function() {
          alert(t('added_to_contacts'));
        },
        function() {}
      );
    }
  };

  render() {
    if (!this.props.exhibitor) {
      return null;
    }
    const { history, theme, t, timezone, exhibitor, matchParams } = this.props;
    const { appId, eventId } = matchParams;

    const {
      address,
      company,
      contact_email,
      contact_name,
      contact_phone_number,
      country_name,
      description,
      facebook_url,
      is_vip,
      linkedin_url,
      logo_url,
      sessions,
      twitter_url,
      website_url,
      floor,
      aisle,
      stand_area,
      stand_number,
      vcard_url
    } = exhibitor || {};

    return (
      <div>
        <Card margin={'15px 0'} padding={'15px 20px 15px'} theme={theme}>
          <FavItem>
            <Star
              {...{ theme }}
              size={'40px'}
              model={exhibitor}
              type={'exhibitor'}
              matchParams={matchParams}
            />
          </FavItem>

          <Logo src={logo_url}></Logo>

          <ItemName>
            <FlexParent justifyContent={'center'} alignItems={'center'}>
              <div>{company}</div>
              <VipIcon isVip={is_vip} width={'25px'} height={'25px'} theme={theme} />
            </FlexParent>
          </ItemName>

          {contact_name && <ItemSubName>{contact_name}</ItemSubName>}
          <SocialLinks
            linkedinUrl={linkedin_url}
            facebookUrl={facebook_url}
            twitterUrl={twitter_url}
          />

          <InfoLine
            icon={IconPoi}
            label={getExhibitorStandLabel(t, aisle, floor, stand_area, stand_number)}
            iconColor={theme.ashesGrey}
          />
          <InfoLine icon={IconPoi} label={country_name} iconColor={theme.ashesGrey} />
          <InfoLine
            icon={IconMail}
            label={contact_email}
            iconColor={theme.ashesGrey}
            type="email"
          />
          <InfoLine
            icon={IconPhone}
            label={contact_phone_number}
            iconColor={theme.ashesGrey}
            type="phone"
          />
          <InfoLine
            icon={IconWebsite}
            label={website_url}
            iconColor={theme.ashesGrey}
            type="link"
          />
          <InfoLine icon={IconPoi} label={address} iconColor={theme.ashesGrey} type={'address'} />

          <DownloadVCard href={vcard_url} onClick={this.onClickDownloadVCard} download>
            {t('add_to_contact')}
          </DownloadVCard>

          <Description description={description} heading={t('description')} />
          <LinkedSessions
            sessions={sessions || []}
            heading={t('sessions')}
            appId={appId}
            eventId={eventId}
            history={history}
            timezone={timezone}
          />
        </Card>
      </div>
    );
  }
}

export default Exhibitor;
