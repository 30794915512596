import React from 'react';
import styled from 'styled-components';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import Icon from '../icons/Icon';
import IconCloseCross from '../icons/IconCloseCross';
import { motion, AnimatePresence } from 'framer-motion';

const PinchContainer = styled(motion.div)`
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 1);
`;

class FullImage extends React.Component {
  constructor(props) {
    super(props);
    document.addEventListener('deviceready', this.onDeviceReady, false);
  }

  onDeviceReady = () => {
    document.addEventListener('backbutton', this.onClickAndroidBack, true);
  };

  onClickAndroidBack = event => {
    if (this.props.url) {
      event.preventDefault();
      this.props.onClickClose();
    } else {
      window.history.back();
    }
  };

  componentWillUnmount() {
    document.removeEventListener('deviceready', this.onDeviceReady, false);
    document.removeEventListener('backbutton', this.onClickAndroidBack, true);
  }

  render() {
    return (
      <AnimatePresence>
        {this.props.url && (
          <PinchContainer
            key="full_image"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <Icon
              icon={IconCloseCross}
              position={'absolute'}
              top={'8px'}
              right={'8px'}
              width={'25px'}
              height={'25px'}
              color={'#fff'}
              padding={'3px'}
              onClick={this.props.onClickClose}
            />

            <PinchZoomPan position={'center'} zoomButtons={false} doubleTapBehavior={'zoom'}>
              <img src={this.props.url} alt="" />
            </PinchZoomPan>
          </PinchContainer>
        )}
      </AnimatePresence>
    );
  }
}

export default FullImage;
