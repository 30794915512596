import FlexParent from './flex/FlexParent';
import Icon from '../icons/Icon';
import IconFacebook from '../icons/IconFacebook';
import IconLinkedin from '../icons/IconLinkedin';
import IconTwitter from '../icons/IconTwitter';
import React from 'react';
import styled from 'styled-components';

export const SocialLinksStyle = styled.div`
  margin: 20px 0;
`;

const SocialLinks = ({ linkedinUrl, facebookUrl, twitterUrl }) => {
  return linkedinUrl || facebookUrl || twitterUrl ? (
    <SocialLinksStyle>
      <FlexParent justifyContent={'center'} alignItems={'center'}>
        <SocialIcon url={linkedinUrl} icon={IconLinkedin} backgroundColor={'#0077B5'} />
        <SocialIcon url={facebookUrl} icon={IconFacebook} backgroundColor={'#3B5998'} />
        <SocialIcon url={twitterUrl} icon={IconTwitter} backgroundColor={'#1B95E0'} />
      </FlexParent>
    </SocialLinksStyle>
  ) : null;
};

const SocialIcon = ({ url, icon, backgroundColor }) => {
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Icon
        icon={icon}
        backgroundColor={backgroundColor}
        borderRadius={'50%'}
        color={'#fff'}
        height={'20px'}
        width={'20px'}
        margin={'5px 5px 0'}
        padding={'4px'}
      />
    </a>
  ) : null;
};

export default SocialLinks;
