import { createSelector } from 'reselect';
import { API_COUNTRIES } from '../constants/apiRoutes';
import { filterIds } from '../helpers/data';

const countries = (state, props) => state.countries;
const countriesIds = (state, props) => state.sortIds[API_COUNTRIES] || [];

// --- Select objects ---------------------
export const selectCountries = createSelector(
  [countries],
  countries => {
    return countries || null;
  }
);
// ----------------------------------------

// --- Select ids in hashmap --------------
export const selectCountriesIds = createSelector(
  [countriesIds, selectCountries],
  (countriesIds, countries) => {
    return filterIds(countriesIds, countries);
  }
);
// ----------------------------------------
