import { UPDATE_GLOBAL, USER_LOGOUT, RESET_EVENT } from '../constants/actionLocal';
import {
  SUCCESS,
  FAILED,
  POST_USER_AVATAR_FILE,
  FETCH_OFFLINE,
  POST_USER_PWD,
  DELETE_USER
} from '../constants/actionApi';

import { SC_WHOS_HERE_MESSAGE, CREATED } from '../constants/socket';

import initialState from '../store/initialState';
import { updateObjectInState } from '../helpers/data';
import Socket from '../socket/Socket';

export default (state = initialState.global, { type, payload }) => {
  switch (type) {
    case UPDATE_GLOBAL:
      return updateObjectInState(state, payload);
    case RESET_EVENT:
      return updateObjectInState(state, {
        agendaCurrentDate: null,
        agendaCategoriesFilters: [],
        surveysCurrentPage: {},
        searchValue: '',
        presenceIds: []
      });
    case DELETE_USER + SUCCESS:
    case USER_LOGOUT:
      localStorage.removeItem('access_token');
      Socket.setAccessToken(null);
      return updateObjectInState(state, { accessToken: null });
    case POST_USER_AVATAR_FILE + SUCCESS:
      return updateObjectInState(state, { userAvatarFileUrl: payload.data.url });
    case FETCH_OFFLINE + SUCCESS:
      return state.offline !== false ? updateObjectInState(state, { offline: false }) : state;
    case FETCH_OFFLINE + FAILED:
      return state.offline !== true ? updateObjectInState(state, { offline: true }) : state;
    case POST_USER_PWD + SUCCESS:
      localStorage.setItem('access_token', payload.data.access_token);
      Socket.setAccessToken(payload.data.access_token);
      return updateObjectInState(state, {
        accessToken: payload.data.access_token,
        smartqaIsAnonymous: false
      });
    case SC_WHOS_HERE_MESSAGE + CREATED:
      return updateObjectInState(state, { mustRefetchWhosHereMessagesUnread: true });

    default:
      return state;
  }
};
