import Button from '../Button';
import Icon from '../../icons/Icon';
import IconReport from '../../icons/IconReport';
import Modal from '../../modal/Modal';
import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 0 30px;
`;

const ModalReport = props => {
  const { onClose, onValidate, open, theme, t } = props;
  return (
    <Modal onClose={onClose} open={open}>
      <Icon
        icon={IconReport}
        width={'80px'}
        height={'80px'}
        color={'#fff'}
        margin={'0 auto 20px'}
        padding={'15px'}
        borderRadius={'50%'}
        backgroundColor={theme.mainColor}
      />
      <Message>{t('smart_qa_modal_report_message')}</Message>
      <Button btntype={'primary'} onClick={onValidate}>
        {t('btn_report')}
      </Button>
      <br />
      <Button btntype={'light'} onClick={onClose}>
        {t('btn_cancel')}
      </Button>
    </Modal>
  );
};

export default ModalReport;
