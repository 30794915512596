import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const ModalStyle = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => (props.overlaycolor ? props.overlaycolor : 'rgba(0, 0, 0, 0.6)')};
  z-index: 30;
`;

const ModalMain = styled(motion.section)`
  text-align: center;
  position: relative;
  background: white;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 40;
  width: 90%;
  max-height: 80%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-width: 500px;
  margin: 0 auto;
  padding: 15px;
  border-radius: ${props => (props.borderradius ? props.borderradius : '8px')};
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.38);
`;

class Modal extends React.Component {
  onClickOutside = event => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { open, children, overlaycolor } = this.props;
    return (
      <React.Fragment>
        <AnimatePresence>
          {open && (
            <ModalStyle
              key="modal_style"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={this.onClickOutside}
              overlaycolor={overlaycolor}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {open && (
            <ModalMain
              key="modal_main"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              borderradius={this.props.borderradius}
            >
              {children}
            </ModalMain>
          )}
        </AnimatePresence>
      </React.Fragment>
    );
  }
}

export default Modal;
