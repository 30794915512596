import React from 'react';
import Card from '../../../components/shared/card/Card';
import FormGroup from '../../../components/shared/form/FormGroup';
import Input from '../../../components/shared/form/Input';
import Label from '../../../components/shared/form/Label';
import Button from '../../../components/shared/Button';
import { MEETING_CREATE_ATTENDEES, MEETING_EDIT_ATTENDEES } from '../../../constants/app';
import SelectAttendees from '../create/SelectAttendees';
import SelectedAttendees from '../create/SelectedAttendees';
import Datetime from 'react-datetime';
import { setLocale } from '../../../helpers/date';
import 'react-datetime/css/react-datetime.css';
import * as moment from 'moment-timezone';
import styled from 'styled-components';
import { applyTimezone } from '../../../helpers/date';
import Loader from '../../../components/shared/Loader';

const DateTimeContainer = styled(FormGroup)`
  input {
    width: 100%;
    margin: 0;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #d6d6d6;
    border-radius: 18px;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
`;

class MeetingCreate extends React.Component {
  constructor(props) {
    super(props);
    if (props.currentMeeting) {
      this.state = {
        id: props.currentMeeting.id,
        description: props.currentMeeting.description,
        location: props.currentMeeting.location,
        selectedAttendees: props.currentMeeting.attendees,
        startDate: applyTimezone(props.currentMeeting.start_date, props.event.timezone),
        endDate: applyTimezone(props.currentMeeting.end_date, props.event.timezone),
        isSending: false
      };
    } else {
      this.state = {
        description: '',
        location: '',
        selectedAttendees: [],
        startDate: this.getDateWithSecond(moment().add(1, 'h')),
        endDate: this.getDateWithSecond(moment().add(2, 'h')),
        isSending: false
      };
    }
  }

  componentDidMount() {
    if (this.props.matchParams.currentId && !this.props.currentMeeting) {
      this.props.fetchMeeting({ meeting_id: this.props.matchParams.currentId });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.matchParams.currentId && !this.props.currentMeeting) {
      this.props.fetchMeeting({ meeting_id: this.props.matchParams.currentId });
    }
    if (!prevProps.currentMeeting && this.props.currentMeeting) {
      this.setState({
        id: this.props.currentMeeting.id,
        description: this.props.currentMeeting.description,
        location: this.props.currentMeeting.location,
        selectedAttendees: this.props.currentMeeting.attendees,
        startDate: applyTimezone(this.props.currentMeeting.start_date, this.props.event.timezone),
        endDate: applyTimezone(this.props.currentMeeting.end_date, this.props.event.timezone)
      });
    }
  }

  isValideDate = function(current) {
    let isValid = true;
    if (moment().isAfter(current, 'day')) {
      isValid = false;
    }
    return isValid;
  };

  getDateWithSecond = date => {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }
    return date.format('YYYY-MM-DD HH:mm:ss');
  };

  getDateWithoutSecond = date => {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }
    return date.format('YYYY-MM-DD HH:mm');
  };

  onFormSubmit = event => {
    event.preventDefault();
    this.props.postMeeting({
      id: this.state.id,
      description: this.state.description,
      location: this.state.location,
      attendee_ids: this.state.selectedAttendees.map(attendee => attendee.id),
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      done: () => {
        this.props.updateView({});
      },
      fail: () => {
        this.setState({
          isSending: false
        });
      }
    });

    this.setState({
      isSending: true
    });
  };

  onChangeInput = event => {
    const data = {};
    data[event.target.name] = event.target.value;
    this.setState(data);
  };

  onChangeInputStartDate = momentDate => {
    if (!moment.isMoment(momentDate)) {
      return;
    }
    const data = { startDate: this.getDateWithSecond(momentDate) };
    if (momentDate.isAfter(this.state.endDate)) {
      data.endDate = this.getDateWithSecond(momentDate);
    }
    this.setState(data);
  };

  onChangeInputEndDate = momentDate => {
    if (!moment.isMoment(momentDate)) {
      return;
    }
    const data = { endDate: this.getDateWithSecond(momentDate) };
    if (momentDate.isBefore(this.state.startDate)) {
      data.startDate = this.getDateWithSecond(momentDate);
    }

    this.setState(data);
  };

  onClickAttendee = event => {
    const id = +event.currentTarget.dataset.id;
    const attendee = this.state.selectedAttendees.filter(att => att.id === id)[0];
    this.unselectAttendee(attendee);
  };

  onClickAddAttendee = () => {
    if (this.props.matchParams.currentId) {
      this.props.updateView({ view: MEETING_EDIT_ATTENDEES, id: this.props.matchParams.currentId });
    } else {
      this.props.updateView({ view: MEETING_CREATE_ATTENDEES });
    }
  };

  selectAttendee = attendee => {
    let selectedAttendees = [...this.state.selectedAttendees, attendee];
    this.setState({ selectedAttendees: selectedAttendees });
  };

  unselectAttendee = attendee => {
    let selectedAttendees = this.state.selectedAttendees.filter(att => attendee.id !== att.id);
    this.setState({ selectedAttendees: selectedAttendees });
  };

  render() {
    const {
      selectAttendee,
      unselectAttendee,
      onChangeInput,
      onChangeInputStartDate,
      onChangeInputEndDate,
      onFormSubmit,
      getDateWithoutSecond,
      onClickAddAttendee,
      onClickAttendee,
      isValideDate
    } = this;
    const { fetchAttendees } = this.props;
    const {
      user,
      theme,
      attendees,
      attendeesIds,
      currentView,
      hasMoreAttendees,
      isLoadingAttendees,
      t,
      offline
    } = this.props;
    const { selectedAttendees } = this.state;

    return (
      <React.Fragment>
        {this.state.isSending ? <Loader overlay={true} /> : null}
        {[MEETING_CREATE_ATTENDEES, MEETING_EDIT_ATTENDEES].indexOf(currentView) === -1 ? (
          <form onSubmit={onFormSubmit}>
            <Card margin={'0 0 20px'} padding={'10px'}>
              <FormGroup>
                <Label htmlFor={'description'}>{t('meeting_name')}</Label>
                <Input
                  id="description"
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={onChangeInput}
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor={'location'}>{t('meeting_location')}</Label>
                <Input
                  id="location"
                  type="text"
                  name="location"
                  value={this.state.location}
                  onChange={onChangeInput}
                />
              </FormGroup>

              <DateTimeContainer>
                <Label htmlFor={'startDate'}>{t('meeting_start_date')}</Label>
                <Datetime
                  id="startDate"
                  value={getDateWithoutSecond(this.state.startDate)}
                  onChange={onChangeInputStartDate}
                  dateFormat={'YYYY-MM-DD'}
                  timeFormat={'HH:mm'}
                  isValidDate={isValideDate}
                  locale={setLocale()}
                />
              </DateTimeContainer>

              <DateTimeContainer>
                <Label htmlFor={'endDate'}>{t('meeting_end_date')}</Label>
                <Datetime
                  id="endDate"
                  value={getDateWithoutSecond(this.state.endDate)}
                  onChange={onChangeInputEndDate}
                  dateFormat={'YYYY-MM-DD'}
                  timeFormat={'HH:mm'}
                  isValidDate={isValideDate}
                  inputProps={{
                    className: `form-control form-control-sm`
                  }}
                  locale={setLocale()}
                />
              </DateTimeContainer>

              <SelectedAttendees
                {...{ selectedAttendees, onClickAddAttendee, onClickAttendee, t }}
              />
            </Card>

            <Button
              display={'block'}
              width={'100%'}
              type={'submit'}
              btntype={'primary'}
              margin={'3px 0'}
              disabled={
                !offline && this.state.description && selectedAttendees.length ? false : 'disabled'
              }
            >
              {t('btn_publish')}
            </Button>
          </form>
        ) : (
          <SelectAttendees
            {...{
              user,
              theme,
              attendees,
              attendeesIds,
              fetchAttendees,
              selectAttendee,
              unselectAttendee,
              hasMoreAttendees,
              isLoadingAttendees,
              t,
              selectedAttendees
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default MeetingCreate;
