import {
  FAILED,
  FETCH_APP,
  FETCH_EVENT,
  POST_SMARTQA_POST,
  POST_USER_AVATAR_FILE,
  POST_USER_EMAIL,
  POST_USER_INCOGNITO,
  POST_USER_PWD,
  POST_USER,
  POST_CAMERA_FILE,
  POST_FAVORITE,
  POST_FILE,
  POST_FORGOT_PWD,
  POST_PUSH_NOTIF_SUBSCRIPTION,
  POST_RESET_PWD,
  POST_SMARTQA_COMMENT_REACTION,
  POST_SMARTQA_COMMENT_REPORT,
  POST_SMARTQA_COMMENT,
  POST_SMARTQA_POST_REACTION,
  POST_SMARTQA_POST_REPORT,
  POST_SMARTQA_POST_HIDE_TO_ANIMATORS,
  POST_SURVEY_ANSWERS,
  POST_VOTE_ANSWERS,
  POST_WHOS_HERE_CONVERSATION_LEAVE,
  POST_WHOS_HERE_CONVERSATION,
  POST_WHOS_HERE_MEETING_STATUS,
  POST_WHOS_HERE_MEETING,
  POST_WHOS_HERE_MESSAGE,
  PUT_USER,
  DELETE_FAVORITE,
  DELETE_SMARTQA_POST_REACTION,
  DELETE_SMARTQA_COMMENT_REACTION,
  DELETE_WHOS_HERE_MEETING
} from '../constants/actionApi';
import { UPDATE_ERRORS } from '../constants/actionLocal';
import initialState from '../store/initialState';
import { resetObjectInState } from '../helpers/data';

export default (state = initialState.errors, { type, payload }) => {
  switch (type) {
    case FETCH_APP + FAILED:
    case FETCH_EVENT + FAILED:
    case POST_SMARTQA_POST + FAILED:
    case POST_USER_AVATAR_FILE + FAILED:
    case POST_USER_EMAIL + FAILED:
    case POST_USER_INCOGNITO + FAILED:
    case POST_USER_PWD + FAILED:
    case POST_USER + FAILED:
    case POST_CAMERA_FILE + FAILED:
    case POST_FAVORITE + FAILED:
    case POST_FILE + FAILED:
    case POST_FORGOT_PWD + FAILED:
    case POST_PUSH_NOTIF_SUBSCRIPTION + FAILED:
    case POST_RESET_PWD + FAILED:
    case POST_SMARTQA_COMMENT_REACTION + FAILED:
    case POST_SMARTQA_COMMENT_REPORT + FAILED:
    case POST_SMARTQA_COMMENT + FAILED:
    case POST_SMARTQA_POST_REACTION + FAILED:
    case POST_SMARTQA_POST_REPORT + FAILED:
    case POST_SMARTQA_POST_HIDE_TO_ANIMATORS + FAILED:
    case POST_SURVEY_ANSWERS + FAILED:
    case POST_VOTE_ANSWERS + FAILED:
    case POST_WHOS_HERE_CONVERSATION_LEAVE + FAILED:
    case POST_WHOS_HERE_CONVERSATION + FAILED:
    case POST_WHOS_HERE_MEETING_STATUS + FAILED:
    case POST_WHOS_HERE_MEETING + FAILED:
    case POST_WHOS_HERE_MESSAGE + FAILED:
    case PUT_USER + FAILED:
    case DELETE_FAVORITE + FAILED:
    case DELETE_SMARTQA_POST_REACTION + FAILED:
    case DELETE_SMARTQA_COMMENT_REACTION + FAILED:
    case DELETE_WHOS_HERE_MEETING + FAILED:
      return resetObjectInState(state, payload.data);
    case UPDATE_ERRORS:
      return resetObjectInState(state, payload);
    default:
      return state;
  }
};
