import { connect } from 'react-redux';
import { updateHeader } from '../../actions/local';
import Event from './Event';
import React from 'react';
import { fetchShortLinks } from '../../actions/api';

import { selectCurrentEvent } from '../../selectors/events';
import { selectShortLinks } from '../../selectors/shortLinks';
import { selectUser } from '../../selectors/user';
import { selectGlobal } from '../../selectors/global';
import Mixpanel from '../../Mixpanel';

class EventContainer extends React.Component {
  constructor(props) {
    super(props);

    const { appId, eventId } = this.props.match.params;

    this.props.history.replace(`/apps/${appId}/events/${eventId}/home`);
  }

  componentDidMount() {
    const { appId, eventId } = this.props.match.params;
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    if (this.props.event) {
      this.props.fetchShortLinks({ event_id: this.props.event.id });
    }

    Mixpanel.track('view', {
      viewableType: 'event',
      viewableId: +eventId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.event && prevProps.event && this.props.event.id !== prevProps.event.id) {
      this.props.fetchShortLinks({ event_id: this.props.event.id });
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.props.fetchShortLinks({ event_id: this.props.event.id });
    }
  }

  render() {
    return (
      <Event
        event={this.props.event}
        matchParams={this.props.match.params}
        t={this.props.t}
        theme={this.props.theme}
        history={this.props.history}
        shortLinks={this.props.shortLinks}
      />
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchShortLinks: fetchShortLinks
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    shortLinks: selectShortLinks(state, { eventId: ownProps.match.params.eventId }),
    global: selectGlobal(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventContainer);
