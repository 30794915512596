import React from 'react';
import styled from 'styled-components';

const LabelStyle = styled.div`
  padding-bottom: 10px;
  font-size: 1.4rem;
  letter-spacing: 0.35px;
  font-weight: 500;
`;

const Label = props => {
  return <LabelStyle {...props}>{props.children}</LabelStyle>;
};

export default Label;
