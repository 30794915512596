import React from 'react';
import styled from 'styled-components';
import ModalOptions from './ModalOptions';
import { MEETING_EDIT } from '../../../constants/app';
import AttendeeItem from '../attendees/AttendeeItem';
import MeetingDetailsHeader from './MeetingDetailsHeader';

const Heading = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 0 15px;
  text-align: center;
`;

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

class MeetingDetails extends React.Component {
  state = {
    openOptionsModal: false
  };

  componentDidMount() {
    this.props.fetchMeeting({ meeting_id: this.props.meetingId });
  }

  componentDidUpdate(prevProps) {
    if (this.props.meetingId !== prevProps.meetingId) {
      this.props.fetchMeeting({ meeting_id: this.props.meetingId });
    }
  }

  onClickMeeting = () => {
    this.openOptionsModal();
  };

  onClickDelete = () => {
    this.props.deleteMeeting({
      meeting_id: this.props.meeting.id
    });
    this.props.updateView({});
  };

  onClickEdit = () => {
    this.props.updateView({ view: MEETING_EDIT, id: this.props.meeting.id });
  };

  onClickUpdateStatus = event => {
    this.props.postMeetingStatus({
      meeting_id: this.props.meeting.id,
      status: event.currentTarget.dataset.status
    });
  };

  openOptionsModal = () => {
    this.setState({ openOptionsModal: true });
  };

  closeOptionsModal = () => {
    this.setState({ openOptionsModal: false });
  };

  render() {
    if (!this.props.meeting) {
      return null;
    }

    const {
      meeting,
      componentFixedTopHeight,
      setComponentFixedTopHeight,
      user,
      theme,
      t,
      headerHeight,
      matchParams,
      history,
      updateView,
      event
    } = this.props;

    const {
      onClickDelete,
      onClickEdit,
      onClickUpdateStatus,
      onClickMeeting,
      closeOptionsModal: onClose
    } = this;

    const open = this.state.openOptionsModal;

    const attendeesWithOwner = [...meeting.attendees];
    attendeesWithOwner.unshift(meeting.owner);

    return (
      <React.Fragment>
        <MeetingDetailsHeader
          {...{
            onClickMeeting,
            headerHeight,
            setComponentFixedTopHeight,
            meeting,
            user,
            event,
            t,
            theme
          }}
        />

        <Container {...{ componentFixedTopHeight }}>
          <Heading>Invités</Heading>
          {attendeesWithOwner.map(attendee => {
            return (
              <AttendeeItem
                key={attendee.id}
                id={attendee.id}
                updateView={updateView}
                withoutFav={true}
                admin={attendee.id === meeting.owner.id}
                {...{ t, attendee, theme, matchParams, history, user }}
              />
            );
          })}
        </Container>

        <ModalOptions
          {...{
            open,
            onClose,
            onClickDelete,
            onClickEdit,
            onClickUpdateStatus,
            meeting,
            user,
            theme,
            t
          }}
        />
      </React.Fragment>
    );
  }
}

export default MeetingDetails;
