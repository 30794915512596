import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import Menu from './Menu';
import MenuControl from './MenuControl';
import { isDarkBgColor } from '../../helpers/helpers';
import FullImage from '../../components/shared/FullImage';

const MapContainer = styled.div`
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: ${props => props.headerHeight + 'px'};
  overflow: hidden;

  .mapbox-directions-component-keyline {
    box-shadow: none;
  }

  .mapboxgl-ctrl-menu {
    &.active {
      g {
        fill: ${props => props.theme.mainColor};
      }
    }
  }

  .mapboxgl-popup-anchor-top-right {
    .mapboxgl-popup-content {
      border-top-right-radius: 0 !important;
    }
  }

  .mapboxgl-popup-anchor-top-left {
    .mapboxgl-popup-content {
      border-top-left-radius: 0 !important;
    }
  }

  .mapboxgl-popup-anchor-bottom-right {
    .mapboxgl-popup-content {
      border-bottom-right-radius: 0 !important;
    }
  }

  .mapboxgl-popup-anchor-bottom-left {
    .mapboxgl-popup-content {
      border-bottom-left-radius: 0 !important;
    }
  }

  .mapboxgl-popup {
    max-width: 280px;

    .mapboxgl-popup-content {
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 #b0b0b0;
      padding: 15px 20px;
      max-height: 150px;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }

    .popup-name {
      font-weight: bold;
      font-size: 1.6rem;
      text-align: center;
      padding: 5px 0 5px;
    }

    .popup-address {
      font-size: 1.2rem;
      color: #000;
      font-weight: 400;
      color: ${props => props.theme.shadowGrey};
    }

    .popup-description {
      font-size: 1.4rem;
      font-weight: 200;
      padding: 3px 0;
    }

    .popup-indoor-map-btn {
      width: 100%;
      padding: 5px;
      cursor: pointer;
    }

    .popup-indoor-map-floor {
      text-align: center;
      font-weight: 500;
      display: inline-block;
      border-radius: 50%;
      margin: 0 5px 0 0;
      padding: 3px;
      width: 25px;
      height: 25px;
      background-color: ${props => props.theme.mainColor};
      color: ${props => {
        return isDarkBgColor(props.theme.mainColor) ? 'white' : props.theme.darkGrey;
      }};
    }

    .mapboxgl-popup-close-button {
      font-size: 1.8rem;
      display: block;
      padding: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: black;
      text-align: center;
    }
  }
`;

class Map extends React.Component {
  state = {
    showMenu: false,
    currentIndoorMap: null,
    markers: []
  };

  componentDidMount() {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
    this.map = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [this.props.map.default_lng, this.props.map.default_lat], // starting position
      zoom: this.props.map.default_zoom
    });

    this.renderMarkers(this.props.poisIds, this.props.pois);

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
    );

    this.initMenu();
  }

  initMenu = () => {
    this.menuControl = new MenuControl();
    this.menuControl.onClick = this.toggleOpenMenu;
    this.map.addControl(this.menuControl);

    this.map.on('load', () => {
      this.map.addSource('single-point', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      this.map.addLayer({
        id: 'point',
        source: 'single-point',
        type: 'circle',
        paint: {
          'circle-radius': 7,
          'circle-color': this.props.theme.mainColor,
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 2
        }
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.poisIds.length && this.props.poisIds.length) {
      this.renderMarkers(this.props.poisIds, this.props.pois);
    }
  }

  renderMarkers = (poisIds, pois) => {
    this.removeMarkers();
    const markers = {};
    for (const poiId of poisIds) {
      const poi = pois[poiId];
      const marker = this.renderMarker(poi);
      markers[poi.id] = marker;
    }

    this.setState({
      markers
    });
  };

  renderMarker = poi => {
    const popup = this.createPopup(poi);
    const marker = new mapboxgl.Marker({
      color: poi.color
    })
      .setLngLat({
        lng: poi.latlng[0][1],
        lat: poi.latlng[0][0]
      })
      .setPopup(popup)
      .addTo(this.map);

    marker.getElement().addEventListener('click', event => {
      this.map.flyTo({ center: { lng: poi.latlng[0][1], lat: poi.latlng[0][0] } });
    });

    return marker;
  };

  removeMarkers = () => {
    for (const markerId of Object.keys(this.state.markers)) {
      const marker = this.state.markers[markerId];
      marker.remove();
    }
  };

  createPopup = poi => {
    let popupContent = `${poi.name ? `<div class="popup-name">${poi.name}</div>` : ``}${
      poi.address ? `<div class="popup-address">${poi.address}</div>` : ``
    }${poi.description ? `<div class="popup-description">${poi.description}</div>` : ``}`;

    for (const indoorMap of poi.child_indoor_maps) {
      popupContent += `<div class="popup-indoor-map-btn" data-indoor-map-id="${indoorMap.id}"><div class="popup-indoor-map-floor">${indoorMap.level}</div> ${indoorMap.name}</div>`;
    }

    let popupEl = window.document.createElement('div');
    popupEl.innerHTML = popupContent;
    popupEl.addEventListener('click', this.onClickPopup);
    return new mapboxgl.Popup().setDOMContent(popupEl);
  };

  closeAllPopups = () => {
    for (const markerId of Object.keys(this.state.markers)) {
      const marker = this.state.markers[markerId];
      if (marker.getPopup().isOpen()) {
        marker.togglePopup();
      }
    }
  };

  onClickPopup = event => {
    if (event.target.classList.contains('popup-indoor-map-btn')) {
      const indoorMap = this.findIndoorMap(event.target.dataset.indoorMapId);
      this.openIndoorMap(indoorMap);
    }
  };

  jumpToPoi = poi => {
    this.map.flyTo({ center: { lng: poi.latlng[0][1], lat: poi.latlng[0][0] } });
    this.closeAllPopups();
    let marker = this.state.markers[poi.id];
    marker.togglePopup();
    this.closeMenu();
  };

  toggleOpenMenu = () => {
    this.setState(
      {
        showMenu: !this.state.showMenu
      },
      () => {
        this.menuControl.setActive(this.state.showMenu);
      }
    );
  };

  closeMenu = () => {
    this.setState({
      showMenu: false
    });
    this.menuControl.setActive(false);
  };

  openIndoorMap = indoorMap => {
    this.setState({
      currentIndoorMap: indoorMap
    });
  };

  closeIndoorMap = () => {
    this.setState({
      currentIndoorMap: null
    });
  };

  findIndoorMap = indoorMapId => {
    return (
      this.props.indoorMaps.find(indoorMap => {
        return indoorMap.id === +indoorMapId;
      }) || {}
    );
  };

  render() {
    const { headerHeight, indoorMaps, poisIds, pois, t, theme } = this.props;
    const { closeMenu, jumpToPoi, openIndoorMap, map } = this;
    return (
      <React.Fragment>
        {this.state.showMenu ? (
          <Menu
            {...{
              t,
              theme,
              headerHeight,
              indoorMaps,
              poisIds,
              pois,
              jumpToPoi,
              closeMenu,
              openIndoorMap,
              map
            }}
          />
        ) : null}

        <MapContainer id="map-container" headerHeight={headerHeight} />

        <FullImage
          url={this.state.currentIndoorMap ? this.state.currentIndoorMap.picture_url : null}
          onClickClose={this.closeIndoorMap}
        ></FullImage>
      </React.Fragment>
    );
  }
}

export default Map;
