import React from 'react';
import Tabs from '../../components/shared/tabs/Tabs';
import {
  TAB_ATTENDEES,
  TAB_CONVERSATIONS,
  TAB_MEETINGS,
  ATTENDEE_DETAILS,
  CONVERSATION_CREATE_ATTENDEES,
  CONVERSATION_CREATE,
  CONVERSATION_EDIT,
  CONVERSATION_EDIT_ATTENDEES,
  CONVERSATION_DETAILS,
  MEETING_CREATE_ATTENDEES,
  MEETING_EDIT_ATTENDEES,
  MEETING_CREATE,
  MEETING_DETAILS,
  MEETING_EDIT
} from '../../constants/app';
import styled from 'styled-components';
import AttendeesList from './attendees/AttendeesList';
import AttendeeDetails from './attendees/AttendeeDetails';
import ConversationsList from './conversations/ConversationsList';
import ConversationDetails from './conversations/ConversationDetails';
import ConversationCreate from './conversations/ConversationCreate';
import MeetingsList from './meetings/MeetingsList';
import MeetingDetails from './meetings/MeetingDetails';
import MeetingCreate from './meetings/MeetingCreate';

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

class WhosHere extends React.Component {
  render() {
    // Props
    const {
      attendees,
      attendeesIds,
      favoritesAttendees,
      favoritesAttendeesIds,
      componentFixedBottomHeight,
      componentFixedTopHeight,
      conversations,
      conversationsIds,
      currentAttendee,
      currentConversation,
      currentId,
      currentMeeting,
      currentTab,
      currentView,
      event,
      hasMoreAttendees,
      hasMoreConversations,
      hasMoreMeetings,
      hasMoreMessages,
      headerHeight,
      history,
      isLoadingAttendees,
      isLoadingConversations,
      isLoadingMeetings,
      isLoadingMessages,
      matchParams,
      meetings,
      meetingsIds,
      messages,
      messagesIds,
      notifsMessagesCount,
      notifsMeetingsCount,
      t,
      theme,
      user,
      whosHere,
      presenceIds,
      locale,
      offline,
      accessToken
    } = this.props;

    // functions
    const {
      deleteMeeting,
      fetchAttendee,
      fetchAttendees,
      fetchFavorites,
      fetchConversation,
      fetchConversations,
      fetchMeeting,
      fetchMeetings,
      fetchMessages,
      postConversation,
      postConversationLeave,
      postMeeting,
      postMeetingStatus,
      postMessage,
      postWhosHereMessageRead,
      uploadPostFile,
      uploadCameraFile,
      setComponentFixedBottomHeight,
      setComponentFixedTopHeight,
      updateTab,
      updateView,
      resetNotification
    } = this.props;

    this.tabs = [
      {
        slug: TAB_ATTENDEES,
        label: t('tab_attendees')
      },
      {
        slug: TAB_CONVERSATIONS,
        label: t('tab_conversations'),
        notif: notifsMessagesCount ? notifsMessagesCount.unread_messages_count : null
      },
      {
        slug: TAB_MEETINGS,
        label: t('tab_meetings'),
        notif: notifsMeetingsCount
      }
    ];

    return (
      <React.Fragment>
        {currentView ? null : (
          <Tabs
            {...{
              currentTab,
              headerHeight,
              history,
              setComponentFixedTopHeight,
              t,
              theme,
              updateTab
            }}
            tabs={this.tabs}
          />
        )}

        <Container componentFixedTopHeight={currentView ? 0 : componentFixedTopHeight}>
          {currentTab === TAB_ATTENDEES && currentView === ATTENDEE_DETAILS && currentId ? (
            <AttendeeDetails
              attendee={currentAttendee}
              attendeeId={currentId}
              {...{ theme, matchParams, history, t, user, whosHere }}
              {...{ fetchAttendee }}
            />
          ) : currentTab === TAB_ATTENDEES && !currentView ? (
            <AttendeesList
              {...{
                attendees,
                attendeesIds,
                favoritesAttendees,
                favoritesAttendeesIds,
                event,
                hasMoreAttendees,
                history,
                isLoadingAttendees,
                matchParams,
                t,
                theme,
                user,
                whosHere,
                presenceIds
              }}
              {...{ fetchAttendees, fetchFavorites, updateView }}
            />
          ) : currentTab === TAB_CONVERSATIONS &&
            currentView === CONVERSATION_DETAILS &&
            currentId ? (
                  <ConversationDetails
                    conversation={currentConversation}
                    conversationId={currentId}
                    {...{
                      fetchConversation,
                      fetchMessages,
                      postConversationLeave,
                      postMessage,
                      postWhosHereMessageRead,
                      uploadPostFile,
                      uploadCameraFile,
                      setComponentFixedBottomHeight,
                      updateView
                    }}
                    {...{
                      componentFixedBottomHeight,
                      componentFixedTopHeight,
                      event,
                      hasMoreMessages,
                      headerHeight,
                      isLoadingMessages,
                      messages,
                      messagesIds,
                      setComponentFixedTopHeight,
                      t,
                      theme,
                      user,
                      offline,
                      accessToken
                    }}
                  />
                ) : currentTab === TAB_CONVERSATIONS &&
                  [
                    CONVERSATION_CREATE,
                    CONVERSATION_CREATE_ATTENDEES,
                    CONVERSATION_EDIT,
                    CONVERSATION_EDIT_ATTENDEES
                  ].indexOf(currentView) !== -1 ? (
                    <ConversationCreate
                      {...{
                        attendees,
                        attendeesIds,
                        currentConversation,
                        currentView,
                        event,
                        hasMoreAttendees,
                        isLoadingAttendees,
                        matchParams,
                        theme,
                        user,
                        t,
                        offline,
                        history
                      }}
                      {...{ fetchAttendees, fetchConversation, postConversation, updateView }}
                    />
                  ) : currentTab === TAB_CONVERSATIONS ? (
                    <ConversationsList
                      {...{
                        conversations,
                        conversationsIds,
                        event,
                        hasMoreConversations,
                        history,
                        isLoadingConversations,
                        matchParams,
                        t,
                        theme,
                        updateView,
                        user,
                        whosHere,
                        locale,
                        resetNotification,
                        notifsMessagesCount
                      }}
                      {...{ fetchConversations }}
                    />
                  ) : currentTab === TAB_MEETINGS &&
                    [
                      MEETING_CREATE,
                      MEETING_CREATE_ATTENDEES,
                      MEETING_EDIT,
                      MEETING_EDIT_ATTENDEES
                    ].indexOf(currentView) !== -1 ? (
                        <MeetingCreate
                          {...{
                            attendees,
                            attendeesIds,
                            currentMeeting,
                            currentView,
                            event,
                            hasMoreAttendees,
                            isLoadingAttendees,
                            matchParams,
                            theme,
                            user,
                            t,
                            offline
                          }}
                          {...{ fetchMeeting, fetchAttendees, postMeeting, updateView }}
                        />
                      ) : currentTab === TAB_MEETINGS && currentView === MEETING_DETAILS && currentId ? (
                        <MeetingDetails
                          meeting={currentMeeting}
                          meetingId={currentId}
                          {...{ fetchMeeting, fetchMeetings, deleteMeeting, updateView, postMeetingStatus }}
                          {...{
                            componentFixedTopHeight,
                            event,
                            headerHeight,
                            history,
                            matchParams,
                            setComponentFixedTopHeight,
                            t,
                            theme,
                            user
                          }}
                        />
                      ) : currentTab === TAB_MEETINGS ? (
                        <MeetingsList
                          {...{
                            event,
                            hasMoreMeetings,
                            history,
                            isLoadingMeetings,
                            matchParams,
                            meetings,
                            meetingsIds,
                            t,
                            theme,
                            user,
                            whosHere,
                            notifsMeetingsCount
                          }}
                          {...{
                            fetchMeetings,
                            postMeetingStatus,
                            updateView,
                            resetNotification
                          }}
                        />
                      ) : null}
        </Container>
      </React.Fragment>
    );
  }
}

export default WhosHere;
