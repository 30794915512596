import { getEventFormatedDate, getEventVenue } from '../../helpers/format';
import { getEventSplitedDate } from '../../helpers/date';
import { isDarkBgColor } from '../../helpers/helpers';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconChevron from '../../components/icons/IconChevron';
import React from 'react';
import styled from 'styled-components';
import { getEventIconBySize } from '../../helpers/format';
import { motion } from 'framer-motion';

const Item = styled(motion.div)`
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.38);
  min-height: 20px;
  margin: 20px 0;
  padding: 0;
  cursor: pointer;
  word-break: break-word;

  &:last-child {
    margin-bottom: 5px;
  }
`;

const Top = styled(motion.div)`
  background-color: ${props => props.eventcolor};
  border-radius: 8px 8px 0 0;
  min-height: ${props => (props.cover ? `300px` : 'initial')};
  background-image: ${props => (props.cover ? `url(${props.cover})` : 'initial')};
  background-size: cover;
  background-position: center;
`;

const Bottom = styled(motion.div)`
  background-color: ${props => props.eventcolor};
  color: ${props => {
    return isDarkBgColor(props.eventcolor) ? 'white' : props.theme.darkGrey;
  }};
  border-radius: 0 0 8px 8px;
  padding: 15px;
`;

const EventLogo = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background-color: #ffffff;
  display: block;
  padding: 3px;
`;

const Overlay = styled(motion.div)`
  border-radius: 8px 8px 0 0;
  background-image: ${props =>
    props.cover ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 95%)' : 'none'};
`;

const EventName = styled.div`
  position: relative;
  color: #efefef;
  text-shadow: 0 1px 0 black;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 15px 15px 8px;
`;

const EventTagline = styled.div`
  position: relative;
  color: white;
  text-shadow: 0 1px 0 black;
  font-size: 2.4rem;
  line-height: 2.5rem;
  font-weight: bold;
  padding: 0 15px 15px;
`;

const EventDate = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  padding: 2px 0;
  text-transform: uppercase;
`;
const EventVenue = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 0;
`;

class EventItem extends React.Component {
  onClickItem = () => {
    this.props.hasSelected(this.props.event.id);
  };

  onAnimationComplete = () => {
    const event = this.props.event;
    if (this.props.selectedId === event.id) {
      this.props.history.push(`/apps/${event.group_id}/events/${event.id}/home`);
    }
  };

  render() {
    const event = this.props.event;
    const eventSplitedDate = getEventSplitedDate(event.start_date, event.end_date);
    const eventFormatedDate = getEventFormatedDate(eventSplitedDate);
    return (
      <Item
        onClick={this.onClickItem}
        key={`event_${event.id}`}
        animate={this.props.selectedId && this.props.selectedId === event.id ? 'selected' : ''}
        variants={{
          selected: {
            borderRadius: '0px',
            marginLeft: '-10px',
            marginRight: '-10px',
            marginTop: '-66px',
            transition: { duration: 0.3 }
          }
        }}
        onAnimationComplete={this.onAnimationComplete}
      >
        <Top
          eventcolor={event.color}
          cover={event.cover_url}
          key={`event_top_${event.id}`}
          variants={{
            selected: { borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }
          }}
        >
          <Overlay
            key={`event_overlay_${event.id}`}
            cover={event.cover_url}
            variants={{
              selected: { borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }
            }}
          >
            <EventName>{event.name}</EventName>
            {event.tagline && <EventTagline>{event.tagline}</EventTagline>}
          </Overlay>
        </Top>
        <Bottom
          eventcolor={event.color}
          key={`event_bottom_${event.id}`}
          variants={{
            selected: { borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }
          }}
        >
          <FlexParent alignItems={'center'} justifyContent={'space-between'}>
            {event.icon_url && (
              <FlexItem width={'48px'} margin={'0 15px 0 0'}>
                <EventLogo src={getEventIconBySize(event.icon_url, '144')} />
              </FlexItem>
            )}
            <FlexItem grow={1}>
              <EventDate>{eventFormatedDate}</EventDate>
              {event.venue && event.venue.name && (
                <EventVenue>{getEventVenue(event.venue)}</EventVenue>
              )}
            </FlexItem>
            <FlexItem alignSelf={'center'}>
              <motion.div
                key={`event_icon_${event.id}`}
                variants={{
                  selected: { opacity: 0 }
                }}
              >
                <Icon
                  icon={IconChevron}
                  width={'15px'}
                  height={'15px'}
                  padding={'0 0 0 0'}
                  color={isDarkBgColor(event.color) ? '#fff' : this.props.theme.darkGrey}
                />
              </motion.div>
            </FlexItem>
          </FlexParent>
        </Bottom>
      </Item>
    );
  }
}

export default EventItem;
