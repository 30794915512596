import { createSelector } from 'reselect';

const getErrors = state => state.errors;

export const selectErrors = createSelector(
  [getErrors],
  error => {
    return error;
  }
);
