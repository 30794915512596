import React from 'react';
import Button from '../../../components/shared/Button';
import FlexParent from '../../../components/shared/flex/FlexParent';
import FlexItem from '../../../components/shared/flex/FlexItem';
import styled from 'styled-components';
import FormGroup from '../../../components/shared/form/FormGroup';
import Label from '../../../components/shared/form/Label';
import Input from '../../../components/shared/form/Input';
import Loader from '../../../components/shared/Loader';

const Container = styled.div`
  margin-top: 100px;
`;

const SSOContainer = styled.div``;

const SSOButton = styled.div`
  border: 1px solid ${props => props.theme.mainColor};
  padding: 5px 10px;
  background-color: white;
  cursor: pointer;
  border-radius: 8px;
`;

const SSOLabel = styled.div`
  font-weight: 500;
`;

const SSOIcon = styled.img`
  width: 25px;
  margin-right: 10px;
`;

const Hr = styled.hr`
  background-color: ${props => props.theme.dustGrey};
  margin: 30px 0;
  border: 0;
  height: 1px;
`;

class UserSignin extends React.Component {
  state = {
    isLoading: false,
    email: '',
    password: '',
    userEmailIsValid: false,
    resetSuccessMessage: ''
  };

  onChangeInputEmail = event => {
    this.setState({ email: event.target.value });
  };

  onChangeInputPassword = event => {
    this.setState({ password: event.target.value });
  };

  onClickEmailInput = () => {
    this.setState({
      userEmailIsValid: false
    });
  };

  onSubmitFormEmail = event => {
    event.preventDefault();
    const email = this.state.email;
    this.setState({
      isLoading: true
    });
    this.props.testUserEmail({
      email,
      done: () => {
        this.setState({
          userEmailIsValid: true
        });
      },
      fail: () => {
        this.setState({
          userEmailIsValid: false
        });
      },
      always: () => {
        this.setState({
          isLoading: false
        });
      }
    });
  };

  onSubmitFormPwd = event => {
    event.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    this.setState({
      isLoading: true
    });

    this.props.testUserPwd({
      email,
      password,
      username: email,
      fail: () => {
        this.setState({
          isLoading: false
        });
      }
    });
    this.setState({ password: '' });
  };

  onClickRegister = event => {
    this.props.navigateToRegister();
  };

  onClickResetPassword = event => {
    const email = this.state.email;
    this.props.resetPassword({
      email
    });
  };

  render() {
    const { t, event } = this.props;

    const authSettingsProviders = {
      google: {
        icon: 'google'
      },
      microsoft: {
        icon: 'microsoft'
      },
      'saml-2': {
        icon: 'saml'
      }
    };

    return (
      <Container>
        {this.props.app && this.props.app.auth_settings ? (
          <SSOContainer>
            <FlexParent alignItems={'center'} justifyContent={'center'}>
              <SSOButton onClick={this.props.singleSignOn}>
                <FlexParent alignItems={'center'} justifyContent={'center'}>
                  <SSOIcon
                    src={`${process.env.PUBLIC_URL}/images/${
                      authSettingsProviders[this.props.app.auth_settings.provider].icon
                    }.svg`}
                  />
                  <SSOLabel>{this.props.t('sso_heading')}</SSOLabel>
                </FlexParent>
              </SSOButton>
            </FlexParent>
            <Hr />
          </SSOContainer>
        ) : null}

        {!(
          this.props.app &&
          this.props.app.auth_settings &&
          this.props.app.auth_settings.is_required
        ) ? (
          <React.Fragment>
            {!this.state.userEmailIsValid ? (
              <form onSubmit={this.onSubmitFormEmail}>
                <FormGroup>
                  <Label htmlFor="email">E-mail</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder={'name@domain.com'}
                    value={this.state.email}
                    onChange={this.onChangeInputEmail}
                    autoFocus={'autofocus'}
                  />
                </FormGroup>
                {this.state.isLoading && <Loader />}
                <FlexParent alignItems={'center'}>
                  <FlexItem grow={1}>
                    {event &&
                    ['public', 'protected'].indexOf(event.visibility) !== -1 &&
                    event.status !== 'draft' ? (
                      <Button
                        type={'button'}
                        margin={'0'}
                        padding={'0'}
                        onClick={this.onClickRegister}
                      >
                        {t('btn_create_account')}
                      </Button>
                    ) : null}
                  </FlexItem>
                  <FlexItem>
                    <Button
                      type={'submit'}
                      btntype={'primary'}
                      margin={'3px 0'}
                      disabled={this.state.email ? false : 'disabled'}
                    >
                      {t('btn_next')}
                    </Button>
                  </FlexItem>
                </FlexParent>
              </form>
            ) : (
              <form onSubmit={this.onSubmitFormPwd}>
                <FormGroup>
                  <Label htmlFor="email2">E-mail</Label>
                  <Input
                    id="email2"
                    type="text"
                    value={this.state.email}
                    onClick={this.onClickEmailInput}
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="password">{t('user_password')}</Label>
                  <Input
                    id="password"
                    type="password"
                    placeholder={t('user_password')}
                    value={this.state.password}
                    onChange={this.onChangeInputPassword}
                  />
                </FormGroup>

                {this.state.isLoading && <Loader />}

                <FlexParent alignItems={'center'}>
                  <FlexItem grow={1}>
                    <Button
                      type={'button'}
                      margin={'0'}
                      padding={'0'}
                      onClick={this.onClickResetPassword}
                    >
                      {t('btn_forgot_password')}
                    </Button>
                  </FlexItem>

                  <FlexItem>
                    <Button
                      type={'submit'}
                      btntype={'primary'}
                      margin={'3px 0'}
                      disabled={this.state.password ? false : 'disabled'}
                    >
                      {t('btn_next')}
                    </Button>
                  </FlexItem>
                </FlexParent>
              </form>
            )}
          </React.Fragment>
        ) : null}
      </Container>
    );
  }
}

export default UserSignin;
