import Button from '../shared/Button';
import Icon from '../icons/Icon';
import IconVisible from '../icons/IconVisible';
import Modal from '../modal/Modal';
import React from 'react';
import styled from 'styled-components';

const Heading = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  padding: 10px 0 30px;
`;

class ModalIsNotIncognito extends React.Component {
  render() {
    const { onClose, onValidate, open, theme, t } = this.props;
    return (
      <Modal onClose={onClose} open={open}>
        <Icon
          icon={IconVisible}
          width={'80px'}
          height={'80px'}
          color={'#fff'}
          margin={'0 auto 20px'}
          padding={'15px'}
          borderRadius={'50%'}
          backgroundColor={theme.mainColor}
        />
        <Heading>{t('whos_here_not_incognito_heading')}</Heading>
        <Button btntype={'primary'} onClick={onValidate}>
          {t('btn_ok')}
        </Button>
      </Modal>
    );
  }
}

export default ModalIsNotIncognito;
