import { createSelector } from 'reselect';
import { filterObjects } from '../helpers/data';

const currentSurveyId = (state, props) => props.match.params.surveyId;
const surveys = (state, props) => state.surveys;
const pages = (state, props) => state.surveyPages;
const answers = (state, props) => state.surveyAnswers;

export const selectCurrentSurvey = createSelector(
  [surveys, currentSurveyId],
  (surveys, surveyId) => {
    return surveys[surveyId] || null;
  }
);

export const selectFilteredPages = createSelector(
  [pages, currentSurveyId],
  (pages, surveyId) => {
    return filterObjects(
      pages,
      page => {
        return page.survey_id === +surveyId;
      },
      true
    );
  }
);

export const selectFilteredAnswers = createSelector(
  [answers, currentSurveyId],
  (answers, surveyId) => {
    const filteredAnswers = answers[surveyId];
    if (!filteredAnswers) {
      return { currentPage: 0, answers: {} };
    } else {
      return filteredAnswers;
    }
  }
);
