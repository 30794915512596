import Icon from '../icons/Icon';
import IconVip from '../icons/IconVip';
import React from 'react';
import styled from 'styled-components';
import HtmlContent from './HtmlContent';
import Hr from './Hr';
// Styled Components

const ItemDesc = styled.div`
  font-size: 1.4rem;
  line-height: 1.4em;
  font-weight: 300;
  text-align: justify;
`;

const ItemHeading = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 0 15px;
  text-align: center;
`;

export const Logo = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: block;
  object-fit: cover;
`;

export const ItemName = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  padding: 15px 0 5px;
  text-align: center;
`;

export const ItemSubName = styled.div`
  color: ${props => props.theme.ashesGrey};
  text-align: center;
  font-size: 1.4rem;
`;

export const FavItem = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const DownloadVCard = styled.a`
  display: block;
  text-align: center;
  color: ${props => props.theme.mainColor};
  padding: 0 12px;
  margin: 10px 3px 0;
  text-transform: uppercase;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-decoration: none;
`;

// Components
export const VipIcon = ({ isVip, ...props }) => {
  return isVip ? (
    <Icon
      icon={IconVip}
      color={'#fff'}
      padding={'3px'}
      margin={'0 0 0 10px'}
      backgroundColor={props.theme.mainColor}
      borderRadius={'50%'}
      {...props}
    />
  ) : null;
};

export const Description = ({ heading, description }) => {
  return description ? (
    <React.Fragment>
      <Hr />
      <ItemHeading>{heading}</ItemHeading>
      <ItemDesc>
        <HtmlContent>{description}</HtmlContent>
      </ItemDesc>
    </React.Fragment>
  ) : null;
};
