import { SUCCESS, FETCH_SURVEY_PAGES } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.surveyPages, { type, payload }) => {
  switch (type) {
    case FETCH_SURVEY_PAGES + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
