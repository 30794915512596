import {
  SUCCESS,
  FAILED,
  FETCH_AGENDA_CATEGORIES,
  FETCH_ATTENDEES,
  FETCH_BONUSES,
  FETCH_COUNTRIES,
  FETCH_EVENTS,
  FETCH_EXHIBITORS,
  FETCH_MENU_ITEMS,
  FETCH_SESSIONS,
  FETCH_SMARTQA_COMMENTS,
  FETCH_SMARTQA_POSTS,
  FETCH_SPEAKERS,
  FETCH_SPONSORS,
  FETCH_SURVEY_PAGES,
  FETCH_TIMELINE_ITEMS,
  FETCH_TWEETS,
  FETCH_VOTE_ROUNDS,
  FETCH_WHOS_HERE_CONVERSATIONS,
  FETCH_WHOS_HERE_MEETINGS,
  FETCH_WHOS_HERE_MESSAGES,
  FETCH_FAVORITES,
  FETCH_YOUTUBE_VIDEOS
} from '../constants/actionApi';
import { addOrUpdateLoadingInState } from '../helpers/data';
import initialState from '../store/initialState';
import {
  API_AGENDA_CATEGORIES,
  API_AGENDA_SESSIONS,
  API_ATTENDEES,
  API_BONUSES,
  API_COUNTRIES,
  API_EVENTS,
  API_EXHIBITORS,
  API_MENU_ITEMS,
  API_SMARTQA_COMMENTS,
  API_SMARTQA_QUESTIONS,
  API_SPEAKERS,
  API_SPONSORS,
  API_SURVEY_PAGES,
  API_TIMELINE_ITEMS,
  API_TWEETS,
  API_VOTE_ROUNDS,
  API_WHOS_HERE_CONVERSATIONS,
  API_WHOS_HERE_MEETINGS,
  API_WHOS_HERE_MESSAGES,
  API_FAVORITES,
  API_YOUTUBE_VIDEOS
} from '../constants/apiRoutes';

export default (state = initialState.loading, { type, payload }) => {
  switch (type) {
    case FETCH_EXHIBITORS:
      return addOrUpdateLoadingInState(state, API_EXHIBITORS, { loading: true });
    case FETCH_SPEAKERS:
      return addOrUpdateLoadingInState(state, API_SPEAKERS, { loading: true });
    case FETCH_SPONSORS:
      return addOrUpdateLoadingInState(state, API_SPONSORS, { loading: true });
    case FETCH_SMARTQA_POSTS:
      return addOrUpdateLoadingInState(state, API_SMARTQA_QUESTIONS, { loading: true });
    case FETCH_SMARTQA_COMMENTS:
      return addOrUpdateLoadingInState(state, API_SMARTQA_COMMENTS, { loading: true });
    case FETCH_ATTENDEES:
      return addOrUpdateLoadingInState(state, API_ATTENDEES, { loading: true });
    case FETCH_WHOS_HERE_CONVERSATIONS:
      return addOrUpdateLoadingInState(state, API_WHOS_HERE_CONVERSATIONS, { loading: true });
    case FETCH_WHOS_HERE_MESSAGES:
      return addOrUpdateLoadingInState(state, API_WHOS_HERE_MESSAGES, { loading: true });
    case FETCH_WHOS_HERE_MEETINGS:
      return addOrUpdateLoadingInState(state, API_WHOS_HERE_MEETINGS, { loading: true });
    case FETCH_AGENDA_CATEGORIES:
      return addOrUpdateLoadingInState(state, API_AGENDA_CATEGORIES, { loading: true });
    case FETCH_SESSIONS:
      return addOrUpdateLoadingInState(state, API_AGENDA_SESSIONS, { loading: true });
    case FETCH_COUNTRIES:
      return addOrUpdateLoadingInState(state, API_COUNTRIES, { loading: true });
    case FETCH_BONUSES:
      return addOrUpdateLoadingInState(state, API_BONUSES, { loading: true });
    case FETCH_MENU_ITEMS:
      return addOrUpdateLoadingInState(state, API_MENU_ITEMS, { loading: true });
    case FETCH_SURVEY_PAGES:
      return addOrUpdateLoadingInState(state, API_SURVEY_PAGES, { loading: true });
    case FETCH_TWEETS:
      return addOrUpdateLoadingInState(state, API_TWEETS, { loading: true });
    case FETCH_YOUTUBE_VIDEOS:
      return addOrUpdateLoadingInState(state, API_YOUTUBE_VIDEOS, { loading: true });
    case FETCH_VOTE_ROUNDS:
      return addOrUpdateLoadingInState(state, API_VOTE_ROUNDS, { loading: true });
    case FETCH_EVENTS:
      return addOrUpdateLoadingInState(state, API_EVENTS, { loading: true });
    case FETCH_TIMELINE_ITEMS:
      return addOrUpdateLoadingInState(state, API_TIMELINE_ITEMS, { loading: true });
    case FETCH_FAVORITES:
      return addOrUpdateLoadingInState(state, API_FAVORITES, { loading: true });

    case FETCH_EXHIBITORS + SUCCESS:
    case FETCH_EXHIBITORS + FAILED:
      return addOrUpdateLoadingInState(state, API_EXHIBITORS, { loading: false });
    case FETCH_SPEAKERS + SUCCESS:
    case FETCH_SPEAKERS + FAILED:
      return addOrUpdateLoadingInState(state, API_SPEAKERS, { loading: false });
    case FETCH_SPONSORS + SUCCESS:
    case FETCH_SPONSORS + FAILED:
      return addOrUpdateLoadingInState(state, API_SPONSORS, { loading: false });
    case FETCH_SMARTQA_POSTS + SUCCESS:
    case FETCH_SMARTQA_POSTS + FAILED:
      return addOrUpdateLoadingInState(state, API_SMARTQA_QUESTIONS, { loading: false });
    case FETCH_SMARTQA_COMMENTS + SUCCESS:
    case FETCH_SMARTQA_COMMENTS + FAILED:
      return addOrUpdateLoadingInState(state, API_SMARTQA_COMMENTS, { loading: false });
    case FETCH_ATTENDEES + SUCCESS:
    case FETCH_ATTENDEES + FAILED:
      return addOrUpdateLoadingInState(state, API_ATTENDEES, { loading: false });
    case FETCH_WHOS_HERE_CONVERSATIONS + SUCCESS:
    case FETCH_WHOS_HERE_CONVERSATIONS + FAILED:
      return addOrUpdateLoadingInState(state, API_WHOS_HERE_CONVERSATIONS, { loading: false });
    case FETCH_WHOS_HERE_MESSAGES + SUCCESS:
    case FETCH_WHOS_HERE_MESSAGES + FAILED:
      return addOrUpdateLoadingInState(state, API_WHOS_HERE_MESSAGES, { loading: false });
    case FETCH_WHOS_HERE_MEETINGS + SUCCESS:
    case FETCH_WHOS_HERE_MEETINGS + FAILED:
      return addOrUpdateLoadingInState(state, API_WHOS_HERE_MEETINGS, { loading: false });
    case FETCH_AGENDA_CATEGORIES + SUCCESS:
    case FETCH_AGENDA_CATEGORIES + FAILED:
      return addOrUpdateLoadingInState(state, API_AGENDA_CATEGORIES, { loading: false });
    case FETCH_SESSIONS + SUCCESS:
    case FETCH_SESSIONS + FAILED:
      return addOrUpdateLoadingInState(state, API_AGENDA_SESSIONS, { loading: false });
    case FETCH_COUNTRIES + SUCCESS:
    case FETCH_COUNTRIES + FAILED:
      return addOrUpdateLoadingInState(state, API_COUNTRIES, { loading: false });
    case FETCH_BONUSES + SUCCESS:
    case FETCH_BONUSES + FAILED:
      return addOrUpdateLoadingInState(state, API_BONUSES, { loading: false });
    case FETCH_MENU_ITEMS + SUCCESS:
    case FETCH_MENU_ITEMS + FAILED:
      return addOrUpdateLoadingInState(state, API_MENU_ITEMS, { loading: false });
    case FETCH_SURVEY_PAGES + SUCCESS:
    case FETCH_SURVEY_PAGES + FAILED:
      return addOrUpdateLoadingInState(state, API_SURVEY_PAGES, { loading: false });
    case FETCH_TWEETS + SUCCESS:
    case FETCH_TWEETS + FAILED:
      return addOrUpdateLoadingInState(state, API_TWEETS, { loading: false });
    case FETCH_YOUTUBE_VIDEOS + SUCCESS:
    case FETCH_YOUTUBE_VIDEOS + FAILED:
      return addOrUpdateLoadingInState(state, API_YOUTUBE_VIDEOS, { loading: false });
    case FETCH_VOTE_ROUNDS + SUCCESS:
    case FETCH_VOTE_ROUNDS + FAILED:
      return addOrUpdateLoadingInState(state, API_VOTE_ROUNDS, { loading: false });
    case FETCH_EVENTS + SUCCESS:
    case FETCH_EVENTS + FAILED:
      return addOrUpdateLoadingInState(state, API_EVENTS, { loading: false });
    case FETCH_TIMELINE_ITEMS + SUCCESS:
    case FETCH_TIMELINE_ITEMS + FAILED:
      return addOrUpdateLoadingInState(state, API_TIMELINE_ITEMS, { loading: false });
    case FETCH_FAVORITES + SUCCESS:
    case FETCH_FAVORITES + FAILED:
      return addOrUpdateLoadingInState(state, API_FAVORITES, { loading: false });
    default:
      return state;
  }
};
