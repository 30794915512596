import React from 'react';
import styled from 'styled-components';
import HtmlContent from '../../components/shared/HtmlContent';
import { motion } from 'framer-motion';

const ShowMoreContainer = styled(motion.div)`
  position: relative;
  overflow: hidden;
`;

export const ShowMoreBtn = styled.div`
  text-align: center;
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 500;
  color: ${props => props.theme.mainColor};
  padding-top: 8px;
`;

export const Gradient = styled.div`
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

class ShowMore extends React.Component {
  showMoreHeight = 100;

  state = {
    showMore: false,
    needShowMore: false,
    hasCalculateHeight: false
  };

  constructor(props) {
    super(props);
    this.descRef = React.createRef();
  }

  componentDidMount() {
    if (this.descRef.current) {
      const height = this.descRef.current.clientHeight;
      if (height > this.showMoreHeight) {
        this.setState({
          needShowMore: true,
          hasCalculateHeight: true
        });
      } else {
        this.setState({
          needShowMore: false,
          hasCalculateHeight: true
        });
      }
    }
  }

  onClickShowMore = () => {
    this.setState({
      showMore: !this.state.showMore
    });
  };

  render() {
    const { t, children } = this.props;
    return (
      <React.Fragment>
        <ShowMoreContainer
          initial={false}
          animate={
            this.state.hasCalculateHeight
              ? !this.state.needShowMore
                ? 'disabled'
                : this.state.showMore
                ? 'opened'
                : 'closed'
              : 'init'
          }
          variants={{
            init: { height: this.showMoreHeight, overflow: 'hidden', transition: { duration: 0 } },
            disabled: { height: 'auto', overflow: 'visible', transition: { duration: 0 } },
            opened: { height: 'auto', overflow: 'visible' },
            closed: {
              height: this.showMoreHeight,
              overflow: 'hidden',
              transition: { duration: 0.2 }
            }
          }}
        >
          <div ref={this.descRef}>
            <HtmlContent>{children}</HtmlContent>
          </div>
          {!this.state.showMore && this.state.needShowMore && <Gradient></Gradient>}
        </ShowMoreContainer>
        {this.state.needShowMore && (
          <ShowMoreBtn onClick={this.onClickShowMore}>
            {this.state.showMore ? t('show_less') : t('show_more')}
          </ShowMoreBtn>
        )}
      </React.Fragment>
    );
  }
}

export default ShowMore;
