// Type
export const CREATED = '.created';
export const UPDATED = '.updated';
export const DELETED = '.deleted';
export const APPROVED = '.approved';
export const APPROVED_ANIMATOR = '.approved_animator';

// Channels
export const SC_EVENT = 'event';
export const SC_AGENDA = 'agenda';
export const SC_WHOS_HERE = 'whosHere';
export const SC_ATTENDEE = 'attendee';
export const SC_WHOS_HERE_MEETING = 'whosHereMeeting';
export const SC_WHOS_HERE_CONVERSATION = 'whosHereConversation';
export const SC_WHOS_HERE_MESSAGE = 'whosHereConversationMessage';
export const SC_SMART_QA = 'smartQa';
export const SC_SMART_QA_POST = 'smartQaQuestion';
export const SC_SMART_QA_COMMENT = 'comment';
export const SC_BONUS_LIST = 'bonusList';
export const SC_EXHIBITOR_LIST = 'exhibitorList';
export const SC_SPONSOR_LIST = 'sponsorList';
export const SC_SPEAKER_LIST = 'speakerList';

export const SC_TIMELINE = 'timeline';
export const SC_TIMELINE_ITEM = 'timelineItem';
export const SC_SURVEY = 'survey';
export const SC_VOTE = 'vote';
export const SC_PRACTICAL_INFO = 'practicalInfo';
export const SC_MAP = 'map';
export const SC_VOTE_ROUND = 'voteRound';
export const SC_SOCIAL_MEDIA = 'socialMedia';

export const SC_NOTIF = 'private-notif';
export const SC_CLIENT_NOTIF = 'client-notif';
