import { createSelector } from 'reselect';
import { filterObjects, filterIds, hasMore, isLoading } from '../helpers/data';
import { API_EVENTS } from '../constants/apiRoutes';

const currentAppId = (state, props) => props.match.params.appId;
const events = (state, props) => state.events;
const eventsIds = (state, props) => state.sortIds[API_EVENTS] || [];
const currentEvent = (state, props) => state.events[props.eventId];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;

// --- Select current object --------------
export const selectCurrentEvent = createSelector(
  [currentEvent],
  event => {
    return event;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredEvents = createSelector(
  [events, currentAppId],
  (events, currentAppId) => {
    return filterObjects(events, event => {
      return event.group_id === +currentAppId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredEventsIds = createSelector(
  [eventsIds, selectFilteredEvents],
  (eventsIds, events) => {
    return filterIds(eventsIds, events);
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMoreEvents = createSelector(
  [currentAppId, pagination],
  (appId, pagination) => {
    return hasMore(pagination, appId, API_EVENTS);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingEvents = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_EVENTS);
  }
);
// ----------------------------------------
