import React from 'react';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import AnimatorPost from './AnimatorPost';
import produce from 'immer';
import Notification from './Notification';
import styled from 'styled-components';
import Card from '../../components/shared/card/Card';

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

class AnimatorPosts extends React.Component {
  state = {
    highlightedIds: []
  };

  constructor(props) {
    super(props);
    this.statehighlightedIds = this.getHighLightedIds();
  }

  componentDidMount() {
    this.props.fetchSmartqaPosts({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.matchParams.serviceId !== prevProps.matchParams.serviceId) {
      this.props.fetchSmartqaPosts({ reset: true });
    }
  }

  getHighLightedIds = () => {
    let highlightedIds = window.localStorage.getItem('highlightedIds');
    if (highlightedIds) {
      highlightedIds = JSON.parse(highlightedIds);
    } else {
      highlightedIds = [];
    }
    return highlightedIds;
  };

  onClickPost = post => {
    this.setState(
      produce(draft => {
        if (draft.highlightedIds.indexOf(post.id) !== -1) {
          draft.highlightedIds.splice(draft.highlightedIds.indexOf(post.id), 1);
        } else {
          draft.highlightedIds.push(post.id);
        }
        window.localStorage.setItem('highlightedIds', JSON.stringify(draft.highlightedIds));
      })
    );
  };

  render() {
    const {
      t,
      theme,
      posts,
      postsIds,
      fetchSmartqaPosts,
      hasMoreSmartqaPosts,
      isLoadingSmartqaPosts,
      notifsItemsCount,
      componentFixedTopHeight,
      setComponentFixedTopHeight,
      headerHeight,
      postHidePost
    } = this.props;

    const onClickPost = this.onClickPost;
    const highlightedIds = this.state.highlightedIds;

    return (
      <React.Fragment>
        {notifsItemsCount ? (
          <Notification
            {...{
              t,
              theme,
              fetchSmartqaPosts,
              setComponentFixedTopHeight,
              headerHeight,
              notifsItemsCount
            }}
          />
        ) : null}

        <Container componentFixedTopHeight={componentFixedTopHeight}>
          <InfiniteScroll
            loadMore={fetchSmartqaPosts}
            hasMore={hasMoreSmartqaPosts}
            isLoading={isLoadingSmartqaPosts}
          >
            <Card
              margin={'0 0 15px 0'}
              padding={'15px 20px'}
              textAlign={'center'}
              position={'relative'}
              color={theme.darkGrey}
            >
              {t('animator_helper')}
            </Card>

            {postsIds.length ? (
              <React.Fragment>
                {postsIds.map(key => {
                  const post = posts[key];

                  return (
                    <AnimatorPost
                      {...{ t, theme, post, onClickPost, highlightedIds, postHidePost }}
                      key={post.id}
                    />
                  );
                })}
              </React.Fragment>
            ) : (
              <Card margin={'15px 0'} padding={'15px'} textAlign={'center'}>
                {t('animator_no_posts')}
              </Card>
            )}
          </InfiniteScroll>
        </Container>
      </React.Fragment>
    );
  }
}

export default AnimatorPosts;
