import { createSelector } from 'reselect';
import { API_BONUSES } from '../constants/apiRoutes';
import { filterObjects, filterIds, hasMore, isLoading } from '../helpers/data';

const currentBonusListId = (state, props) => props.match.params.bonusListId;
const bonusLists = (state, props) => state.bonusLists;
const bonuses = (state, props) => state.bonuses;
const bonusesIds = (state, props) => state.sortIds[API_BONUSES] || [];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;

// --- Select current object --------------
export const selectCurrentBonusList = createSelector(
  [bonusLists, currentBonusListId],
  (bonusLists, currentBonusListId) => {
    return bonusLists[currentBonusListId] || null;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredBonuses = createSelector(
  [currentBonusListId, bonuses],
  (bonusListId, bonuses) => {
    return filterObjects(bonuses, bonus => {
      return bonus.list_id === +bonusListId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredBonusesIds = createSelector(
  [bonusesIds, selectFilteredBonuses],
  (bonusesIds, bonuses) => {
    return filterIds(bonusesIds, bonuses);
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMoreBonuses = createSelector(
  [currentBonusListId, pagination],
  (listId, pagination) => {
    return hasMore(pagination, listId, API_BONUSES);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingBonuses = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_BONUSES);
  }
);
// ----------------------------------------
