import { getSponsorShortDesc } from '../../helpers/format';
import ListItem from '../../components/shared/ListItem';
import React from 'react';
import ServiceHeader from '../../components/shared/ServiceHeader';
import InfiniteScroll from '../../components/shared/InfiniteScroll';
import Card from '../../components/shared/card/Card';
import { Filler } from '../../components/shared/ListItem';
import { motion, AnimatePresence } from 'framer-motion';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';
class SponsorList extends React.Component {
  componentDidMount() {
    this.props.fetchSponsors({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.sponsorList.id !== prevProps.sponsorList.id) {
      this.props.fetchSponsors({ reset: true });
    }
  }
  render() {
    const {
      t,
      theme,
      sponsorList,
      sponsors,
      sponsorsIds,
      showItemDetails,
      fetchSponsors,
      isLoadingSponsors,
      hasMoreSponsors,
      matchParams,
      global,
      header,
      ...props
    } = this.props;

    return (
      <React.Fragment>
        <AnimatePresence initial={false}>
          {!header.showSearchBar ? (
            <motion.div
              key="sponsor_desc"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0, transition: { duration: 0 } }}
            >
              <ServiceHeader
                t={t}
                icon={getServiceIconByType(sponsorList.custom_icon || 'sponsorlist')}
                name={sponsorList.name}
                description={sponsorList.description}
                theme={theme}
              />
            </motion.div>
          ) : (
            <Filler key="sponsor_desc" />
          )}
        </AnimatePresence>

        {global.searchValue && !sponsorsIds.length ? (
          <Card padding={'15px'} textAlign={'center'}>
            {t('nothing_found')} {global.searchValue}
          </Card>
        ) : (
          <InfiniteScroll
            loadMore={fetchSponsors}
            hasMore={hasMoreSponsors}
            isLoading={isLoadingSponsors}
          >
            {sponsorsIds.map(key => {
              const sponsor = sponsors[key];
              const { id, description, logo_url, company, is_vip } = sponsor;
              const desc = getSponsorShortDesc(description);

              return (
                <ListItem
                  type={'sponsor'}
                  model={sponsor}
                  key={id}
                  id={id}
                  heading={company}
                  desc={desc}
                  logoUrl={logo_url}
                  isVip={is_vip}
                  onClickItem={showItemDetails}
                  matchParams={matchParams}
                  theme={theme}
                  {...props}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </React.Fragment>
    );
  }
}

export default SponsorList;
