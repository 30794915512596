import React from 'react';
import FlexParent from '../../../components/shared/flex/FlexParent';
import ConversationItem from './ConversationItem';
import Button from '../../../components/shared/Button';
import { CONVERSATION_CREATE } from '../../../constants/app';
import Icon from '../../../components/icons/Icon';
import IconPlus from '../../../components/icons/IconPlus';
import InfiniteScroll from '../../../components/shared/InfiniteScroll';
import { isDarkBgColor } from '../../../helpers/helpers';

import { API_WHOS_HERE_CONVERSATIONS } from '../../../constants/apiRoutes';

class ConversationsList extends React.Component {
  componentDidMount() {
    this.props.fetchConversations({ reset: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.whosHere.id !== prevProps.whosHere.id) {
      this.props.fetchConversations({ reset: true });
    }

    if (this.props.notifsConversationsCount !== prevProps.notifsConversationsCount) {
      this.props.resetNotification({
        parent_id: this.props.whosHere.id,
        api_route: API_WHOS_HERE_CONVERSATIONS
      });
    }
  }

  onClickNewConversation = () => {
    this.props.updateView({ view: CONVERSATION_CREATE });
  };

  render() {
    const {
      conversations,
      conversationsIds,
      theme,
      matchParams,
      history,
      t,
      updateView,
      user,
      isLoadingConversations,
      hasMoreConversations,
      fetchConversations,
      deleteConversation,
      event,
      locale,
      notifsMessagesCount
    } = this.props;

    return (
      <React.Fragment>
        <Button
          margin={'3px auto 15px auto'}
          display={'block'}
          btntype={'primary'}
          onClick={this.onClickNewConversation}
        >
          <FlexParent alignItems={'center'}>
            <Icon
              icon={IconPlus}
              color={isDarkBgColor(theme.mainColor) ? '#fff' : '#000'}
              border={`1px solid ${isDarkBgColor(theme.mainColor) ? '#fff' : '#000'}`}
              width={'20px'}
              height={'20px'}
              borderRadius={'50%'}
              padding={'4px'}
              margin={'0 5px 0 0'}
            />
            <div>{t('btn_new_conversation')}</div>
          </FlexParent>
        </Button>

        <InfiniteScroll
          loadMore={fetchConversations}
          hasMore={hasMoreConversations}
          isLoading={isLoadingConversations}
        >
          {conversationsIds.map((key, index) => {
            const conversation = conversations[key];
            return (
              <ConversationItem
                key={conversation.id}
                id={conversation.id}
                unReadMessages={
                  notifsMessagesCount &&
                  notifsMessagesCount.conversations &&
                  notifsMessagesCount.conversations[conversation.id]
                    ? notifsMessagesCount.conversations[conversation.id]
                    : null
                }
                {...{
                  deleteConversation,
                  updateView,
                  conversation,
                  theme,
                  matchParams,
                  history,
                  user,
                  event,
                  t,
                  locale
                }}
              />
            );
          })}
        </InfiniteScroll>
      </React.Fragment>
    );
  }
}

export default ConversationsList;
