import React from 'react';
import styled from 'styled-components';
import Icon from '../../components/icons/Icon';
import IconChevron from '../../components/icons/IconChevron';
import Card from '../../components/shared/card/Card';
import FlexParent from '../../components/shared/flex/FlexParent';
import FlexItem from '../../components/shared/flex/FlexItem';
import HtmlContent from '../../components/shared/HtmlContent';
import { motion, AnimatePresence } from 'framer-motion';

const PartName = styled.div`
  font-size: 1.5rem;
  font-weight: ${props => (props.selected ? '700' : 'initial')};
`;

const PartContent = styled(motion.div)`
  overflow: hidden;
`;

class PracticalInfoPart extends React.Component {
  onClickItem = () => {
    this.props.onClickItem(this.props.part.id);
  };

  render() {
    return (
      <div>
        <Card margin={'0 0 20px'} padding={'10px'}>
          <FlexParent
            justifyContent={'space-between'}
            alignItems={'center'}
            onClick={this.onClickItem}
          >
            <FlexItem grow={1}>
              <PartName selected={this.props.openedId === this.props.part.id}>
                {this.props.part.name}
              </PartName>
            </FlexItem>
            {this.props.part.content ? (
              <FlexItem>
                <motion.div
                  key={`practical_header_${this.props.part.id}`}
                  animate={this.props.openedId === this.props.part.id ? 'opened' : 'closed'}
                  variants={{
                    opened: { rotate: 90 },
                    closed: { rotate: 0 }
                  }}
                >
                  <Icon
                    icon={IconChevron}
                    width={'30px'}
                    height={'30px'}
                    padding={'10px'}
                    color={this.props.theme.mainColor}
                  />
                </motion.div>
              </FlexItem>
            ) : (
              ''
            )}
          </FlexParent>
          <AnimatePresence>
            {this.props.openedId === this.props.part.id ? (
              <PartContent
                key={`practical_content_${this.props.part.id}`}
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
              >
                <HtmlContent>{this.props.part.content}</HtmlContent>
              </PartContent>
            ) : null}
          </AnimatePresence>
        </Card>
      </div>
    );
  }
}

export default PracticalInfoPart;
