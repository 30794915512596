import React from 'react';
import styled from 'styled-components';

const InputStyle = styled.input`
  width: 100%;
  margin: 0;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.dustGrey};
  border-radius: 18px;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);

  :focus {
    outline: none;
  }
`;

const Input = props => {
  return <InputStyle {...props} />;
};

export default Input;
