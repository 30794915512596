import styled from 'styled-components';
import React from 'react';
import FullImage from './FullImage';

export const HtmlContentStyle = styled.div`
  overflow: hidden;
  img {
    max-width: 100%;
  }
  p {
    margin: 0 0 10px 0;
  }
`;

class HtmlContent extends React.Component {
  state = {
    fullScreenPictureUrl: null
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if (this.containerRef.current) {
      [...this.containerRef.current.querySelectorAll('img')].forEach(image => {
        image.addEventListener('click', this.onClickPicture);
      });
    }
  }

  componentDidChange(prevProps) {
    if (this.props.children !== prevProps.children && this.props.children) {
      if (this.containerRef.current) {
        [...this.containerRef.current.querySelectorAll('img')].forEach(image => {
          image.removeEventListener('click', this.onClickPicture);
        });
        [...this.containerRef.current.querySelectorAll('img')].forEach(image => {
          image.addEventListener('click', this.onClickPicture);
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.containerRef.current) {
      [...this.containerRef.current.querySelectorAll('img')].forEach(image => {
        image.removeEventListener('click', this.onClickPicture);
      });
    }
  }

  onClickPicture = event => {
    const imgUrl = event.currentTarget.src;
    this.setState({
      fullScreenPictureUrl: imgUrl
    });
  };

  closeFullScreenPicture = () => {
    this.setState({
      fullScreenPictureUrl: null
    });
  };

  render() {
    return this.props.children ? (
      <div ref={this.containerRef}>
        {this.state.fullScreenPictureUrl ? (
          <FullImage
            url={this.state.fullScreenPictureUrl}
            onClickClose={this.closeFullScreenPicture}
          ></FullImage>
        ) : null}

        <HtmlContentStyle dangerouslySetInnerHTML={{ __html: this.props.children }} />
      </div>
    ) : null;
  }
}

export default HtmlContent;
