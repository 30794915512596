import React from 'react';
import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconAvatar from '../../components/icons/IconAvatar';
import { getTimeFromNow } from '../../helpers/date';
import { nl2br } from '../../helpers/format';
import {
  PostAvatar,
  PostAuthorName,
  PostHour,
  PostMessage,
  PostPicture,
  PostCommentsCount,
  PostReactionContainer
} from '../../components/shared/smartqa/Styled';
import { Reactions } from '../../components/shared/smartqa/Reactions';
import HtmlContent from '../../components/shared/HtmlContent';

const Avatar = props => {
  return props.profile_picture_url ? (
    <PostAvatar src={props.profile_picture_url} />
  ) : (
    <Icon
      icon={IconAvatar}
      color={'#fff'}
      padding={'10px'}
      margin={'0 10px 0 0'}
      width={'50px'}
      height={'50px'}
      borderRadius={'50%'}
      backgroundColor={props.theme.dustGrey}
    />
  );
};

class SmartqaPost extends React.Component {
  onClick = event => {
    this.props.navigateToPostDetails(this.props.post.smart_qa_id, this.props.post.id);
  };

  render() {
    const { post, theme, t } = this.props;
    const { angry, haha, like, love, wow } = post.reactions;

    return (
      <React.Fragment>
        <Card margin={'15px 0'} padding={'15px'} onClick={this.onClick}>
          <FlexParent alignItems={'center'}>
            <FlexItem>
              <Avatar profile_picture_url={post.author.profile_picture_url} theme={theme} />
            </FlexItem>
            <FlexItem grow={1}>
              <PostAuthorName>{post.author.name || t('smartqa_anonymous')}</PostAuthorName>
              <PostHour>{getTimeFromNow(post.created_at)}</PostHour>
            </FlexItem>
          </FlexParent>

          {post.text && (
            <PostMessage>
              <HtmlContent>{nl2br(post.text)}</HtmlContent>
            </PostMessage>
          )}
          {post.picture_url && <PostPicture src={post.picture_url} />}

          <PostReactionContainer alignContent={'center'} alignItems={'center'}>
            <Reactions {...{ angry, haha, like, love, wow }} />
            <FlexItem grow={1}>
              <PostCommentsCount onClick={this.onClickComments}>
                {t('smartqa_comments_count', { count: post.comments_count })}
              </PostCommentsCount>
            </FlexItem>
          </PostReactionContainer>
        </Card>
      </React.Fragment>
    );
  }
}

export default SmartqaPost;
