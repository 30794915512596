import { connect } from 'react-redux';
import { fetchMap, fetchMapPois } from '../../actions/api';
import { updateHeader } from '../../actions/local';
import Map from './Map';
import React from 'react';
import ClosedService from '../../components/shared/ClosedService';
import { selectCurrentMap, selectFilteredPois, selectFilteredPoisIds } from '../../selectors/maps';
import { selectHeader } from '../../selectors/header';
import { selectUser } from '../../selectors/user';
import { selectGlobal } from '../../selectors/global';
import Mixpanel from '../../Mixpanel';
import { getServiceIconByType } from '../../components/icons/ServiceIcons';
import produce from 'immer';

class MapContainer extends React.Component {
  componentDidMount() {
    const { appId, eventId, mapId } = this.props.match.params;
    this.getAPiData(this.props.match.params);
    this.props.updateHeader({
      leftBtn: 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'map',
      viewableId: +mapId,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });
  }

  componentDidUpdate(prevProps) {
    const { mapId } = this.props.match.params;
    const oldMapId = prevProps.match.params.mapId;
    if (mapId !== oldMapId) {
      this.getAPiData(this.props.match.params);
    }

    if (!this.props.global.offline && prevProps.global.offline) {
      this.refreshData();
    }
  }

  getAPiData({ eventId, mapId }) {
    this.fetchMap();
    this.fetchMapPois();
  }

  refreshData = event => {
    this.getAPiData(this.props.match.params);
  };

  fetchMap = () => {
    this.props.fetchMap(this.getSharedApiParams());
  };

  fetchMapPois = () => {
    this.props.fetchMapPois(this.getSharedApiParams());
  };

  getSharedApiParams = () => {
    const { appId, eventId, mapId } = this.props.match.params;
    return {
      app_id: +appId,
      event_id: +eventId,
      map_id: +mapId,
      reset: true
    };
  };

  render() {
    if (!this.props.map) {
      return null;
    }

    const { map, match, theme, t, header, pois, poisIds } = this.props;
    const matchParams = match.params;

    const { height: headerHeight } = header;
    const indoorMaps = [];

    for (const poiId of poisIds) {
      const poi = pois[poiId];
      for (const indoorMap of poi.child_indoor_maps) {
        const newIndoorMap = produce(indoorMap, draftState => {
          draftState.poi = poi;
          return draftState;
        });

        indoorMaps.push(newIndoorMap);
      }
    }

    return map.is_open ? (
      <Map
        {...{
          t,
          map,
          theme,
          headerHeight,
          matchParams,
          indoorMaps,
          pois,
          poisIds
        }}
      />
    ) : !map.is_open ? (
      <ClosedService
        icon={getServiceIconByType(map.custom_icon || 'map')}
        name={map.name}
        theme={theme}
        t={t}
        onRefresh={this.refreshData}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  fetchMap: fetchMap,
  fetchMapPois: fetchMapPois
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    map: selectCurrentMap(state, ownProps),
    pois: selectFilteredPois(state, ownProps),
    poisIds: selectFilteredPoisIds(state, ownProps),
    header: selectHeader(state, ownProps),
    global: selectGlobal(state, ownProps)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
