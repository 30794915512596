import React from 'react';
import styled from 'styled-components';
import FlexItem from '../../../components/shared/flex/FlexItem';
import FlexParent from '../../../components/shared/flex/FlexParent';
import IconOption from '../../../components/icons/IconOption';
import Icon from '../../../components/icons/Icon';

const Container = styled.div`
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  position: fixed;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  left: 0;
  right: 0;
  top: ${props => props.headerHeight - 1 + 'px'};
  z-index: 20;
  padding: 10px;
`;

const ConversationTitleContainer = styled(FlexItem)`
  overflow: hidden;
`;

const ConversationTitle = styled.div`
  font-size: 1.7rem;
  font-weight: 500;
  text-align: center;
  padding: 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FlexIcon = styled(FlexItem)`
  width: 20px;
  min-width: 20px;
`;

class ConversationDetailsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
  }

  render() {
    const { headerHeight, onClickConversation, conversation, theme, user } = this.props;

    let title = conversation.description || null;

    if (title === null) {
      let conversationAttendees = conversation.attendees.filter(attendee => {
        return attendee.id !== user.id;
      });

      if (conversation.owner.id !== user.id) {
        conversationAttendees.push(conversation.owner);
      }

      title = conversationAttendees
        .map(attendee => {
          return attendee.first_name;
        })
        .sort()
        .join(', ');
    }

    return (
      <Container headerHeight={headerHeight} onClick={onClickConversation} ref={this.containerRef}>
        <FlexParent>
          <FlexIcon />
          <ConversationTitleContainer grow={1}>
            <ConversationTitle>{title}</ConversationTitle>
          </ConversationTitleContainer>
          <FlexIcon>
            <Icon
              icon={IconOption}
              width={'20px'}
              height={'20px'}
              padding={'0'}
              color={theme.mainColor}
            />
          </FlexIcon>
        </FlexParent>
      </Container>
    );
  }
}

export default ConversationDetailsHeader;
