import initialState from '../store/initialState';
import { resetObjectInState } from '../helpers/data';
import { SC_CLIENT_NOTIF } from '../constants/socket';
import { RESET_NOTIF } from '../constants/actionLocal';

export default (state = initialState.notifs, { type, payload }) => {
  switch (type) {
    case SC_CLIENT_NOTIF:
      return resetObjectInState(state, payload.data);
    case RESET_NOTIF:
      return resetObjectInState(state, initialState.notifs);
    default:
      return state;
  }
};
