import React from 'react';
import linkifyHtml from 'linkifyjs/html';
import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import IconAvatar from '../../components/icons/IconAvatar';
import IconReport from '../../components/icons/IconReport';
import IconCommentOutline from '../../components/icons/IconCommentOutline';
import { getTimeFromNow } from '../../helpers/date';
import { nl2br } from '../../helpers/format';
import PopupReaction from '../../components/shared/smartqa/PopupReaction';
import {
  PostAvatar,
  PostAuthorName,
  PostHour,
  PostMessage,
  PostPicture,
  PostCommentsCount,
  PostLike,
  PostActionContainer,
  PostReactionContainer
} from '../../components/shared/smartqa/Styled';
import { Reactions, ViewerReaction } from '../../components/shared/smartqa/Reactions';
import HtmlContent from '../../components/shared/HtmlContent';

const Avatar = props => {
  return props.profile_picture_url ? (
    <PostAvatar src={props.profile_picture_url} />
  ) : (
    <Icon
      icon={IconAvatar}
      color={'#fff'}
      padding={'10px'}
      margin={'0 10px 0 0'}
      width={'50px'}
      height={'50px'}
      borderRadius={'50%'}
      backgroundColor={props.theme.dustGrey}
    />
  );
};

class Post extends React.Component {
  state = {
    openReactionModal: false
  };

  onClickLike = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openReactionModal: true });
  };

  onClickUnlike = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openReactionModal: false });
    const type = event.currentTarget.dataset.type;
    this.props.deleteReaction({ post: this.props.post, type });
  };

  onClick = event => {
    if (!event.target.classList.contains('linkified')) {
      this.props.navigateToPostDetails(this.props.post.id);
    }
  };

  onClickReport = event => {
    event.stopPropagation();
    this.props.sendReport({ post: this.props.post });
  };

  onClickPicture = event => {
    event.stopPropagation();
    this.props.showFullScreenPicture({ url: this.props.post.picture_url });
  };

  sendReaction = type => {
    this.props.sendReaction({ post: this.props.post, type });
  };

  closeReactionModal = event => {
    event.preventDefault();
    this.setState({ openReactionModal: false });
  };

  render() {
    const { post, theme, t, smartqa, user, offline } = this.props;
    const { angry, haha, like, love, wow } = post.reactions;
    const { viewer_reaction } = post;
    if (!post.is_displayed_to_attendees) {
      return false;
    }
    return (
      <Card margin={'15px 0'} padding={'15px'} onClick={this.onClick}>
        <FlexParent alignItems={'center'}>
          <FlexItem>
            <Avatar profile_picture_url={post.author.profile_picture_url} theme={theme} />
          </FlexItem>
          <FlexItem grow={1}>
            <PostAuthorName>{post.author.name || t('smartqa_anonymous')}</PostAuthorName>
            <PostHour>{getTimeFromNow(post.created_at)}</PostHour>
          </FlexItem>
        </FlexParent>

        {post.text && (
          <PostMessage>
            <HtmlContent>
              {linkifyHtml(nl2br(post.text), {
                target: '_blank',
                attributes: {
                  rel: 'nofollow'
                }
              })}
            </HtmlContent>
          </PostMessage>
        )}
        {post.picture_url && (
          <PostPicture
            data-src={post.picture_url}
            className="lazyload"
            onClick={this.onClickPicture}
          />
        )}

        <PostReactionContainer alignContent={'center'} alignItems={'center'}>
          {smartqa.is_likable ? <Reactions {...{ angry, haha, like, love, wow }} /> : null}

          <FlexItem grow={1}>
            {smartqa.is_commentable ? (
              <PostCommentsCount>
                {t('smartqa_comments_count', { count: post.comments_count })}
              </PostCommentsCount>
            ) : null}
          </FlexItem>
        </PostReactionContainer>

        {user && smartqa.is_likable && !offline ? (
          <PostActionContainer alignContent={'center'} alignItems={'center'}>
            <FlexItem grow={1}>
              {viewer_reaction ? (
                <ViewerReaction onClick={this.onClickUnlike} reaction={viewer_reaction} t={t} />
              ) : (
                <React.Fragment>
                  <PopupReaction
                    open={this.state.openReactionModal}
                    onClose={this.closeReactionModal}
                    onValidate={this.sendReaction}
                    theme={theme}
                    t={t}
                  />
                  <PostLike onClick={this.onClickLike}>{t('smartqa_like')}</PostLike>
                </React.Fragment>
              )}
            </FlexItem>

            <Icon
              icon={IconReport}
              width={'25px'}
              height={'25px'}
              color={'#b0b0b0'}
              margin={'0 0 0 5px'}
              padding={'2px'}
              onClick={this.onClickReport}
            />
            <Icon
              icon={IconCommentOutline}
              width={'25px'}
              height={'25px'}
              color={'#b0b0b0'}
              margin={'0 0 0 5px'}
              padding={'2px'}
            />
            {/* {
            smartqa.is_shareable ||
              (false && (
                <Icon
                  icon={IconShare}
                  width={'25px'}
                  height={'25px'}
                  color={'#b0b0b0'}
                  padding={'2px'}
                  margin={'0 0 0 5px'}
                  onClick={this.onClickShare}
                />
              ))} */}
          </PostActionContainer>
        ) : null}
      </Card>
    );
  }
}

export default Post;
