import { createSelector } from 'reselect';
import { filterObjects, filterIds, hasMore, isLoading, getNotifs } from '../helpers/data';
import { API_TIMELINE_ITEMS } from '../constants/apiRoutes';

const currentTimelineId = (state, props) => props.match.params.timelineId;
const timelines = (state, props) => state.timelines;
const timelineItems = (state, props) => state.timelineItems;
const timelineItemsIds = (state, props) => state.sortIds[API_TIMELINE_ITEMS] || [];
const pagination = (state, props) => state.pagination;
const loading = (state, props) => state.loading;
const notifications = (state, props) => state.notifications;

// --- Select current object --------------
export const selectCurrentTimeline = createSelector(
  [timelines, currentTimelineId],
  (timelines, currentTimelineId) => {
    return timelines[currentTimelineId] || null;
  }
);
// ----------------------------------------

// --- Select filtered objects ------------
export const selectFilteredTimelineItems = createSelector(
  [currentTimelineId, timelineItems],
  (timelineId, timelineItems) => {
    return filterObjects(timelineItems, timelineItem => {
      return timelineItem.timeline_id === +timelineId;
    });
  }
);
// ----------------------------------------

// --- Select filtered ids in hashmap -----
export const selectFilteredTimelineItemsIds = createSelector(
  [timelineItemsIds, selectFilteredTimelineItems],
  (timelineItemsIds, timelineItems) => {
    return filterIds(timelineItemsIds, timelineItems);
  }
);
// ----------------------------------------

// --- Pagination has more ----------------
export const selectHasMoreTimelineItems = createSelector(
  [currentTimelineId, pagination],
  (listId, pagination) => {
    return hasMore(pagination, listId, API_TIMELINE_ITEMS);
  }
);
// ----------------------------------------

// --- API is loading ---------------------
export const selectIsLoadingTimelineItems = createSelector(
  [loading],
  loading => {
    return isLoading(loading, API_TIMELINE_ITEMS);
  }
);
// ----------------------------------------

// --- Notifications ----------------------
export const selectNotifsItemsCount = createSelector(
  [currentTimelineId, notifications],
  (timelineId, notifications) => {
    let notifs = getNotifs(notifications, timelineId, API_TIMELINE_ITEMS);
    return notifs.length;
  }
);

// ----------------------------------------
