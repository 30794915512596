import { SUCCESS, FETCH_MAP_POIS } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.mapPoi, { type, payload }) => {
  switch (type) {
    case FETCH_MAP_POIS + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data);
    default:
      return state;
  }
};
