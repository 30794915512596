import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import ICU from 'i18next-icu';
import ar from 'i18next-icu/locale-data/ar';
import de from 'i18next-icu/locale-data/de';
import en from 'i18next-icu/locale-data/en';
import es from 'i18next-icu/locale-data/es';
import fr from 'i18next-icu/locale-data/fr';
import it from 'i18next-icu/locale-data/it';
import ja from 'i18next-icu/locale-data/ja';
import nl from 'i18next-icu/locale-data/nl';
import pt from 'i18next-icu/locale-data/pt';
import ru from 'i18next-icu/locale-data/ru';
import sv from 'i18next-icu/locale-data/sv';
import zh from 'i18next-icu/locale-data/zh';
import lb from 'i18next-icu/locale-data/lb';

const icu = new ICU({
  localeData: [ar, de, en, es, fr, it, ja, nl, pt, ru, sv, zh, lb]
});

i18n
  .use(LanguageDetector)
  .use(XHR)
  .use(icu)
  .use(initReactI18next)
  .init({
    i18nFormat: {},
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    backend: {
      loadPath: process.env.PUBLIC_URL + '/lang/{{lng}}.json',
      queryStringParams: { v: new Date().valueOf() },
      crossDomain: true
    },
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: false
    }
  });
export default i18n;
