import React from 'react';
import FlexParent from '../../components/shared/flex/FlexParent';
import FlexItem from '../../components/shared/flex/FlexItem';
import styled from 'styled-components';
import { formatDate } from '../../helpers/date';
import { firstLetter } from '../../helpers/format';
import { isDarkBgColor } from '../../helpers/helpers';
import Scrollable from '../../components/shared/Scrollable';

const Container = styled.div`
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  position: fixed;
  left: 0;
  right: 0;
  top: ${props => props.headerHeight - 1 + 'px'};
  z-index: 20;
`;

const Filer = styled(FlexItem)`
  border-bottom: 3px solid #fff;
  padding: 0;
`;

const Tab = styled(FlexItem)`
  border-bottom: 3px solid ${props => (props.selected ? props.theme.mainColor : '#fff')};
  padding: 0;
  cursor: pointer;
  text-align: center;
`;

const DateName = styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  padding: 2px 30px;
  background-color: ${props => props.theme.mainColor};
  color: ${props => {
    return isDarkBgColor(props.theme.mainColor) ? '#fff' : '#000';
  }};
`;

const DateDay = styled.div`
  font-size: 1.2rem;
  color: ${props => (props.selected ? props.theme.mainColor : props.theme.ashesGrey)};
  padding: 10px 0;
`;

class DatesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.props.setComponentFixedTopHeight(this.containerRef.current.clientHeight);
  }

  onTabClick = event => {
    this.props.setDate(event.currentTarget.dataset.date);
  };

  render() {
    const { headerHeight, agenda, currentDate } = this.props;
    return (
      <Container headerHeight={headerHeight} ref={this.containerRef}>
        <Scrollable>
          <FlexParent alignItems={'center'}>
            <Filer grow={1}>
              <DateName>&nbsp;</DateName>
              <DateDay>&nbsp;</DateDay>
            </Filer>
            {agenda.dates.map(date => {
              return (
                <Tab
                  key={date}
                  onClick={this.onTabClick}
                  data-date={date}
                  selected={currentDate === date}
                >
                  <DateName>{firstLetter(formatDate(date, 'dddd'))}</DateName>
                  <DateDay selected={currentDate === date}>{formatDate(date, 'DD/MM')}</DateDay>
                </Tab>
              );
            })}
            <Filer grow={1}>
              <DateName>&nbsp;</DateName>
              <DateDay>&nbsp;</DateDay>
            </Filer>
          </FlexParent>
        </Scrollable>
      </Container>
    );
  }
}

export default DatesFilter;
