import React from 'react';
import Card from '../../../components/shared/card/Card';
import FormGroup from '../../../components/shared/form/FormGroup';
import Label from '../../../components/shared/form/Label';
import Input from '../../../components/shared/form/Input';
import InputError from '../../../components/shared/form/InputError';
import Button from '../../../components/shared/Button';
import Disclaimer from '../../../components/shared/Disclaimer';

class UserRegister extends React.Component {
  state = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    password_confirmation: ''
  };

  onChangeInput = event => {
    const data = {};
    data[event.target.name] = event.target.value;
    this.setState(data);
  };

  onSubmitForm = event => {
    event.preventDefault();
    this.props.postUser({
      email: this.state.email || null,
      first_name: this.state.first_name || null,
      last_name: this.state.last_name || null,
      password: this.state.password || null,
      password_confirmation: this.state.password_confirmation || null
    });
  };

  onClickSignin = event => {
    this.props.navigateToSignin();
  };

  renderFormGroup(t, key, isRequired) {
    return (
      <FormGroup>
        <Label htmlFor={key}>
          {t(`user_${key}`)}
          {isRequired ? '*' : ''}
        </Label>
        <Input
          id={key}
          name={key}
          type={
            ['password', 'password_confirmation'].indexOf(key) !== -1
              ? 'password'
              : key === 'email'
              ? 'email'
              : 'text'
          }
          placeholder={t(`user_${key}`)}
          value={this.state[key]}
          onChange={this.onChangeInput}
        />
        {this.props.inputErrors[key] ? (
          <InputError>{this.props.inputErrors[key]}</InputError>
        ) : null}
      </FormGroup>
    );
  }

  render() {
    const { t, matchParams, isSaving } = this.props;
    return (
      <Card padding={'20px'} margin={'20px 0'}>
        <form onSubmit={this.onSubmitForm}>
          {this.renderFormGroup(t, 'first_name', true)}
          {this.renderFormGroup(t, 'last_name', true)}
          {this.renderFormGroup(t, 'email', true)}
          {this.renderFormGroup(t, 'password', true)}
          {this.renderFormGroup(t, 'password_confirmation', true)}

          <Button
            type={'submit'}
            btntype={'primary'}
            margin={'3px auto'}
            padding={'12px 0'}
            width={'100%'}
            disabled={
              !this.state.email ||
              !this.state.first_name ||
              !this.state.last_name ||
              !this.state.password ||
              !this.state.password_confirmation ||
              isSaving
            }
          >
            {isSaving ? t('btn_saving') : t('btn_create_account')}
          </Button>

          <Disclaimer label={t('register_cgv')} {...{ t, matchParams }} />

          <Button
            btntype={'linkMain'}
            margin={'3px auto'}
            padding={'12px 0'}
            width={'100%'}
            onClick={this.onClickSignin}
          >
            {t('btn_already_account')}
          </Button>
        </form>
      </Card>
    );
  }
}

export default UserRegister;
