import { createSelector } from 'reselect';

const app = (state, props) => state.app;

export const selectCurrentApp = createSelector(
  [app],
  app => {
    if (!Object.keys(app).length) {
      return null;
    }
    return app;
  }
);
