import React from 'react';
import styled from 'styled-components';
import CardIcon from './card/CardIcon';
import ShowMore from '../../components/shared/ShowMore';

const ServiceName = styled.div`
  text-align: center;
  padding: 40px 10px 10px;
  font-size: 1.8rem;
  font-weight: 500;
`;

const ServiceDescription = styled.div`
  text-align: center;
  font-size: 1.3rem;
`;

class ServiceHeader extends React.Component {
  render() {
    const { icon: ServiceIcon, theme, name, description, t } = this.props;
    return (
      <CardIcon
        icon={ServiceIcon}
        theme={theme}
        logoBorderRadius={'50%'}
        padding={'15px'}
        withShadow={true}
      >
        <ServiceName>{name}</ServiceName>
        {description ? (
          <React.Fragment>
            <ServiceDescription>
              <ShowMore t={t}>{description}</ShowMore>
            </ServiceDescription>
          </React.Fragment>
        ) : null}
      </CardIcon>
    );
  }
}

export default ServiceHeader;
