import { SUCCESS, FETCH_TWEETS } from '../constants/actionApi';
import { addOrUpdateItemsInState } from '../helpers/data';
import initialState from '../store/initialState';

export default (state = initialState.socialMediaTweets, { type, payload }) => {
  switch (type) {
    case FETCH_TWEETS + SUCCESS:
      return addOrUpdateItemsInState(
        state,
        payload.data.statuses,
        true,
        initialState.socialMediaTweets
      );
    default:
      return state;
  }
};
