import React from 'react';
import styled from 'styled-components';
import FormGroup from '../../components/shared/form/FormGroup';
import Switch from '../../components/shared/form/Switch';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled.div`
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.dustGrey};
  position: fixed;
  right: 0;
  left: 0;
  top: ${props => props.headerHeight - 1 + 'px'};
  z-index: 30;
  padding: 15px;
  overflow: hidden;
`;

const FormGroupStyle = styled(FormGroup)`
  &:last-child {
    margin-bottom: 0;
  }
`;

const CategoryCircle = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin: 1px 2px;
`;

const ClearLink = styled.a`
  font-weight: 700;
  color: ${props => props.theme.mainColor};
`;

class CategoriesFilter extends React.Component {
  onChangeSwitch = event => {
    this.props.filterCategories(+event.currentTarget.name, event.currentTarget.checked);
  };

  onClearAll = event => {
    event.preventDefault();
    this.props.filterAllCategories([]);
  };

  hasOneChecked = event => {
    const { categoriesFilters } = this.props;
    return categoriesFilters.length > 0;
  };

  render() {
    const {
      headerHeight,
      categories,
      categoriesFilters,
      categoriesIds,
      isCategoriesFiltersOpen,
      t
    } = this.props;
    return (
      <AnimatePresence>
        {isCategoriesFiltersOpen ? (
          <motion.div
            key={'category_filter'}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            <Container headerHeight={headerHeight}>
              {categoriesFilters.length > 0 && (
                <FormGroupStyle key={'switch_all'}>
                  <ClearLink href={'#'} onClick={this.onClearAll}>
                    {t('clear_all')}
                  </ClearLink>
                </FormGroupStyle>
              )}

              {categoriesIds.map((key, index) => {
                const category = categories[key];
                return (
                  <FormGroupStyle key={category.id}>
                    <Switch
                      name={category.id}
                      checked={categoriesFilters.indexOf(category.id) !== -1}
                      onChange={this.onChangeSwitch}
                      textBefore={true}
                    >
                      <div>
                        <CategoryCircle style={{ backgroundColor: category.color }} />{' '}
                        {category.name}
                      </div>
                    </Switch>
                  </FormGroupStyle>
                );
              })}
            </Container>
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  }
}

export default CategoriesFilter;
