import React from 'react';
import Card from '../../components/shared/card/Card';
import FlexItem from '../../components/shared/flex/FlexItem';
import FlexParent from '../../components/shared/flex/FlexParent';
import Icon from '../../components/icons/Icon';
import HtmlContent from '../../components/shared/HtmlContent';

import IconCloseCross from '../../components/icons/IconCloseCross';

import IconAvatar from '../../components/icons/IconAvatar';
import { getTimeFromNow } from '../../helpers/date';
import { nl2br } from '../../helpers/format';
import {
  PostAvatar,
  PostAuthorName,
  PostHour,
  PostMessage,
  PostPicture
} from '../../components/shared/smartqa/Styled';

const Avatar = props => {
  return props.profile_picture_url ? (
    <PostAvatar src={props.profile_picture_url} />
  ) : (
    <Icon
      icon={IconAvatar}
      color={'#fff'}
      padding={'10px'}
      margin={'0 10px 0 0'}
      width={'50px'}
      height={'50px'}
      borderRadius={'50%'}
      backgroundColor={props.theme.dustGrey}
    />
  );
};

class AnimatorPost extends React.Component {
  onClick = () => {
    const { post } = this.props;
    this.props.onClickPost(post);
  };

  onClickClose = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.postHidePost(this.props.post);
  };

  isHighlighted = () => {
    return this.props.highlightedIds.indexOf(this.props.post.id) !== -1;
  };

  render() {
    const { t, theme, post } = this.props;

    if (!post.is_displayed_to_animators) {
      return false;
    }

    return (
      <Card
        margin={'15px 0'}
        padding={'15px'}
        onClick={this.onClick}
        border={this.isHighlighted() ? '2px solid #4cb54c' : null}
      >
        <Icon
          icon={IconCloseCross}
          position={'absolute'}
          top={'8px'}
          right={'8px'}
          width={'25px'}
          height={'25px'}
          color={theme.dustGrey}
          padding={'3px'}
          onClick={this.onClickClose}
        />
        <FlexParent alignItems={'center'}>
          <FlexItem>
            <Avatar profile_picture_url={post.author.profile_picture_url} theme={theme} />
          </FlexItem>
          <FlexItem grow={1}>
            <PostAuthorName>{post.author.name || t('smartqa_anonymous')}</PostAuthorName>
            <PostHour>{getTimeFromNow(post.created_at)}</PostHour>
          </FlexItem>
        </FlexParent>

        {post.text && (
          <PostMessage>
            <HtmlContent>{nl2br(post.text)}</HtmlContent>
          </PostMessage>
        )}
        {post.picture_url && (
          <PostPicture
            data-src={post.picture_url}
            className="lazyload"
            onClick={this.onClickPicture}
          />
        )}
      </Card>
    );
  }
}

export default AnimatorPost;
