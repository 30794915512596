import React from 'react';
import styled from 'styled-components';
import FlexParent from '../shared/flex/FlexParent';
import FlexItem from '../shared/flex/FlexItem';
import Icon from '../icons/Icon';
import IconAnswerRight from '../icons/IconAnswerRight';
import IconCloseCross from '../../components/icons/IconCloseCross';
import { selectSuccess } from '../../selectors/success';
import { connect } from 'react-redux';
import { updateSuccess } from '../../actions/local';
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled(motion.div)`
  position: fixed;
  z-index: 100;
  top: 0px;
  right: -350px;
  max-width: 300px;
  margin: 10px;
  min-height: 50px;
  color: white;
  border-radius: 8px;
  background: repeating-linear-gradient(135deg, #00cb10, #00cb10 10px, #2fd012 10px, #2fd012 20px);
  padding: 15px;
  text-shadow: 0.075em 0.08em 0.1em rgba(30, 30, 30, 0.8);
  box-shadow: -2px 1px 5px 0px rgba(85, 85, 85, 0.75);
  font-weight: bold;
`;

const Label = styled.div`
  padding-right: 10px;
`;

class SuccessContainer extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      (!prevProps.success.message && this.props.success.message) ||
      (prevProps.success.message &&
        this.props.success.message &&
        prevProps.success.message !== this.props.success.message)
    ) {
      if (this.timeout) {
        this.timeout = clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.props.updateSuccess({});
      }, 8000);
    }
  }

  onClickClose = event => {
    this.props.updateSuccess({});
  };

  render() {
    return (
      <AnimatePresence>
        {this.props.success.message && (
          <Container
            key="success_message"
            initial={{ x: 0 }}
            animate={{ x: -350 }}
            exit={{ x: 0 }}
            onClick={this.onClickClose}
          >
            <FlexParent alignItems={'center'}>
              <Icon
                icon={IconAnswerRight}
                color={'#00cb10'}
                padding={'7px'}
                margin={'0 10px 0 0'}
                width={'40px'}
                height={'40px'}
                borderRadius={'50%'}
                backgroundColor={'#fff'}
              />
              <FlexItem grow={1}>
                <Label>{this.props.success.message}</Label>
              </FlexItem>
              <Icon
                icon={IconCloseCross}
                position={'absolute'}
                top={'3px'}
                right={'3px'}
                width={'20px'}
                height={'20px'}
                color={'#fff'}
                padding={'5px'}
              />
            </FlexParent>
          </Container>
        )}
      </AnimatePresence>
    );
  }
}

const mapDispatchToProps = {
  updateSuccess: updateSuccess
};

const mapStateToProps = (state, ownProps) => {
  return {
    success: selectSuccess(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessContainer);
