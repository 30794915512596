import { withTranslation } from 'react-i18next';
import Button from '../../components/shared/Button';
import Card from '../../components/shared/card/Card';
import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
  color: ${props => props.theme.mainColor};
  font-size: 4.3rem;
  letter-spacing: 0.3px;
  padding-bottom: 15px;
`;
const SubTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Info = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const NotFound = props => (
  <Card margin={'30px 0 20px'} padding={'30px 15px'} textAlign={'center'}>
    <Title>{props.t('error_heading')}</Title>
    <SubTitle>{props.t('error_btn_1')}</SubTitle>
    <Info>
      <p>{props.t('error_type')}</p>
      <p>
        {props.t('error_text_1')} <br />
      </p>
    </Info>
    <Button btntype={'primary'} onClick={props.onClickBackBtn}>
      {props.t('error_btn_1')}
    </Button>
  </Card>
);

export default withTranslation()(NotFound);
