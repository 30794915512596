import React from 'react';
import { connect } from 'react-redux';
import AnimatorServices from './AnimatorServices';
import AnimatorPosts from './AnimatorPosts';
import { updateHeader, updateGlobal } from '../../actions/local';

import { fetchSmartqa, fetchSmartqaPosts, postSmartqaPostHideToAnimator } from '../../actions/api';
import { selectCurrentEvent } from '../../selectors/events';
import { selectUser } from '../../selectors/user';
import { selectAnimatorItems } from '../../selectors/menuItems';
import {
  selectFilteredAnimatorPosts,
  selectFilteredAnimatorPostsIds,
  selectCurrentSmartqa,
  selectIsLoadingPosts,
  selectHasMorePosts,
  selectNotifsPostsCount
} from '../../selectors/smartqa';
import { selectGlobal } from '../../selectors/global';

import Mixpanel from '../../Mixpanel';
import Socket from '../../socket/Socket';

import {
  CREATED,
  UPDATED,
  DELETED,
  APPROVED_ANIMATOR,
  SC_SMART_QA,
  SC_SMART_QA_POST
} from '../../constants/socket';

class AnimatorContainer extends React.Component {
  state = {
    socketIsListening: false
  };

  constructor(props) {
    super(props);
    this.setComponentFixedTopHeight(0);
  }

  componentDidMount() {
    const { appId, eventId, smartqaId } = this.props.match.params;
    this.props.updateHeader({
      leftBtn: window.history.length > 1 ? 'back' : 'nav',
      rightBtn: null,
      rightBtn2: null
    });

    Mixpanel.track('view', {
      viewableType: 'animator-list',
      viewableId: null,
      appId: +appId,
      eventId: +eventId,
      userId: this.props.user ? this.props.user.id : null
    });

    if (smartqaId) {
      this.fetchSmartqa();
    }

    if (this.props.smartqa && this.props.event && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }
  }

  componentDidUpdate(prevProps) {
    const { smartqaId } = this.props.match.params;
    const oldSmartqaId = prevProps.match.params.smartqaId;
    if (smartqaId && smartqaId !== oldSmartqaId) {
      this.fetchSmartqa();
      this.unsubscribeSocketEvents(oldSmartqaId);
    }

    if (this.props.smartqa && this.props.event && !this.state.socketIsListening) {
      this.subscribeToSocketEvents();
    }

    if (!this.props.global.offline && prevProps.global.offline && smartqaId) {
      this.fetchSmartqa();
    }
  }

  componentWillUnmount() {
    const { smartqaId } = this.props.match.params;
    if (smartqaId) {
      this.unsubscribeSocketEvents(smartqaId);
    }
  }

  fetchSmartqa = () => {
    const { appId, eventId, smartqaId } = this.props.match.params;
    this.props.fetchSmartqa({ app_id: +appId, event_id: +eventId, smart_qa_id: +smartqaId });
  };

  fetchSmartqaPosts = data => {
    const { appId, eventId, smartqaId } = this.props.match.params;
    this.props.fetchSmartqaPosts({
      ...data,
      ...{
        app_id: +appId,
        event_id: +eventId,
        smart_qa_id: +smartqaId,
        is_displayed_to_animators: true
      }
    });
  };

  postHidePost = post => {
    const { appId, eventId, smartqaId } = this.props.match.params;
    this.props.postSmartqaPostHideToAnimator({
      app_id: +appId,
      event_id: +eventId,
      smart_qa_id: +smartqaId,
      ids: [post.id]
    });
  };

  subscribeToSocketEvents = () => {
    const { smartqaId } = this.props.match.params;
    this.socket = Socket.subscribeChannel(
      `${SC_SMART_QA}.${smartqaId}`,
      this.props.event.visibility === 'public' ? true : false
    );
    Socket.listenEvents(this.socket, [
      SC_SMART_QA_POST + CREATED,
      SC_SMART_QA_POST + UPDATED,
      SC_SMART_QA_POST + DELETED,
      SC_SMART_QA_POST + APPROVED_ANIMATOR
    ]);

    this.setState({
      socketIsListening: true
    });
  };

  unsubscribeSocketEvents = smartqaId => {
    Socket.unsubscribeChannel(`${SC_SMART_QA}.${smartqaId}`);
    this.setState({
      socketIsListening: false
    });
  };

  setComponentFixedTopHeight = height => {
    this.props.updateGlobal({
      componentFixedTopHeight: height
    });
  };

  render() {
    if (!this.props.user || (this.props.user && !this.props.user.is_animator)) {
      return null;
    }

    const {
      global,
      t,
      theme,
      history,
      user,
      items,
      posts,
      postsIds,
      notifsItemsCount
    } = this.props;
    const { fetchSmartqaPosts, postHidePost, setComponentFixedTopHeight } = this;

    const { componentFixedTopHeight } = global;

    const matchParams = this.props.match.params;
    return matchParams.smartqaId ? (
      <AnimatorPosts
        {...{
          t,
          theme,
          history,
          matchParams,
          user,
          items,
          fetchSmartqaPosts,
          posts,
          postsIds,
          setComponentFixedTopHeight,
          componentFixedTopHeight,
          notifsItemsCount,
          postHidePost
        }}
      ></AnimatorPosts>
    ) : (
      <AnimatorServices {...{ t, theme, history, matchParams, user, items }} />
    );
  }
}

const mapDispatchToProps = {
  updateHeader: updateHeader,
  updateGlobal: updateGlobal,
  fetchSmartqaPosts: fetchSmartqaPosts,
  fetchSmartqa: fetchSmartqa,
  postSmartqaPostHideToAnimator: postSmartqaPostHideToAnimator
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps),
    smartqa: selectCurrentSmartqa(state, ownProps),
    items: selectAnimatorItems(state, ownProps),
    posts: selectFilteredAnimatorPosts(state, ownProps),
    postsIds: selectFilteredAnimatorPostsIds(state, ownProps),
    isLoadingSmartqaPosts: selectIsLoadingPosts(state, ownProps),
    hasMoreSmartqaPosts: selectHasMorePosts(state, ownProps),
    global: selectGlobal(state, ownProps),
    event: selectCurrentEvent(state, { eventId: ownProps.match.params.eventId }),
    notifsItemsCount: selectNotifsPostsCount(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnimatorContainer);
