import store from '../store';

export const stripHtml = text => {
  const regex = /(<([^>]+)>)/gi;
  return text.replace(regex, '');
};

export const cutString = (text, size, ellipsis) => {
  if (text.length > size) {
    text = text.substring(0, size) + ellipsis;
  }
  return text;
};

export const firstLetter = text => {
  return text.substring(0, 1);
};

export const nl2br = string => {
  string = string || '';
  var breakTag = '<br>';
  return (string + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

export const getEventFormatedDate = dates => {
  const { startDay, startMonth, startYear, endDay, endMonth, endYear } = dates;
  const s = store.getState();
  const locale = s.global.locale;
  const fr = locale === 'fr';
  let out = '';

  if (startMonth === endMonth) {
    if (!fr) {
      out += `${startMonth} `;
    }

    if (startDay === endDay) {
      out += `${endDay}${!fr ? ', ' : ''} `;
    } else {
      out += `${startDay}-${endDay}${fr ? '' : ','} `;
    }

    if (fr) {
      out += `${startMonth} `;
    }
  } else {
    if (fr) {
      out += `${startDay} ${startMonth} - ${endDay} ${endMonth}, `;
    } else {
      out += `${startMonth} ${startDay} - ${endMonth} ${endDay}, `;
    }
  }

  if (startYear === endYear) {
    out += `${startYear} `;
  } else {
    out += `${startYear}/${endYear} `;
  }

  return out;
};

export const getExhibitorStandLabel = (t, aisle, floor, standArea, standNumber) => {
  let standLabel = '';

  if (standNumber) {
    standLabel += `${t('stand_number')} ${standNumber}`;
  }

  if (standNumber && standArea) {
    standLabel += `, `;
  }

  if (standArea) {
    standLabel += `${t('stand_area')} ${standArea}`;
  }

  if (standNumber || standArea) {
    standLabel += `; `;
  }

  if (floor) {
    standLabel += `${t('floor')} ${floor}`;
  }

  if (floor && aisle) {
    standLabel += `, `;
  }

  if (aisle) {
    standLabel += `${t('aisle')} ${aisle}`;
  }

  return standLabel;
};

export const getSponsorShortDesc = description => {
  description = description || '';
  description = stripHtml(description);
  description = cutString(description, 80, '...');
  return description;
};

export const getServiceDesc = description => {
  description = description || '';
  description = stripHtml(description);
  description = cutString(description, 55, '...');
  return description;
};

export const getFullName = (firstName, lastName, title) => {
  let fullName = '';
  if (!firstName && !lastName) {
    return fullName;
  } else {
    fullName = `${firstName} ${lastName}`;
    if (title) {
      fullName = `${title} ${fullName}`;
    }
  }
  return fullName;
};

export const getJobWithCompany = (jobTitle, company, country) => {
  let job = '';
  if (jobTitle) {
    job += jobTitle;
  }

  if (jobTitle && company) {
    job += ', ';
  }

  if (company) {
    job += company;
  }

  if (country) {
    job += ` • ${country}`;
  }
  return job;
};

export const getEventIconBySize = (url, size) => {
  if (!url) {
    return '';
  }
  // 32, 57, 72, 144, 180, 192
  return url.replace(/(\.[a-z]{3,4})$/, `_${size}x${size}$1`);
};

export const getEventVenue = ({ city, name }) => {
  let venue = '';
  if (name) {
    venue += name;
  }

  if (name && city) {
    venue += ', ';
  }

  if (city) {
    venue += city;
  }
  return venue;
};
