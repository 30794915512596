import React from 'react';
import styled from 'styled-components';
import FlexParent from '../flex/FlexParent';
import FlexItem from '../flex/FlexItem';
import { motion } from 'framer-motion';

const SwitchStyle = styled.div`
  position: relative;
  display: inline-block;
  width: 44px;
  min-width: 44px;
  height: 24px;
  margin: ${props => (props.textBefore ? '0 0 0 15px' : '0 15px 0 0')};
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${props => props.theme.mainColor};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${props => props.theme.mainColor};
  }

  &:checked + span:before {
    transform: translateX(20px);
  }
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
`;

const SwitchCircle = styled(motion.div)`
  position: absolute;
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
`;

const Switch = props => {
  const { onChange, checked, name, textBefore } = props;
  return (
    <label htmlFor={name}>
      <FlexParent alignItems={'center'}>
        {textBefore ? <FlexItem grow={1}>{props.children}</FlexItem> : null}
        <SwitchStyle textBefore={textBefore}>
          <SwitchInput id={name} type="checkbox" {...{ onChange, checked, name }} />
          <SwitchSlider />
          <SwitchCircle
            initial={false}
            key={`switch_${name}`}
            animate={checked ? 'checked' : 'uncheked'}
            variants={{
              checked: { x: 20 },
              uncheked: { x: 0 }
            }}
          />
        </SwitchStyle>
        {!textBefore ? <FlexItem grow={1}>{props.children}</FlexItem> : null}
      </FlexParent>
    </label>
  );
};

export default Switch;
