import React from 'react';

export default props => (
  <svg viewBox={props.viewBox || '0 0 25 25'} style={{ display: 'block', margin: '0 auto' }}>
    <g fill={props.color}>
      <path d="M19.4,25H3.2C1.4,25,0,23.6,0,21.8V5.6c0-1.7,1.4-3.2,3.2-3.2h11.1v1.8H3.2c-0.7,0-1.3,0.6-1.3,1.3v16.2 c0,0.7,0.6,1.3,1.3,1.3h16.2c0.7,0,1.3-0.6,1.3-1.3V10.7h1.8v11.1C22.5,23.6,21.1,25,19.4,25z" />
      <path d="M24.7,4c0.4-0.4,0.4-1,0-1.4l-2.3-2.3c-0.4-0.4-1-0.4-1.4,0l-1.9,1.9l3.7,3.7L24.7,4z M22.1,7l-4-4L7.4,13.6v4h4L22.1,7z" />
    </g>
  </svg>
);
