import { SUCCESS, FETCH_COUNTRIES } from '../constants/actionApi';
import initialState from '../store/initialState';
import { addOrUpdateItemsInState } from '../helpers/data';

export default (state = initialState.countries, { type, payload }) => {
  switch (type) {
    case FETCH_COUNTRIES + SUCCESS:
      return addOrUpdateItemsInState(state, payload.data, true, initialState.countries);
    default:
      return state;
  }
};
